<template>
    <div class="">
        <button
            class="relative rounded overflow-hidden flex items-center justify-center bg-gradient-to-tl"
            :class="[tierClass[item.type]?.[tier] || itemTierClass[item.item], small ? 'w-10 h-10' : 'w-14 h-14']"
            v-tippy.dropdown="{ html: uniqueId, onShow: opened, onHidden: hide, keepOpen }"
        >
            <img
                :src="`${spaces}/items/${item.type}/${item.item}${(maxTier[item.type] > 0 ? `_${tier}` : '')}.png`"
                :class="small ? 'max-h-[2rem] max-w-[2rem]' : 'max-h-[2.7rem] max-w-[2.7rem]'"
            />

            <div class="absolute bottom-0 right-0 bg-gray-500 rounded-tl px-1 py-px text-xs" v-if="missing">
                {{ abbr(missing, item.item !== 'mora') }}
            </div>

            <div class="absolute bg-gray-700 inset-0 flex items-center justify-center opacity-75" v-if="!missing">
                <check-icon class="w-6 text-white"/>
            </div>
        </button>

        <div :id="uniqueId" class="tippy-seelie min-w-max">
            <div class="flex items-center" :class="mode === 'horizontal' ? 'flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-1' : 'space-x-1'">
                <component
                    :is="`inventory-item-${mode}`"
                    v-for="i in (maxTier[item.type] || 0) + 1"
                    :key="`${item.item}-${i}`"
                    :tier="-1 * i + (maxTier[item.type] || 0) + 1"
                    :type="item.type"
                    :item="item.item"
                    :size="item.item === 'mora' ? 'w-64' : 'sm:w-48'"
                    :is-opened="i == 1 ? isOpened : false"
                    :craft-button="craftButton"
                    :add-input="addInput"
                    :index="i"
                    :length="(maxTier[item.type] || 0) + 1 + (item.type === 'boss' && 3)"
                    buttons
                />
                <template v-if="item.type === 'boss'">
                    <inventory-item-vertical :tier="0" type="boss" :item="bosses[bosses[item.item]].filter(i => i != item.item)[0]" size="sm:w-48" :add-input="addInput" buttons :index="2" :length="4"/>
                    <inventory-item-vertical :tier="0" type="boss" :item="bosses[bosses[item.item]].filter(i => i != item.item)[1]" size="sm:w-48" :add-input="addInput" buttons :index="3" :length="4"/>
                    <inventory-item-vertical :tier="0" type="special" item="dream_solvent" size="sm:w-48" :add-input="addInput" buttons :index="4" :length="4"/>
                </template>
                <template v-if="item.item === 'xp'">
                    <inventory-item-horizontal :tier="0" type="xp" item="xp_sub_1" size="w-full sm:w-28" hide-info/>
                    <inventory-item-horizontal :tier="0" type="xp" item="xp_sub_0" size="w-full sm:w-28" hide-info/>
                </template>
                <template v-if="item.item === 'wep_xp'">
                    <inventory-item-horizontal :tier="0" type="wep_xp" item="wep_xp_sub_1" size="w-full sm:w-28" hide-info/>
                    <inventory-item-horizontal :tier="0" type="wep_xp" item="wep_xp_sub_0" size="w-full sm:w-28" hide-info/>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import InventoryItemVertical from './InventoryItemVertical'
import InventoryItemHorizontal from './InventoryItemHorizontal'

export default {
    components: { InventoryItemVertical, InventoryItemHorizontal },

    name: 'item',

    props: {
        item: Object,
        tier: Number,
        small: { type: Boolean, default: false },
        total: { type: Boolean, default: false },
        addInput: { type: Boolean, default: false },
    },

    data () {
        return {
            modal: false,
            uniqueId: Math.random().toString(36).substr(2, 9),
            isOpened: false
        }
    },

    computed: {
        craftButton () {
            return this.settings.inventory_craft_button
        },

        keepOpen () {
            return this.settings.planner_keep_item_open
        },

        mode () {
            return ['mora', 'xp', 'wep_xp'].includes(this.item.item) ? 'horizontal' : 'vertical'
        },

        missing () {
            if (this.total) return this.getMissing(this.item.type, this.item.item, this.tier)

            return this.maxTier[this.item.type] > 0 ? this.item.need[this.tier] :
                this.item.need / (this.item.item === 'xp' ? 20000 : (this.item.item === 'wep_xp' ? 10000 : 1))
        }
    },

    methods: {
        opened () {
            this.isOpened = true
        },

        hide () {
            this.isOpened = false
        }
    }
}
</script>
