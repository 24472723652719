<template>
    <div class="flex flex-wrap">
        <div class="w-8/12 sm:w-5/12 p-2">
            <label for="name" class="block px-1 mb-1 text-xs font-semibold">{{ $t('name') }}</label>
            <input
                type="text"
                id="name"
                class="w-full bg-gray-700 rounded-md p-2 border-none"
                :class="errorName && 'ring ring-red-500'"
                v-model="custom.name"
                @input="errorName = !custom.name"
                maxlength="20"
            >
        </div>
        <div class="w-4/12 sm:w-2/12 p-2">
            <div class="block px-1 mb-1 text-xs font-semibold">{{ $t('gender') }}</div>
            <div class="flex items-center justify-center rounded-md overflow-hidden text-sm font-semibold h-10">
                <button
                    class="w-1/2 p-2 h-full flex items-center justify-center"
                    :class="custom.gender == 'male' ? 'bg-teal-600/40' : 'bg-gray-700'"
                    @click="custom.gender = 'male'"
                >
                    <male-icon class="h-4"/>
                </button>
                <button
                    class="w-1/2 p-2 h-full flex items-center justify-center"
                    :class="custom.gender == 'female' ? 'bg-teal-600/40' : 'bg-gray-700'"
                    @click="custom.gender = 'female'"
                >
                    <female-icon class="h-5"/>
                </button>
            </div>
        </div>
        <div class="w-full sm:w-5/12 p-2">
            <div class="block px-1 mb-1 text-xs font-semibold">{{ $t('weapon') }}</div>
            <div class="flex items-center justify-center rounded-md overflow-hidden text-sm font-semibold h-10">
                <button
                    class="h-full w-full flex items-center justify-center overflow-hidden"
                    :class="!custom.weapon ? 'bg-teal-600/40' : 'bg-gray-700'"
                    @click="custom.weapon = null"
                >
                    <img :src="`${url}img/other/question-mark.svg`" class="h-6">
                </button>
                <button
                    v-for="weaponType in weaponTypes"
                    :key="`custom-weapon-${weaponType}`"
                    class="h-full w-full flex items-center justify-center overflow-hidden"
                    v-tippy="$t(weaponType)"
                    :class="custom.weapon == weaponType ? 'bg-teal-600/40' : 'bg-gray-700'"
                    @click="custom.weapon = weaponType"
                >
                    <img :src="`${url}img/weapons/__${weaponType}-billet.png`" class="h-8">
                </button>
            </div>
        </div>
        <div class="w-full sm:w-5/12 p-2">
            <div class="block px-1 mb-1 text-xs font-semibold">{{ $t('rarity') }}</div>
            <div class="flex items-center justify-center rounded-md overflow-hidden text-sm font-semibold h-10">
               <button
                    v-for="i in 2"
                    :key="`custom-tier-${i+3}`"
                    class="flex items-center justify-center overflow-hidden w-1/2 h-full"
                    :class="custom.tier == i+3 ? 'bg-teal-600/40' : 'bg-gray-700'"
                    @click="custom.tier = i+3"
                >
                    <star-icon class="w-5 -mr-1" :class="['text-epic-550', 'text-legendary-550'][i-1]" v-for="s in i+3" :key="`star-${i}-${s}`"/>
                </button>
            </div>
        </div>
        <div class="w-full sm:w-7/12 p-2">
            <div class="block px-1 mb-1 text-xs font-semibold">{{ $t('element') }}</div>
            <div class="flex items-center justify-center rounded-md overflow-hidden text-sm font-semibold h-10">
                <button
                    class="h-full w-full flex items-center justify-center overflow-hidden"
                    :class="!custom.element ? 'bg-teal-600/40' : 'bg-gray-700'"
                    @click="changeElement(null)"
                >
                    <img :src="`${url}img/other/question-mark.svg`" class="h-6">
                </button>
                <button
                    v-for="element in elements"
                    :key="`custom-element-${element}`"
                    class="h-full w-full flex items-center justify-center overflow-hidden"
                    :class="custom.element == element ? 'bg-teal-600/40' : 'bg-gray-700'"
                    @click="changeElement(element)"
                >
                    <img :src="`${url}img/elements/${element}.png`" class="h-6">
                </button>
            </div>
        </div>

        <div class="flex justify-between w-full mt-3">
            <div class="flex flex-col items-center justify-center w-full">
                <div class="font-semibold text-[0.6rem] sm:text-xs md:text-sm uppercase mb-1 text-center">{{ $t('element_1') }}</div>
                <div class="bg-gray-700 rounded-full p-2 flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 md:h-14 md:w-14">
                    <img :src="`${spaces}/items/element_1/${custom.element_1}_0.png`" class="max-h-[1.5rem] max-w-[1.5rem] sm:max-h-[2rem] sm:max-w-[2rem]" v-if="custom.element_1"/>
                    <img :src="`${url}img/other/question-mark.svg`" v-else>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center w-full">
                <div class="font-semibold text-[0.6rem] sm:text-xs md:text-sm uppercase mb-1 text-center">{{ $t('element_2') }}</div>
                <button class="bg-gray-700 rounded-full p-2 flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 md:h-14 md:w-14" v-tippy.dropdown="'element_2'">
                    <img :src="`${spaces}/items/element_2/${custom.element_2}.png`" class="max-h-[1.5rem] max-w-[1.5rem] sm:max-h-[2rem] sm:max-w-[2rem]" v-if="custom.element_2"/>
                    <img :src="`${url}img/other/question-mark.svg`" v-else>
                </button>
                <custom-goal-items type="element_2" v-model:selected="custom.element_2"/>
            </div>

            <div class="flex flex-col items-center justify-center w-full">
                <div class="font-semibold text-[0.6rem] sm:text-xs md:text-sm uppercase mb-1 text-center">{{ $t('local') }}</div>
                <button class="bg-gray-700 rounded-full p-2 flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 md:h-14 md:w-14" v-tippy.dropdown="'local'">
                    <img :src="`${spaces}/items/local/${custom.local}.png`" class="max-h-[1.5rem] max-w-[1.5rem] sm:max-h-[2rem] sm:max-w-[2rem]" v-if="custom.local"/>
                    <img :src="`${url}img/other/question-mark.svg`" v-else>
                </button>
                <custom-goal-items type="local" v-model:selected="custom.local"/>
            </div>

            <div class="flex flex-col items-center justify-center w-full">
                <div class="font-semibold text-[0.6rem] sm:text-xs md:text-sm uppercase mb-1 text-center">{{ $t('common') }}</div>
                <button class="bg-gray-700 rounded-full p-2 flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 md:h-14 md:w-14" v-tippy.dropdown="'common'">
                    <img :src="`${spaces}/items/common/${custom.common}_0.png`" class="max-h-[1.5rem] max-w-[1.5rem] sm:max-h-[2rem] sm:max-w-[2rem]" v-if="custom.common"/>
                    <img :src="`${url}img/other/question-mark.svg`" v-else>
                </button>
                <custom-goal-items type="common" v-model:selected="custom.common"/>
            </div>

            <div class="flex flex-col items-center justify-center w-full">
                <div class="font-semibold text-[0.6rem] sm:text-xs md:text-sm uppercase mb-1 text-center">{{ $t('talent') }}</div>
                <button class="bg-gray-700 rounded-full p-2 flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 md:h-14 md:w-14" v-tippy.dropdown="'talent'">
                    <img :src="`${spaces}/items/talent/${custom.talent}_0.png`" class="max-h-[1.5rem] max-w-[1.5rem] sm:max-h-[2rem] sm:max-w-[2rem]" v-if="custom.talent"/>
                    <img :src="`${url}img/other/question-mark.svg`" v-else>
                </button>
                <custom-goal-items type="talent" v-model:selected="custom.talent"/>
            </div>

            <div class="flex flex-col items-center justify-center w-full">
                <div class="font-semibold text-[0.6rem] sm:text-xs md:text-sm uppercase mb-1 text-center">{{ $t('boss') }}</div>
                <button class="bg-gray-700 rounded-full p-2 flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 md:h-14 md:w-14" v-tippy.dropdown="'boss'">
                    <img :src="`${spaces}/items/boss/${custom.boss}.png`" class="max-h-[1.5rem] max-w-[1.5rem] sm:max-h-[2rem] sm:max-w-[2rem]" v-if="custom.boss"/>
                    <img :src="`${url}img/other/question-mark.svg`" v-else>
                </button>
                <custom-goal-items type="boss" v-model:selected="custom.boss"/>
            </div>
        </div>

        <div class="flex items-center justify-end w-full mt-6">
            <button class="bg-red-800/70 shadow rounded-md ml-1 px-4 py-2 font-semibold text-sm" @click="cancel" v-if="!added">{{ $t('cancel') }}</button>
            <button class="bg-red-800/70 shadow rounded-md ml-1 px-4 py-2 font-semibold text-sm" @click="remove" v-else>{{ $t('delete') }}</button>

            <button
                class="bg-yellow-600/70 shadow rounded-md ml-1 px-4 py-2 font-semibold text-sm disabled:pointer-events-none disabled:opacity-50"
                @click="$emit('update:replacing', selected)"
                v-if="added"
                :disabled="!hasGoal"
            >{{ $t('replace') }}</button>

            <button class="bg-green-700/80 shadow rounded-md ml-1 px-4 py-2 font-semibold text-sm" @click="save">{{ $t('save') }}</button>
        </div>

        <div class="flex justify-end w-full text-xs font-semibold text-yellow-400 mr-1 mt-1" v-if="added && !hasGoal">
            {{ $t('add_goal_before_replacing') }}
        </div>

        <div class="bg-gray-600 opacity-25 w-full h-1 my-4 mx-1 rounded-full" v-if="added"/>
    </div>
</template>

<script>
import CustomGoalItems from './CustomGoalItems.vue'

export default {
    emits: ['update:selected', 'update:editing', 'update:replacing'],

    components: { CustomGoalItems },

    props: {
        selected: String,
        editing: Boolean
    },

    data () {
        return {
            errorName: false,
            custom: {
                type: 'character',
                tier: 4,
                gender: 'male',
                element: null,
                name: null,
                weapon: null,
                element_1: null,
                element_2: null,
                local: null,
                common: null,
                talent: null,
                boss: null
            }
        }
    },

    computed: {
        added () {
            return this.customs[this.selected]
        },

        hasGoal () {
            return !!this.goals.find(g => g.character === this.selected)
        },
    },

    mounted () {
        if (this.added) this.custom = {
            type: 'character',
            tier: this.added.tier,
            gender: this.added.gender,
            element: this.added.element,
            name: this.added.name,
            weapon: this.added.weapon,
            element_1: this.added.element_1,
            element_2: this.added.element_2,
            local: this.added.local,
            common: this.added.common,
            talent: this.added.talent,
            boss: this.added.boss
        }
    },

    methods: {
        changeElement (element) {
            this.custom.element = element
            this.custom.element_1 = {
                pyro: 'agnidus_agate',
                hydro: 'varunada_lazurite',
                electro: 'vajrada_amethyst',
                cryo: 'shivada_jade',
                anemo: 'vayuda_turqoise',
                geo: 'prithiva_topaz',
                dendro: 'nagadus_emerald',
            }[element]
        },

        cancel () {
            this.$emit('update:selected', null)
        },

        remove () {
            if (!this.added || this.selected == 'custom') return
            this.setCustom(this.selected, null)
            this.$emit('update:selected', null)
            this.$emit('update:editing', false)
        },

        save () {
            if (!this.custom.name || !this.custom.name.length) {
                this.errorName = true
                return
            }

            let id = (this.added && this.selected) || `custom-${Math.random().toString(36).substr(2, 6)}`

            this.setCustom(id, {
                custom: 'character',
                tier: this.custom.tier,
                element_1: this.custom.element_1,
                element_2: this.custom.element_2,
                local: this.custom.local,
                common: this.custom.common,
                talent: this.custom.talent,
                boss: this.custom.boss,
                weapon: this.custom.weapon,
                element: this.custom.element,
                name: this.custom.name,
                gender: this.custom.gender
            })
            this.$emit('update:selected', id)
            this.$emit('update:editing', false)
        }
    }
}
</script>
