<template>
    <div class="mt-6">
        <div v-if="current.length || isLoading">
            <p class="ml-1 uppercase font-semibold flex items-center">
                {{ $t('current_events') }}

                <svg v-if="isLoading" class="animate-spin ml-2 h-4 w-4 my-px text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </p>

            <div class="mt-2 grid sm:grid-cols-2 gap-3 items-start" v-if="current.length">
                <event-line v-for="(event, i) in current" :event="event" :started="true" :key="`current-${i}`"/>
            </div>
        </div>

        <div v-if="upcoming.length" :class="current.length && 'mt-6'">
            <p class="ml-1 uppercase font-semibold">{{ $t('upcoming_events') }}</p>

            <div class="mt-2 grid sm:grid-cols-2 gap-3 items-start">
                <event-line v-for="(event, i) in upcoming" :event="event" :started="false" :key="`upcoming-${i}`"/>
            </div>
        </div>
    </div>
</template>

<script>
import database from '../mixins/database.js'
import EventLine from './EventLine.vue'

export default {
    mixins: [database],

    components: { EventLine },

    data () {
        return {
            isLoading: true,
            events: []
        }
    },

    computed: {
        filteredEvents () {
            return this.events?.map(e => ({
                ...e,
                local: e.start?.length != 10,
                start: e.start && this.dayjs.utc(e.start?.length == 10 ? `${e.start} 02:30` : e.start),
                end: e.end && this.dayjs.utc(e.end)
            }))
            .filter(e => !e.start || (this.serverTime.diff(e.end, 'minute') <= 0))
        },

        upcoming () {
            return this.filteredEvents.filter(e => (e.local ? this.serverTime : this.time).diff(e.start) < 0 || !e.start)
                .sort((a, b) => {
                    if (!a.start) return 1
                    if (!b.start) return -1

                    if (a.start.unix() == b.start.unix()) return 0

                    return a.start.unix() > b.start.unix() ? 1 : -1
                })
        },

        current () {
            return this.filteredEvents.filter(e => (e.local ? this.serverTime : this.time).diff(e.start) >= 0)
                .sort((a, b) => {
                    if (a.end.unix() == b.end.unix()) return 0

                    return a.end.unix() > b.end.unix() ? 1 : -1
                })
        }
    },

    async created () {
        let oldEvents = localStorage.getItem('events')

        this.events = oldEvents == 'undefined' ? [] : (JSON.parse(oldEvents) || [])

        this.events = await this.get('events')

        // console.log(this.serverTime)
        // console.log(this.time)

        // this.events.push({
        //     id: 99999, hoyo: null, start: null, end: null,
        //     characters: ['wanderer', 'faruzan'],
        //     weapons: ['tulaytullahs_remembrance', 'toukabou_shigure'],
        //     img: 'https://i.imgur.com/TyA89Ii.png',
        //     img_pt: '', name_en: 'Test Event', name_pt: 'Evento de Teste', type: 'wish' // wish, normal, main
        // })

        if (oldEvents != JSON.stringify(this.events)) {
            localStorage.setItem('events', JSON.stringify(this.events))
        }

        this.isLoading = false
    }
}
</script>
