export default {
	"character": [
		{level: 1, asc: 0, 'xp': 0, 'mora': 0, 'element_1': [0, 0], 'element_2': 0, 'local': 0, 'common': [0, 0]},  			  	// lvl 1,  asc 0
		{level: 20, asc: 0, 'xp': 120175, 'mora': 0, 'element_1': [0, 0], 'element_2': 0, 'local': 0, 'common': [0, 0]},  		 	// lvl 20, asc 0
		{level: 40, asc: 1, 'xp': 578325, 'mora': 20000, 'element_1': [1, 0], 'element_2': 0, 'local': 3, 'common': [3, 0]},       	// lvl 40, asc 1
		{level: 50, asc: 2, 'xp': 579100, 'mora': 40000, 'element_1': [3, 1], 'element_2': 2, 'local': 10, 'common': [15, 0]},     	// lvl 50, asc 2
		{level: 60, asc: 3, 'xp': 854125, 'mora': 60000, 'element_1': [6, 1], 'element_2': 4, 'local': 20, 'common': [12, 1]},     	// lvl 60, asc 3
		{level: 70, asc: 4, 'xp': 1195925, 'mora': 80000, 'element_1': [3, 2], 'element_2': 8, 'local': 30, 'common': [18, 1]},    	// lvl 70, asc 4
		{level: 80, asc: 5, 'xp': 1611875, 'mora': 100000, 'element_1': [6, 2], 'element_2': 12, 'local': 45, 'common': [12, 2]},  	// lvl 80, asc 5
		{level: 90, asc: 6, 'xp': 3423125, 'mora': 120000, 'element_1': [6, 3], 'element_2': 20, 'local': 60, 'common': [24, 2]},  	// lvl 90, asc 6
	],
	"talent": [
		{level: 1, 'mora': 0, 'talent': [0, 0], 'common': [0, 0], 'boss': 0, 'asc': 0},
		{level: 2, 'mora': 12500, 'talent': [3, 0], 'common': [6, 0], 'boss': 0, 'asc': 2, 'crown': 0},
		{level: 3, 'mora': 17500, 'talent': [2, 1], 'common': [3, 1], 'boss': 0, 'asc': 3, 'crown': 0},
		{level: 4, 'mora': 25000, 'talent': [4, 1], 'common': [4, 1], 'boss': 0, 'asc': 3, 'crown': 0},
		{level: 5, 'mora': 30000, 'talent': [6, 1], 'common': [6, 1], 'boss': 0, 'asc': 4, 'crown': 0},
		{level: 6, 'mora': 37500, 'talent': [9, 1], 'common': [9, 1], 'boss': 0, 'asc': 4, 'crown': 0},
		{level: 7, 'mora': 120000, 'talent': [4, 2], 'common': [4, 2], 'boss': 1, 'asc': 5, 'crown': 0},
		{level: 8, 'mora': 260000, 'talent': [6, 2], 'common': [6, 2], 'boss': 1, 'asc': 5, 'crown': 0},
		{level: 9, 'mora': 450000, 'talent': [12, 2], 'common': [9, 2], 'boss': 2, 'asc': 6, 'crown': 0},
		{level: 10, 'mora': 700000, 'talent': [16, 2], 'common': [12, 2], 'boss': 2, 'asc': 6, 'crown': 1},
	],
	"3_weapon": [
		{level: 1, asc: 0, 'xp': 0, 'mora': 0, 'wam': [0, 0], 'common_rare': [0, 0], 'common': [0, 0]},
		{level: 20, asc: 0, 'xp': 53475, 'mora': 0, 'wam': [0, 0], 'common_rare': [0, 0], 'common': [0, 0]},
		{level: 40, asc: 1, 'xp': 274000, 'mora': 5000, 'wam': [2, 0], 'common_rare': [2, 0], 'common': [1, 0]},
		{level: 50, asc: 2, 'xp': 276350, 'mora': 10000, 'wam': [2, 1], 'common_rare': [8, 0], 'common': [5, 0]},
		{level: 60, asc: 3, 'xp': 408150, 'mora': 15000, 'wam': [4, 1], 'common_rare': [4, 1], 'common': [4, 1]},
		{level: 70, asc: 4, 'xp': 571625, 'mora': 20000, 'wam': [2, 2], 'common_rare': [8, 1], 'common': [6, 1]},
		{level: 80, asc: 5, 'xp': 770125, 'mora': 25000, 'wam': [4, 2], 'common_rare': [6, 2], 'common': [4, 2]},
		{level: 90, asc: 6, 'xp': 1634475, 'mora': 30000, 'wam': [3, 3], 'common_rare': [12, 2], 'common': [8, 2]},
	],
	"4_weapon": [
		{level: 1, asc: 0, 'xp': 0, 'mora': 0, 'wam': [0, 0], 'common_rare': [0, 0], 'common': [0, 0]},
		{level: 20, asc: 0, 'xp': 81000, 'mora': 0, 'wam': [0, 0], 'common_rare': [0, 0], 'common': [0, 0]},
		{level: 40, asc: 1, 'xp': 415125, 'mora': 5000, 'wam': [3, 0], 'common_rare': [3, 0], 'common': [2, 0]},
		{level: 50, asc: 2, 'xp': 418725, 'mora': 15000, 'wam': [3, 1], 'common_rare': [12, 0], 'common': [8, 0]},
		{level: 60, asc: 3, 'xp': 618400, 'mora': 20000, 'wam': [6, 1], 'common_rare': [6, 1], 'common': [6, 1]},
		{level: 70, asc: 4, 'xp': 866050, 'mora': 30000, 'wam': [3, 2], 'common_rare': [12, 1], 'common': [9, 1]},
		{level: 80, asc: 5, 'xp': 1166875, 'mora': 35000, 'wam': [6, 2], 'common_rare': [9, 2], 'common': [6, 2]},
		{level: 90, asc: 6, 'xp': 2476475, 'mora': 45000, 'wam': [4, 3], 'common_rare': [18, 2], 'common': [12, 2]},
	],
	"5_weapon": [
		{level: 1, asc: 0, 'xp': 0, 'mora': 0, 'wam': [0, 0], 'common_rare': [0, 0], 'common': [0, 0]},
		{level: 20, asc: 0, 'xp': 121550, 'mora': 0, 'wam': [0, 0], 'common_rare': [0, 0], 'common': [0, 0]},
		{level: 40, asc: 1, 'xp': 622800, 'mora': 10000, 'wam': [5, 0], 'common_rare': [5, 0], 'common': [3, 0]},
		{level: 50, asc: 2, 'xp': 628150, 'mora': 20000, 'wam': [5, 1], 'common_rare': [18, 0], 'common': [12, 0]},
		{level: 60, asc: 3, 'xp': 927675, 'mora': 30000, 'wam': [9, 1], 'common_rare': [9, 1], 'common': [9, 1]},
		{level: 70, asc: 4, 'xp': 1299125, 'mora': 45000, 'wam': [5, 2], 'common_rare': [18, 1], 'common': [14, 1]},
		{level: 80, asc: 5, 'xp': 1750375, 'mora': 55000, 'wam': [9, 2], 'common_rare': [14, 2], 'common': [9, 2]},
		{level: 90, asc: 6, 'xp': 3714775, 'mora': 65000, 'wam': [6, 3], 'common_rare': [27, 2], 'common': [18, 2]},
	]
}
