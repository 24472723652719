<template>
    <div>
        <div class="flex justify-between items-center text-sm mt-1">
            <button class="w-full flex items-center justify-center uppercase font-semibold h-8 rounded focus:outline-none focus:ring mx-1"
                :class="[characterGoal ? 'text-blue-500' : 'opacity-50', mode === 'character' ? 'bg-gray-900' : 'hover:bg-gray-700']"
                @click="mode = 'character'"
            >
                <img :src="$url('general/wish.png')" class="w-5 sm:mr-2"/>
                <div class="hidden sm:block">{{ $t('level') }}</div>
            </button>
            <button class="w-full flex items-center justify-center uppercase font-semibold h-8 rounded focus:outline-none focus:ring mx-1"
                :class="[anyTalentGoal ? 'text-purple-400' : 'opacity-50', mode === 'talent' ? 'bg-gray-900' : 'hover:bg-gray-700']"
                @click="mode = 'talent'"
            >
                <img :src="`${url}img/general/talent.png`" class="w-5 sm:mr-2"/>
                <div class="hidden sm:block">{{ $t('talents') }}</div>
            </button>
            <button class="w-full flex items-center justify-center uppercase font-semibold h-8 rounded focus:outline-none focus:ring mx-1"
                :class="[weaponGoal ? 'text-pink-500' : 'opacity-50', mode === 'weapon' ? 'bg-gray-900' : 'hover:bg-gray-700']"
                @click="mode = 'weapon'"
            >
                <img :src="`${url}img/general/weapons.png`" class="w-5 sm:mr-2"/>
                <div class="hidden sm:block">{{ $t('weapon') }}</div>
            </button>
            <button class="w-full flex items-center justify-center uppercase font-semibold h-8 rounded focus:outline-none focus:ring mx-1"
                :class="[artifactGoal ? 'text-orange-500' : 'opacity-50', mode === 'artifact' ? 'bg-gray-900' : 'hover:bg-gray-700']"
                @click="mode = 'artifact'"
            >
                <img :src="`${url}img/general/artifact.png`" class="w-5 sm:mr-2"/>
                <div class="hidden sm:block">{{ $t('artifacts') }}</div>
            </button>
        </div>

        <!-- <div class="bg-orange-700 rounded py-3 px-2 mt-4 leading-none tracking-tight font-semibold flex items-center" v-if="character && character.release && ['character', 'talent'].includes(mode) && isFuture(character.release)">
            <exclamation-circle-icon class="w-6 mr-2 flex-shrink-0"/>
            {{ $t('unreleased_character_exp') }}
        </div> -->

        <template v-if="mode === 'character'">
            <div class="mt-4 text-center uppercase text-sm font-semibold py-1 px-2 rounded text-blue-500 bg-gray-700">{{ $t('level') }}</div>
            <goals-modal-character :selected="selected"/>
        </template>

        <template v-else-if="mode === 'talent'">
            <div class="mt-4 uppercase text-center text-sm font-semibold py-1 px-2 rounded bg-gray-700 text-purple-400">{{ $t('talents') }}</div>
            <goals-modal-talent :selected="selected"/>
        </template>

        <template v-else-if="mode === 'weapon'">
            <div class="mt-4 uppercase text-center text-sm font-semibold py-1 px-2 rounded bg-gray-700 text-pink-500" ref="weapons">{{ $t('weapon') }}</div>
            <goals-modal-weapon :selected-character="selected"/>
        </template>

        <template v-else-if="mode === 'artifact'">
            <div class="mt-4 uppercase text-center text-sm font-semibold py-1 px-2 rounded bg-gray-700 text-orange-500" ref="weapons">{{ $t('artifacts') }}</div>
            <goals-modal-artifact :selected="selected"/>
        </template>
    </div>
</template>

<script>
import GoalsModalCharacter from './GoalsModalCharacter'
import GoalsModalTalent from './GoalsModalTalent'
import GoalsModalWeapon from './GoalsModalWeapon'
import GoalsModalArtifact from './GoalsModalArtifact'

export default {
    emits: ['update:selected'],

    components: {
        GoalsModalCharacter, GoalsModalTalent, GoalsModalWeapon, GoalsModalArtifact
    },

    data () {
        return {
            mode: 'character',
            selectingWeapon: false
        }
    },

    props: {
        selected: String
    },

    computed: {
        character () {
            return this.characters[this.selected]
        },

        characterGoal () {
            let goal = this.goals.find(g => g.character === this.selected && g.type === 'character')
            return goal && JSON.stringify(goal.current) !== JSON.stringify(goal.goal)
        },

        talentGoals () {
            return this.goals.filter(g => g.character === this.selected && g.type === 'talent') || []
        },

        anyTalentGoal () {
            return this.talentGoals.filter(tg => {
                return tg.normal.goal > tg.normal.current || tg.skill.goal > tg.skill.current || tg.burst.goal > tg.burst.current
            }).length
        },

        weaponGoal () {
            let goal = this.goals.find(g => g.character === this.selected && g.type === 'weapon')
            return goal && JSON.stringify(goal.current) !== JSON.stringify(goal.goal)
        },

        artifactGoal () {
            let goal = this.goals.find(g => g.character === this.selected && g.type === 'artifact')
            if (!goal) return false

            if ((goal?.sands && !goal.done?.sands) || (goal.goblet && !goal.done?.goblet) || (goal.circlet && !goal.done?.circlet)) return true
            if (goal.artifacts && goal.artifacts.length && goal.artifacts.filter(a => !goal.done?.[a]).length) return true
            return false
        },
    },

    methods: {
        remove () {
            this.removeGoals(this.selected)
            this.$emit('update:selected', null)
        },

        selectWeapon () {
            this.selectingWeapon = !this.selectingWeapon
            if (this.selectingWeapon) this.$nextTick(() => this.$refs.weapons.scrollIntoView())
        }
    }
}
</script>
