<template>
    <button @click="click" @contextmenu.prevent="context" :class="wFull && 'w-full'">
        <slot name="trigger"></slot>
    </button>

    <Dialog
        :draggable="false"
        :closable="selected != 'custom' && !preventClosing"
        dismissable-mask
        :close-on-escape="selected != 'custom'"
        position="top"
        v-model:visible="isVisible"
        modal
        style="width: 600px;"
        @hide="hide" @show="show"
        :show-header="false"
    >
        <div v-if="selected" class="flex items-center -mx-6 bg-cover bg-left bg-no-repeat px-6 py-2 mb-3 shadow border-b border-gray-600 relative" :style="selected == 'custom' || character.custom ? '' : `background-image:url(${spaces}/characters/bg/${character?.traveler ? 'traveler' : selected}.png)`">
            <div class="absolute inset-0 bg-black/40 z-0"></div>
            <div class="flex items-center justify-center z-10 mr-2">
                <button @click="selected = null" class="flex items-center bg-gray-700 rounded-full p-2 mr-1"><chevron-left-icon class="w-4"/></button>

                <div class="w-6 sm:w-14 h-6 sm:h-14 rounded-full overflow-hidden relative bg-gradient-to-tl mr-2 flex-shrink-0"
                    :class="darkTierClass[character?.tier || 0]"
                >
                    <img :src="`${spaces}/characters/select/${character?.traveler ? `traveler_${gender}` : selected}.png`" v-if="character && !character.custom">
                    <img :src="`${url}img/other/custom_${character?.gender || 'male'}.png`" v-else>

                    <img :src="`${url}img/elements/${character.element}.png`" class="absolute bottom-0 right-0 w-4 h-4" v-if="character?.element">
                </div>
                <p class="text-sm sm:text-lg uppercase text-center font-semibold tracking-tight">
                    {{ character?.traveler ? `${$t(selected)} (${$t(character.element)})` : (character?.name || $t(selected)) }}
                </p>

                <button class="ml-2 sm:ml-3" @click="remove" v-if="character && !character.custom"><trash-icon class="w-5"/></button>
                <button class="ml-2 sm:ml-3" @click="editingCustom = !editingCustom" v-if="character?.custom"><pencil-alt-icon class="w-5"/></button>
            </div>

            <div class="ml-auto flex items-center z-10">
                <input
                    type="checkbox"
                    class="rounded w-5 h-5"
                    v-tippy="$t('active')"
                    :checked="!inactive[selected]"
                    @input="setInactive(selected, !inactive[selected])"
                    v-if="character"
                >
            </div>

            <button class="ml-3 z-10" v-if="selected !== 'custom'" @click="characterModal = false">
                <x-icon class="w-6 h-6"/>
            </button>
        </div>

        <div v-else class="h-6">
            <button class="absolute right-0 p-3" @click="characterModal = false">
                <x-icon class="w-6 h-6"/>
            </button>
        </div>

        <div v-if="character" class="mt-1">
            <div v-if="!replacingCustom" class="flex items-start mb-2">
                <Textarea
                    class="w-full !text-sm"
                    :placeholder="$t('notes')"
                    :model-value="notes[selected]"
                    @input="updateNotes"
                    auto-resize
                    rows="1"
                />

                <button @click="e => $refs.characterCons.toggle(e)" class="ml-2 bg-gray-500 rounded-full py-2 flex justify-center w-12">
                    <span class="font-semibold text-sm">C{{ characterCons||0 }}</span>
                </button>

                <OverlayPanel ref="characterCons" position="left" appendTo="body" style="width: 80px">
                    <div class="-m-1 flex items-center justify-center flex-wrap gap-1">
                        <button v-for="i in 7"
                            :key="`cons-${i}`"
                            class="rounded-full w-12 font-semibold"
                            :class="(characterCons == i-1 || !characterCons && i == 1) ? 'bg-blue-500' : 'bg-gray-600 hover:bg-gray-500'"
                            @click="selectCons(i-1)"
                        >
                            C{{ i-1 }}
                        </button>
                    </div>
                </OverlayPanel>
            </div>

            <div class="mb-2 bg-teal-600 rounded py-3 px-2 mt-4 leading-none tracking-tight font-semibold flex items-start" v-else>
                <exclamation-circle-icon class="w-6 mr-2 flex-shrink-0"/>
                <div class="flex flex-col items-start">
                    {{ $t('select_to_replace') }}
                    <button class="mt-2 bg-yellow-600 py-1 px-2 rounded shadow font-semibold" @click="replacingCustom = null">{{ $t('cancel') }}</button>
                </div>
            </div>
        </div>

        <edit-custom-character
            v-if="!replacingCustom && (editingCustom || selected == 'custom')"
            class="mt-4"
            v-model:selected="selected"
            v-model:editing="editingCustom"
            v-model:replacing="replacingCustom"
        />

        <characters-modal-select v-if="!selected || replacingCustom" v-model:selected="selected" :replacing="!!replacingCustom"/>
        <goals-modal v-else-if="character" v-model:selected="selected"/>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import Textarea from 'primevue/textarea'
import EditCustomCharacter from './EditCustomCharacter'
import CharactersModalSelect from './CharactersModalSelect'
import GoalsModal from './GoalsModal'
import OverlayPanel from 'primevue/overlaypanel'
// import ShareCharacterModal from './ShareCharacterModal'

export default {
    props: {
        editing: String,
        wFull: Boolean
    },

    components: {
        Dialog, Textarea, EditCustomCharacter, CharactersModalSelect, GoalsModal, OverlayPanel
        // ShareCharacterModal
    },

    data () {
        return {
            selected: null,
            current: null,
            goal: null,
            notesTimeout: null,
            editingCustom: false,
            replacingCustom: null,
            preventClosing: false
        }
    },

    watch: {
        selected () {
            if (this.selected) {
                let el = document.getElementsByClassName('p-dialog-content')
                el && el[0] && el[0].scrollTo(0, 0)
            }

            if (this.selected && this.replacingCustom) {
                let goals = this.goals
                goals.filter(g => g.character == this.replacingCustom)
                    .forEach(g => g.character = this.selected)
                this.goals = goals
                this.setCustom(this.replacingCustom, null)
                this.editingCustom = false
                this.replacingCustom = null
            }
        }
    },

    computed: {
        isVisible: {
            get () { return this.characterModal === this.editing },
            set (v) {
                if (v) this.characterModal = this.editing
                else this.characterModal = false
            }
        },

        character () {
            return this.characters[this.selected] || this.customs[this.selected]
        },

        characterCons () {
            let goal = this.goals.find(g => g.character === this.selected && g.type === 'character')
            return (goal && goal.cons) || 0
        },

        fates () {
            // 1, 3, 5
            if (!this.goal || !this.current || this.goal.asc < 1 || this.current.asc >= 5) return 0
            if (this.current.asc < 1) return this.goal.asc < 3 ? 1 : (this.goal.asc < 5 ? 2 : 3)
            if (this.current.asc < 3) return this.goal.asc < 3 ? 0 : (this.goal.asc < 5 ? 1 : 2)
            return this.goal.asc < 5 ? 0 : 1
        }
    },

    methods: {
        click () {
            this.characterModal = this.editing
        },

        context () {
            this.setInactive(this.editing, !this.inactive[this.editing])
        },

        remove () {
            this.removeGoals(this.selected)
            this.selected = null
        },

        show () {
            if (this.editing) this.selected = this.editing
        },

        hide () {
            if (this.selected && !this.editing) this.selected = null
            this.replacingCustom = null
            this.editingCustom = null
        },

        updateNotes (e) {
            this.preventClosing = true
            clearTimeout(this.notesTimeout)

            this.notesTimeout = setTimeout(() => {
                this.setNotes(this.selected, e.target.value)
                this.preventClosing = false
            }, 400)
        },

        selectCons (option) {
            this.$refs.characterCons.hide()
            let goal = this.goals.find(g => g.character === this.selected && g.type === 'character')

            if (goal) {
                this.addGoal({
                    type: 'character',
                    character: this.selected,
                    cons: option
                })
            }

            else {
                this.addGoal({
                    type: 'character',
                    character: this.selected,
                    cons: option,
                    current: { level: 1, asc: 0, text: '1' },
                    goal: { level: 1, asc: 0, text: '1' }
                })
            }
        },
    }
}
</script>
