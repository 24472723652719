<template>
    <router-link :to="`/achievements/commissions/${area}`" class="bg-gray-700 p-2 rounded-lg flex flex-col items-center justify-center">
        <img :src="`${url}img/areas/${area}.png`" class="w-20 h-20 object-contain"/>

        <div class="relative w-full h-2 rounded-full bg-gray-600/80 overflow-hidden mt-2">
            <div class="absolute left-0 bg-purple-500 h-2" :style="`width:${percent}%`"></div>
        </div>

        <p class="mt-2 font-semibold text-sm">{{ done }} / {{ total }} ({{percent}}%)</p>
    </router-link>
</template>

<script>
export default {
    props: ['area', 'allAchievements'],

    computed: {
        total () {
            return this.allAchievements.filter(a => a.c == this.area).length
        },

        done () {
            return this.allAchievements.filter(a => a.c == this.area && this.achievements[a.id]?.done).length
        },

        percent () {
            return Math.round(this.done * 100 / this.total) || 0
        },
    }
}
</script>
