export default {
    kinich: {
        show: '2024-09-11 06:00', release: '2024-09-17 18:00',
        tier: 5, element: 'dendro', weapon: 'claymore',
        element_1: 'nagadus_emerald', element_2: 'flamegranate',
        local: 'saurian_succulent', common: 'n_fang',
        talent: 'kindling', boss: 'denial_and_judgment',
        birthday: [11, 11],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'kinich_101', dim_hash: '4172712634'
    },
    kachina: {
        release: '2024-08-28',
        tier: 4, element: 'geo', weapon: 'polearm',
        element_1: 'prithiva_topaz', element_2: 'flamegranate',
        local: 'quenepa', common: 'whistle',
        talent: 'conflict', boss: 'fading_candle',
        birthday: [4, 22],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'kachina_100', dim_hash: '2531816890'
    },
    mualani: {
        release: '2024-08-28',
        tier: 5, element: 'hydro', weapon: 'catalyst',
        element_1: 'varunada_lazurite', element_2: 'binding_blessing',
        local: 'sprayfeather', common: 'whistle',
        talent: 'contention', boss: 'lightless_mass',
        birthday: [8, 3],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'mualani_102', dim_hash: '3949670506'
    },
    emilie: {
        show: '2024-07-31 06:00', release: '2024-08-06 18:00',
        tier: 5, element: 'dendro', weapon: 'polearm',
        element_1: 'nagadus_emerald', element_2: 'golden_melody',
        local: 'lakelight_lily', common: 'gear',
        talent: 'order', boss: 'silken_feather',
        birthday: [9, 22],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: '', dim_hash: '1705786122'
    },
    traveler_anemo: {
        tier: 5, element: 'anemo', weapon: 'sword', traveler: true,
        element_1: 'brilliant_diamond', element_2: null,
        local: 'windwheel_aster', common: 'mask',
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'playerboy_005'
    },
    traveler_geo: {
        tier: 5, element: 'geo', weapon: 'sword', traveler: true,
        element_1: 'brilliant_diamond', element_2: null,
        local: 'windwheel_aster', common: 'mask',
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'playerboy_005'
    },
    traveler_electro: {
        tier: 5, element: 'electro', weapon: 'sword', traveler: true,
        element_1: 'brilliant_diamond', element_2: null,
        local: 'windwheel_aster', common: 'mask',
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'playerboy_005'
    },
    traveler_dendro: {
        tier: 5, element: 'dendro', weapon: 'sword', traveler: true,
        element_1: 'brilliant_diamond', element_2: null,
        local: 'windwheel_aster', common: 'mask',
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'playerboy_005'
    },
    traveler_hydro: {
        tier: 5, element: 'hydro', weapon: 'sword', traveler: true,
        element_1: 'brilliant_diamond', element_2: null,
        local: 'windwheel_aster', common: 'mask',
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'playerboy_005'
    },

    amber: {
        tier: 4, element: 'pyro', weapon: 'bow',
        element_1: 'agnidus_agate', element_2: 'everflame_seed',
        local: 'small_lamp_grass', common: 'arrowhead',
        talent: 'freedom', boss: 'dvalins_sigh',
        birthday: [8, 10],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'ambor_021', dim_hash: '1966438658'
    },
    barbara: {
        tier: 4, element: 'hydro', weapon: 'catalyst',
        element_1: 'varunada_lazurite', element_2: 'cleansing_heart',
        local: 'philanemo_mushroom', common: 'scroll',
        talent: 'freedom', boss: 'ring_of_boreas',
        birthday: [7, 5],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'barbara_014', dim_hash: '3775299170'
    },
    beidou: {
        tier: 4, element: 'electro', weapon: 'claymore',
        element_1: 'vajrada_amethyst', element_2: 'lightning_prism',
        local: 'noctilucous_jade', common: 'th_insignia',
        talent: 'gold', boss: 'dvalins_sigh',
        birthday: [2, 14],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'beidou_024', dim_hash: '2646367730'
    },
    bennett: {
        tier: 4, element: 'pyro', weapon: 'sword',
        element_1: 'agnidus_agate', element_2: 'everflame_seed',
        local: 'windwheel_aster', common: 'th_insignia',
        talent: 'resistance', boss: 'dvalins_plume',
        birthday: [2, 29],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'bennett_032', dim_hash: '968893378'
    },
    chongyun: {
        tier: 4, element: 'cryo', weapon: 'claymore',
        element_1: 'shivada_jade', element_2: 'hoarfrost_core',
        local: 'cor_lapis', common: 'mask',
        talent: 'diligence', boss: 'dvalins_sigh',
        birthday: [9, 7],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'chongyun_036', dim_hash: '2876340530'
    },
    diluc: {
        tier: 5, element: 'pyro', weapon: 'claymore',
        element_1: 'agnidus_agate', element_2: 'everflame_seed',
        local: 'small_lamp_grass', common: 'f_insignia',
        talent: 'resistance', boss: 'dvalins_plume',
        birthday: [4, 30],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'diluc_016', dim_hash: '3608180322'
    },
    fischl: {
        tier: 4, element: 'electro', weapon: 'bow',
        element_1: 'vajrada_amethyst', element_2: 'lightning_prism',
        local: 'small_lamp_grass', common: 'arrowhead',
        talent: 'ballad', boss: 'spirit_locket_of_boreas',
        birthday: [5, 27],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'fischl_031', dim_hash: '3277782506'
    },
    jean: {
        tier: 5, element: 'anemo', weapon: 'sword',
        element_1: 'vayuda_turqoise', element_2: 'hurricane_seed',
        local: 'dandelion_seed', common: 'mask',
        talent: 'resistance', boss: 'dvalins_plume',
        birthday: [3, 14],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'qin_003', dim_hash: '3221566250'
    },
    kaeya: {
        tier: 4, element: 'cryo', weapon: 'sword',
        element_1: 'shivada_jade', element_2: 'hoarfrost_core',
        local: 'calla_lily', common: 'th_insignia',
        talent: 'ballad', boss: 'spirit_locket_of_boreas',
        birthday: [11, 30],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'kaeya_015', dim_hash: '4119663210'
    },
    keqing: {
        tier: 5, element: 'electro', weapon: 'sword',
        element_1: 'vajrada_amethyst', element_2: 'lightning_prism',
        local: 'cor_lapis', common: 'nectar',
        talent: 'prosperity', boss: 'ring_of_boreas',
        birthday: [11, 20],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'keqing_042', dim_hash: '1864015138'
    },
    klee: {
        tier: 5, element: 'pyro', weapon: 'catalyst',
        element_1: 'agnidus_agate', element_2: 'everflame_seed',
        local: 'philanemo_mushroom', common: 'scroll',
        talent: 'freedom', boss: 'ring_of_boreas',
        birthday: [7, 27],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'klee_029', dim_hash: '3339083250'
    },
    lisa: {
        tier: 4, element: 'electro', weapon: 'catalyst',
        element_1: 'vajrada_amethyst', element_2: 'lightning_prism',
        local: 'valberry', common: 'slime',
        talent: 'ballad', boss: 'dvalins_claw',
        birthday: [6, 9],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'lisa_006', dim_hash: '3344622722'
    },
    mona: {
        tier: 5, element: 'hydro', weapon: 'catalyst',
        element_1: 'varunada_lazurite', element_2: 'cleansing_heart',
        local: 'philanemo_mushroom', common: 'nectar',
        talent: 'resistance', boss: 'ring_of_boreas',
        birthday: [8, 31],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'mona_041', dim_hash: '1113306282'
    },
    ningguang: {
        tier: 4, element: 'geo', weapon: 'catalyst',
        element_1: 'prithiva_topaz', element_2: 'basalt_pillar',
        local: 'glaze_lilly', common: 'f_insignia',
        talent: 'prosperity', boss: 'spirit_locket_of_boreas',
        birthday: [8, 26],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'ningguang_027', dim_hash: '4127888970'
    },
    noelle: {
        tier: 4, element: 'geo', weapon: 'claymore',
        element_1: 'prithiva_topaz', element_2: 'basalt_pillar',
        local: 'valberry', common: 'mask',
        talent: 'resistance', boss: 'dvalins_claw',
        birthday: [3, 21],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'noel_034', dim_hash: '1921418842'
    },
    qiqi: {
        tier: 5, element: 'cryo', weapon: 'sword',
        element_1: 'shivada_jade', element_2: 'hoarfrost_core',
        local: 'violetgrass', common: 'scroll',
        talent: 'prosperity', boss: 'tail_of_boreas',
        birthday: [3, 3],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'qiqi_035', dim_hash: '168956722'
    },
    razor: {
        tier: 4, element: 'electro', weapon: 'claymore',
        element_1: 'vajrada_amethyst', element_2: 'lightning_prism',
        local: 'wolfhook', common: 'mask',
        talent: 'resistance', boss: 'dvalins_claw',
        birthday: [9, 9],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'razor_020', dim_hash: '4160147242'
    },
    sucrose: {
        tier: 4, element: 'anemo', weapon: 'catalyst',
        element_1: 'vayuda_turqoise', element_2: 'hurricane_seed',
        local: 'windwheel_aster', common: 'nectar',
        talent: 'freedom', boss: 'spirit_locket_of_boreas',
        birthday: [11, 26],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'sucrose_043', dim_hash: '1053433018'
    },
    venti: {
        tier: 5, element: 'anemo', weapon: 'bow',
        element_1: 'vayuda_turqoise', element_2: 'hurricane_seed',
        local: 'cecilia', common: 'slime',
        talent: 'ballad', boss: 'tail_of_boreas',
        birthday: [6, 16],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'venti_022', dim_hash: '2466140362'
    },
    xiangling: {
        tier: 4, element: 'pyro', weapon: 'polearm',
        element_1: 'agnidus_agate', element_2: 'everflame_seed',
        local: 'jueyun_chili', common: 'slime',
        talent: 'diligence', boss: 'dvalins_claw',
        birthday: [11, 2],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'xiangling_023', dim_hash: '1130996346'
    },
    xingqiu: {
        tier: 4, element: 'hydro', weapon: 'sword',
        element_1: 'varunada_lazurite', element_2: 'cleansing_heart',
        local: 'silk_flower', common: 'mask',
        talent: 'gold', boss: 'tail_of_boreas',
        birthday: [10, 9],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'xingqiu_025', dim_hash: '4197635682'
    },
    tartaglia: {
        tier: 5, element: 'hydro', weapon: 'bow',
        element_1: 'varunada_lazurite', element_2: 'cleansing_heart',
        local: 'starconch', common: 'f_insignia',
        talent: 'freedom', boss: 'shard_of_a_foul_legacy',
        birthday: [7, 20],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'tartaglia_033', dim_hash: '3847143266'
    },
    diona: {
        tier: 4, element: 'cryo', weapon: 'bow',
        element_1: 'shivada_jade', element_2: 'hoarfrost_core',
        local: 'calla_lily', common: 'arrowhead',
        talent: 'freedom', boss: 'shard_of_a_foul_legacy',
        birthday: [1, 18],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'diona_039', dim_hash: '1468367538'
    },
    zhongli: {
        tier: 5, element: 'geo', weapon: 'polearm',
        element_1: 'prithiva_topaz', element_2: 'basalt_pillar',
        local: 'cor_lapis', common: 'slime',
        talent: 'gold', boss: 'tusk_of_monoceros_caeli',
        birthday: [12, 31],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'zhongli_030', dim_hash: '3862787418'
    },
    xinyan: {
        tier: 4, element: 'pyro', weapon: 'claymore',
        element_1: 'agnidus_agate', element_2: 'everflame_seed',
        local: 'violetgrass', common: 'th_insignia',
        talent: 'gold', boss: 'tusk_of_monoceros_caeli',
        birthday: [10, 16],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'xinyan_044', dim_hash: '4273845410'
    },
    albedo: {
        tier: 5, element: 'geo', weapon: 'sword',
        element_1: 'prithiva_topaz', element_2: 'basalt_pillar',
        local: 'cecilia', common: 'scroll',
        talent: 'ballad', boss: 'tusk_of_monoceros_caeli',
        birthday: [9, 13],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'albedo_038', dim_hash: '4108620722'
    },
    ganyu: {
        tier: 5, element: 'cryo', weapon: 'bow',
        element_1: 'shivada_jade', element_2: 'hoarfrost_core',
        local: 'qingxin', common: 'nectar',
        talent: 'diligence', boss: 'shadow_of_the_warrior',
        birthday: [12, 2],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'ganyu_037', dim_hash: '2679781122'
    },
    xiao: {
        tier: 5, element: 'anemo', weapon: 'polearm',
        element_1: 'vayuda_turqoise', element_2: 'juvenile_jade',
        local: 'qingxin', common: 'slime',
        talent: 'prosperity', boss: 'shadow_of_the_warrior',
        birthday: [4, 17],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'xiao_026', dim_hash: '1021947690'
    },
    hutao: {
        tier: 5, element: 'pyro', weapon: 'polearm',
        element_1: 'agnidus_agate', element_2: 'juvenile_jade',
        local: 'silk_flower', common: 'nectar',
        talent: 'diligence', boss: 'shard_of_a_foul_legacy',
        birthday: [7, 15],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'hutao_046', dim_hash: '1940919994'
    },
    rosaria: {
        tier: 4, element: 'cryo', weapon: 'polearm',
        element_1: 'shivada_jade', element_2: 'hoarfrost_core',
        local: 'valberry', common: 'f_insignia',
        talent: 'ballad', boss: 'shadow_of_the_warrior',
        birthday: [1, 24],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'rosaria_045', dim_hash: '4260733330'
    },
    yanfei: {
        tier: 4, element: 'pyro', weapon: 'catalyst',
        element_1: 'agnidus_agate', element_2: 'juvenile_jade',
        local: 'noctilucous_jade', common: 'th_insignia',
        talent: 'gold', boss: 'bloodjade_branch',
        birthday: [7, 28],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'feiyan_048', dim_hash: '697277554'
    },
    eula: {
        tier: 5, element: 'cryo', weapon: 'claymore',
        element_1: 'shivada_jade', element_2: 'crystalline_bloom',
        local: 'dandelion_seed', common: 'mask',
        talent: 'resistance', boss: 'dragon_lords_crown',
        birthday: [10, 25],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'eula_051', dim_hash: '3717667418'
    },
    kazuha: {
        tier: 5, element: 'anemo', weapon: 'sword',
        element_1: 'vayuda_turqoise', element_2: 'maguu_kishin',
        local: 'sea_ganoderma', common: 'th_insignia',
        talent: 'diligence', boss: 'gilded_scale',
        birthday: [10, 29],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'kazuha_047', dim_hash: '88505754'
    },
    ayaka: {
        tier: 5, element: 'cryo', weapon: 'sword',
        element_1: 'shivada_jade', element_2: 'perpetual_heart',
        local: 'sakura_bloom', common: 'handguard',
        talent: 'elegance', boss: 'bloodjade_branch',
        birthday: [9, 28],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'ayaka_002', dim_hash: '1006042610'
    },
    yoimiya: {
        tier: 5, element: 'pyro', weapon: 'bow',
        element_1: 'agnidus_agate', element_2: 'smoldering_pearl',
        local: 'naku_weed', common: 'scroll',
        talent: 'transience', boss: 'dragon_lords_crown',
        birthday: [6, 21],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'yoimiya_049', dim_hash: '2504399314'
    },
    sayu: {
        tier: 4, element: 'anemo', weapon: 'claymore',
        element_1: 'vayuda_turqoise', element_2: 'maguu_kishin',
        local: 'crystal_marrow', common: 'nectar',
        talent: 'light', boss: 'gilded_scale',
        birthday: [10, 19],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'sayu_053', dim_hash: '2388785242'
    },
    shogun: {
        tier: 5, element: 'electro', weapon: 'polearm',
        element_1: 'vajrada_amethyst', element_2: 'storm_beads',
        local: 'tenkumo_fruit', common: 'handguard',
        talent: 'light', boss: 'signora_flower',
        birthday: [6, 26],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'shougun_052', dim_hash: '3024507506'
    },
    sara: {
        tier: 4, element: 'electro', weapon: 'bow',
        element_1: 'vajrada_amethyst', element_2: 'storm_beads',
        local: 'dendrobium', common: 'mask',
        talent: 'elegance', boss: 'signora_heart',
        birthday: [7, 14],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'sara_056', dim_hash: '1483922610'
    },
    aloy: {
        tier: 5, element: 'cryo', weapon: 'bow',
        element_1: 'shivada_jade', element_2: 'crystalline_bloom',
        local: 'crystal_marrow', common: 'spectral_husk',
        talent: 'freedom', boss: 'signora_flower',
        birthday: [4, 4],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'aloy_062', dim_hash: '3689108098'
    },
    kokomi: {
        tier: 5, element: 'hydro', weapon: 'catalyst',
        element_1: 'varunada_lazurite', element_2: 'dew_of_repudiation',
        local: 'sango_pearl', common: 'spectral_husk',
        talent: 'transience', boss: 'signora_wings',
        birthday: [2, 22],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'kokomi_054', dim_hash: '3914045794'
    },
    thoma: {
        tier: 4, element: 'pyro', weapon: 'polearm',
        element_1: 'agnidus_agate', element_2: 'smoldering_pearl',
        local: 'fluorescent_fungus', common: 'th_insignia',
        talent: 'transience', boss: 'signora_wings',
        birthday: [1, 9],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'tohma_050', dim_hash: '3555115602'
    },
    itto: {
        tier: 5, element: 'geo', weapon: 'claymore',
        element_1: 'prithiva_topaz', element_2: 'riftborn_regalia',
        local: 'onikabuto', common: 'slime',
        talent: 'elegance', boss: 'signora_heart',
        birthday: [6, 1],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'itto_057', dim_hash: '3068316954'
    },
    gorou: {
        tier: 4, element: 'geo', weapon: 'bow',
        element_1: 'prithiva_topaz', element_2: 'perpetual_heart',
        local: 'sango_pearl', common: 'spectral_husk',
        talent: 'light', boss: 'signora_flower',
        birthday: [5, 18],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'gorou_055', dim_hash: '3400133546'
    },
    yunjin: {
        tier: 4, element: 'geo', weapon: 'polearm',
        element_1: 'prithiva_topaz', element_2: 'riftborn_regalia',
        local: 'glaze_lilly', common: 'mask',
        talent: 'diligence', boss: 'signora_heart',
        birthday: [5, 21],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'yunjin_064', dim_hash: '655825874'
    },
    shenhe: {
        tier: 5, element: 'cryo', weapon: 'polearm',
        element_1: 'shivada_jade', element_2: 'dragonheirs_false_fin',
        local: 'qingxin', common: 'nectar',
        talent: 'prosperity', boss: 'signora_wings',
        birthday: [3, 10],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'shenhe_063', dim_hash: '334242634'
    },
    yaemiko: {
        tier: 5, element: 'electro', weapon: 'catalyst',
        element_1: 'vajrada_amethyst', element_2: 'dragonheirs_false_fin',
        local: 'sea_ganoderma', common: 'handguard',
        talent: 'light', boss: 'the_meaning_of_aeons',
        birthday: [6, 27],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'yae_058', dim_hash: '2713453234'
    },
    ayato: {
        tier: 5, element: 'hydro', weapon: 'sword',
        element_1: 'varunada_lazurite', element_2: 'dew_of_repudiation',
        local: 'sakura_bloom', common: 'handguard',
        talent: 'elegance', boss: 'mudra_of_the_malefic_general',
        birthday: [3, 26],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'ayato_066', dim_hash: '1588620330'
    },
    yelan: {
        tier: 5, element: 'hydro', weapon: 'bow',
        element_1: 'varunada_lazurite', element_2: 'runic_fang',
        local: 'starconch', common: 'f_insignia',
        talent: 'prosperity', boss: 'gilded_scale',
        birthday: [4, 20],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'yelan_060', dim_hash: '2848374378'
    },
    shinobu: {
        tier: 4, element: 'electro', weapon: 'sword',
        element_1: 'vajrada_amethyst', element_2: 'runic_fang',
        local: 'naku_weed', common: 'spectral_husk',
        talent: 'elegance', boss: 'tears_of_the_calamitous_god',
        birthday: [7, 27],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'shinobu_065', dim_hash: '1940821986'
    },
    heizou: {
        tier: 4, element: 'anemo', weapon: 'catalyst',
        element_1: 'vayuda_turqoise', element_2: 'runic_fang',
        local: 'onikabuto', common: 'th_insignia',
        talent: 'transience', boss: 'the_meaning_of_aeons',
        birthday: [7, 24],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'heizo_059', dim_hash: '646032090'
    },
    tighnari: {
        tier: 5, element: 'dendro', weapon: 'bow',
        element_1: 'nagadus_emerald', element_2: 'majestic_hooked_beak',
        local: 'nilotpala', common: 'spores',
        talent: 'admonition', boss: 'the_meaning_of_aeons',
        birthday: [12, 29],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'tighnari_069', dim_hash: '2506955778'
    },
    collei: {
        tier: 4, element: 'dendro', weapon: 'bow',
        element_1: 'nagadus_emerald', element_2: 'majestic_hooked_beak',
        local: 'rukkhashava', common: 'arrowhead',
        talent: 'praxis', boss: 'tears_of_the_calamitous_god',
        birthday: [5, 8],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'collei_067', dim_hash: '2948362178'
    },
    dori: {
        tier: 4, element: 'electro', weapon: 'claymore',
        element_1: 'vajrada_amethyst', element_2: 'thunderclap_fruitcore',
        local: 'kalpalata', common: 'red_satin',
        talent: 'ingenuity', boss: 'bloodjade_branch',
        birthday: [12, 21],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'dori_068', dim_hash: '388272194'
    },
    cyno: {
        tier: 5, element: 'electro', weapon: 'polearm',
        element_1: 'vajrada_amethyst', element_2: 'thunderclap_fruitcore',
        local: 'scarab', common: 'scroll',
        talent: 'admonition', boss: 'mudra_of_the_malefic_general',
        birthday: [6, 23],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'cyno_071', dim_hash: '1049891906'
    },
    candace: {
        tier: 4, element: 'hydro', weapon: 'polearm',
        element_1: 'varunada_lazurite', element_2: 'light_guiding_tetrahedron',
        local: 'redcrest', common: 'red_satin',
        talent: 'admonition', boss: 'tears_of_the_calamitous_god',
        birthday: [5, 3],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'candace_072', dim_hash: '3092975658'
    },
    nilou: {
        tier: 5, element: 'hydro', weapon: 'sword',
        element_1: 'varunada_lazurite', element_2: 'perpetual_caliber',
        local: 'padisarah', common: 'spores',
        talent: 'praxis', boss: 'tears_of_the_calamitous_god',
        birthday: [12, 3],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'nilou_070', dim_hash: '3850149970'
    },
    nahida: {
        tier: 5, element: 'dendro', weapon: 'catalyst',
        element_1: 'nagadus_emerald', element_2: 'quelled_creeper',
        local: 'kalpalata', common: 'spores',
        talent: 'ingenuity', boss: 'puppet_strings',
        birthday: [10, 27],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'nahida_073', dim_hash: '712501082'
    },
    layla: {
        tier: 4, element: 'cryo', weapon: 'sword',
        element_1: 'shivada_jade', element_2: 'perpetual_caliber',
        local: 'nilotpala', common: 'scroll',
        talent: 'ingenuity', boss: 'mirror_of_mushin',
        birthday: [12, 19],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'layla_074', dim_hash: '2889777514'
    },
    wanderer: {
        tier: 5, element: 'anemo', weapon: 'catalyst',
        element_1: 'vayuda_turqoise', element_2: 'perpetual_caliber',
        local: 'rukkhashava', common: 'handguard',
        talent: 'praxis', boss: 'dakas_bell',
        birthday: [1, 3],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'wanderer_075', dim_hash: '3230559562'
    },
    faruzan: {
        tier: 4, element: 'anemo', weapon: 'bow',
        element_1: 'vayuda_turqoise', element_2: 'light_guiding_tetrahedron',
        local: 'redcrest', common: 'red_satin',
        talent: 'admonition', boss: 'puppet_strings',
        birthday: [8, 20],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'faruzan_076', dim_hash: '2387711994'
    },
    alhaitham: {
        tier: 5, element: 'dendro', weapon: 'sword',
        element_1: 'nagadus_emerald', element_2: 'pseudo_stamens',
        local: 'sand_grease_pupa', common: 'red_satin',
        talent: 'ingenuity', boss: 'mirror_of_mushin',
        birthday: [2, 11],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'alhatham_078', dim_hash: '4002157418'
    },
    yaoyao: {
        tier: 4, element: 'dendro', weapon: 'polearm',
        element_1: 'nagadus_emerald', element_2: 'quelled_creeper',
        local: 'jueyun_chili', common: 'slime',
        talent: 'diligence', boss: 'dakas_bell',
        birthday: [3, 6],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'yaoyao_077', dim_hash: '1732418482'
    },
    dehya: {
        tier: 5, element: 'pyro', weapon: 'claymore',
        element_1: 'agnidus_agate', element_2: 'light_guiding_tetrahedron',
        local: 'sand_grease_pupa', common: 'red_satin',
        talent: 'praxis', boss: 'puppet_strings',
        birthday: [4, 7],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'dehya_079', dim_hash: '2360533154'
    },
    mika: {
        tier: 4, element: 'cryo', weapon: 'polearm',
        element_1: 'shivada_jade', element_2: 'pseudo_stamens',
        local: 'wolfhook', common: 'f_insignia',
        talent: 'ballad', boss: 'mirror_of_mushin',
        birthday: [8, 11],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'mika_080', dim_hash: '231911858'
    },
    baizhu: {
        tier: 5, element: 'dendro', weapon: 'catalyst',
        element_1: 'nagadus_emerald', element_2: 'evergloom_ring',
        local: 'violetgrass', common: 'spores',
        talent: 'gold', boss: 'worldspan_fern',
        birthday: [4, 25],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'baizhuer_082', dim_hash: '2984815066'
    },
    kaveh: {
        tier: 4, element: 'dendro', weapon: 'claymore',
        element_1: 'nagadus_emerald', element_2: 'quelled_creeper',
        local: 'mourning_flower', common: 'spores',
        talent: 'ingenuity', boss: 'primordial_greenbloom',
        birthday: [7, 9],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'kaveh_081', dim_hash: '466355514'
    },
    kirara: {
        tier: 4, element: 'dendro', weapon: 'sword',
        element_1: 'nagadus_emerald', element_2: 'evergloom_ring',
        local: 'tenkumo_fruit', common: 'spectral_husk',
        talent: 'transience', boss: 'everamber',
        birthday: [1, 22],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'momoka_061', dim_hash: '1456643042'
    },
    lyney: {
        tier: 5, element: 'pyro', weapon: 'bow',
        element_1: 'agnidus_agate', element_2: 'emperors_resolution',
        local: 'rainbow_rose', common: 'f_insignia',
        talent: 'fairness', boss: 'primordial_greenbloom',
        birthday: [2, 2],
        cons: { 3: 'normal', 5: 'burst' },
        other_id: 'liney_084', dim_hash: '2472444970'
    },
    lynette: {
        tier: 4, element: 'anemo', weapon: 'sword',
        element_1: 'vayuda_turqoise', element_2: 'clockwork_component_coppelia',
        local: 'lumidouce_bell', common: 'gear',
        talent: 'order', boss: 'everamber',
        birthday: [2, 2],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'linette_083', dim_hash: '1225226258'
    },
    freminet: {
        tier: 4, element: 'cryo', weapon: 'claymore',
        element_1: 'shivada_jade', element_2: 'clockwork_component_coppelius',
        local: 'romaritime_flower', common: 'transoceanic',
        talent: 'justice', boss: 'worldspan_fern',
        birthday: [9, 24],
        cons: { 3: 'normal', 5: 'skill' },
        other_id: 'freminet_085', dim_hash: '4201964354'
    },
    neuvillette: {
        tier: 5, element: 'hydro', weapon: 'catalyst',
        element_1: 'varunada_lazurite', element_2: 'fontemer_unihorn',
        local: 'lumitoile', common: 'transoceanic',
        talent: 'fairness', boss: 'everamber',
        birthday: [12, 18],
        cons: { 3: 'normal', 5: 'burst' },
        other_id: 'neuvillette_087', dim_hash: '1504902330'
    },
    wriothesley: {
        tier: 5, element: 'cryo', weapon: 'catalyst',
        element_1: 'shivada_jade', element_2: 'tourbillon_device',
        local: 'subdetection_unit', common: 'gear',
        talent: 'order', boss: 'primordial_greenbloom',
        birthday: [11, 23],
        cons: { 3: 'normal', 5: 'burst' },
        other_id: 'wriothesley_086', dim_hash: '370151050'
    },
    furina: {
        tier: 5, element: 'hydro', weapon: 'sword',
        element_1: 'varunada_lazurite', element_2: 'water_that_failed',
        local: 'lakelight_lily', common: 'nectar',
        talent: 'justice', boss: 'lightless_mass',
        birthday: [10, 13],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'furina_089', dim_hash: '2180900234'
    },
    charlotte: {
        tier: 4, element: 'cryo', weapon: 'catalyst',
        element_1: 'shivada_jade', element_2: 'tourbillon_device',
        local: 'beryl_conch', common: 'gear',
        talent: 'justice', boss: 'lightless_silk_string',
        birthday: [4, 10],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'charlotte_088', dim_hash: '3831162130'
    },
    navia: {
        tier: 5, element: 'geo', weapon: 'claymore',
        element_1: 'prithiva_topaz', element_2: 'clockwork_component_coppelius',
        local: 'first_dewdrop', common: 'transoceanic',
        talent: 'fairness', boss: 'lightless_silk_string',
        birthday: [8, 16],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'navia_091', dim_hash: '2877588578'
    },
    chevreuse: {
        tier: 4, element: 'pyro', weapon: 'polearm',
        element_1: 'agnidus_agate', element_2: 'fontemer_unihorn',
        local: 'lumidouce_bell', common: 'gear',
        talent: 'order', boss: 'lightless_eye_of_the_maelstrom',
        birthday: [1, 10],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'chevreuse_090', dim_hash: '540313842'
    },
    xianyun: {
        tier: 5, element: 'anemo', weapon: 'catalyst',
        element_1: 'vayuda_turqoise', element_2: 'cloudseam_scale',
        local: 'clearwater_jade', common: 'scroll',
        talent: 'gold', boss: 'lightless_eye_of_the_maelstrom',
        birthday: [4, 11],
        cons: { 3: 'burst', 5: 'skill' },
        other_id: 'liuyun_093', dim_hash: '3648220770'
    },
    gaming: {
        tier: 4, element: 'pyro', weapon: 'claymore',
        element_1: 'agnidus_agate', element_2: 'emperors_resolution',
        local: 'starconch', common: 'slime',
        talent: 'prosperity', boss: 'lightless_mass',
        birthday: [12, 22],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'gaming_092', dim_hash: '2822240322'
    },
    chiori: {
        tier: 5, element: 'geo', weapon: 'sword',
        element_1: 'prithiva_topaz', element_2: 'clockwork_component_coppelia',
        local: 'dendrobium', common: 'spectral_husk',
        talent: 'light', boss: 'lightless_silk_string',
        birthday: [8, 17],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'chiori_094', dim_hash: '1944606922'
    },
    arlecchino: {
        tier: 5, element: 'pyro', weapon: 'polearm',
        element_1: 'agnidus_agate', element_2: 'golden_melody',
        local: 'rainbow_rose', common: 'f_insignia',
        talent: 'order', boss: 'fading_candle',
        birthday: [8, 22],
        cons: { 3: 'normal', 5: 'burst' },
        other_id: 'arlecchino_096', dim_hash: '167199474'
    },
    clorinde: {
        tier: 5, element: 'electro', weapon: 'sword',
        element_1: 'vajrada_amethyst', element_2: 'fontemer_unihorn',
        local: 'lumitoile', common: 'transoceanic',
        talent: 'justice', boss: 'everamber',
        birthday: [9, 20],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'clorinde_098', dim_hash: '2745369298'
    },
    sethos: {
        tier: 4, element: 'electro', weapon: 'bow',
        element_1: 'vajrada_amethyst', element_2: 'cloudseam_scale',
        local: 'trishiraite', common: 'red_satin',
        talent: 'praxis', boss: 'dakas_bell',
        birthday: [5, 31],
        cons: { 3: 'normal', 5: 'burst' },
        other_id: 'sethos_097', dim_hash: '1431322346'
    },
    sigewinne: {
        tier: 5, element: 'hydro', weapon: 'bow',
        element_1: 'varunada_lazurite', element_2: 'water_that_failed',
        local: 'romaritime_flower', common: 'transoceanic',
        talent: 'fairness', boss: 'lightless_eye_of_the_maelstrom',
        birthday: [3, 30],
        cons: { 3: 'skill', 5: 'burst' },
        other_id: 'sigewinne_095', dim_hash: '781488962'
    }
};
