<template>
    <div class="mx-auto bg-gray-800 rounded overflow-hidden text-white relative h-[250px] md:h-[90px] min-w-[250px] max-w-[300px] md:min-w-[728px] md:max-w-auto">
        <div class="absolute flex flex-col md:flex-row items-center justify-center inset-0 pointer-events-none select-none">
            <img :src="`${url}img/stickers/please.png`" class="max-h-[60px] mb-2 md:mb-0 md:mr-4">

            <div class="text-center text-sm opacity-80 leading-tight mx-4 md:mx-0">
                <strong>{{ $t('ads_supported') }}</strong><br/>
                <span class="text-xs opacity-70">{{ $t('ads_whitelist') }} ♥</span>
            </div>
        </div>

        <!-- <adsense-ad v-if="inmagi"/> -->
        <!-- <div v-else class="relative z-10"> -->
        <div class="relative z-10">
            <template v-if="!bottom">
                <div id="nn_lb1"/>
                <div id="nn_mobile_mpu1"/>
            </template>
            <template v-else>
                <div id="nn_lb3"/>
                <div id="nn_mobile_mpu2"/>
            </template>
        </div>
    </div>
</template>

<script>
// import AdsenseAd from './AdsenseAd'

export default {
    // components: { AdsenseAd },

    props: {
        bottom: Boolean
    },

    data () {
        return {
            timeout: null
        }
    },

    mounted () {
        // this.$nextTick(() => this.reloadAds())
    },

    created() {
        // window.addEventListener('resize', this.reloadAds)
    },

    unmounted() {
        // clearTimeout(this.timeout)
        // window.removeEventListener('resize', this.reloadAds)
    },

    methods: {
        reloadAds () {
            if (this.inmagi || typeof window.reloadAdSlots != 'function') return

            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                window.reloadAdSlots()
            }, 2000)
        }
    }
}
</script>
