<template>
    <div class="flex">
        <template v-if="$route.path != '/map'">
            <div id="nn_lb2"></div> <!-- kumo-ad -->
            <div id="nn_mobile_lb1"></div> <!-- kumo-ad -->
        </template>

        <div
            class="w-56 min-h-screen flex-col justify-between flex-shrink-0 bg-gradient-to-b from-gray-800 to-gray-900 px-6 py-4 text-gray-300 font-semibold custom-scrollbar overflow-y-auto max-h-screen"
            :class="[
                $route.path != '/map' && 'pt-20 lg:pt-6',
                mobileMenu ? 'flex absolute z-40 shadow-xl' : 'hidden',
                $route.path != '/map' && (mobileMenu ? 'lg:relative lg:shadow-md' : 'lg:flex')
            ]"
        >
            <div class="flex flex-col">
                <div class="flex justify-center">
                    <seelie-selector small active="gi"/>
                </div>

                <router-link to="/" @click="mobileMenu = false" class="mb-2 -mx-2 flex justify-center mt-4">
                    <img :src="`${url}img/logo.svg`" class="h-14">
                </router-link>

                <router-link to="/characters" @click="mobileMenu = false" active-class="!bg-gray-900 text-white"
                    class="flex items-center my-1 py-2 px-3 hover:bg-gray-800 transition-all rounded-lg"
                >
                    <img class="h-6 w-6 mr-2 object-contain opacity-75" :src="`${url}img/general/character.png`">
                    {{ $t('characters') }}
                </router-link>
                <router-link to="/weapons" @click="mobileMenu = false" active-class="!bg-gray-900 text-white"
                    class="flex items-center my-1 py-2 px-3 hover:bg-gray-800 transition-all rounded-lg"
                >
                    <img class="h-6 w-6 mr-2 object-contain opacity-75" :src="`${url}img/general/weapons.png`">
                    {{ $t('weapons') }}
                </router-link>
                <router-link to="/planner" @click="mobileMenu = false" active-class="!bg-gray-900 text-white"
                    class="flex items-center my-1 py-2 px-3 hover:bg-gray-800 transition-all rounded-lg relative"
                >
                    <img class="h-6 w-6 mr-2 object-contain opacity-75" :src="`${url}img/general/quest.png`">
                    {{ $t('planner') }}

                    <div
                        v-if="settings.resin_planner_badge && computedResin >= 40"
                        class="absolute w-4 h-4 top-0 left-0 -m-px flex"
                        :class="{'opacity-50': $route.path != '/planner'}"
                    >
                        <span class="text-xs animate-ping absolute inline-flex h-full w-full rounded-full opacity-75" :class="computedResin >= resinCap ? 'bg-red-400' : 'bg-blue-400'"></span>
                        <span class="text-xs font-semibold text-white relative inline-flex rounded-full h-full w-full items-center justify-center" :class="computedResin >= resinCap ? 'bg-red-500' : 'bg-blue-500'">{{ Math.floor(computedResin/40) }}</span>
                    </div>
                </router-link>
                <router-link to="/inventory" @click="mobileMenu = false" active-class="!bg-gray-900 text-white"
                    class="flex items-center my-1 py-2 px-3 hover:bg-gray-800 transition-all rounded-lg"
                >
                    <img class="h-6 w-6 mr-2 object-contain opacity-75" :src="`${url}img/general/bag.png`">
                    {{ $t('inventory') }}
                </router-link>
                <router-link to="/achievements" @click="mobileMenu = false" active-class="!bg-gray-900 text-white"
                    class="relative flex items-center my-1 py-2 px-3 hover:bg-gray-800 transition-all rounded-lg"
                >
                    <img class="h-6 w-6 mr-2 object-contain opacity-75" :src="`${url}img/general/achievement.png`">
                    {{ $t('achievements') }}
                </router-link>
                <!-- <router-link to="/archive" @click="mobileMenu = false" active-class="!bg-gray-900 text-white"
                    class="relative flex items-center my-1 py-2 px-3 hover:bg-gray-800 transition-all rounded-lg"
                >
                    <img class="w-6 mr-2 opacity-75" :src="`${url}img/general/lorebook.png`">
                    {{ 'Archive' }}
                </router-link> -->
                <a href="https://act.hoyolab.com/ys/app/interactive-map/index.html?utm_source=seelie_me"
                    target="_blank"
                    class="flex items-center my-1 py-2 px-3 hover:bg-gray-800 transition-all rounded-lg"
                >
                    <img class="h-6 w-6 mr-2 object-contain opacity-75" :src="`${url}img/general/map.png`">
                    {{ $t('map') }} <external-link-icon class="ml-2 w-5 h-5"/>
                </a>
                <router-link to="/settings" @click="mobileMenu = false" active-class="!bg-gray-900 text-white"
                    class="flex items-center my-1 py-2 px-3 hover:bg-gray-800 transition-all rounded-lg"
                >
                    <img class="h-6 w-6 mr-2 object-contain opacity-75" :src="`${url}img/general/gadget.png`">
                    {{ $t('settings') }}
                </router-link>
            </div>

            <div class="flex flex-col items-center justify-center w-full mt-3">
                <language-selector/>

                <button
                    class="w-full flex items-center justify-center h-12 bg-gray-800 pl-2 pr-3 rounded-lg shadow hover:bg-gray-700 transition-all mt-2"
                    @click="isDonateModalVisible = true"
                >
                    <img :src="`${url}img/stickers/1-mora.png`" class="h-12 mr-3"/>
                    <p class="font-semibold text-lg">{{ $t('donate') }}</p>
                </button>

                <div class="mt-2 flex items-center justify-center w-full rounded-lg overflow-hidden h-10">
                    <a
                        class="w-1/2 py-2 h-full flex items-center justify-center shadow bg-gray-800 hover:bg-gray-700 transition-all"
                        target="_blank"
                        href="https://twitter.com/MeSeelie"
                    >
                        <twitter-icon class="h-5"/>
                    </a>
                    <a
                        class="w-1/2 py-2 h-full flex items-center justify-center shadow bg-gray-800 hover:bg-gray-700 transition-all"
                        target="_blank"
                        :href="$i18n.locale === 'pt' ? 'mailto:contato@seelie.me' : 'mailto:contact@seelie.me'"
                    >
                        <mail-icon class="h-6"/>
                    </a>
                </div>
                <router-link to="/policy" class="mt-1 text-xs">{{ $t('privacy_policy') }}</router-link>
            </div>
        </div>

        <div class="fixed top-0 left-0 m-4 z-40 lg:hidden" v-if="$route.path != '/map'">
            <button @click="mobileMenu = !mobileMenu" class="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span class="sr-only">Open main menu</span>
                <menu-icon class="w-6" :class="mobileMenu ? 'hidden' : 'block'"/>
                <x-icon class="w-6" :class="mobileMenu ? 'block' : 'hidden'"/>
            </button>

            <!-- <div class="bg-gray-800 text-white text-sm py-1 px-3 rounded-full -mt-4 ml-4 quick-fade-out pointer-events-none hidden lg:block" v-if="$route.path == '/map'">
                {{ $t('click_here_go_back_to_seelie') }}
            </div> -->
        </div>

        <div class="fixed z-40 bottom-0 mb-5 left-[50%] translate-x-[-50%]" v-else>
            <button @click="mobileMenu = !mobileMenu" class="bg-[#3b4354] text-white font-semibold py-2 px-4 rounded-full shadow-lg flex items-center justify-center">
                <img :src="`${url}img/icons/icon-32.png`" class="h-6 mr-2"/>
                {{ $t('back_to_seelie') }}
            </button>
        </div>

        <div
            id="main"
            class="bg-gray-900 w-full h-screen overflow-y-auto flex flex-col focus:outline-none"
            :class="[
                mobileMenu ? 'opacity-75 overflow-y-hidden cursor-pointer' : 'overflow-y-auto',
                $route.path != '/map' && 'lg:cursor-default lg:opacity-100 lg:overflow-y-auto'
            ]"
            @click="mobileMenu = false"
        >

        <reload-prompt/>

        <div
        :class="[
            $route.path != '/map' && 'container mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 py-16 lg:py-8 xl:py-10',
            mobileMenu && 'pointer-events-none lg:pointer-events-auto'
        ]"
            >

                <router-view/>
            </div>

            <div class="footer mt-auto">
                <!-- <a href="https://inmagi.com/?ref=seelie" v-if="$i18n.locale === 'pt'" target="_blank" class="w-full py-6 flex flex-col items-center justify-center text-white" style="background: linear-gradient(to right, #021029, #30033c)">
                    <svg viewBox="0 0 126 26" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-10">
                        <path d="M4.5 0L4.5 25.2L0 25.2L0 0L4.5 0ZM28.8 0L28.8 16.056L12.6 3.564L8.1 0.108L8.1 25.236L12.6 25.236L12.6 9.288L28.8 21.78L33.3 25.236L33.3 0.0359998L28.8 0ZM49.5 9.684L57.6 3.456L62.1 0L62.1 25.2L57.6 25.2L57.6 9.144L53.172 12.528L49.5 15.372L45.828 12.528L41.4 9.144L41.4 25.2L36.9 25.2L36.9 0L41.4 3.456L49.5 9.684ZM87.696 20.7L77.148 0L66.6 20.7L64.296 25.2L69.336 25.2L71.64 20.7L77.148 9.9L82.656 20.7L84.96 25.2L90 25.2L87.696 20.7ZM117.36 4.5L104.76 4.5C100.296 4.5 96.66 8.136 96.66 12.6C96.66 17.064 100.296 20.7 104.76 20.7L112.86 20.7L112.86 15.876L104.76 15.876L104.76 11.376L117.36 11.376L117.36 25.2L104.76 25.2C97.812 25.2 92.16 19.548 92.16 12.6C92.16 5.652 97.812 0 104.76 0L117.36 0L117.36 4.5ZM120.96 0L125.46 0L125.46 25.2L120.96 25.2L120.96 0Z" id="INMAGI"/>
                    </svg>

                    <div class="text-xs mt-2 leading-none uppercase font-semibold tracking-wide opacity-75">Seu universo de filmes e séries</div>
                </a> -->

                <div class="bg-gray-800 w-full py-6 px-4 text-gray-200 text-center">
                    <div class="text-sm">{{ $t('layout.footer_1') }}</div>
                    <div class="my-2 text-sm">{{ $t('layout.footer_2') }}</div>
                    <div>{{ $t('layout.footer_3') }}</div>
                </div>
            </div>
        </div>

        <Dialog :draggable="false" :show-header="false" position="center" style="max-width: 600px;" v-model:visible="isConflictModalVisible" modal :closable="false">
            <conflict
                @driveSave="driveSaveData"
                @driveCopy="driveCopyFile"
            />
        </Dialog>

        <Dialog :draggable="false" :header="$t('donate')" position="center" v-model:visible="isDonateModalVisible" modal style="max-width: 440px;" dismissable-mask>
            <template v-if="$i18n.locale === 'pt'">
                <p class="leading-tight">
                    Você pode ajudar a contribuir com o projeto doando qualquer valor. 💗
                </p>
                <div class="mt-4">
                    <a href="https://picpay.me/gbdematos" target="_blank">
                        <img :src="`${url}img/picpay.jpeg`" class="w-40 rounded-lg"/>
                    </a>
                </div>
                <p class="mt-2">
                    Doe pelo <a class="text-purple-300 underline font-semibold mt-2" target="_blank" href="https://picpay.me/gbdematos">PicPay</a>
                    ou <a class="text-purple-300 underline font-semibold" target="_blank" href="https://www.paypal.com/donate?hosted_button_id=VKHY8KY52DSEW">PayPal</a>
                </p>
                <p class="mt-2">Chave Pix: 59f51e41-5c38-4da3-8685-19b54a45f477</p>
                <p class="mt-2">Carteira ETH/BSC: 0x4601367b90f1b32B525b229577b36Fe92127da4d</p>
                <div class="mt-4 leading-tight">Se você usa algum bloqueador de anúncios, por favor considere desabilitá-lo no site. Possuímos apenas anúncios não intrusivos.</div>
                <p class="mt-4 mb-2 leading-tight">
                    Você também pode participar do <a href="https://inmagi.com/?ref=seelie-home" target="_blank" class="font-semibold underline text-purple-300">INMAGI</a>, um organizador para Séries, Filmes e Animes. Lá você pode gerenciar tudo que já assistiu ou ainda quer ver, comentar, avaliar, ganhar selos e muito mais.
                </p>
            </template>
            <template v-else>
                <div>You can help with this project by donating any amount.</div>
                <div class="mt-2">
                    <a class="text-purple-300 underline font-semibold" target="_blank" href="https://www.paypal.com/donate?hosted_button_id=FTU4ZPEXE9SK8">Donate via PayPal</a>
                    <p class="mb-2">or</p>
                    <a href="https://www.buymeacoffee.com/seelie" target="_blank">
                        <img src="https://cdn.buymeacoffee.com/buttons/v2/default-violet.png" alt="Buy Me A Coffee" style="height: 48px !important" >
                    </a>
                </div>
                <p class="mt-3">ETH/BSC Wallet: 0x4601367b90f1b32B525b229577b36Fe92127da4d</p>
                <div class="my-2">Your help is greatly appreciated.</div>
                <div class="my-2">If you use an adblocker, please consider whitelisting us.</div>
                💗
            </template>
        </Dialog>

        <toasts/>
    </div>
</template>

<script>
import axios from 'axios'
import Dialog from 'primevue/dialog'
import SeelieSelector from '@/common/components/SeelieSelector'
import LanguageSelector from './components/LanguageSelector'
import Conflict from './components/Conflict'
import Toasts from './components/Toasts'
import ReloadPrompt from './components/ReloadPrompt'
import driveMixin from './mixins/drive.js'

export default {
    mixins: [ driveMixin ],

    components: { Dialog, Conflict, Toasts, ReloadPrompt, SeelieSelector, LanguageSelector },

    data () {
        return {
            isSettingsModalVisible: false,
            mobileMenu: false,
            lastNotified: null,
        }
    },

    watch: {
        computedResin: {
            immediate: true,
            handler (to, from) {
                if (!this.settings.resin_app_badge) return

                let resins = Math.floor(to)
                if (resins == Math.floor(from)) return

                this.setResinBadge(resins)
            }
        },

        server () {
            this.time = this.dayjs()
        },

        time: {
            immediate: true,
            handler (to) {
                this.serverTime = to.utc().add(this.zones[this.server], 'hour')
                this.resetTime = this.serverTime.add(-4, 'hour')
                this.resetDay = this.dayjs(this.resetTime.format('YYYY-MM-DD'))
            }
        },

        driveIsLoading () { this.checkOldData() },
        driveIsSyncing () { this.checkOldData() },
        account () { this.checkOldData() },
    },

    computed: {
        quantities () { return this.settings.resin_notification_quantities },
        alreadyChecked: {
            get () { return localStorage.getItem(`${this.account}-checked`) || false },
            set (v) { localStorage.setItem(`${this.account}-checked`, v) }
        }
    },

    // async beforeMount () {
    //     if (this.$i18n.locale != 'en') await this.loadLocale(this.$i18n.locale)
    //     this.localeLoaded = true
    // },

    async mounted () {
        document.title = this.$t('title')

        if (location.host == 'seelie.inmagi.com' && !this.lastUpdate) {
            location.href = 'https://seelie.me'
        }

        this.insertKumoAds()

        // this.getNews()

        // if (!this.inmagi) this.insertKumoAds()
        // else this.insertAdsense()

        this.driveInit()
        this.driveCheckBan()

        window.addEventListener('beforeunload', (e) => {
            if (this.driveIsSignedIn && (this.driveIsSyncing || this.driveIsWaiting)) {
                e.preventDefault()
                e.returnValue = ''
            }
        });

        //  if (Notification.permission !== 'granted' && this.settings.resin_notification) Notification.requestPermission()

         this.lastNotified = this.computedResin

         setInterval(() => {
            this.time = this.dayjs()

            if (!this.settings.resin_notification || this.driveIsLoading || this.isConflictModalVisible) return

            ([...this.quantities]).reverse().forEach(q => {
                if (this.computedResin >= q && this.lastNotified < q) {
                    this.notify(this.$t('resin_tracker_notification', { resin: q }))
                    this.lastNotified = q
                }

                if (this.computedResin < q && this.lastNotified > this.computedResin) {
                    this.lastNotified = this.computedResin
                }
            })
        }, 1000 * this.resinInterval / 100)
    },

    methods: {
        checkOldData () {
            if (this.alreadyChecked) return
            if (this.driveIsLoading || (this.driveIsSignedIn && (this.driveIsSyncing || this.driveIsWaiting))) return

            this.checkDuplicates()
            this.checkWrongTraveler()
            this.checkOldStorage()
            this.checkOldTasks()

            this.alreadyChecked = true
            localStorage.setItem(`${this.account}-checked`, true)
        },

        checkOldStorage () {
            let ls = localStorage

            ls.removeItem('lang')
            ls.removeItem('unsaved')

            let keys = ['characters_mode', 'inventory_mode', 'inventory_buttons']

            keys.forEach(key => {
                if (ls.getItem(key)) {
                    this.setSetting(key, ls.getItem(key))
                    ls.removeItem(key)
                }
            })

            let accountKeys = ['goals', 'inactive', 'inventory', 'tasks', 'wl']

            accountKeys.forEach(key => {
                if (ls.getItem(key)) {
                    this[key] = JSON.parse(ls.getItem(key))
                    ls.removeItem(key)
                }
            })

            if (ls.getItem('server')) {
                this.server = ls.getItem('server')
                ls.removeItem('server')
            }
        },

        setResinBadge (resins) {
            if (!navigator || typeof navigator.setAppBadge != 'function') return

            navigator.setAppBadge(Math.floor(resins/40))
        },

        insertKumoAds () {
            if (this.dev) return

            let link1 = document.createElement('link')
            link1.rel = 'preconnect'
            link1.href = 'https://securepubads.g.doubleclick.net/'
            link1.crossOrigin = true
            document.head.appendChild(link1)

            let link2 = document.createElement('link')
            link2.rel = 'preconnect'
            link2.href = 'https://cdn.consentmanager.mgr.consensu.org/'
            link2.crossOrigin = true
            document.head.appendChild(link2)

            window.AdSlots = window.AdSlots || {clg: [], disableScripts: ['gpt']}

            let script1 = document.createElement('script')
            script1.async = true
            script1.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'
            document.head.appendChild(script1)

            let script2 = document.createElement('script')
            script2.async = true
            script2.setAttribute('site', 'seelieme')
            script2.src = 'https://kumo.network-n.com/dist/app.js'
            document.head.appendChild(script2)
        },

        insertAdsense () {
            let script = document.createElement('script')
            script.async = true
            script.onload = () => console.log("loaded")
            script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1264507135255733'
            script.crossOrigin = 'anonymous'
            document.head.appendChild(script)
        },

        async getNews () {
            if (typeof this.lastNewsUpdate == 'string') this.lastNewsUpdate = { en: null, pt: null, es: null, 'zh-tw': null }

            let now = this.dayjs()
            let langs = { en: ['en', 10], pt: ['pt', 203], es: ['es', 224], zh: ['zh-tw', 87], tw: ['zh-tw', 87] }
            let langCode = langs[this.$i18n.locale]?.[0] || 'en'
            let langId = langs[this.$i18n.locale]?.[1] || 10
            let news = this.news[langCode]
            let lastNewsUpdate = this.lastNewsUpdate[langCode]

            if (lastNewsUpdate && news.length && now.diff(lastNewsUpdate, 'hours') < 6) return
            console.log('getting news...')

            let url = `https://content-static-sea.hoyoverse.com/content/yuanshen/getContentList?pageSize=5&pageNum=1&channelId=${langId}`

            this.news[langCode] = (await axios.get(url))?.data.data.list || []
            this.lastNewsUpdate[langCode] = now.toISOString()

            localStorage.setItem('last_news_update', JSON.stringify(this.lastNewsUpdate))
            localStorage.setItem('news', JSON.stringify(this.news))
        },

        checkOldTasks () {
            if (!this.tasks.filter(t => !t.id).length) return
            console.log('needs to convert...')

            this.tasks = this.tasks.map((task, i) => ({...task, id: i+1}))
        }
    }
}
</script>
