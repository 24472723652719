<template>
    <div
        class="border-b border-gray-600 pb-3 mb-3 last:border-b-0 last:pb-0 last:mb-0 flex justify-between transition-all flex-col sm:flex-row"
        :class="{ 'opacity-30': cantComplete }"
    >
        <div>
            <p class="font-semibold md:text-lg leading-tight">
                {{ name }}

                <a :href="`https://genshin-impact.fandom.com/wiki/Special:Search?query=${name}`" target="_blank" class="mx-1" v-if="!index"><search-icon class="w-4 h-4 inline"/></a>

                <span class="text-xs bg-gray-700 shadow px-[.4rem] py-[.15rem] rounded-lg leading-none">{{ achievement.v }}</span>
            </p>
            <p class="mt-px opacity-75 leading-tight text-sm md:text-base">{{ description }}</p>

            <template v-if="quests">
                <div class="mt-1">
                    {{ $t('commissions') }}:

                    <div class="inline">
                        <a v-for="quest in quests" :key="quest[0]"
                            class="font-semibold text-purple-200 bg-purple-950/60 hover:bg-purple-950/80 text-xs px-2 py-1 rounded-md inline-block mr-1"
                            target="_blank"
                            :href="`https://genshin-impact.fandom.com/wiki/${
                                quest[0].replaceAll('?', '%3F')
                            }`"
                        >
                            {{ quest[1] }}
                        </a>
                    </div>
                </div>
            </template>

            <notes
                v-if="userAchievement.notes?.length"
                :note="userAchievement.notes"
                class="mt-2 bg-purple-700/60 px-2 py-1 text-sm rounded"
            />
        </div>

        <div class="flex items-center flex-shrink-0 sm:ml-2 mt-2 sm:mt-0 ml-auto">
            <router-link v-if="achievement.c" class="mr-4 relative" v-tippy="$t(achievement.c)" :to="`/achievements/commissions/${achievement.c}`">
                <img :src="`${url}img/areas/${achievement.c}.png`" class="w-10"/>
                <img :src="`${url}img/general/commission-color.png`" class="absolute bottom-0 right-0 w-8 -m-2"/>
            </router-link>

            <div class="mr-1 font-semibold text-lg">{{ achievement.r }}</div>
            <img :src="`${url}img/items/special/primogem.png`" class="w-6"/>

            <button
                class="rounded-xl ml-2 flex items-center justify-center p-2 bg-gray-700"
                @click="$emit('edit', achievement)"
            >
                <pencil-alt-icon class="w-4 opacity-60"/>
            </button>

            <button
                class="rounded-xl ml-1 flex items-center justify-center p-2 transition-all"
                :class="[done ? 'bg-purple-500' : 'bg-gray-700', { 'pointer-events-none': cantComplete }]"
                @click="toggleDone(achievement.id, !done, index)"
            >
                <check-icon class="w-4 transition-all" :class="{ 'opacity-20': !done }"/>
            </button>
        </div>
    </div>
</template>

<script>
import Notes from './Notes'

export default {
    components: { Notes },

    props: {
        achievement: Object,
        collection: Array,
        isSub: Boolean,
        index: Number
    },

    computed: {
        userAchievement () {
            return this.achievements[this.achievement.id] || {}
        },

        done () {
            return !!this.userAchievement.done
        },

        previous () {
            return this.collection?.[this.index-1]
        },

        next () {
            return this.collection?.[this.index+1]
        },

        cantComplete () {
            if (!this.isSub || !this.previous) return false

            if (!this.achievements[this.previous.id]?.done) return true

            return false
        },

        name () {
            return this.achievement?.n
                // ?.replace(/{M#(\S+)}{F#\S+}|{F#\S+}{M#(\S+)}/g, (a, b) => b).replace('#','')
        },

        description () {
            return this.achievement?.d
                // ?.replace(/{M#(\S+)}{F#\S+}|{F#\S+}{M#(\S+)}/g, (a, b) => b).replace('#','')
        },

        quests () {
            return this.achievement?.q
        }
    },

    methods: {
        toggleDone (id, done, index) {
            let achievements = this.achievements

            this.achievements = {
                ...achievements,
                [id]: { ...(achievements[id] || {}), done }
            }

            if (done) return

            let next = this.collection?.[index+1]
            if (next && achievements[next.id]?.done) this.toggleDone(next.id, false, index+1)
        }
    }
}
</script>
