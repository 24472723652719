<template>
    <div :id="type" class="tippy-seelie flex items-center justify-center flex-wrap gap-1 w-[240px] max-h-[300px] overflow-y-auto custom-scrollbar">
        <button
            class="rounded-full h-12 w-12 flex items-center justify-center"
            :class="!selected ? 'bg-teal-600' : 'bg-gray-600'"
            @click="$emit('update:selected', null)"
        >
            <img class="max-h-[2rem] max-w-[2rem]" :src="`${url}img/other/question-mark.svg`"/>
        </button>
        <button
            v-for="key in goalItems" :key="`item-option-${key}`"
            class="rounded-full h-12 w-12 flex items-center justify-center"
            :class="selected == key ? 'bg-teal-600' : 'bg-gray-600'"
            @click="$emit('update:selected', key)"
            v-tippy.arrow="maxTier[type] > 0 ? $t(`${key}.0`) : $t(key)"
        >
            <img
                class="max-h-[2rem] max-w-[2rem]"
                :src="`${spaces}/items/${type}/${key}${(maxTier[type] > 0 ? '_0' : '')}.png`"
            />
        </button>
    </div>
</template>

<script>
export default {
    emits: ['update:selected'],

    props: {
        type: String,
        selected: String
    },

    computed: {
        goalItems () {
            return Object.keys(this.items).filter(key => {
                if (this.getType(key) != this.type) return false
                if (this.isFuture(this.items[key].show)) return false

                return true
            })
        }
    }
}
</script>
