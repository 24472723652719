<template>
    <div class="bg-gray-600/80 shadow rounded-xl m-1 p-2 flex transition-colors">
        <div class="flex items-center w-full">
            <button class="-ml-2 mr-2 text-gray-300 opacity-75 handle cursor-move flex-shrink-0" v-if="showAll">
                <selector-icon class="w-5"/>
            </button>

            <button
                class="mr-3 rounded-full py-1 px-1 flex items-center justify-center"
                :class="diff < 0 ? 'opacity-25 bg-red-500' : 'bg-gray-400 opacity-75'"
                @click="toggleDone"
            >
                <check-icon class="w-5"/>
            </button>
            <div class="mr-4 leading-tight flex flex-col justify-center items-start">
                <div class="font-semibold text-sm sm:text-base">{{ defaultTasks[task.task] ? $t(task.task) : task.task }}</div>
                <!-- <div class="text-xs sm:text-sm text-gray-300 opacity-75">{{ task.notes }}</div> -->
                <notes
                    v-if="task.notes?.length"
                    :note="task.notes"
                    class="bg-teal-700/60 px-2 py-1 text-sm rounded"
                    button-class="bg-teal-900/90"
                />
            </div>
        </div>

        <div class="flex justify-end items-center w-auto ml-6 flex-shrink-0">
            <div
                class="rounded py-1 px-2 text-xs lowercase font-semibold flex items-center"
                :class="classes[diffText] || 'bg-gray-500'"
                v-tippy.arrow.left="`<span class='lowercase text-xs'>${$t('every')} ${task.recurring > 1 ? task.recurring : ''} ${$tc('day', task.recurring)}</span>`"
            >
                <refresh-icon class="w-4 h-4 mr-1"/>
                {{ $t(diffText) }}
            </div>

            <button class="ml-2 text-gray-300 opacity-75" @click="$emit('edit', task.id)" v-if="showAll">
                <pencil-alt-icon class="w-5"/>
            </button>

            <button class="ml-2 text-gray-300 opacity-75" @click="remove" v-if="showAll">
                <trash-icon class="w-5"/>
            </button>
        </div>
    </div>
</template>

<script>
import defaultTasks from '../database/default_tasks'
import Notes from './Notes'

export default {
    components: { Notes },

    emits: ['edit'],

    props: {
        task: Object,
        showAll: Boolean
    },

    data () {
        return {
            days: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
            defaultTasks,
            classes: {
                'overdue': 'bg-red-500',
                'today': 'bg-teal-500',
                'tomorrow': 'bg-teal-700'
            }
        }
    },

    computed: {
        diff () {
            return this.resetDay.diff(this.task.next, 'days')
        },
        diffText () {
            if (this.diff > 0) return 'overdue'
            if (this.diff === 0) return 'today'
            if (this.diff === -1) return 'tomorrow'
            return this.days[this.dayjs(this.task.next).day()]
        }
    },

    methods: {
        remove () {
            let index = this.tasks.findIndex(task => task.id == this.task.id)
            if (index < 0) return

            let tasks = this.tasks
            tasks.splice(index, 1)
            this.tasks = tasks
        },

        toggleDone () {
            let index = this.tasks.findIndex(task => task.id == this.task.id)
            if (index < 0) return

            let tasks = this.tasks

            if (this.diff < 0) {
                tasks[index].next = this.dayjs(tasks[index].next).subtract(tasks[index].recurring, 'days').format('YYYY-MM-DD')
            } else {
                tasks[index].next = this.dayjs(tasks[index].next).add(tasks[index].recurring, 'days').format('YYYY-MM-DD')
            }
            this.tasks = tasks
        }
    }
}
</script>
