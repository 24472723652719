<template>
    <SwitchGroup>
      <div class="flex items-center" :class="{ 'opacity-50': disabled }">
        <Switch
          :model-value="modelValue"
          @update:model-value="v => $emit('update:modelValue', v)"
          :class='[
            (!disabled || defaultValue) && modelValue ? "bg-purple-500" : "bg-gray-500",
            small ? "h-4 w-8" : "h-6 w-10"
          ]'
          class="relative inline-flex items-center rounded-full transition-colors focus:outline-none flex-shrink-0"
          :disabled="disabled"
        >
          <span
            :class='[
                (!disabled || defaultValue) && modelValue ? (small ? "translate-x-4" : "translate-x-5") : "translate-x-1",
                small ? "h-3 w-3" : "h-4 w-4"
            ]'
            class="inline-block transform rounded-full bg-gray-200 transition-transform"
          />
        </Switch>

        <SwitchLabel class="ml-2 font-semibold" :class="{ 'text-xs': small }">
            {{ label }}
            <slot></slot>
        </SwitchLabel>
      </div>
    </SwitchGroup>
  </template>

  <script>
    import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

    export default {
        props: {
            modelValue: Boolean,
            label: String,
            small: Boolean,
            disabled: Boolean,
            disabledValue: Boolean
        },

        emits: ['update:modelValue'],

        components: { Switch, SwitchGroup, SwitchLabel },

        data () {
            return {
                enabled: false
            }
        }
    }
  </script>
