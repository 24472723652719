import characters from '../database/characters'
import traveler from '../database/traveler'
import weapons from '../database/weapons'
import artifacts from '../database/artifacts'
import farming from '../database/farming'
import resin from '../database/resin'
import drops from '../database/drops'
import bosses from '../database/bosses'
import items from '../database/items'

export default {
    data () {
        let tierClasses = [
            'from-common-400 to-common-600',        // gray
            'from-uncommon-400 to-uncommon-600',    // green
            'from-rare-400 to-rare-600',            // blue
            'from-epic-400 to-epic-600',            // purple
            'from-legendary-400 to-legendary-600',  // gold
        ]

        return {
            bypassRelease: false,
            dev: import.meta.env.DEV,
            inmagi: window.location.host == 'seelie.inmagi.com',
            resinInterval: 8*60, // seconds
            resinCap: 200,
            url: import.meta.env.BASE_URL,
            // spaces: 'https://seelie.sfo3.cdn.digitaloceanspaces.com',
            spaces: import.meta.env.BASE_URL + 'img',
            zones: { america: -5, europe: 1, asia: 8 },
            characters, traveler, weapons, artifacts, farming, resin, drops, bosses, items,
            maxTier: { element_1: 3, wam: 3, common: 2, common_rare: 2, talent: 2 }, // element_2, boss, special, local
            elements: [ 'anemo', 'cryo', 'dendro', 'electro', 'geo', 'hydro', 'pyro', ],
            weaponTypes: [ 'sword', 'claymore', 'polearm', 'bow', 'catalyst' ],
            tiers: ['', 'common', 'uncommon', 'rare', 'epic', 'legendary'],
            tierClass: {
                common: tierClasses.slice(0, 3),
                common_rare: tierClasses.slice(1, 4),
                boss: tierClasses.slice(4),
                element_2: tierClasses.slice(3, 4),
                element_1: tierClasses.slice(1),
                talent: tierClasses.slice(1, 4),
                special: tierClasses.slice(4),
                wam: tierClasses.slice(1),
                local: tierClasses.slice(0, 1),
            },
            itemTierClass: {
                mora: tierClasses[2],
                xp: tierClasses[3],
                xp_sub_1: tierClasses[2],
                xp_sub_0: tierClasses[1],
                wep_xp: tierClasses[2],
                wep_xp_sub_1: tierClasses[1],
                wep_xp_sub_0: tierClasses[0],
                dream_solvent: tierClasses[3],
            },
            darkTierClass: {
                0: 'from-gray-500 to-gray-900',
                3: 'from-rare-550 to-rare-750',
                4: 'from-epic-550 to-epic-750',
                5: 'from-legendary-550 to-legendary-750',
            },
            characterCardTierClass: {
                3: 'from-rare-550/70 to-rare-750/90',
                4: 'from-epic-550/70 to-epic-750/90',
                5: 'from-legendary-550/60 to-legendary-750/90',
            }
        }
    },

    computed: {
        touch () {
            return 'ontouchstart' in window || !!navigator.maxTouchPoints || !!navigator.msMaxTouchPoints
        },
    },

    methods: {
        abbr (abbr, kDigit) {
            if (abbr > 999999) return Math.round(abbr / 100000) / 10 + 'm'
            if (abbr > 999 && kDigit) return Math.ceil(abbr / 100) / 10 + 'k'
            if (abbr > 999) return Math.ceil(abbr / 1000) + 'k'
            return Math.ceil(abbr)
        },

        heroWits (xp) {
            return Math.ceil(xp / 20000)
        },

        isFuture (value) {
            if (this.bypassRelease) return false

            if (!value) return false

            if (value.length == 10) {
                value = this.dayjs.utc(`${value} 02:30`)

                return this.time.diff(value, 'hour', true) < 0
            }

            value = this.dayjs.utc(value)

            return this.serverTime.diff(value, 'hour', true) < 0
        },

        isNew (value) {
            if (!value) return false

            let diff

            if (value.length == 10) {
                value = this.dayjs.utc(`${value} 02:30`)
                diff = this.time.diff(value, 'hour', true)
            }
            else {
                value = this.dayjs.utc(value)
                diff = this.serverTime.diff(value, 'hour', true)
            }

            if (this.bypassRelease) return diff <= (7*24)

            return diff >= 0 && diff <= (7*24)
        },

        isFutureOrNew (value) {
            return this.isFuture(value) || this.isNew(value)
        },

        calculateSize (data) {
            let bytes = encodeURI(JSON.stringify(data)).split(/%..|./).length - 1
            return Math.round(bytes / 1024 * 100) / 100
        },

        notify (msg) {
            if (Notification.permission !== 'granted') this.toast = this.$t('notifications_exp')
            else {
                let notification = new Notification('SEELIE.me', {
                    icon: `${this.url}img/icons/icon-192.png`,
                    body: msg
                })
                notification.onclick = function() {
                    window.focus()
                    this.cancel()
                }
            }
        },

        getType (key) {
            return this.items[key]?.type || key
        }
    }
}
