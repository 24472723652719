export default {
	"anemo": [	// anemo: normal, skill, burst	geo: normal
		{},
		{},
		{'talent': 'freedom', 'common': 'scroll', 'boss': 'dvalins_sigh'},
		{'talent': 'resistance', 'common': 'scroll', 'boss': 'dvalins_sigh'},
		{'talent': 'ballad', 'common': 'scroll', 'boss': 'dvalins_sigh'},
		{'talent': 'freedom', 'common': 'scroll', 'boss': 'dvalins_sigh'},
		{'talent': 'resistance', 'common': 'scroll', 'boss': 'dvalins_sigh'},
		{'talent': 'ballad', 'common': 'scroll', 'boss': 'dvalins_sigh'},
		{'talent': 'freedom', 'common': 'scroll', 'boss': 'dvalins_sigh'},
		{'talent': 'resistance', 'common': 'scroll', 'boss': 'dvalins_sigh'},
		{'talent': 'ballad', 'common': 'scroll', 'boss': 'dvalins_sigh'},
	],
	"geo": [	// geo: skill, burst
		{},
		{},
		{'talent': 'prosperity', 'common': 'arrowhead', 'boss': 'tail_of_boreas'},
		{'talent': 'diligence', 'common': 'arrowhead', 'boss': 'tail_of_boreas'},
		{'talent': 'gold', 'common': 'arrowhead', 'boss': 'tail_of_boreas'},
		{'talent': 'prosperity', 'common': 'arrowhead', 'boss': 'tail_of_boreas'},
		{'talent': 'diligence', 'common': 'arrowhead', 'boss': 'tail_of_boreas'},
		{'talent': 'gold', 'common': 'arrowhead', 'boss': 'tail_of_boreas'},
		{'talent': 'prosperity', 'common': 'arrowhead', 'boss': 'tail_of_boreas'},
		{'talent': 'diligence', 'common': 'arrowhead', 'boss': 'tail_of_boreas'},
		{'talent': 'gold', 'common': 'arrowhead', 'boss': 'tail_of_boreas'},
	],
	"electro": [	// electro: all
		{},
		{},
		{'talent': 'transience', 'common': 'handguard', 'boss': 'dragon_lords_crown'},
		{'talent': 'elegance', 'common': 'handguard', 'boss': 'dragon_lords_crown'},
		{'talent': 'light', 'common': 'handguard', 'boss': 'dragon_lords_crown'},
		{'talent': 'transience', 'common': 'handguard', 'boss': 'dragon_lords_crown'},
		{'talent': 'elegance', 'common': 'handguard', 'boss': 'dragon_lords_crown'},
		{'talent': 'light', 'common': 'handguard', 'boss': 'dragon_lords_crown'},
		{'talent': 'transience', 'common': 'handguard', 'boss': 'dragon_lords_crown'},
		{'talent': 'elegance', 'common': 'handguard', 'boss': 'dragon_lords_crown'},
		{'talent': 'light', 'common': 'handguard', 'boss': 'dragon_lords_crown'},
	],
    "dendro": [	// dendro: all
		{},
		{},
		{'talent': 'admonition', 'common': 'spores', 'boss': 'mudra_of_the_malefic_general'},
		{'talent': 'ingenuity', 'common': 'spores', 'boss': 'mudra_of_the_malefic_general'},
		{'talent': 'praxis', 'common': 'spores', 'boss': 'mudra_of_the_malefic_general'},
		{'talent': 'admonition', 'common': 'spores', 'boss': 'mudra_of_the_malefic_general'},
		{'talent': 'ingenuity', 'common': 'spores', 'boss': 'mudra_of_the_malefic_general'},
		{'talent': 'praxis', 'common': 'spores', 'boss': 'mudra_of_the_malefic_general'},
		{'talent': 'admonition', 'common': 'spores', 'boss': 'mudra_of_the_malefic_general'},
		{'talent': 'ingenuity', 'common': 'spores', 'boss': 'mudra_of_the_malefic_general'},
		{'talent': 'praxis', 'common': 'spores', 'boss': 'mudra_of_the_malefic_general'},
	],
    "hydro": [	// hydro: all
		{},
		{},
		{'talent': 'fairness', 'common': 'transoceanic', 'boss': 'worldspan_fern'},
		{'talent': 'justice', 'common': 'transoceanic', 'boss': 'worldspan_fern'},
		{'talent': 'order', 'common': 'transoceanic', 'boss': 'worldspan_fern'},
		{'talent': 'fairness', 'common': 'transoceanic', 'boss': 'worldspan_fern'},
		{'talent': 'justice', 'common': 'transoceanic', 'boss': 'worldspan_fern'},
		{'talent': 'order', 'common': 'transoceanic', 'boss': 'worldspan_fern'},
		{'talent': 'fairness', 'common': 'transoceanic', 'boss': 'worldspan_fern'},
		{'talent': 'justice', 'common': 'transoceanic', 'boss': 'worldspan_fern'},
		{'talent': 'order', 'common': 'transoceanic', 'boss': 'worldspan_fern'},
	]
}
