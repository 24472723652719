<template>
    <div
        class="rounded-3xl py-3 px-3 sm:px-5 text-left shadow bg-gradient-to-br from-gray-700 to-gray-600 flex relative overflow-hidden w-full transition-all"
        :class="{ 'opacity-100': allDone }"
    >
        <div
            class="absolute inset-0 transition-all duration-500 z-0 bg-gradient-to-br from-purple-700/20 to-purple-950/60"
            :class="allDone ? 'w-full' : 'w-0'"
        ></div>

        <div class="self-start flex items-center w-6 md:w-8 mr-2 md:mr-3 mt-1 flex-shrink-0">
            <img class="w-full opacity-50" :src="`${url}img/general/achievement.png`">
        </div>

        <div class="w-full z-10" v-if="Array.isArray(achievement)">
            <achievement-item
                v-for="(sub, index) in achievement"
                :key="`achievement-sub-${sub.id}`"
                :achievement="sub"
                :collection="achievement"
                :index="index"
                is-sub
                @edit="v => $emit('edit', v)"
            />
        </div>

        <div class="w-full z-10" v-else>
            <achievement-item :achievement="achievement" @edit="v => $emit('edit', v)"/>
        </div>
    </div>
</template>

<script>
import AchievementItem from './AchievementItem.vue'

export default {
    components: { AchievementItem },

    props: {
        achievement: [Array, Object]
    },

    computed: {
        allDone () {
            if (!Array.isArray(this.achievement)) return (this.achievements[this.achievement.id] || {}).done

            return this.achievement.map(a => (this.achievements[a.id] || {}).done).filter(d => d).length == this.achievement.length
        }
    }
}
</script>
