<template>
    <div class="text-white">
        <h1 class="text-xl font-bold">{{ $t('characters') }}</h1>
        <div class="mt-4 flex items-center">
            <div class="sm:w-32">
                <characters-modal>
                    <template v-slot:trigger>
                        <div class="bg-blue-600/60 hover:bg-blue-600 font-semibold text-sm rounded-lg py-2 px-3 shadow hover:shadow-lg w-full sm:w-32 transition-all">
                            {{ $t('add') }}
                        </div>
                    </template>
                </characters-modal>
            </div>

            <div class="ml-2 h-full">
                <button class="rounded-l p-2 h-full" :class="mode === 'vertical' ? 'bg-yellow-500' : 'bg-gray-700'" @click="mode = 'vertical'">
                    <view-grid-icon class="w-4"/>
                </button>
                <button class="rounded-r p-2 h-full" :class="mode === 'horizontal' ? 'bg-yellow-500' : 'bg-gray-700'" @click="mode = 'horizontal'">
                    <view-list-icon class="w-4"/>
                </button>
            </div>
        </div>

        <div class="mt-4">
            <div class="font-semibold uppercase text-sm mb-1">{{ $t('filter') }}</div>

            <div class="flex flex-wrap lg:flex-nowrap items-center justify-center lg:justify-start gap-x-2 gap-y-2">
                <div class="flex items-center space-x-[0.15rem] flex-shrink-0">
                    <button @click="filter('element', element)" v-for="element in elements" :key="`element-${element}`" :class="elementFilter === element ? 'bg-yellow-500' : 'bg-gray-700/70'" class="w-10 h-10 flex items-center justify-center rounded-full focus:outline-none overflow-hidden">
                        <img :src="`${url}img/elements/${element}.png`" class="h-6">
                    </button>
                </div>
                <div class="flex items-center space-x-[0.15rem] flex-shrink-0">
                    <button @click="filter('weapon', weaponType)" v-for="weaponType in weaponTypes" :key="`weapon-type-${weaponType}`" :class="weaponFilter === weaponType ? 'bg-yellow-500' : 'bg-gray-700/70'" class="w-10 h-10 flex items-center justify-center rounded-full focus:outline-none overflow-hidden">
                        <img :src="`${spaces}/weapons/_${weaponType}.png`">
                    </button>
                </div>
                <button @click="hideInactive = !hideInactive" :class="hideInactive ? 'bg-yellow-500' : 'bg-gray-700/70'" class="w-10 h-10 flex-shrink-0 rounded-full overflow-hidden flex items-center justify-center">
                    <eye-off-icon class="w-6"/>
                </button>

                <div class="relative flex items-center justify-center">
                    <input type="text" :placeholder="$t('search')"
                        class="bg-gray-800 border-none rounded-md w-full max-w-xs pr-8" v-model="search" @keyup.esc="search = ''"
                    >
                    <button v-if="search.trim().length" class="absolute right-0 mr-2" @click="search = ''">
                        <x-icon class="w-5 h-5"/>
                    </button>
                </div>
            </div>

            <ad class="mt-5"/>

            <div v-if="filteredCharacters.length"
                class="mt-4"
                :class="mode === 'vertical' ? 'flex flex-wrap -mx-2 sm:-mx-3 justify-center sm:justify-start' :
                    'grid 2xl:grid-cols-2 gap-y-2 gap-x-3'"
            >
                <div
                    v-show="!hideInactive || !inactive[character]"
                    v-for="character in filteredCharacters"
                    :key="`character-${character}`"
                    class="shadow-md flex"
                    :class="{ 'm-2 sm:m-3': mode === 'vertical' }"
                >
                    <characters-modal :editing="character" w-full>
                        <template v-slot:trigger>
                            <component :is="`character-card-${mode}`" :selected="character" :class="{ 'opacity-50 filter-grayscale': inactive[character] }"/>
                        </template>
                    </characters-modal>
                </div>
            </div>

            <div v-else class="font-semibold text-xl mt-4 text-gray-200 opacity-75">
                {{ $t('no_characters') }}
            </div>
        </div>
    </div>

    <ad class="mt-5" bottom v-if="filteredCharacters.length >= 4"/>
</template>

<script>
import Ad from '@/components/Ad.vue'
import CharactersModal from '@/components/CharactersModal.vue'
import CharacterCardHorizontal from '@/components/CharacterCardHorizontal.vue'
import CharacterCardVertical from '@/components/CharacterCardVertical.vue'

export default {
    components: {
        Ad, CharactersModal, CharacterCardHorizontal, CharacterCardVertical
    },

    data () {
        return {
            search: '',
            elementFilter: null,
            weaponFilter: null,
            hideInactive: JSON.parse(localStorage.getItem('charactersHideInactive')) || false
        }
    },

    watch: {
        hideInactive (value) { localStorage.setItem('charactersHideInactive', value) }
    },

    computed: {
        mode: {
            get () { return this.settings.characters_mode },
            set (value) { this.setSetting('characters_mode', value) }
        },

        filteredCharacters () {
            return Object.keys(this.characters).concat(Object.keys(this.customs))
                .filter(c => {
                    return (!this.customs[c] || this.customs[c].custom == 'character')
                        && (!this.elementFilter || this.elementFilter === (this.characters[c] || this.customs[c]).element)
                        && (!this.weaponFilter || this.weaponFilter === (this.characters[c] || this.customs[c]).weapon)
                        && (this.customs[c] || this.goals.find(g => g.character && g.character === c))
                        && (!this.search || (this.customs[c]?.name || this.$t(c)).toLowerCase().includes(this.search.toLowerCase()))
                })
                .sort((a, b) => (this.customs[a]?.name || this.$t(a)).localeCompare(this.customs[b]?.name || this.$t(b)))
        }
    },

    methods: {
        filter (type, value) {
            if (this[`${type}Filter`] === value) return this[`${type}Filter`] = null
            return this[`${type}Filter`] = value
        }
    }
}
</script>
