<template>
    <div>
        <div class="font-semibold text-center uppercase text-sm mb-1">{{ $t('filter') }}</div>
        <div class="flex flex-wrap items-center justify-center gap-x-2 gap-y-2">
            <div class="flex items-center space-x-[0.12rem]">
                <button @click="filter('element', element)" v-for="element in elements" :key="`element-${element}`" :class="elementFilter === element ? 'bg-yellow-500' : 'bg-gray-700/70'" class="w-9 h-9 flex items-center justify-center rounded-full focus:outline-none overflow-hidden">
                    <img :src="`${url}img/elements/${element}.png`" class="h-6">
                </button>
            </div>
            <div class="flex items-center space-x-[0.12rem]">
                <button @click="filter('weapon', weaponType)" v-for="weaponType in weaponTypes" :key="`weapon-type-${weaponType}`" :class="weaponFilter === weaponType ? 'bg-yellow-500' : 'bg-gray-700/70'" class="w-9 h-9 flex items-center justify-center rounded-full focus:outline-none overflow-hidden">
                    <img :src="`${spaces}/weapons/_${weaponType}.png`" class="h-7">
                </button>
            </div>
            <div v-if="!replacing">
                <button @click="showAdded = !showAdded" :class="showAdded ? 'bg-yellow-500' : 'bg-gray-700/70'" class="w-9 h-9 rounded-full overflow-hidden flex items-center justify-center">
                    <eye-icon class="w-5"/>
                </button>
            </div>
        </div>
        <div class="flex flex-wrap items-start justify-center mt-4 -m-2">
            <button class="mx-1 mb-3 relative" v-if="!replacing && !elementFilter && !weaponFilter" @click="$emit('update:selected', 'custom')">
                <div class="w-20 sm:w-24 h-20 sm:h-24 rounded-xl bg-gradient-to-br" :class="darkTierClass[0]">
                    <img :src="`${url}img/other/custom_male.png`">
                </div>
                <p class="text-xs text-center font-semibold tracking-tight">{{ $t('custom') }}</p>
            </button>
            <button
                v-for="character in filteredCharacters"
                :key="`add-${character.key}`"
                class="mx-1 mb-3 relative w-20 sm:w-24"
                :class="{ 'opacity-25': goals.find(g => g.character === character.key) }"
                @click="$emit('update:selected', character.key)"
            >
                <div class="w-20 sm:w-24 h20 sm:h-24 rounded-xl overflow-hidden relative bg-gradient-to-br"
                    :class="darkTierClass[character.tier]"
                >
                    <img :src="`${spaces}/characters/select/${character.traveler ? `traveler_${gender}` : character.key}.png`">
                    <img :src="`${url}img/elements/${character.element}.png`" class="absolute bottom-0 right-0 w-6 h-6" v-if="character.element">
                </div>
                <div class="bg-orange-700 absolute-tag !text-sm" v-if="isFuture(character.release)">{{ $t('soon')}}</div>
                <div class="bg-green-700 absolute-tag !text-sm" v-if="isNew(character.release)">{{ $t('new')}}</div>
                <p class="text-xs text-center font-semibold tracking-tight flex justify-center">
                    {{ character.traveler ? `${character.name} (${$t(character.element)})` : character.name }}
                </p>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['update:selected'],

    props: {
        replacing: Boolean
    },

    data () {
        return {
            elementFilter: null,
            weaponFilter: null,
            showAdded: false,
        }
    },

    computed: {
        filteredCharacters () {
            return Object.keys(this.characters)
                .filter(c => {
                    return ((!this.replacing && this.showAdded) || !this.goals.find(g => g.character === c))
                        && (!this.elementFilter || this.elementFilter === this.characters[c].element)
                        && (!this.weaponFilter || this.weaponFilter === this.characters[c].weapon)
                        && !this.isFuture(this.characters[c].show)
                })
                .map(c => ({ key: c, name: this.$t(c), ...this.characters[c] }))
                .sort((a, b) => a.name.localeCompare(b.name))
        }
    },

    methods: {
        filter (type, value) {
            if (this[`${type}Filter`] === value) return this[`${type}Filter`] = null
            return this[`${type}Filter`] = value
        }
    }
}
</script>
