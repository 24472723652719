<template>
    <button @click="click" @contextmenu.prevent="context" class="w-full h-full">
        <slot name="trigger"></slot>
    </button>

    <Dialog :draggable="false" :closable="selected != 'custom' && !preventClosing" :close-on-escape="selected != 'custom'" content-class="-mt-4" :header="$t('weapons')" position="top" dismissable-mask v-model:visible="isVisible" modal style="width: 600px;" @hide="hide">
        <template v-if="selected" v-slot:header>
            <div class="flex items-center justify-center mr-2">
                <button @click="selected = null" v-if="!editingGoal" class="flex items-center bg-gray-700 rounded-full p-2 mr-1"><chevron-left-icon class="w-4"/></button>

                <div class="w-8 h-8 rounded-lg overflow-hidden relative bg-gradient-to-tl mr-2 flex items-center justify-center"
                    :class="darkTierClass[weapon?.tier || 0]"
                >
                    <img :src="`${spaces}/weapons/${selected}.png`" class="object-contain" v-if="weapon && !weapon.custom">
                    <img :src="`${url}img/weapons/__${weapon.type}-billet.png`" class="max-h-6" v-else-if="weapon?.type">
                    <img :src="`${url}img/other/question-mark.svg`" class="w-5 h-5" v-else>
                </div>

                <div class="w-32 sm:w-96 md:w-auto flex items-center">
                    <p class="text-sm md:text-base uppercase font-semibold tracking-tight leading-none">
                        {{ weapon?.name || $t(selected == 'custom' ? 'custom_a' : selected) }}
                    </p>

                    <button class="ml-3" v-if="editingGoal && weapon && !weapon.custom" @click="remove"><trash-icon class="w-5"/></button>
                    <button class="ml-2 sm:ml-3" @click="editingCustom = !editingCustom" v-if="!replacingCustom && weapon?.custom"><pencil-alt-icon class="w-5"/></button>
                </div>
            </div>

            <div class="ml-auto flex items-center">
                <img :src="`${spaces}/characters/select/${character.traveler ? `traveler_${gender}` : editingGoal.character}.png`" class="rounded-full w-7 h-7 mr-1" v-if="character && !character.custom"/>

                <img :src="`${url}img/other/custom_${character.gender}.png`" class="rounded-full w-7 h-7 mr-1" v-else-if="character?.custom"/>

                <input type="checkbox" class="rounded w-5 h-5" v-tippy="$t('active')"
                    :checked="!inactive[editingGoal.id]"
                    @input="setInactive(editingGoal.id, !inactive[editingGoal.id])"
                    v-else-if="editingGoal && weapon"
                >
            </div>
        </template>

        <div v-if="weapon && editingGoal && !editingGoal.character" class="mt-1">
            <Textarea
                class="w-full !text-sm"
                :placeholder="$t('notes')"
                :model-value="notes[editingGoal.id]"
                @input="updateNotes"
                auto-resize
                rows="1"
                v-if="!replacingCustom"
            />

            <div class="mb-2 bg-teal-600 rounded py-3 px-2 mt-4 leading-none tracking-tight font-semibold flex items-start" v-else>
                <exclamation-circle-icon class="w-6 mr-2 flex-shrink-0"/>
                <div class="flex flex-col items-start">
                    Selecione uma arma existente para substituir.
                    <button class="mt-2 bg-yellow-600 py-1 px-2 rounded shadow font-semibold" @click="replacingCustom = null">{{ $t('cancel') }}</button>
                </div>
            </div>
        </div>

        <edit-custom-weapon
            v-if="!replacingCustom && (editingCustom || selected == 'custom')"
            class="mt-4"
            v-model:editing-goal="editingGoal"
            v-model:selected="selected"
            v-model:editing="editingCustom"
            v-model:replacing="replacingCustom"
        />

        <goals-modal-weapon
            decoupled
            v-model:selected-decoupled="selected"
            v-model:editing-goal="editingGoal"
            :replacing="!!replacingCustom"
            v-if="!selected || replacingCustom || weapon"
        />
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import Textarea from 'primevue/textarea'
import EditCustomWeapon from './EditCustomWeapon'
import GoalsModalWeapon from './GoalsModalWeapon'

export default {
    props: {
        goal: Object
    },

    components: { Dialog, Textarea, EditCustomWeapon, GoalsModalWeapon },

    data () {
        return {
            editingGoal: this.goal,
            selected: this.goal?.weapon,
            notesTimeout: null,
            editingCustom: false,
            replacingCustom: null,
            preventClosing: false
        }
    },

    watch: {
        selected () {
            if (this.selected) {
                let el = document.getElementsByClassName('p-dialog-content')
                el && el[0] && el[0].scrollTo(0, 0)
            }

            if (this.selected && this.replacingCustom) {
                let goals = this.goals
                goals.filter(g => g.weapon == this.replacingCustom)
                    .forEach(g => g.weapon = this.selected)
                this.goals = goals
                this.setCustom(this.replacingCustom, null)
                this.editingCustom = false
                this.replacingCustom = null
            }
        }
    },

    computed: {
        isVisible: {
            get () { return this.weaponModal === this.goal?.id },
            set (v) {
                if (v) this.weaponModal = this.goal?.id
                else this.weaponModal = false
            }
        },

        weapon () {
            return this.weapons[this.selected] || this.customs[this.selected]
        },

        character () {
            return this.characters[this.editingGoal?.character] || this.customs[this.editingGoal?.character]
        }
    },

    methods: {
        click () {
            this.weaponModal = this.goal?.id
        },

        context () {
           if (!this.editingGoal?.character) return this.setInactive(this.goal.id, !this.inactive[this.goal.id])
        },

        hide () {
            if (!this.goal) {
                this.editingGoal = null
                this.selected = null
            }
            this.replacingCustom = null
            this.editingCustom = null
        },

        remove () {
            this.isVisible = false

            if (this.editingGoal) {
                this.removeGoal(this.editingGoal)
                if (!this.editingGoal.character) this.setNotes(this.editingGoal.id, null)
            }
            this.editingGoal = null
            this.selected = null
            this.replacingCustom = null
            this.editingCustom = null
        },

        updateNotes (e) {
            this.preventClosing = true
            clearTimeout(this.notesTimeout)

            this.notesTimeout = setTimeout(() => {
                this.setNotes(this.editingGoal.id, e.target.value)
                this.preventClosing = false
            }, 400)
        }
    }
}
</script>
