import { nextTick } from 'vue'
import Tippy, { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/translucent.css'
import 'tippy.js/dist/svg-arrow.css'

window.tippy = Tippy

let defaultOptions = {
    ignoreAttributes: true,
    allowHTML: true,
    arrow: false,
    theme: 'translucent',
    // interactive: true,
    // appendTo: function () {
    //     return document.getElementById('app')
    // }
}

function createTippy (el, binding, vnode) {
    const handlers = (vnode.data && vnode.data.on) || (vnode.componentOptions && vnode.componentOptions.listeners)
    let options = typeof binding.value === 'object' ? (binding.value || {}) : { content: binding.value }

    if (options.html) {
        options.content = document.getElementById(options.html)
        delete options.html
    } else if (binding.modifiers.html || binding.modifiers.dropdown) {
        options.content = document.getElementById(options.content)
    }

    if (typeof options.content === 'object') {
        options.content && options.content.addEventListener('click', (e) => {
            let closable = e.target.closest('.close')
            if (closable && (closable === e.target || closable.contains(e.target))) {
                el._tippy && el._tippy.hide()
            }
        })
    }

    if (!options.content) return

    if (handlers && handlers.shown) options.onShown = () => handlers.shown.call(el, vnode)
    if (handlers && handlers.hidden) options.onHidden = () => handlers.hidden.call(el, vnode)

    if (binding.modifiers.dropdown) {
        options.placement = 'bottom'
        options.interactive = true
        options.interactiveBorder = 30
        options.interactiveDebounce = 400

        if (!binding.modifiers.hover) {
            options.trigger = 'click'

            const keepOpen = options.keepOpen

            let onShown = options.onShown
            options.onShown = (i) => {
                if (!keepOpen) i.setProps({ trigger: 'mouseenter' })
                if (onShown) onShown.call(this, i)
            }

            let onHidden = options.onHidden
            options.onHidden = (i) => {
                i.setProps({ trigger: 'click' })
                if (onHidden) onHidden.call(this, i)
            }
        }
    }

    if (binding.modifiers.touch) {
        options.touch = ['hold', 1000]
        el.style.userSelect = 'none'
    }

    if (binding.modifiers.fasttouch) {
        options.touch = ['hold', 400]
        el.style.userSelect = 'none'
    }

    if (binding.modifiers.interactive) options.interactive = true
    if (binding.modifiers.click) options.trigger = 'click'
    if (binding.modifiers.focus) options.trigger = 'focus'

    if (binding.modifiers.arrow) options.arrow = roundArrow

    if (binding.modifiers.lessDelayed) options.delay = [250, 0]
    if (binding.modifiers.delayed) options.delay = [500, 0]
    if (binding.modifiers.moreDelayed) options.delay = [800, 0]

    // if (binding.modifiers.dark) options.theme = 'inmagi-dark'
    // if (binding.modifiers.light) options.theme = 'inmagi-light'

    let placement = getPlacement(binding.modifiers)
    if (placement) options.placement = placement

    delete options.keepOpen

    options = {
        ...defaultOptions,
        ...options
    }

    if (el._tippy) {
        return el._tippy.setProps(options)
    }

    // eslint-disable-next-line no-new
    new Tippy(el, options)
}

function getPlacement (modifiers) {
    let MODS = Object.keys(modifiers)
    let POSITIONS = ['top', 'bottom', 'left', 'right']
    let SUB_POSITIONS = ['start', 'end']
    let head = null
    let tail = null

    for (let i = 0; i < MODS.length; i++) {
        const pos = MODS[i]
        if (POSITIONS.indexOf(pos) > -1) {
            head = pos
        }
        if (SUB_POSITIONS.indexOf(pos) > -1) {
            tail = pos
        }
    }
    return (head && tail) ? `${head}-${tail}` : head
}

export default {
    mounted (el, binding, vnode) {
        nextTick(() => {
            createTippy(el, binding, vnode)
        })
    },
    unmounted (el) {
        el._tippy && el._tippy.destroy()
    },
    updated (el, binding, vnode) {
        const options = binding.value || {}
        const oldOptions = binding.oldValue || {}

        if (el._tippy && (JSON.stringify(options) !== JSON.stringify(oldOptions))) {
            nextTick(() => {
                createTippy(el, binding, vnode)
            })
        }

        if (el._tippy && el._tippy.popperInstance && options.show) {
            el._tippy.show()
        } else if (el._tippy && el._tippy.popperInstance && !options.show && options.trigger === 'manual') {
            el._tippy.hide()
        }
    }
}
