import { createApp } from 'vue'
import setupI18n from './i18n.js'
import App from './App.vue'
import router from './router'
import './assets/styles.scss'
import commonMixin from './mixins/common.js'
import userMixin from './mixins/user.js'
import TippyDirective from './tippy'
import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/vela-purple/theme.css'
import 'primevue/resources/primevue.min.css'
import ToastService from 'primevue/toastservice'
import RegisterIcons from './icons.js'

const app = createApp(App)

app.use(router)

app.use(ToastService)
app.directive('tippy', TippyDirective)
app.mixin(commonMixin)
app.mixin(userMixin)

RegisterIcons(app)

app.use(PrimeVue)

app.config.globalProperties.$url = path => {
    return new URL(`./common/images/${path}`, import.meta.url).href
}

app.config.globalProperties.$public = path => {
    return `/img/${path}`
}

setupI18n().then(i18n => {
    app.use(i18n)
    app.mount('#app')
})
