<template>
    <div class="text-white">
        <h1 class="text-xl font-bold">{{ $t('inventory') }}</h1>

        <div class="uppercase font-semibold text-sm mt-4">{{ $t('filter') }}</div>
        <div class="text-xs flex gap-1 flex-wrap">
            <button @click="filter = null" :class="!filter ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('all') }}</button>
            <button @click="filter = 'common'" :class="filter === 'common' ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('weapon_and_character') }}</button>
            <button @click="filter = 'common_rare'" :class="filter === 'common_rare' ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('weapon_items') }}</button>
            <button @click="filter = 'wam'" :class="filter === 'wam' ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('weapon_domains') }}</button>
            <button @click="filter = 'element_2'" :class="filter === 'element_2' ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('bosses') }}</button>
            <button @click="filter = 'boss'" :class="filter === 'boss' ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('weekly_bosses') }}</button>
            <button @click="filter = 'element_1'" :class="filter === 'element_1' ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('gems') }}</button>
            <button @click="filter = 'talent'" :class="filter === 'talent' ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('talent') }}</button>
            <button @click="filter = 'local'" :class="filter === 'local' ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('specialties') }}</button>
        </div>

        <div class="uppercase font-semibold text-sm mt-4">{{ $t('show') }}</div>
        <div class="flex text-xs flex-wrap gap-1">
            <button @click="show = 'all'" :class="show === 'all' ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('all') }}</button>
            <button @click="show = 'needed'" :class="show === 'needed' ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('needed') }}</button>
            <button @click="show = 'craftable'" :class="show === 'craftable' ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('craftable') }}</button>

            <div class="rounded overflow-hidden bg-gray-500 place-self-start">
                <button class="py-2 px-2" :class="mode === 'vertical' ? 'bg-yellow-500' : 'bg-gray-500'" @click="mode = 'vertical'">
                    <view-grid-icon class="w-4"/>
                </button>
                <button class="py-2 px-2" :class="mode === 'horizontal' ? 'bg-yellow-500' : 'bg-gray-500'" @click="mode = 'horizontal'">
                    <view-list-icon class="w-4"/>
                </button>
            </div>

            <template v-if="mode === 'vertical'">
                <button @click="buttons = !buttons" :class="buttons ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">+ / -</button>
                <button @click="craftButton = !craftButton" :class="craftButton ? 'bg-yellow-500' : 'bg-gray-500'" class="rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">{{ $t('craft_button') }}</button>
            </template>

            <inventory-import/>
            <inventory-clean/>
        </div>

        <div class="uppercase font-semibold text-sm mt-4">{{ $t('hide') }}</div>
        <div class="flex text-xs flex-wrap space-x-1">
            <button @click="options.hideNeed = !options.hideNeed" :class="options.hideNeed ? 'bg-red-500' : 'bg-gray-500'" class="flex rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">
                <flag-icon class="w-4 mr-1"/>{{ $t('need') }}
            </button>
            <button @click="options.hideMissing = !options.hideMissing" :class="options.hideMissing ? 'bg-red-500' : 'bg-gray-500'" class="flex rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">
                <exclamation-circle-icon class="w-4 mr-1"/>{{ $t('missing') }}
            </button>
            <button @click="options.hideCraft = !options.hideCraft" :class="options.hideCraft ? 'bg-red-500' : 'bg-gray-500'" class="flex rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">
                <beaker-icon class="w-4 mr-1"/>{{ $t('craft') }}
            </button>
            <button @click="hideUnreleased = !hideUnreleased" :class="hideUnreleased ? 'bg-red-500' : 'bg-gray-500'" class="flex rounded py-2 px-3 shadow hover:shadow-lg transition-shadow">
                {{ $t('unreleased_s') }}
            </button>
        </div>

        <div v-if="!filter" class="mt-6 space-y-2">
            <div class="flex flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-1">
                <component :is="`inventory-item-horizontal`" :tier="0" type="mora" item="mora" :options="options" size="sm:w-60"/>
                <component :is="`inventory-item-horizontal`" :tier="0" type="special" item="dream_solvent" :options="options" size="sm:w-44"/>
            </div>
            <div class="flex flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-1">
                <component :is="`inventory-item-horizontal`" :tier="0" type="xp" item="xp" :options="options" size="sm:w-60"/>
                <component :is="`inventory-item-horizontal`" :tier="0" type="xp" item="xp_sub_1" :options="options" size="sm:w-28" hide-info/>
                <component :is="`inventory-item-horizontal`" :tier="0" type="xp" item="xp_sub_0" :options="options" size="sm:w-28" hide-info/>
            </div>
            <div class="flex flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-1">
                <component :is="`inventory-item-horizontal`" :tier="0" type="wep_xp" item="wep_xp" :options="options" size="sm:w-60"/>
                <component :is="`inventory-item-horizontal`" :tier="0" type="wep_xp" item="wep_xp_sub_1" :options="options" size="sm:w-28" hide-info/>
                <component :is="`inventory-item-horizontal`" :tier="0" type="wep_xp" item="wep_xp_sub_0" :options="options" size="sm:w-28" hide-info/>
            </div>
        </div>

        <div class="bg-gray-500 rounded-full h-1 mt-4 opacity-25" v-if="!filter"></div>

        <div class="mt-4" :class="mode === 'horizontal' ? 'flex flex-wrap sm:-mr-1 md:-mr-2' : 'flex flex-wrap items-start -mr-1 md:-mr-2'" v-if="loaded">
            <template v-for="(key, i) in filteredItems" :key="`item-${key}`">
                <div class="bg-gray-500 rounded-full h-1 w-full mt-3 md:mt-2 mb-4 opacity-25" v-if="filteredItems[i-1] && getType(key) === 'local' && getType(filteredItems[i-1]) !== 'local'"></div>

                <component :is="`inventory-item-${mode}`"
                    class="mb-1 md:mb-2"
                    :class="mode === 'horizontal' ? 'sm:mr-1 md:mr-2' : 'mr-1 md:mr-2'"
                    v-for="i in (maxTier[getType(key)] || 0) + 1"
                    :key="`${key}-${i}`"
                    :tier="-1 * i + (maxTier[getType(key)] || 0) + 1"
                    :type="getType(key)"
                    :item="key"
                    :buttons="buttons"
                    :options="options"
                    :craft-button="craftButton"
                    :release="items[key].release"
                />
            </template>
        </div>
        <div v-else class="mt-4 text-lg opacity-75 text-center text-gray-200">
            {{ $t('loading_inventory') }}...
        </div>
    </div>
</template>

<script>
import InventoryItemHorizontal from '@/components/InventoryItemHorizontal.vue'
import InventoryItemVertical from '@/components/InventoryItemVertical.vue'
import InventoryImport from '@/components/InventoryImport.vue'
import InventoryClean from '@/components/InventoryClean.vue'

export default {
    components: { InventoryItemHorizontal, InventoryItemVertical, InventoryImport, InventoryClean },

    data () {
        return {
            loaded: false,
            filter: null,
            options: {
                showAll: true,
                hideNeed: false,
                hideMissing: false,
                hideCraft: false
            }
        }
    },

    mounted () {
        setTimeout(() => {
            this.loaded = true
        }, 0);
    },

    computed: {
        mode: {
            get () { return this.settings.inventory_mode },
            set (value) { this.setSetting('inventory_mode', value) }
        },

        show: {
            get () { return this.settings.inventory_show },
            set (value) { this.setSetting('inventory_show', value) }
        },

        buttons: {
            get () { return this.settings.inventory_buttons },
            set (value) { this.setSetting('inventory_buttons', value) }
        },

        craftButton: {
            get () { return this.settings.inventory_craft_button },
            set (value) { this.setSetting('inventory_craft_button', value) }
        },

        hideUnreleased: {
            get () { return this.settings.inventory_hide_unreleased },
            set (value) { this.setSetting('inventory_hide_unreleased', value) }
        },

        filteredItems () {
            return Object.keys(this.items)
                .filter(key => {
                    const type = this.getType(key)
                    if (this.filter && type != this.filter) return false
                    if (this.isFuture(this.items[key].show)) return false
                    if (this.hideUnreleased && this.isFuture(this.items[key].release)) return false
                    if (this.show === 'all') return true
                    if (this.show === 'needed') return this.totalCosts.find(c => c.item === key || (type == 'boss' && this.getTotalBossNeed(key)))

                    return (type == 'boss' &&
                        (this.getNeed('boss', key, 0) > this.getInventory('boss', key, 0) || this.getBossExtra(key)))
                        ||
                        (this.maxTier[type] &&
                        this.getCraft(type, key, 1) || this.getCraft(type, key, 2) ||
                        (this.maxTier[type] === 3 && this.getCraft(type, key, 3)))
                })
        }
    }
}
</script>
