<template>
    <div>
        <slot :open-modal="() => isModalVisible = true"/>

        <Dialog :draggable="false" position="top" v-model:visible="isModalVisible" modal style="width: 450px;" dismissable-mask @hide="$emit('update')">

            <template #header>
                <div class="w-full flex items-center justify-between">
                    <div class="font-semibold">{{ $t('manage_items') }}</div>

                    <input
                        type="checkbox"
                        class="rounded w-5 h-5 mr-2"
                        v-tippy="$t('active')"
                        :checked="!inactive.custom"
                        @input="setInactive('custom', !inactive.custom)"
                    >
                </div>
            </template>


            <div class="bg-blue-700 text-sm leading-tight px-3 py-2 rounded">
                {{ $t('manage_items_exp') }}
            </div>

            <select class="bg-gray-600 rounded mt-3 w-full" v-model="selectedCategory" required @change="categoryChanged">
                <option :value="null" disabled>{{ $t('category') }} </option>
                <option :value="category" v-for="category in categories" :key="`category-${category}`">{{ $t(labels[category]) }}</option>
            </select>

            <div v-if="selectedCategory" class="flex flex-wrap mt-2 gap-1">
                <button
                    v-for="key in filteredItems" :key="`item-option-${key}`"
                    @click="selectedItem = key"
                    class="rounded h-10 w-10 flex items-center justify-center"
                    :class="selectedItem == key ? 'bg-blue-600' : (haveAny(key) ? 'bg-blue-500/30' : 'bg-gray-700')"
                >
                    <img
                        class="max-h-[2rem] max-w-[2rem]"
                        :src="`${spaces}/items/${getType(key)}/${key}${(maxTier[getType(key)] > 0 ? '_0' : '')}.png`"
                    />
                </button>
            </div>

            <div v-if="selectedItem" class="flex flex-wrap mt-4 gap-1">

                <item
                    v-for="i in (maxTier[getType(selectedItem)] || 0) + 1"
                    :key="`${selectedItem}-${i}`"
                    :item="selectedItem"
                    :type="getType(selectedItem)"
                    :tier="i-1"
                />

            </div>

            <div class="mt-3">
                <div class="bg-gray-600 opacity-25 w-full h-1 mb-3 rounded-full"/>
                <button
                    class="bg-yellow-600/50 rounded px-2 py-1 text-sm font-semibold mr-1"
                    :class="{ 'opacity-50 pointer-events-none': !selectedItem }"
                    :disabled="!selectedItem"
                    @click="clearItems"
                >{{ $t('clear_items') }}</button>
                <button
                    class="bg-red-600/50 rounded px-2 py-1 text-sm font-semibold"
                    :class="{ 'opacity-50 pointer-events-none': !selectedCategory }"
                    :disabled="!selectedCategory"
                    @click="clearCategory"
                >{{ $t('clear_category') }}</button>
            </div>

        </Dialog>
    </div>
</template>

<script>
import Dialog from 'primevue/dialog'
import Item from './CustomItemsItem.vue'

export default {
    components: { Dialog, Item },

    data () {
        return {
            isModalVisible: false,
            selectedCategory: null,
            selectedItem: null,
            labels: {
                common: 'weapon_and_character',
                common_rare: 'weapon_items',
                wam: 'weapon_domains',
                element_2: 'bosses',
                boss: 'weekly_bosses',
                element_1: 'gems',
                talent: 'talent',
                local: 'specialties',
                special: 'special_and_others'
            }
        }
    },

    computed: {
        categories () {
            return Object.values(this.items).map(item => item.type).filter((v, i, s) => s.indexOf(v) === i)
        },

        filteredItems () {
            if (!this.selectedCategory) return false

            let items = Object.keys(this.items).filter(key => {
                if (this.items[key].type != this.selectedCategory) return false
                if (this.isFuture(this.items[key].show)) return false
                return true
            })

            if (this.selectedCategory == 'special') {
                items.push('mora', 'xp', 'wep_xp')
            }

            return items
        }
    },

    methods: {
        haveAny (item) {
            return this.custom_items.filter(c => c.item == item && c.value).length
        },

        categoryChanged () {
            this.selectedItem = null
        },

        clearItems () {
            if (!this.selectedItem) return

            this.custom_items.filter(c => c.item == this.selectedItem && c.value).forEach(item => {
                this.setCustomItem(item.type, item.item, item.tier, 0)
            })
            this.$emit('update')
        },

        clearCategory () {
            if (!this.selectedCategory) return
            let cat = this.selectedCategory

            this.custom_items
                .filter(c => (c.type == cat || (cat == 'special' && ['mora', 'xp', 'wep_xp'].includes(c.type))) && c.value)
                .forEach(item => {
                    this.setCustomItem(item.type, item.item, item.tier, 0)
                })
            this.$emit('update')
        }
    }
}
</script>
