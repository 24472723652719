<template>
    <div class="z-50 relative">
        <transition
            enter-from-class="opacity-0 transform translate-y-6"
            enter-to-class="opacity-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0 transform translate-y-6"
        >
            <div v-if="sw && sw.needRefresh" class="transition-all duration-200 fixed top-0 z-50 inset-x-0 flex justify-center m-2">
                <div class="bg-black/90 shadow-lg text-white py-2 font-semibold px-4 rounded-lg text-lg">
                    {{ $t('new_version_available') }}
                    <button
                        @click="update"
                        class="bg-gray-700/80 px-4 py-1 rounded-full ml-1 text-base uppercase font-bold"
                        :class="{ 'opacity-60 pointer-events-none': disabled }"
                    >{{ $t('update') }}</button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { useRegisterSW } from 'virtual:pwa-register/vue'

export default {
    data () {
        return {
            sw: null
        }
    },

    computed: {
        disabled () {
            return this.isConflictModalVisible || this.driveIsLoading || (this.driveIsSignedIn && (this.driveIsSyncing || this.driveIsWaiting))
        }
    },

    mounted () {
        this.sw = useRegisterSW({ immediate: true, async onRegisteredSW(_, r) {
            r && await r.update() && setInterval(async () => {
                await r.update()
            }, 30 * 60 * 1000)
        } })
    },

    methods: {
        async update () {
            if (this.disabled) return

            this.sw && this.sw.updateServiceWorker()
        }
    }
}
</script>
