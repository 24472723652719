<template>
    <div class="text-white">
        <h1 class="text-xl font-bold">{{ $t('weapons') }}</h1>
        <div class="mt-4 flex items-center">
            <div class="sm:w-32">
                <weapons-modal>
                    <template v-slot:trigger>
                        <div class="bg-pink-600/60 hover:bg-pink-600 font-semibold text-sm rounded-lg py-2 px-3 shadow hover:shadow-lg w-full sm:w-32 transition-all">
                            {{ $t('add') }}
                        </div>
                    </template>
                </weapons-modal>
            </div>
        </div>

        <div class="mt-4">
            <div class="font-semibold uppercase text-sm mb-1">{{ $t('filter') }}</div>

            <div class="flex flex-wrap lg:flex-nowrap items-center justify-center lg:justify-start gap-x-2 gap-y-2">
                <div class="flex items-center space-x-[0.15rem]">
                    <button @click="filter('rarity', i+2)" v-for="i in 3" :key="`filter-tier-${i+2}`" :class="rarityFilter === i+2 ? 'bg-yellow-500' : 'bg-gray-700/70'" class="flex w-10 h-10 rounded-full items-center justify-center overflow-hidden">
                        <star-icon class="w-8" :class="{ 'text-rare-550': i === 1, 'text-epic-550': i === 2, 'text-legendary-550': i === 3 }"/>
                    </button>
                </div>
                <div class="flex items-center space-x-[0.15rem]">
                    <button @click="filter('type', weaponType)" v-for="weaponType in weaponTypes" :key="`weapon-type-${weaponType}`" :class="typeFilter === weaponType ? 'bg-yellow-500' : 'bg-gray-700/70'" class="w-10 h-10 rounded-full overflow-hidden">
                        <img :src="`${spaces}/weapons/_${weaponType}.png`">
                    </button>
                </div>
                <button @click="hideInactive = !hideInactive" :class="hideInactive ? 'bg-yellow-500' : 'bg-gray-700/70'" class="w-10 h-10 flex-shrink-0 rounded-full overflow-hidden flex items-center justify-center">
                    <eye-off-icon class="w-6"/>
                </button>

                <div class="relative flex items-center justify-center">
                    <input type="text" :placeholder="$t('search')"
                        class="bg-gray-800 border-none rounded-md max-w-xs pr-8" v-model="search" @keyup.esc="search = ''"
                    >
                    <button v-if="search.trim().length" class="absolute right-0 mr-2" @click="search = ''">
                        <x-icon class="w-5 h-5"/>
                    </button>
                </div>
            </div>

            <ad class="mt-5"/>

            <div v-if="filteredWeapons.length" class="mt-4 flex flex-wrap -mx-2 sm:-mx-3 items-stretch justify-center sm:justify-start">
                <div
                    v-show="!hideInactive || (!weapon.goal?.character && !inactive[weapon.goal?.id || weapon.key]) || (weapon.goal?.character && !inactive[weapon.goal.character])"
                    v-for="weapon in filteredWeapons"
                    :key="`weapon-goal-${weapon.goal?.id || weapon.key}`"
                    class="m-2 sm:m-3"
                >
                    <weapons-modal :goal="weapon.goal">
                        <template v-slot:trigger>
                            <weapon-card :selected="weapon.key" :weapon="weapon" :class="{ 'opacity-50 filter-grayscale': inactive[weapon.goal?.character || weapon.goal?.id || weapon.key] }"/>
                        </template>
                    </weapons-modal>
                </div>
            </div>

            <div v-else class="font-semibold text-xl mt-4 text-gray-200 opacity-75">
                {{ $t('no_weapons') }}
            </div>
        </div>
    </div>

    <ad class="mt-5" bottom v-if="filteredWeapons.length >= 4"/>
</template>

<script>
import Ad from '@/components/Ad.vue'
import WeaponsModal from '@/components/WeaponsModal.vue'
import WeaponCard from '@/components/WeaponCard.vue'

export default {
    components: {
        Ad, WeaponsModal, WeaponCard
    },

    data () {
        return {
            search: '',
            rarityFilter: null,
            typeFilter: null,
            hideInactive: JSON.parse(localStorage.getItem('weaponsHideInactive')) || false
        }
    },

    watch: {
        hideInactive (value) { localStorage.setItem('weaponsHideInactive', value) }
    },

    computed: {
        filteredWeapons () {
            return this.goals.filter(g => g.type === 'weapon')
                .map(g => {
                    let weapon = this.weapons[g.weapon] || this.customs[g.weapon]
                    return {
                        goal: g,
                        key: g.weapon,
                        name: weapon.name || this.$t(g.weapon),
                        ...(weapon),
                    }
                }).filter(w => {
                    return (!this.rarityFilter || w.tier === this.rarityFilter)
                        && (!this.typeFilter || w.type === this.typeFilter)
                        && (!this.search || w.name.toLowerCase().includes(this.search.toLowerCase()))
                }).sort((a, b) => a.name.localeCompare(b.name))
        }
    },

    methods: {
        filter (type, value) {
            if (this[`${type}Filter`] === value) return this[`${type}Filter`] = null
            return this[`${type}Filter`] = value
        }
    }
}
</script>
