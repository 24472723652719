<template>
    <div class="flex flex-col items-center">
        <div class="mt-4 w-full">
            <div class="flex -mx-1 w-full flex-col sm:flex-row items-center">
                <div class="flex flex-col mx-1 items-center w-full sm:w-1/3 bg-gray-700 p-2 shadow rounded-lg" :class="{ 'opacity-50': !sands, 'opacity-25': done?.sands }">
                    <div class="flex items-center">
                        <img :src="`${url}img/general/artifact-sands.png`" class="w-5 h-5 object-fit"/>
                        <span class="font-semibold ml-1">{{ $t('sands') }}</span>

                        <button v-if="sands" class="rounded-md ml-2 p-1" @click="markDone('sands')" :class="done?.sands ? 'bg-green-600' : 'bg-gray-600'">
                            <check-icon class="w-4"></check-icon>
                        </button>
                    </div>

                    <select class="mt-2 text-sm truncate bg-gray-600 w-full rounded-md" v-model="sands" @change="addOrUpdateArtifacts()">
                        <option :value="null">{{ $t('none_m') }}</option>
                        <option :value="option" v-for="option in commonOptions.concat(sandsOptions)" :key="`${sands}-${option}`">{{ $t(option) }}</option>
                    </select>
                </div>
                <div class="flex flex-col mx-1 items-center w-full sm:w-1/3 bg-gray-700 p-2 shadow rounded-lg" :class="{ 'opacity-50': !goblet, 'opacity-25': done?.goblet }">
                    <div class="flex items-center">
                        <img :src="`${url}img/general/artifact-goblet.png`" class="w-5 h-5 object-fit"/>
                        <span class="font-semibold ml-1">{{ $t('goblet') }}</span>

                        <button v-if="goblet" class="rounded-md ml-2 p-1" @click="markDone('goblet')" :class="done?.goblet ? 'bg-green-600' : 'bg-gray-600'">
                            <check-icon class="w-4"></check-icon>
                        </button>
                    </div>

                    <select class="mt-2 text-sm truncate bg-gray-600 w-full rounded-md" v-model="goblet" @change="addOrUpdateArtifacts()">
                        <option :value="null">{{ $t('none_m') }}</option>
                        <option :value="option" v-for="option in commonOptions.concat(gobletOptions)" :key="`${goblet}-${option}`">{{ $t(option) }}</option>
                    </select>
                </div>
                <div class="flex flex-col mx-1 items-center w-full sm:w-1/3 bg-gray-700 p-2 shadow rounded-lg" :class="{ 'opacity-50': !circlet, 'opacity-25': done?.circlet }">
                    <div class="flex items-center">
                        <img :src="`${url}img/general/artifact-circlet.png`" class="w-5 h-5 object-fit"/>
                        <span class="font-semibold ml-1">{{ $t('circlet') }}</span>

                        <button v-if="circlet" class="rounded-md ml-2 p-1" @click="markDone('circlet')" :class="done?.circlet ? 'bg-green-600' : 'bg-gray-600'">
                            <check-icon class="w-4"></check-icon>
                        </button>
                    </div>

                    <select class="mt-2 text-sm truncate bg-gray-600 w-full rounded-md" v-model="circlet" @change="addOrUpdateArtifacts()">
                        <option :value="null">{{ $t('none_m') }}</option>
                        <option :value="option" v-for="option in commonOptions.concat(circletOptions)" :key="`${circlet}-${option}`">{{ $t(option) }}</option>
                    </select>
                </div>
            </div>
        </div>

        <hr class="w-full border-gray-600/50 mt-4">

        <!-- <div class="mt-3"> -->
            <!-- <button class="flex items-center bg-gray-600 rounded px-3 py-2 leading-none font-semibold" @click="markDone" :class="{ 'opacity-50': !done }">
                <input type="checkbox" class="pointer-events-none rounded w-4 h-4 mr-2 text-orange-500" :checked="done"/>
                {{ $t('done') }}
            </button> -->
        <!-- </div> -->

        <div v-if="selectedArtifacts.length" class="flex flex-wrap items-stretch h-full justify-center -m-1 mt-3 gap-2">
            <div
                v-for="key in selectedArtifacts"
                :key="`selected-artifact-${key}`"
                class="text-center bg-gray-700/80 shadow rounded-lg p-2 h-full"
                :class="{ 'opacity-25': done?.[key] }"
            >

                <div>
                    <button class="rounded-md p-1" @click="markDone(key)" :class="done?.[key] ? 'bg-green-600' : 'bg-gray-600'">
                        <check-icon class="w-5"></check-icon>
                    </button>
                    <button class="bg-gray-600 rounded-md p-1 ml-1" @click="select(key)">
                        <trash-icon class="w-5"></trash-icon>
                    </button>
                </div>

                <div class="mt-1 w-24 h-24 rounded-xl overflow-hidden relative bg-gradient-to-tl flex items-center justify-center"
                    :class="darkTierClass[artifacts[key].max_tier]"
                >
                    <img :src="`${spaces}/artifacts/${key}.png`" class="object-cover">
                </div>
                <div class="mt-1 truncate w-24 text-xs font-semibold">{{ $t(key) }}</div>
            </div>
        </div>

        <button class="bg-orange-500 rounded mt-4 py-2 px-3 font-semibold text-sm" @click="adding = !adding">{{ $t('add_artifact') }}</button>

        <div class="flex flex-col items-center" v-if="adding">
            <div class="mt-4 font-semibold uppercase text-sm mb-1 text-center">{{ $t('filter') }}</div>

            <div class="flex flex-wrap flex-col sm:flex-row">
                <div class="mb-2 sm:mb-0 flex items-center justify-center space-x-[0.15rem]">
                    <button @click="filter('rarity', i+2)" v-for="i in 3" :key="`filter-tier-${i+2}`" :class="rarityFilter === i+2 ? 'bg-yellow-500' : 'bg-gray-600'" class="flex w-8 sm:w-10 h-8 sm:h-10 rounded-xl items-center justify-center overflow-hidden">
                        <star-icon class="w-7" :class="{ 'text-rare-550': i === 1, 'text-epic-550': i === 2, 'text-legendary-550': i === 3 }"/>
                    </button>
                </div>
            </div>

            <div class="flex flex-wrap items-start justify-center mt-6 -m-2">
                <button
                    v-for="artifact in filteredArtifacts"
                    :key="`artifact-${artifact.key}`"
                    class="mx-1 mb-4"
                    @click="select(artifact.key)"
                    :class="{ 'opacity-25': !selectedArtifacts.includes(artifact.key) }"
                >
                    <div class="w-16 sm:w-20 h-16 sm:h-20 rounded-xl overflow-hidden relative bg-gradient-to-tl flex items-center justify-center"
                        :class="darkTierClass[artifact.max_tier]"
                    >
                        <img :src="`${spaces}/artifacts/${artifact.key}.png`" class="max-h-[4rem] max-w-[4rem]">
                        <div class="absolute flex items-center justify-center inset-0 bg-black bg-opacity-50 z-0" v-if="selectedArtifacts.includes(artifact.key)">
                            <check-icon class="w-10 text-white"/>
                        </div>
                        <div class="bg-orange-700 absolute-tag" v-if="isFuture(artifact.release)">
                            {{ $t('soon') }}
                        </div>
                        <div class="bg-green-700 absolute-tag" v-if="isNew(artifact.release)">
                            {{ $t('new') }}
                        </div>
                    </div>
                    <div class="flex items-center justify-center text-xs text-center font-semibold tracking-tighter sm:tracking-tight w-16 sm:w-20 leading-none sm:leading-tight mt-1">{{ artifact.name }}</div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        selected: String // character
    },

    data () {
        return {
            adding: false,
            rarityFilter: null,
            done: {},
            sands: null,
            goblet: null,
            circlet: null,
            commonOptions: ['hp_p', 'def_p', 'atk_p', 'elemental_mastery'],
            sandsOptions: ['energy_recharge_p'],
            gobletOptions: ['physical_dmg', 'pyro_dmg', 'electro_dmg', 'hydro_dmg', 'cryo_dmg', 'anemo_dmg', 'geo_dmg', 'dendro_dmg'],
            circletOptions: ['crit_rate_p', 'crit_dmg_p', 'crit_rate_dmg_p', 'healing_bonus']
        }
    },

    computed: {
        artifactGoal () {
            return this.goals.find(g => g.character === this.selected && g.type === 'artifact')
        },

        selectedArtifacts () {
            return (this.artifactGoal && this.artifactGoal.artifacts) || []
        },

        filteredArtifacts () {
            return Object.keys(this.artifacts)
                .filter(key => {
                    return (!this.rarityFilter || this.artifacts[key].max_tier === this.rarityFilter)
                })
                .filter(key => {
                    return !this.artifacts[key].show || !this.isFuture(this.artifacts[key].show)
                })
                .map(key => ({ key, name: this.$t(key), ...this.artifacts[key] }))
                .sort((a, b) => a.name.localeCompare(b.name))
                .sort((artifact) => this.selectedArtifacts.includes(artifact.key) ? -1 : 1)
        }
    },

    mounted () {
        this.sands = this.artifactGoal?.sands || null
        this.goblet = this.artifactGoal?.goblet || null
        this.circlet = this.artifactGoal?.circlet || null
        this.done = typeof this.artifactGoal?.done == 'object' ? this.artifactGoal?.done : {}
    },

    methods: {
        markDone (key) {
            if (this.done[key]) delete this.done[key]
            else this.done[key] = true

            this.addOrUpdateArtifacts()
        },

        filter (type, value) {
            if (this[`${type}Filter`] === value) return this[`${type}Filter`] = null
            return this[`${type}Filter`] = value
        },

        select (artifact) {
            let selected = this.selectedArtifacts
            let index = selected.indexOf(artifact)

            if (index >= 0) {
                selected.splice(index, 1)

                if (this.done?.[artifact]) delete this.done[artifact]
            } else {
                selected.push(artifact)
                this.adding = false
            }

            this.addOrUpdateArtifacts(selected)
        },

        addOrUpdateArtifacts (selected) {
            if (this.artifactGoal && !this.sands && !this.goblet && !this.circlet && ((selected && !selected.length) || !this.selectedArtifacts.length)) {
                return this.removeGoal(this.artifactGoal)
            }

            this.addGoal({
                type: 'artifact',
                character: this.selected,
                artifacts: selected || this.selectedArtifacts || [],
                sands: this.sands,
                goblet: this.goblet,
                circlet: this.circlet,
                done: this.done
            })
        }
    }
}
</script>
