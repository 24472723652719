<template>
    <button
        @click="isVisible = true"
        class="bg-orange-600 rounded py-2 px-3 shadow hover:shadow-lg transition-shadow font-semibold"
    >{{ $t('import') }}</button>

    <Dialog
        v-model:visible="isVisible"
        dismissable-mask
        modal
        style="width: 420px;"
        :header="$t('import')"
        @hide="hide"
    >
        <div class="leading-tight">
            <div class="flex items-center mb-4">
                <button class="bg-gray-700/40 transition-all duration-100 font-semibold px-3 py-2 rounded hover:bg-gray-700/80 mr-1" :class="{ '!bg-gray-600/80 shadow': format == 'seelie' }" @click="format = 'seelie'">Seelie</button>
                <button class="bg-gray-700/40 transition-all duration-100 font-semibold px-3 py-2 rounded hover:bg-gray-700/80" :class="{ '!bg-gray-600/80 shadow': format == 'good' }" @click="format = 'good'">GOOD</button>
            </div>

            <p>{{ $t('experimental_feature') }}</p>
            <p class="mt-2" v-html="$t('inventory_import_overwritten_warning')"></p>

            <div v-if="format == 'seelie'" class="bg-green-800/80 rounded-md px-3 py-3 mt-2">
                <p v-html="$t('inventory_import_seelie')"></p>
            </div>

            <div v-if="format == 'good'" class="bg-green-800/80 rounded-md px-3 py-3 mt-2">
                <p v-html="$t('inventory_import_good')"></p>
            </div>

            <!-- <p class="mt-2 bg-teal-700/60 px-3 py-2 rounded-md text-sm leading-tight" v-html="$t('inventory_import_optimizer')" ></p> -->

            <div class="flex items-center justify-between mt-4">
                <button
                    class="bg-gray-600 shadow rounded-md py-2 px-3 flex items-center font-semibold disabled:pointer-events-none disabled:opacity-75"
                    @click="clickUpload"
                    :disabled="loading"
                >
                    <svg class="animate-spin -ml-1 mr-2 h-5 w-5 my-px text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="loading">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <upload-icon class="-ml-1 w-5 h-5 mr-2 my-px" v-else/>
                    {{ $t('select_file') }}
                </button>

                <button class="transition-all duration-100 font-semibold px-3 py-2 rounded hover:bg-gray-700/60" @click="download" v-if="format == 'seelie'">
                    {{ $t('export') }}
                </button>
            </div>
            <input type="file" ref="file" class="hidden" @change="upload" accept="application/JSON"/>

            <p class="mt-2 text-red-500 font-semibold" v-if="errorMsg">{{ errorMsg }}</p>
            <p class="mt-2 text-green-400 font-semibold" v-else-if="success">{{ $t('inventory_imported') }}</p>
        </div>

    </Dialog>
</template>

<script>
import en from '../locales/game/en'
import Dialog from 'primevue/dialog'

export default {
    components: { Dialog },

    data () {
        return {
            isVisible: false,
            format: 'seelie',
            loading: false,
            success: false,
            errorMsg: null
        }
    },

    computed: {
        good () {
            let good = {
                Mora: ['mora', 'mora'],
                HerosWit: ['xp', 'xp'],
                AdventurersExperience: ['xp', 'xp_sub_1'],
                WanderersAdvice: ['xp', 'xp_sub_0'],
                MysticEnhancementOre: ['wep_xp', 'wep_xp'],
                FineEnhancementOre: ['wep_xp', 'wep_xp_sub_1'],
                EnhancementOre: ['wep_xp', 'wep_xp_sub_0'],
                DreamSolvent: ['special', 'dream_solvent'],
            }

            Object.keys(this.items).forEach(key => {
                let type = this.getType(key)
                let max = this.maxTier[type] || 0

                for (let tier = 0; tier <= max; tier++) {
                    let name = max > 0 ? en[key]?.[tier] : en[key]
                    if (!name) return

                    // let slug = this.slug(name)
                    // good[slug] = [type, key, tier]

                    let capitalizedSlug = this.capitalizedSlug(name)
                    good[capitalizedSlug] = [type, key, tier]
                }
            })

            return good
        }
    },

    mounted () {
        if (!this.dev) return
        this.checkGood()
        console.log(this.good)
    },

    methods: {
        capitalizedSlug (string) {
            return string.replace(/[^a-zA-Z0-9 ]/g, '').split(' ')
                .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
                .join('')
        },

        slug (string) {
            return string.replaceAll(' ', '').replaceAll('\'', '').replaceAll('-', '')
        },

        hide () {
            this.success = false
            this.loading = false
            this.errorMsg = null
        },

        clickUpload () {
            this.$refs.file && this.$refs.file.click()
        },

        upload (v) {
            this.success = false
            this.errorMsg = null
            this.loading = true
            const files = v.target.files
            if (files.length <= 0) return this.error(this.$t('error_reading'))

            const fr = new FileReader()

            fr.onload = e => {
                const result = JSON.parse(e.target.result)

                if (this.format == 'seelie') this.importSeelie(result)
                if (this.format == 'good') this.importGood(result)

                this.success = true
            }
            fr.readAsText(files.item(0))
            this.loading = false
        },

        error (msg) {
            this.loading = false
            this.errorMsg = msg
        },

        importSeelie (file) {
            if (!file.inventory) return this.error(this.$t('no_materials'))

            this.inventory = []

            file.inventory.forEach(item => {
                if (!item.type || !item.item) return
                this.setInventory(item.type, item.item, item.tier, item.value)
            })
        },

        importGood (file) {
            if (file?.format !== 'GOOD') return this.error(this.$t('not_good_format'))
            if (!file.materials) return this.error(this.$t('good_but_no_materials'))

            this.inventory = []

            Object.keys(file.materials).forEach(key => {
                if (!this.good[key]) return

                let amount = Number(file.materials[key])
                if (!amount) return

                let item = this.good[key]

                this.setInventory(item[0], item[1], item[2] || 0, amount)
            })
        },

        download () {
            let data = { inventory: this.inventory }
            let name = this.dayjs().format('YYYY-MM-DD-HH-mm') + `-${this.account}-seelie-inventory.json`

            const a = document.createElement('a')
            a.href = URL.createObjectURL(new Blob([JSON.stringify(data)], { type: "application/JSON" }))
            a.setAttribute('download', name)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        checkGood () {
            let notFound = []

            let goodArray = Object.values(this.good)

            Object.keys(this.items).forEach(key => {
                let type = this.getType(key)
                let maxTier = this.maxTier[type] || 0

                for (let i = 0; i <= maxTier; i++) {
                    let found = goodArray.find(g => g && g[0] == type && g[1] == key && (g[2] == i || (!i && !g[2])))
                    if (!found) notFound.push([type, key, i])
                }
            })

            console.log('not found', notFound)
        }
    }
}
</script>
