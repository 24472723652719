import { reactive, toRefs } from "vue"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

let settings = JSON.parse(localStorage.getItem('settings')) || {}
let defaults = {
    goals: [],
    inactive: {},
    notes: {},
    custom_items: [],
    customs: {},
    inventory: [],
    tasks: [],
    achievements: {},
    ar: 1,
    wl: 0,
    server: 'america',
    gender: 'male',
    resin: {
        amount: 0,
        time: null
    },
}
let accounts = (JSON.parse(localStorage.getItem('accounts')) || []).concat('main')
let userData = {}

accounts.forEach(account => {
    Object.keys(defaults).forEach(key => {
        let k = `${account}-${key}`
        userData[k] = JSON.parse(localStorage.getItem(k)) || JSON.parse(JSON.stringify(defaults[key]))
    })
})

let lastUpdate = localStorage.getItem('last_update')

let lastNewsUpdate = localStorage.getItem('last_news_update')

try {
    lastNewsUpdate = JSON.parse(lastNewsUpdate) || { en: null, pt: null, es: null, 'zh-tw': null }
} catch {
    lastNewsUpdate = { en: null, pt: null, es: null, 'zh-tw': null }
}

const state = reactive({
    settings: {
        account_names: settings.account_names || {},
        characters_mode: settings.characters_mode || 'vertical',
        inventory_mode: settings.inventory_mode || 'vertical',
        inventory_show: settings.inventory_show || 'all',
        inventory_buttons: settings.inventory_buttons || false,
        inventory_craft_button: settings.inventory_craft_button || false,
        inventory_hide_unreleased: settings.inventory_hide_unreleased || false,
        hour_format: settings.hour_format || 24,
        resin_notification: settings.resin_notification == undefined ? true : settings.resin_notification,
        resin_notification_quantities: settings.resin_notification_quantities || [],
        resin_app_badge: settings.resin_app_badge == undefined ? true : settings.resin_app_badge,
        resin_planner_badge: settings.resin_planner_badge == undefined ? true : settings.resin_planner_badge,
        collapse_no_resin_activities: settings.collapse_no_resin_activities || false,
        auto_sync: settings.auto_sync || false,
        planner_keep_item_open: settings.planner_keep_item_open || false,
        planner_show_add_input: settings.planner_show_add_input || false,
        planner_tab_order: settings.planner_tab_order || 'alternating', // alternating, sequential_inv, sequential_add
        hide_completed_categories: settings.hide_completed_categories || false,
        tasks_order: settings.tasks_order || {}
    },

    accounts: JSON.parse(localStorage.getItem('accounts')) || [],
    account: localStorage.getItem('account') || 'main',

    defaults,
    data: userData,

    characterModal: false,
    weaponModal: false,

    isDonateModalVisible: false,
    isConflictModalVisible: false,
    remoteTime: null,
    remoteSize: null,

    driveIsSignedIn: false,
    driveIsLoading: true,
    driveIsWaiting: false,
    driveIsSyncing: true,
    driveErrorTimeout: null,
    driveFileId: null,
    driveSyncTimeout: null,
    driveIsError: false,

    lastNewsUpdate: lastNewsUpdate,
    news: JSON.parse(localStorage.getItem('news')) || { en: [], pt: [], es: [], 'zh-tw': [] },

    birthdays: [],

    lastUpdate: lastUpdate ? dayjs(lastUpdate) : null,

    toast: null,

    dayjs,

    time: dayjs(),
    serverTime: dayjs(),
    resetTime: dayjs(),
    resetDay: dayjs(dayjs().format('YYYY-MM-DD'))
});

export default {
    ...toRefs(state)
}
