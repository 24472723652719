<template>
    <div class="text-white font-semibold uppercase tracking-wide text-lg opacity-75" v-if="!$route.params.id && !$route.params.area && isLoading">
        {{ $t('loading') }} {{ $t('achievements') }}...
    </div>

    <div v-else-if="!$route.params.id && !$route.params.area">
        <div class="flex justify-between items-center text-white bg-gray-700 rounded-md px-4 py-3">
            <p class="font-semibold text-lg">{{ $t('achievements') }}</p>

            <div class="font-semibold tracking-wide">
                {{ done }} / {{ total }} <span class="text-gray-400">• {{ percent }}%</span>
            </div>
        </div>

        <div class="mt-4">
            <p class="font-semibold">{{ $t('commissions') }}</p>

            <div class="flex flex-wrap mt-2">
                <div class="w-1/3 sm:w-32 p-1" v-for="area in commissionsAreas" :key="`area-${area}`">
                    <achievements-area
                        :area="area"
                        :all-achievements="flatAchievements"
                        v-tippy="$t(area)"
                        class="w-full"
                    ></achievements-area>
                </div>
            </div>
        </div>

        <ad class="mt-5"/>

        <div class="flex items-center mt-4 flex-col md:flex-row">
            <achievements-filters
                :search="search"
                @update:search="s => search = s"
                :version="version"
                @update:version="v => version = v"
                :versions="versions"
            />

            <switch-button
                v-model="hideCompletedCategories"
                :label="$t('hide_completed_categories')"
                class="ml-4"
                :disabled="!!(trimmedSearch.length || version !== 'all')"
                :disabled-value="false"
            />
        </div>


        <div class="text-white grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:flex flex-wrap justify-center lg:justify-start gap-3 mt-4">
            <router-link custom
                v-slot="{ navigate }"
                v-for="cat in categories"
                :key="`category-${cat[0]}`"
                :to="`/achievements/category/${cat[0]}`"
            >
                <category
                    :id="Number(cat[0])"
                    :category="cat[1]"
                    :hide-completed="hideCompletedCategories"
                    @click="navigate"
                    role="link"
                    class="sm:w-44 cursor-pointer"
                    :search="trimmedSearch"
                    :version="version"
                />
            </router-link>
        </div>

        <ad class="mt-5" bottom/>
    </div>

    <div v-else class="flex">
        <div class="h-screen w-[245px] sticky top-3 flex-shrink-0 text-white overflow-y-scroll custom-scrollbar hidden xl:flex flex-col pb-4 pr-2 gap-2">
            <p class="font-semibold text-sm">{{ $t('commissions') }}</p>

            <router-link custom
                v-slot="{ navigate }"
                v-for="area in commissionsAreas"
                :key="`commission-area-${area}`"
                :to="`/achievements/commissions/${area}`"
            >
                <category-small
                    :isActive="$route.params.area == area"
                    :achievs="flatAchievements.filter(a => a.c == area)"
                    :area="area"
                    @click="navigate"
                    role="link"
                    class="cursor-pointer"
                />
            </router-link>

            <p class="font-semibold text-sm mt-2">{{ $t('achievements') }}</p>

            <switch-button
                v-model="hideCompletedCategories"
                :label="$t('hide_completed_categories')"
                :disabled="!!(trimmedSearch.length || version !== 'all')"
                :disabled-value="false"
                small
            />

            <router-link custom
                v-slot="{ navigate }"
                v-for="cat in categories"
                :key="`category-${cat[0]}`"
                :to="`/achievements/category/${cat[0]}`"
            >
                <category-small
                    :isActive="$route.params.id == cat[0]"
                    :achievs="cat[1].a.flatMap(a => a)"
                    :category-name="cat[1].n"
                    :category-id="cat[0]"
                    :hide-completed="hideCompletedCategories"
                    @click="navigate"
                    role="link"
                    class="cursor-pointer"
                    :search="trimmedSearch"
                    :version="version"
                />
            </router-link>
        </div>

        <div class="w-full">
            <div class="sticky top-0 z-30 xl:mx-4 pt-16 -mt-16 lg:pt-4 lg:-mt-4">
                <achievements-filters
                    :search="search"
                    @update:search="s => search = s"
                    :version="version"
                    @update:version="v => version = v"
                    :versions="versions"
                />
            </div>
            <router-view :key="`${$route.params.id || $route.params.area}-achievements-list`" class="xl:px-4" :search="trimmedSearch" :version="version"/>
        </div>
    </div>
</template>

<script>
import Ad from '@/components/Ad.vue'
import Category from '@/components/AchievementCategory.vue'
import CategorySmall from '@/components/AchievementCategorySmall.vue'
import AchievementsArea from '@/components/AchievementsArea.vue'
import AchievementsFilters from '@/components/AchievementsFilters.vue'
import SwitchButton from '@/components/SwitchButton.vue'

export default {
    components: { Ad, Category, CategorySmall, AchievementsArea, AchievementsFilters, SwitchButton },

    data () {
        return {
            categories: {},
            isLoading: true,
            search: '',
            version: 'all',
            commissionsAreas: ['mondstadt', 'liyue', 'inazuma', 'sumeru', 'fontaine', 'natlan']
        }
    },

    computed: {
        flatAchievements () {
            return Object.values(this.categories).flatMap(c => c[1].a).flatMap(a => a)
        },

        total () {
            return this.flatAchievements.length
        },

        done () {
            return this.flatAchievements.filter(a => this.achievements[a.id]?.done).length
        },

        percent () {
            return Math.round(this.done * 100 / this.total)
        },

        hideCompletedCategories: {
            get () { return this.settings.hide_completed_categories },
            set (value) { this.setSetting('hide_completed_categories', value) }
        },

        versions () {
            return [...new Set(this.flatAchievements.map(a => a.v))].sort()
        },

        trimmedSearch () {
            return this.search.toLowerCase().trim()
        },
    },

    mounted () {
        this.loadAchievements()
    },

    watch: {
        '$i18n.locale' () {
            this.loadAchievements()
        }
    },

    methods: {
        async loadAchievements () {
            this.isLoading = true
            const data = await import(`../locales/achievements/${this.$i18n.locale}.json`)
            this.categories = Object.entries(data.default).sort((a, b) => a[1].order - b[1].order)
            this.isLoading = false
        },

        textSize (name) {
            let len = name.length
            if (len <= 26) return 'text-xl'
            if (len <= 32) return 'text-lg'
            if (len <= 40) return 'text-base'
            return 'text-sm'
        }
    }
}
</script>
