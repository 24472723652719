<template>
    <div class="text-white">
        <h1 class="text-xl font-bold">{{ $t('planner') }}</h1>

        <div class="mt-4 flex flex-col items-start">
            <div class="items-center justify-center rounded text-xs mr-2 hidden lg:flex divide-x divide-gray-400 divide-opacity-50 overflow-hidden">
                <button
                    v-for="option in ['today'].concat(Object.keys(activities))" :key="`option-${option}`"
                    class="py-3 px-3 focus:outline-none"
                    :class="selected === option ? 'bg-yellow-600' : 'bg-gray-500'"
                    @click="selected = option"
                >
                    {{ $t(option) }}
                </button>
            </div>

            <div class="mr-1 flex lg:hidden">
                <button class="bg-gray-500 px-2 rounded-l flex items-center" @click="changeDay(-1)">
                    <chevron-double-left-icon class="w-4 mx-1"/>
                </button>
                <select class="bg-gray-600 text-sm w-24" v-model="selected">
                    <option :value="day" v-for="day in ['today'].concat(Object.keys(activities))" :key="`filter-${day}`">
                        {{ $t(day) }}
                    </option>
                </select>
                <button class="bg-gray-500 px-2 rounded-r flex items-center" @click="changeDay(+1)">
                    <chevron-double-right-icon class="w-4 mx-1"/>
                </button>
            </div>
        </div>

        <planner-subjects/>

        <div class="mt-4" v-if="selected === 'today'">
            <tasks/>
            <div class="bg-gray-600 opacity-25 w-full h-1 mt-4 mx-1 rounded-full"/>
        </div>

        <ad class="mt-5"/>

        <div class="mt-6">
            <div class="flex items-start justify-between mb-4 flex-wrap">
                <resin-tracker :estimation="reqResin"/>
                <!-- <div class="h-20 bg-blue-800 py-3 pl-4 pr-3 rounded-md inline-flex items-center font-semibold text-sm">
                    <img :src="`${spaces}/items/special/resin.png`" class="w-10"/>
                    <div v-if="count && resin" class="ml-2">

                        <div class="text-xs sm:w-60 leading-tight">{{ $t('to_get_all_obtainable_items') }}</div>
                    </div>
                </div> -->

                <div class="flex mt-2 sm:mt-0 h-20">
                    <planner-settings v-slot="slotProps">
                        <button
                            class="py-2 sm:py-2 px-3 rounded-lg bg-gray-500 h-full flex items-center justify-center text-xs flex-col leading-tight font-semibold"
                            @click="slotProps.openModal"
                        >
                            <cog-icon class="w-5 mb-1"/>
                            <span>{{ $t('settings') }}</span>
                        </button>
                    </planner-settings>

                    <button
                        class="ml-2 py-2 sm:py-2 px-3 rounded-lg bg-blue-500 h-full flex items-center justify-center text-xs flex-col leading-tight font-semibold"
                        @click="calculateCosts"
                    >
                        <refresh-icon class="w-5 mb-1"/>
                        <span v-html="$t('refresh_br_planner')"/>
                    </button>

                    <custom-items class="ml-2" @update="calculateCosts" v-slot="slotProps">
                        <button
                            class="py-2 sm:py-2 px-3 rounded-lg h-full flex items-center justify-center text-xs flex-col leading-tight font-semibold"
                            :class="inactive.custom ? 'bg-gray-600/80' : 'bg-blue-600'"
                            @click="slotProps.openModal"
                            @contextmenu.prevent="setInactive('custom', !inactive.custom)"
                        >
                            <img :src="`${url}img/general/currency.png`" class="h-5 mb-1"/>
                            <span v-html="$t('manage_br_items')"/>
                        </button>
                    </custom-items>
                </div>
            </div>

            <div></div>

            <div class="mt-6 text-gray-400 text-center flex flex-col items-center py-10 text-2xl mx-10" v-if="!count">
                <calendar-icon class="w-20 mb-4 text-gray-500 opacity-75"/>
                <div v-if="selected === 'today'">
                    {{ $t('no_activities_today') }}
                </div>
                <div v-else>
                    {{ $t('no_activities_day') }}
                </div>
             </div>

            <div class="" v-else-if="selected === 'any' || selected === 'today'" v-for="(cost, i) in costs" :key="`cost-${cost}`">
                <div class="bg-gray-600 opacity-25 w-full h-1 mx-1 rounded-full" v-if="i"/>

                <div class="flex items-center my-4">
                    <div class="inline-flex font-semibold items-center bg-blue-700 py-1 pl-2 pr-3 rounded-full">
                        <img :src="`${spaces}/items/special/resin2.png`" class="w-5 mr-1"/>
                        {{ cost == 0 ? $t('none') : (cost == 60 ? '30/60' : cost) }}
                    </div>

                    <button v-if="cost == 0" class="ml-2 bg-gray-600/60 rounded-full p-1" @click="collapseNoResin = !collapseNoResin">
                        <chevron-up-icon class="w-4 transition-all" :class="{ 'rotate-180': collapseNoResin }"/>
                    </button>
                </div>

                <transition
                    leave-active-class="transition-all duration-200 overflow-hidden"
                    enter-active-class="transition-all duration-200 overflow-hidden"
                    enter-from-class="opacity-0 max-h-0 -translate-x-20"
                    enter-to-class="opacity-100 max-h-screen"
                    leave-from-class="opacity-100 max-h-screen"
                    leave-to-class="opacity-0 max-h-0 -translate-x-20"
                >
                    <div v-if="cost || !collapseNoResin">
                        <div class="mb-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
                            <planner-activity
                                v-for="(items, location) in activities.any[cost]"
                                :key="`activity-${location}`"
                                :location="location"
                                :activity-items="items"
                                :cost="cost"
                            />

                            <template v-if="selected === 'today' && cost == 20">
                                <planner-activity
                                    class="border-2 border-yellow-400"
                                    v-for="(items, location) in activities[days[day]]"
                                    :key="`activity-${location}`"
                                    :location="location"
                                    :activity-items="items"
                                    :cost="20"
                                />
                            </template>
                        </div>
                    </div>
                </transition>
            </div>

            <div v-else class="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3 mb-4">
                <planner-activity
                    v-for="(items, location) in activities[selected]"
                    :key="`activity-${location}`"
                    :location="location"
                    :activity-items="items"
                    :cost="20"
                />
            </div>
        </div>

        <planner-artifacts/>
    </div>

    <ad class="mt-5" bottom v-if="count >= 4"/>
</template>

<script>
import farming from '@/database/farming'
import Ad from '@/components/Ad'
import Tasks from '@/components/Tasks'
import CustomItems from '@/components/CustomItems'
import PlannerSubjects from '@/components/PlannerSubjects'
import PlannerActivity from '@/components/PlannerActivity'
import PlannerArtifacts from '@/components/PlannerArtifacts'
import PlannerSettings from '@/components/PlannerSettings'
import ResinTracker from '@/components/ResinTracker'

export default {
    components: { Ad, Tasks, CustomItems, PlannerSubjects, PlannerActivity, PlannerArtifacts, PlannerSettings, ResinTracker },

    data () {
        return {
            farming,
            selected: 'today',
            days: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
            estimations: {},
            activities: {
                'any': {},
                'mon': {}, 'tue': {}, 'wed': {}, 'thu': {}, 'fri': {}, 'sat': {}, 'sun': {}
            },
        }
    },

    computed: {
        costs () {
            let costs = Object.keys(this.activities.any).map(cost => Number(cost))

            if (this.selected == 'today' && !costs.includes(20) && Object.values(this.activities[this.days[this.day]]).length) costs.push(20)

            return costs.sort((a, b) => a-b)
        },

        collapseNoResin: {
            get () { return this.settings.collapse_no_resin_activities },
            set (value) { this.setSetting('collapse_no_resin_activities', value) }
        },

        reqResin () {
            // return this.totalCosts.reduce((a, item) => {
            //     return a + (this.getEstimatedRuns(item)?.runs || 0) * (['element_1', 'element_2'].includes(item.type) ? 40 : 20)
            // }, 0)
            return Object.values(this.estimations).reduce((a, b) => a + b.runs * (Number(b.cost) || 0), 0)
        },

        day () {
            return this.resetDay.day()
        },

        count () {
            if (this.selected === 'today') {
                return Object.values(this.activities.any).reduce((a, b) => a + Object.values(b).length, 0)
                    + Object.values(this.activities[this.days[this.day]]).length
            }

            if (this.selected === 'any')  {
                return Object.values(this.activities.any).reduce((a, b) => a + Object.values(b).length, 0)
            }

            return Object.values(this.activities[this.selected]).length
        }
    },

    watch: {
        customs: {
            deep: true,
            handler () {
                this.calculateCosts()
            }
        },
        goals: {
            deep: true,
            handler () {
                this.calculateCosts()
            }
        },
        inactive: {
            deep: true,
            handler () {
                this.calculateCosts()
            }
        }
    },

    mounted () {
        this.calculateCosts()
    },

    methods: {
        changeDay (v) {
            let days = Object.keys(this.activities)
            let i = days.findIndex(a => a === this.selected)
            this.selected = days[i+v] || (v > 0 ? days[0] : days[8])
        },

        calculateCosts () {
            this.activities = {
                'any': {},
                'mon': {}, 'tue': {}, 'wed': {}, 'thu': {}, 'fri': {}, 'sat': {}, 'sun': {}
            }
            this.estimations = {}

            this.totalCosts.forEach(item => {
                if (this.isFuture(this.items[item.item]?.release)) return

                if (typeof item.need === 'number') {
                    item.missing = this.getMissing(item.type, item.item, 0)
                    if (item.missing <= 0) return
                }
                else {
                    item.missing = []
                    item.need.forEach((need, tier) => {
                        item.missing.push(this.getMissing(item.type, item.item, tier))
                    })
                    if (item.missing.reduce((a, b) => a + b, 0) <= 0) return
                }

                if (!item.item || !this.farming[item.item]) return

                this.farming[item.item].when.forEach(day => {
                    this.farming[item.item].where.forEach(loc => {
                        let type = this.farming[item.item].type
                        let cost = this.resin[type] || 0

                        if (day === 'any') {
                            if (!this.activities.any[cost]) this.activities.any[cost] = {}
                            if (!this.activities.any[cost][loc]) this.activities.any[cost][loc] = {}

                            this.activities.any[cost][loc][item.item] = item
                        }
                        else {
                            if (!this.activities[day][loc]) this.activities[day][loc] = {}
                            this.activities[day][loc][item.item] = item
                        }

                        if (cost !== 60) {
                            let estimated = this.getEstimatedRuns(item)
                            cost = cost || 20

                            if (!this.estimations[loc])
                                this.estimations[loc] = { cost, items: [ item.item ], runs: estimated.runs, unobtainable: estimated.unobtainable }
                            else if (!this.estimations[loc].items.includes(item.item)) {
                                this.estimations[loc].items.push(item.item)
                                this.estimations[loc].unobtainable = this.estimations[loc]?.unobtainable || estimated.unobtainable

                                if (cost === 20) this.estimations[loc].runs += estimated.runs
                                else this.estimations[loc].runs = Math.max(this.estimations[loc]?.runs || 0, estimated.runs)
                            }

                            // this.estimations[loc] = {
                            //     cost: cost || 20,
                            //     runs: Math.max(this.estimations[loc]?.runs || 0, estimated.runs),
                            //     unobtainable: this.estimations[loc]?.unobtainable || estimated.unobtainable
                            // }
                        }
                    })
                })
            })
        },
    }
}
</script>
