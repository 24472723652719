import {
	ArrowCircleDownIcon,
	ArrowCircleUpIcon,
	BeakerIcon,
	CalendarIcon,
	CheckCircleIcon,
	CheckIcon,
	ChevronDoubleDownIcon,
	ChevronDoubleLeftIcon,
	ChevronDoubleRightIcon,
	ChevronDownIcon,
	ChevronLeftIcon,
	ChevronUpIcon,
	CogIcon,
	DesktopComputerIcon,
	ExclamationCircleIcon,
	ExternalLinkIcon,
	EyeIcon,
	EyeOffIcon,
	FlagIcon,
	InformationCircleIcon,
	SearchIcon,
	MailIcon,
	LocationMarkerIcon,
	MenuIcon,
	PlusIcon,
	QuestionMarkCircleIcon,
	PencilAltIcon,
	RefreshIcon,
	SelectorIcon,
	ShoppingBagIcon,
	StarIcon,
	TrashIcon,
	UploadIcon,
	ViewGridIcon,
	ViewListIcon,
	XIcon,
} from '@heroicons/vue/solid'

import { createVNode, openBlock, createBlock } from "vue"

export default function (app) {
	app.component('ArrowCircleDownIcon', ArrowCircleDownIcon)
	app.component('ArrowCircleUpIcon', ArrowCircleUpIcon)
	app.component('BeakerIcon', BeakerIcon)
	app.component('CalendarIcon', CalendarIcon)
	app.component('CheckCircleIcon', CheckCircleIcon)
	app.component('CheckIcon', CheckIcon)
	app.component('ChevronDoubleDownIcon', ChevronDoubleDownIcon)
	app.component('ChevronDoubleLeftIcon', ChevronDoubleLeftIcon)
	app.component('ChevronDoubleRightIcon', ChevronDoubleRightIcon)
	app.component('ChevronDownIcon', ChevronDownIcon)
	app.component('ChevronLeftIcon', ChevronLeftIcon)
	app.component('ChevronUpIcon', ChevronUpIcon)
	app.component('CogIcon', CogIcon)
	app.component('DesktopComputerIcon', DesktopComputerIcon)
	app.component('ExclamationCircleIcon', ExclamationCircleIcon)
	app.component('ExternalLinkIcon', ExternalLinkIcon)
	app.component('EyeIcon', EyeIcon)
	app.component('EyeOffIcon', EyeOffIcon)
	app.component('FlagIcon', FlagIcon)
	app.component('InformationCircleIcon', InformationCircleIcon)
	app.component('SearchIcon', SearchIcon)
	app.component('MailIcon', MailIcon)
	app.component('LocationMarkerIcon', LocationMarkerIcon)
	app.component('MenuIcon', MenuIcon)
	app.component('PlusIcon', PlusIcon)
	app.component('QuestionMarkCircleIcon', QuestionMarkCircleIcon)
	app.component('PencilAltIcon', PencilAltIcon)
	app.component('RefreshIcon', RefreshIcon)
	app.component('SelectorIcon', SelectorIcon)
	app.component('ShoppingBagIcon', ShoppingBagIcon)
	app.component('StarIcon', StarIcon)
	app.component('TrashIcon', TrashIcon)
	app.component('UploadIcon', UploadIcon)
	app.component('ViewGridIcon', ViewGridIcon)
	app.component('ViewListIcon', ViewListIcon)
	app.component('XIcon', XIcon)

	app.component('GoogleDriveIcon', GoogleDriveIcon)
	app.component('TwitterIcon', TwitterIcon)
	app.component('MaleIcon', MaleIcon)
	app.component('FemaleIcon', FemaleIcon)
}

const GoogleDriveIcon = function render() {
	return (openBlock(), createBlock("svg", {
		xmlns: "http://www.w3.org/2000/svg",
		viewBox: "-3 -3 30 30",
		fill: "currentColor"
	}, [
		createVNode("path", {
			"fill-rule": "evenodd",
			d: "M4.433 22.396l4-6.929H24l-4 6.929H4.433zm3.566-6.929l-3.998 6.929L0 15.467 7.785 1.98l3.999 6.931-3.785 6.556zm15.784-.375h-7.999L7.999 1.605h8.002l7.785 13.486h-.003z",
			"clip-rule": "evenodd"
		})
	]))
}

const TwitterIcon = function render() {
	return (openBlock(), createBlock("svg", {
		xmlns: "http://www.w3.org/2000/svg",
		viewBox: "0 0 24 24",
		fill: "currentColor"
	}, [
		createVNode("path", {
			"fill-rule": "evenodd",
			d: "M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z",
			"clip-rule": "evenodd"
		})
	]))
}

const FemaleIcon = function render() {
	return (openBlock(), createBlock("svg", {
		xmlns: "http://www.w3.org/2000/svg",
		viewBox: "0 0 61.132 61.132",
		fill: "currentColor"
	}, [
		createVNode("path", {
			"fill-rule": "evenodd",
			d: "M27.482,34.031v12.317h-6.92c-1.703,0-3.084,1.381-3.084,3.084s1.381,3.084,3.084,3.084h6.92v5.531  c0,1.703,1.381,3.084,3.084,3.084s3.084-1.381,3.084-3.084v-5.531h6.92c1.703,0,3.084-1.381,3.084-3.084s-1.381-3.084-3.084-3.084  h-6.92V34.031c7.993-1.458,14.072-8.467,14.072-16.874C47.723,7.697,40.026,0,30.566,0c-9.46,0-17.157,7.697-17.157,17.157  C13.409,25.564,19.489,32.573,27.482,34.031z M30.566,6.169c6.059,0,10.988,4.929,10.988,10.988s-4.929,10.988-10.988,10.988  s-10.988-4.929-10.988-10.988S24.507,6.169,30.566,6.169z",
			"clip-rule": "evenodd"
		})
	]))
}

const MaleIcon = function render() {
	return (openBlock(), createBlock("svg", {
		xmlns: "http://www.w3.org/2000/svg",
		viewBox: "0 0 49.267 49.267",
		fill: "currentColor"
	}, [
		createVNode("path", {
			"fill-rule": "evenodd",
			d: "M31.261,22.368l11.838-11.838v12.602c0,0.851,0.345,1.623,0.903,2.181s1.33,0.904,2.181,0.903  c1.704,0,3.085-1.381,3.085-3.085V3.084C49.268,1.382,47.886-0.001,46.183,0L26.135,0c-1.704,0-3.085,1.381-3.085,3.085  c-0.001,1.702,1.382,3.085,3.084,3.084h12.602L26.899,18.006c-6.682-4.621-15.938-3.964-21.882,1.981  c-6.689,6.689-6.689,17.574,0,24.263s17.574,6.689,24.263,0C35.225,38.306,35.882,29.05,31.261,22.368z M9.379,39.888  c-4.284-4.284-4.284-11.255,0-15.54s11.255-4.284,15.54,0s4.284,11.255,0,15.54S13.663,44.173,9.379,39.888z",
			"clip-rule": "evenodd"
		})
	]))
}
