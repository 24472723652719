export default {
    // 5.0 Phase II
    fang_of_the_mountain_king: {
        show: '2024-09-15 06:00', release: '2024-09-17 18:00',
        type: 'claymore', tier: 5,
        wam: 'delirious', common_rare: 'ignited', common: 'whistle',
        other_id: 'i_n12513', dim_hash: '1183454019'
    },

    // 5.0 Event
    ash_graven_drinking_horn: {
        release: '2024-08-28',
        type: 'catalyst', tier: 4,
        wam: 'night_wind', common_rare: 'secret_source', common: 'n_fang',
        other_id: 'i_n14427', dim_hash: '422144187'
    },

    // 5.0 Phase I
    surfs_up: {
        release: '2024-08-28',
        type: 'catalyst', tier: 5,
        wam: 'bs_heart', common_rare: 'will', common: 'n_fang',
        other_id: 'i_n14516', dim_hash: '1673987515'
    },

    // 5.0 Forge
    flute_of_ezpitzal: {
        release: '2024-08-28',
        type: 'sword', tier: 4,
        wam: 'bs_heart', common_rare: 'hilt', common: 'n_fang',
        other_id: 'i_n11431', dim_hash: '3434743027'
    },
    earth_shaker: {
        release: '2024-08-28',
        type: 'claymore', tier: 4,
        wam: 'bs_heart', common_rare: 'ignited', common: 'whistle',
        other_id: 'i_n12431', dim_hash: '2481464075'
    },
    footprint_of_the_rainbow: {
        release: '2024-08-28',
        type: 'polearm', tier: 4,
        wam: 'delirious', common_rare: 'secret_source', common: 'whistle',
        other_id: 'i_n13431', dim_hash: '415520051'
    },
    ring_of_yaxche: {
        release: '2024-08-28',
        type: 'catalyst', tier: 4,
        wam: 'delirious', common_rare: 'will', common: 'mask',
        other_id: 'i_n14431', dim_hash: '3092853059'
    },
    chain_breaker: {
        release: '2024-08-28',
        type: 'bow', tier: 4,
        wam: 'night_wind', common_rare: 'fin', common: 'n_fang',
        other_id: 'i_n15431', dim_hash: '565052971'
    },

    // 4.8 Phase II
    lumidouce_elegy: {
        show: '2024-08-04 06:00', release: '2024-08-06 18:00',
        type: 'polearm', tier: 5,
        wam: 'pristine_sea', common_rare: 'hilt', common: 'nectar',
        other_id: '', dim_hash: '1948935171'
    },

    // 4.7 Phase II
    silvershower_heartstrings: {
        show: '2024-06-23 06:00', release: '2024-06-25 18:00',
        type: 'bow', tier: 5,
        wam: 'dewdrop', common_rare: 'fin', common: 'transoceanic',
        other_id: 'i_n15513', dim_hash: '1257396043'
    },

    // 4.7 Event
    cloudforged: {
        release: '2024-06-07',
        type: 'bow', tier: 4,
        wam: 'aerosiderite', common_rare: 'hilt', common: 'f_insignia',
        other_id: 'i_n15426', dim_hash: '874866991'
    },

    // 4.7 Phase I
    absolution: {
        release: '2024-06-05',
        type: 'sword', tier: 5,
        wam: 'ancient_chord', common_rare: 'pocket_watch', common: 'gear',
        other_id: 'i_n11515', dim_hash: '1009471939'
    },

    // 4.6 Phase I
    crimson_moons_semblance: {
        release: '2024-04-24',
        type: 'polearm', tier: 5,
        wam: 'pristine_sea', common_rare: 'hilt', common: 'gear',
        other_id: 'i_n13512', dim_hash: '2165805671'
    },

    // 4.5 Phase I
    uraku_misugiri: {
        type: 'sword', tier: 5,
        wam: 'sea_branch', common_rare: 'chaos_g', common: 'handguard',
        other_id: 'i_n11514', dim_hash: '758082927'
    },

    // 4.5 Event
    dialogues_of_the_desert_sages: {
        type: 'polearm', tier: 4,
        wam: 'talisman', common_rare: 'fin', common: 'spectral_husk',
        other_id: 'i_n13426', dim_hash: '299884423'
    },

    // 4.4 Phase I
    cranes_echoing_call: {
        type: 'catalyst', tier: 5,
        wam: 'mist_veiled_elixer', common_rare: 'fin', common: 'scroll',
        other_id: 'i_n14515', dim_hash: '3660626387'
    },

    // 4.3 Event
    ultimate_overlords_mega_magic_sword: {
        type: 'claymore', tier: 4,
        wam: 'pristine_sea', common_rare: 'pocket_watch', common: 'gear',
        other_id: 'i_n12426', dim_hash: '933442195'
    },

    // 4.3 Phase I
    verdict: {
        type: 'claymore', tier: 5,
        wam: 'ancient_chord', common_rare: 'core', common: 'gear',
        other_id: 'i_n12512', dim_hash: '3740381383'
    },

    // 4.2 Phase I
    splendor_of_tranquil_waters: {
        type: 'sword', tier: 5,
        wam: 'dewdrop', common_rare: 'tainted_water', common: 'transoceanic',
        other_id: 'i_n11513', dim_hash: '1473399443'
    },

    // 4.2 Story Quest
    sword_of_narzissenkreuz: {
        type: 'sword', tier: 4,
        wam: 'ancient_chord', common_rare: 'pocket_watch', common: 'transoceanic',
        other_id: 'i_n11428', dim_hash: '594850707'
    },

    // v4.1 Phase II
    cashflow_supervision: {
        type: 'catalyst', tier: 5,
        wam: 'pristine_sea', common_rare: 'pocket_watch', common: 'transoceanic',
        other_id: 'i_n14513', dim_hash: '724881171'
    },
    prospectors_drill: {
        type: 'polearm', tier: 4,
        wam: 'ancient_chord', common_rare: 'pocket_watch', common: 'gear',
        other_id: 'i_n13427', dim_hash: '882305891'
    },
    range_gauge: {
        type: 'bow', tier: 4,
        wam: 'ancient_chord', common_rare: 'tainted_water', common: 'transoceanic',
        other_id: 'i_n15427', dim_hash: '497088831'
    },

    // v4.1 Event
    ballad_of_the_boundless_blue: {
        type: 'catalyst', tier: 4,
        wam: 'boreal_wolf', common_rare: 'bone_shard', common: 'th_insignia',
        other_id: 'i_n14426', dim_hash: '1265615055'
    },

    // v4.1 Phase I
    tome_of_the_eternal_flow: {
        type: 'catalyst', tier: 5,
        wam: 'dewdrop', common_rare: 'core', common: 'gear',
        other_id: 'i_n14514', dim_hash: '3973708695'
    },
    the_dockhands_assistant: {
        type: 'sword', tier: 4,
        wam: 'dewdrop', common_rare: 'pocket_watch', common: 'transoceanic',
        other_id: 'i_n11427', dim_hash: '1191414935'
    },
    portable_power_saw: {
        type: 'claymore', tier: 4,
        wam: 'pristine_sea', common_rare: 'core', common: 'gear',
        other_id: 'i_n12427', dim_hash: '2687461991'
    },

    finale_of_the_deep: {
        type: 'sword', tier: 4,
        wam: 'dewdrop', common_rare: 'tainted_water', common: 'handguard',
        other_id: 'i_n11425', dim_hash: '800766043'
    },
    tidal_shadow: {
        type: 'claymore', tier: 4,
        wam: 'pristine_sea', common_rare: 'core', common: 'gear',
        other_id: 'i_n12425', dim_hash: '710642639'
    },
    rightful_reward: {
        type: 'polearm', tier: 4,
        wam: 'pristine_sea', common_rare: 'core', common: 'gear',
        other_id: 'i_n13425', dim_hash: '1656717559'
    },
    flowing_purity: {
        type: 'catalyst', tier: 4,
        wam: 'dewdrop', common_rare: 'core', common: 'transoceanic',
        other_id: 'i_n14425', dim_hash: '2432865507'
    },
    song_of_stillness: {
        type: 'bow', tier: 4,
        wam: 'ancient_chord', common_rare: 'tainted_water', common: 'arrowhead',
        other_id: 'i_n15425', dim_hash: '2600875323'
    },

    wolf_fang: {
        type: 'sword', tier: 4,
        wam: 'decarabian', common_rare: 'chaos', common: 'mask',
        other_id: 'i_n11424', dim_hash: '2265792579'
    },
    talking_stick: {
        type: 'claymore', tier: 4,
        wam: 'oasis_gardens', common_rare: 'shell', common: 'slime',
        other_id: 'i_n12424', dim_hash: '2196412231'
    },
    ballad_of_the_fjords: {
        type: 'polearm', tier: 4,
        wam: 'pristine_sea', common_rare: 'flower', common: 'nectar',
        other_id: 'i_n13424', dim_hash: '449192923'
    },
    sacrificial_jade: {
        type: 'catalyst', tier: 4,
        wam: 'guyun', common_rare: 'mist', common: 'scroll',
        other_id: 'i_n14424', dim_hash: '1413595327'
    },
    scion_of_the_blazing_sun: {
        type: 'bow', tier: 4,
        wam: 'scorching_might', common_rare: 'flower', common: 'spores',
        other_id: 'i_n15424', dim_hash: '2443658695'
    },

    crossing_of_fleuve_cendre: {
        type: 'sword', tier: 4,
        wam: 'ancient_chord', common_rare: 'tainted_water', common: 'transoceanic',
        other_id: 'i_n11426', dim_hash: '1921306659'
    },
    the_first_great_magic: {
        type: 'bow', tier: 5,
        wam: 'ancient_chord', common_rare: 'tainted_water', common: 'transoceanic',
        other_id: 'i_n15512', dim_hash: '880827495'
    },

    ibis_piercer: {
        type: 'bow', tier: 4,
        wam: 'talisman', common_rare: 'flower', common: 'red_satin',
        other_id: 'i_n15419', dim_hash: '316078811'
    },

    jadefalls_splendor: {
        type: 'catalyst', tier: 5,
        wam: 'guyun', common_rare: 'flower', common: 'spores',
        other_id: 'i_n14505', dim_hash: '3314723143'
    },
    mailed_flower: {
        type: 'claymore', tier: 4,
        wam: 'dandelion_gladiator', common_rare: 'shell', common: 'spectral_husk',
        other_id: 'i_n12418', dim_hash: '1200948859'
    },
    beacon_of_the_reed_sea: {
        type: 'claymore', tier: 5,
        wam: 'scorching_might', common_rare: 'shell', common: 'red_satin',
        other_id: 'i_n12511', dim_hash: '515156999'
    },
    light_of_foliar_incision: {
        type: 'sword', tier: 5,
        wam: 'talisman', common_rare: 'shell', common: 'red_satin',
        other_id: 'i_n11512', dim_hash: '275622963'
    },
    toukabou_shigure: {
        type: 'sword', tier: 4,
        wam: 'narukami', common_rare: 'd_prism', common: 'handguard',
        other_id: 'i_n11422', dim_hash: '776485631'
    },
    tulaytullahs_remembrance: {
        type: 'catalyst', tier: 5,
        wam: 'scorching_might', common_rare: 'fungal_nucleus', common: 'spores',
        other_id: 'i_n14512', dim_hash: '359484419'
    },
    a_thousand_floating_dreams: {
        type: 'catalyst', tier: 5,
        wam: 'oasis_gardens', common_rare: 'd_prism', common: 'spores',
        other_id: 'i_n14511', dim_hash: '985492495'
    },
    key_of_khaj_nisut: {
        type: 'sword', tier: 5,
        wam: 'talisman', common_rare: 'd_prism', common: 'red_satin',
        other_id: 'i_n11511', dim_hash: '454086795'
    },
    xiphos_moonlight: {
        type: 'sword', tier: 4,
        wam: 'talisman', common_rare: 'd_prism', common: 'red_satin',
        other_id: 'i_n11418', dim_hash: '91715615'
    },
    wandering_evenstar: {
        type: 'catalyst', tier: 4,
        wam: 'oasis_gardens', common_rare: 'fungal_nucleus', common: 'spores',
        other_id: 'i_n14416', dim_hash: '426363739'
    },
    makhaira_aquamarine: {
        type: 'claymore', tier: 4,
        wam: 'scorching_might', common_rare: 'chaos_s', common: 'th_insignia',
        other_id: 'i_n12415', dim_hash: '2495991735'
    },
    missive_windspear: {
        type: 'polearm', tier: 4,
        wam: 'boreal_wolf', common_rare: 'statuette', common: 'slime',
        other_id: 'i_n13419', dim_hash: '1470442731'
    },
    staff_of_the_scarlet_sands: {
        type: 'polearm', tier: 5,
        wam: 'oasis_gardens', common_rare: 'chaos_s', common: 'spores',
        other_id: 'i_n13511', dim_hash: '493543735'
    },

    amos_bow: {
        type: 'bow', tier: 5,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'slime',
        other_id: 'i_n15502', dim_hash: '764146020'
    },
    skyward_harp: {
        type: 'bow', tier: 5,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'arrowhead',
        other_id: 'i_n15501', dim_hash: '1503822203'
    },
    compound_bow: {
        type: 'bow', tier: 4,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'f_insignia',
        other_id: 'i_n15407', dim_hash: '411685275'
    },
    blackcliff_warbow: {
        type: 'bow', tier: 4,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'nectar',
        other_id: 'i_n15408', dim_hash: '2973699991'
    },
    the_viridescent_hunt: {
        type: 'bow', tier: 4,
        wam: 'decarabian', common_rare: 'horn', common: 'arrowhead',
        other_id: 'i_n15409', dim_hash: '3024994927'
    },
    prototype_crescent: {
        type: 'bow', tier: 4,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'th_insignia',
        other_id: 'i_n15406', dim_hash: '383090263'
    },
    sacrificial_bow: {
        type: 'bow', tier: 4,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'slime',
        other_id: 'i_n15403', dim_hash: '1172545236'
    },
    rust: {
        type: 'bow', tier: 4,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'mask',
        other_id: 'i_n15405', dim_hash: '3169209451'
    },
    the_stringless: {
        type: 'bow', tier: 4,
        wam: 'decarabian', common_rare: 'horn', common: 'arrowhead',
        other_id: 'i_n15402', dim_hash: '1377242735'
    },
    royal_bow: {
        type: 'bow', tier: 4,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'scroll',
        other_id: 'i_n15404', dim_hash: '1850565807'
    },
    favonius_warbow: {
        type: 'bow', tier: 4,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'nectar',
        other_id: 'i_n15401', dim_hash: '1240067179'
    },
    sharpshooters_oath: {
        type: 'bow', tier: 3,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'slime',
        other_id: 'i_n15302', dim_hash: '656120259'
    },
    slingshot: {
        type: 'bow', tier: 3,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'mask',
        other_id: 'i_n15304', dim_hash: '500802395'
    },
    raven_bow: {
        type: 'bow', tier: 3,
        wam: 'decarabian', common_rare: 'horn', common: 'arrowhead',
        other_id: 'i_n15301', dim_hash: '678693135'
    },
    messenger: {
        type: 'bow', tier: 3,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'th_insignia',
        other_id: 'i_n15305', dim_hash: '1563268175'
    },
    recurve_bow: {
        type: 'bow', tier: 3,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'scroll',
        other_id: 'i_n15303', dim_hash: '3918495615'
    },
    alley_hunter: {
        type: 'bow', tier: 4,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'slime',
        other_id: 'i_n15410', dim_hash: '2417717595'
    },
    windblume_ode: {
        type: 'bow', tier: 4,
        wam: 'dandelion_gladiator', common_rare: 'ley_line', common: 'nectar',
        other_id: 'i_n15413', dim_hash: '74815335'
    },
    elegy_for_the_end: {
        type: 'bow', tier: 5,
        wam: 'boreal_wolf', common_rare: 'horn', common: 'f_insignia',
        other_id: 'i_n15503', dim_hash: '1856192151'
    },
    mitternachts_waltz: {
        type: 'bow', tier: 4,
        wam: 'decarabian', common_rare: 'horn', common: 'th_insignia',
        other_id: 'i_n15412', dim_hash: '1099797423'
    },
    demon_slayer_bow: {
        type: 'bow', tier: 4,
        wam: 'narukami', common_rare: 'prism', common: 'arrowhead',
        other_id: 'i_n15414', dim_hash: '103200455'
    },
    thundering_pulse: {
        type: 'bow', tier: 5,
        wam: 'narukami', common_rare: 'prism', common: 'arrowhead',
        other_id: 'i_n15509', dim_hash: '1027555479'
    },
    predator: {
        type: 'bow', tier: 4,
        wam: 'narukami', common_rare: 'prism', common: 'arrowhead',
        other_id: 'i_n15415', dim_hash: '540938627'
    },
    mouuns_moon: {
        type: 'bow', tier: 4,
        wam: 'narukami', common_rare: 'prism', common: 'spectral_husk',
        other_id: 'i_n15416', dim_hash: '1860795787'
    },
    brumal_star: {
        type: 'bow', tier: 5,
        wam: 'mask_w', common_rare: 'claw', common: 'spectral_husk',
        other_id: 'i_n15507', dim_hash: '589149287'
    },
    aqua_simulacra: {
        type: 'bow', tier: 5,
        wam: 'guyun', common_rare: 'statuette', common: 'spectral_husk',
        other_id: 'i_n15508', dim_hash: '3613991655'
    },
    fading_twilight: {
        type: 'bow', tier: 4,
        wam: 'aerosiderite', common_rare: 'sacrificial_knife', common: 'scroll',
        other_id: 'i_n15411', dim_hash: '2341441191'
    },
    kings_squire: {
        type: 'bow', tier: 4,
        wam: 'scorching_might', common_rare: 'fungal_nucleus', common: 'arrowhead',
        other_id: 'i_n15417', dim_hash: '251309068'
    },
    hunters_path: {
        type: 'bow', tier: 5,
        wam: 'scorching_might', common_rare: 'fungal_nucleus', common: 'red_satin',
        other_id: 'i_n15511', dim_hash: '1348687251'
    },
    trawler: {
        type: 'bow', tier: 4,
        wam: 'scorching_might', common_rare: 'fungal_nucleus', common: 'spores',
        other_id: 'i_n15418', dim_hash: '2928889407'
    },

    blackcliff_amulet: {
        type: 'catalyst', tier: 4,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'scroll',
        other_id: 'i_n14408', dim_hash: '3184574983'
    },
    dodoco_tales: {
        type: 'catalyst', tier: 4,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'mask',
        other_id: 'i_n14413', dim_hash: '1616738887'
    },
    emerald_orb: {
        type: 'catalyst', tier: 3,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'th_insignia',
        other_id: 'i_n14304', dim_hash: '1647200303'
    },
    eye_of_perception: {
        type: 'catalyst', tier: 4,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'mask',
        other_id: 'i_n14409', dim_hash: '3156385731'
    },
    favonius_codex: {
        type: 'catalyst', tier: 4,
        wam: 'decarabian', common_rare: 'horn', common: 'scroll',
        other_id: 'i_n14401', dim_hash: '623534363'
    },
    frostbearer: {
        type: 'catalyst', tier: 4,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'nectar',
        other_id: 'i_n14412', dim_hash: '2587614459'
    },
    fruit_of_fulfillment: {
        type: 'catalyst', tier: 4,
        wam: 'oasis_gardens', common_rare: 'statuette', common: 'spores',
        other_id: 'i_n14417', dim_hash: '477511084'
    },
    fumetsu_gekka: {
        type: 'catalyst', tier: 5,
        wam: 'sea_branch', common_rare: 'prism', common: 'spectral_husk',
        other_id: 'i_n14506', dim_hash: '1890163363'
    },
    kaguras_verity: {
        type: 'catalyst', tier: 5,
        wam: 'mask_w', common_rare: 'claw', common: 'spectral_husk',
        other_id: 'i_n14509', dim_hash: '310247243'
    },
    lost_prayer_to_the_sacred_winds: {
        type: 'catalyst', tier: 5,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'slime',
        other_id: 'i_n14502', dim_hash: '199912564'
    },
    magic_guide: {
        type: 'catalyst', tier: 3,
        wam: 'decarabian', common_rare: 'horn', common: 'slime',
        other_id: 'i_n14301', dim_hash: '1166332260'
    },
    mappa_mare: {
        type: 'catalyst', tier: 4,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'slime',
        other_id: 'i_n14407', dim_hash: '1072884907'
    },
    memory_of_dust: {
        type: 'catalyst', tier: 5,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'mask',
        other_id: 'i_n14504', dim_hash: '693354267'
    },
    oathsworn_eye: {
        type: 'catalyst', tier: 4,
        wam: 'sea_branch', common_rare: 'claw', common: 'spectral_husk',
        other_id: 'i_n14415', dim_hash: '2539208459'
    },
    otherworldly_story: {
        type: 'catalyst', tier: 3,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'mask',
        other_id: 'i_n14303', dim_hash: '1406746947'
    },
    prototype_malice: {
        type: 'catalyst', tier: 4,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'arrowhead',
        other_id: 'i_n14406', dim_hash: '2521338131'
    },
    royal_grimoire: {
        type: 'catalyst', tier: 4,
        wam: 'decarabian', common_rare: 'horn', common: 'f_insignia',
        other_id: 'i_n14404', dim_hash: '2574551135'
    },
    sacrificial_fragments: {
        type: 'catalyst', tier: 4,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'th_insignia',
        other_id: 'i_n14403', dim_hash: '774846175'
    },
    skyward_atlas: {
        type: 'catalyst', tier: 5,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'arrowhead',
        other_id: 'i_n14501', dim_hash: '807607555'
    },
    solar_pearl: {
        type: 'catalyst', tier: 4,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'nectar',
        other_id: 'i_n14405', dim_hash: '37147251'
    },
    the_widsith: {
        type: 'catalyst', tier: 4,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'mask',
        other_id: 'i_n14402', dim_hash: '551729292'
    },
    thrilling_tales_of_dragon_slayers: {
        type: 'catalyst', tier: 3,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'scroll',
        other_id: 'i_n14302', dim_hash: '743272311'
    },
    twin_nephrite: {
        type: 'catalyst', tier: 3,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'f_insignia',
        other_id: 'i_n14305', dim_hash: '2187374887'
    },
    white_dragon_ring: {
        type: 'catalyst', tier: 4,
        wam: 'sea_branch', common_rare: 'prism', common: 'scroll',
        other_id: 'i_n14414', dim_hash: '1622053103'
    },
    wine_and_song: {
        type: 'catalyst', tier: 4,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'th_insignia',
        other_id: 'i_n14410', dim_hash: '1935574775'
    },

    akuoumaru: {
        type: 'claymore', tier: 4,
        wam: 'sea_branch', common_rare: 'claw', common: 'handguard',
        other_id: 'i_n12416', dim_hash: '1280943279'
    },
    blackcliff_slasher: {
        type: 'claymore', tier: 4,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'f_insignia',
        other_id: 'i_n12408', dim_hash: '20848859'
    },
    bloodtainted_greatsword: {
        type: 'claymore', tier: 3,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'arrowhead',
        other_id: 'i_n12302', dim_hash: '307074191'
    },
    debate_club: {
        type: 'claymore', tier: 3,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'mask',
        other_id: 'i_n12305', dim_hash: '2996485895'
    },
    favonius_greatsword: {
        type: 'claymore', tier: 4,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'f_insignia',
        other_id: 'i_n12401', dim_hash: '382760225'
    },
    ferrous_shadow: {
        type: 'claymore', tier: 3,
        wam: 'decarabian', common_rare: 'horn', common: 'nectar',
        other_id: 'i_n12301', dim_hash: '1479961579'
    },
    forest_regalia: {
        type: 'claymore', tier: 4,
        wam: 'talisman', common_rare: 'chaos_s', common: 'red_satin',
        other_id: 'i_n12417', dim_hash: '902184579'
    },
    katsuragis_slasher: {
        type: 'claymore', tier: 4,
        wam: 'narukami', common_rare: 'chaos_g', common: 'handguard',
        other_id: 'i_n12414', dim_hash: '550568279'
    },
    lithic_blade: {
        type: 'claymore', tier: 4,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'arrowhead',
        other_id: 'i_n12410', dim_hash: '2006422931'
    },
    luxurious_sea_lord: {
        type: 'claymore', tier: 4,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'slime',
        other_id: 'i_n12412', dim_hash: '346510395'
    },
    prototype_aminus: {
        type: 'claymore', tier: 4,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'mask',
        other_id: 'i_n12406', dim_hash: '1410662759'
    },
    rainslasher: {
        type: 'claymore', tier: 4,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'scroll',
        other_id: 'i_n12405', dim_hash: '3583403215'
    },
    redhorn_stonethresher: {
        type: 'claymore', tier: 5,
        wam: 'narukami', common_rare: 'claw', common: 'handguard',
        other_id: 'i_n12510', dim_hash: '3905365671'
    },
    royal_greatsword: {
        type: 'claymore', tier: 4,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'slime',
        other_id: 'i_n12404', dim_hash: '599556295'
    },
    sacrificial_greatsword: {
        type: 'claymore', tier: 4,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'arrowhead',
        other_id: 'i_n12403', dim_hash: '328604564'
    },
    serpent_spine: {
        type: 'claymore', tier: 4,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'nectar',
        other_id: 'i_n12409', dim_hash: '127404095'
    },
    skyrider_greatsword: {
        type: 'claymore', tier: 3,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'th_insignia',
        other_id: 'i_n12306', dim_hash: '2614170427'
    },
    skyward_pride: {
        type: 'claymore', tier: 5,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'slime',
        other_id: 'i_n12501', dim_hash: '1089950259'
    },
    snow_tombed_starsliver: {
        type: 'claymore', tier: 4,
        wam: 'decarabian', common_rare: 'horn', common: 'slime',
        other_id: 'i_n12411', dim_hash: '775798695'
    },
    song_of_broken_pines: {
        type: 'claymore', tier: 5,
        wam: 'decarabian', common_rare: 'horn', common: 'mask',
        other_id: 'i_n12503', dim_hash: '1075647299'
    },
    the_bell: {
        type: 'claymore', tier: 4,
        wam: 'decarabian', common_rare: 'horn', common: 'nectar',
        other_id: 'i_n12402', dim_hash: '930640955'
    },
    the_unforged: {
        type: 'claymore', tier: 5,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'th_insignia',
        other_id: 'i_n12504', dim_hash: '2088699186'
    },
    white_iron_greatsword: {
        type: 'claymore', tier: 3,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'slime',
        other_id: 'i_n12303', dim_hash: '426635004'
    },
    whiteblind: {
        type: 'claymore', tier: 4,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'th_insignia',
        other_id: 'i_n12407', dim_hash: '680510411'
    },
    wolfs_gravestone: {
        type: 'claymore', tier: 5,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'scroll',
        other_id: 'i_n12502', dim_hash: '1205556486'
    },

    black_tassel: {
        type: 'polearm', tier: 3,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'arrowhead',
        other_id: 'i_n13303', dim_hash: '3573475207'
    },
    blackcliff_pole: {
        type: 'polearm', tier: 4,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'f_insignia',
        other_id: 'i_n13404', dim_hash: '442882487'
    },
    calamity_queller: {
        type: 'polearm', tier: 5,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'nectar',
        other_id: 'i_n13507', dim_hash: '688991243'
    },
    crescent_pike: {
        type: 'polearm', tier: 4,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'th_insignia',
        other_id: 'i_n13403', dim_hash: '578575283'
    },
    deathmatch: {
        type: 'polearm', tier: 4,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'nectar',
        other_id: 'i_n13405', dim_hash: '1861175383'
    },
    dragons_bane: {
        type: 'polearm', tier: 4,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'scroll',
        other_id: 'i_n13401', dim_hash: '2664629131'
    },
    dragonspine_spear: {
        type: 'polearm', tier: 4,
        wam: 'boreal_wolf', common_rare: 'mist', common: 'f_insignia',
        other_id: 'i_n13409', dim_hash: '3443142923'
    },
    favonius_lance: {
        type: 'polearm', tier: 4,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'slime',
        other_id: 'i_n13407', dim_hash: '3344656191'
    },
    grasscutters_light: {
        type: 'polearm', tier: 5,
        wam: 'mask_w', common_rare: 'chaos_g', common: 'handguard',
        other_id: 'i_n13509', dim_hash: '543747775'
    },
    halberd: {
        type: 'polearm', tier: 3,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'nectar',
        other_id: 'i_n13302', dim_hash: '618786571'
    },
    kitain_cross_spear: {
        type: 'polearm', tier: 4,
        wam: 'mask_w', common_rare: 'chaos_g', common: 'th_insignia',
        other_id: 'i_n13414', dim_hash: '629851439'
    },
    lithic_spear: {
        type: 'polearm', tier: 4,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'arrowhead',
        other_id: 'i_n13406', dim_hash: '539899356'
    },
    moonpiercer: {
        type: 'polearm', tier: 4,
        wam: 'oasis_gardens', common_rare: 'chaos_s', common: 'f_insignia',
        other_id: 'i_n13417', dim_hash: '244732383'
    },
    primordial_jade_winged_spear: {
        type: 'polearm', tier: 5,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'f_insignia',
        other_id: 'i_n13505', dim_hash: '1997709467'
    },
    prototype_grudge: {
        type: 'polearm', tier: 4,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'mask',
        other_id: 'i_n13402', dim_hash: '1154009435'
    },
    royal_spear: {
        type: 'polearm', tier: 4,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'f_insignia',
        other_id: 'i_n13408', dim_hash: '1581224975'
    },
    skyward_spine: {
        type: 'polearm', tier: 5,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'scroll',
        other_id: 'i_n13502', dim_hash: '342388612'
    },
    staff_of_homa: {
        type: 'polearm', tier: 5,
        wam: 'aerosiderite', common_rare: 'ley_line', common: 'slime',
        other_id: 'i_n13501', dim_hash: '2329450847'
    },
    the_catch: {
        type: 'polearm', tier: 4,
        wam: 'mask_w', common_rare: 'chaos_g', common: 'spectral_husk',
        other_id: 'i_n13415', dim_hash: '1148024603'
    },
    vortex_vanquisher: {
        type: 'polearm', tier: 5,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'th_insignia',
        other_id: 'i_n13504', dim_hash: '197755235'
    },
    wavebreakers_fin: {
        type: 'polearm', tier: 4,
        wam: 'mask_w', common_rare: 'claw', common: 'handguard',
        other_id: 'i_n13416', dim_hash: '2217521167'
    },
    white_tassel: {
        type: 'polearm', tier: 3,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'f_insignia',
        other_id: 'i_n13301', dim_hash: '297640580'
    },

    'freedom-sworn': {
        type: 'sword', tier: 5,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'scroll',
        other_id: 'i_n11503', dim_hash: '739816439'
    },
    amenoma_kageuta_blade: {
        type: 'sword', tier: 4,
        wam: 'sea_branch', common_rare: 'chaos_g', common: 'handguard',
        other_id: 'i_n11414', dim_hash: '1990820123'
    },
    aquila_favonia: {
        type: 'sword', tier: 5,
        wam: 'decarabian', common_rare: 'horn', common: 'arrowhead',
        other_id: 'i_n11501', dim_hash: '90386684'
    },
    blackcliff_longsword: {
        type: 'sword', tier: 4,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'arrowhead',
        other_id: 'i_n11408', dim_hash: '1614690231'
    },
    cinnabar_spindle: {
        type: 'sword', tier: 4,
        wam: 'decarabian', common_rare: 'chaos', common: 'mask',
        other_id: 'i_n11415', dim_hash: '342097547'
    },
    cool_steel: {
        type: 'sword', tier: 3,
        wam: 'decarabian', common_rare: 'horn', common: 'arrowhead',
        other_id: 'i_n11301', dim_hash: '479076483'
    },
    cursed_blade: {
        type: 'sword', tier: 4,
        wam: 'mask_w', common_rare: 'statuette', common: 'spectral_husk',
        other_id: 'i_n11416', dim_hash: '458193088'
    },
    dark_iron_sword: {
        type: 'sword', tier: 3,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'mask',
        other_id: 'i_n11304', dim_hash: '160493219'
    },
    favonius_sword: {
        type: 'sword', tier: 4,
        wam: 'decarabian', common_rare: 'horn', common: 'arrowhead',
        other_id: 'i_n11401', dim_hash: '272958340'
    },
    festering_desire: {
        type: 'sword', tier: 4,
        wam: 'dandelion_gladiator', common_rare: 'horn', common: 'f_insignia',
        other_id: 'i_n11413', dim_hash: '267204103'
    },
    fillet_blade: {
        type: 'sword', tier: 3,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'th_insignia',
        other_id: 'i_n11305', dim_hash: '1122808775'
    },
    haran_geppaku_futsu: {
        type: 'sword', tier: 5,
        wam: 'narukami', common_rare: 'statuette', common: 'handguard',
        other_id: 'i_n11510', dim_hash: '1600275315'
    },
    harbinger_of_dawn: {
        type: 'sword', tier: 3,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'slime',
        other_id: 'i_n11302', dim_hash: '1608953539'
    },
    iron_sting: {
        type: 'sword', tier: 4,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'nectar',
        other_id: 'i_n11407', dim_hash: '3618903199'
    },
    lions_roar: {
        type: 'sword', tier: 4,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'th_insignia',
        other_id: 'i_n11405', dim_hash: '1321135667'
    },
    mistsplitters_reflection: {
        type: 'sword', tier: 5,
        wam: 'sea_branch', common_rare: 'chaos_g', common: 'handguard',
        other_id: 'i_n11509', dim_hash: '1705416799'
    },
    primordial_jade_cutter: {
        type: 'sword', tier: 5,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'th_insignia',
        other_id: 'i_n11505', dim_hash: '689633524'
    },
    prototype_rancour: {
        type: 'sword', tier: 4,
        wam: 'mist_veiled_elixer', common_rare: 'mist', common: 'f_insignia',
        other_id: 'i_n11406', dim_hash: '613888599'
    },
    royal_longsword: {
        type: 'sword', tier: 4,
        wam: 'decarabian', common_rare: 'horn', common: 'arrowhead',
        other_id: 'i_n11404', dim_hash: '17475455'
    },
    sacrificial_sword: {
        type: 'sword', tier: 4,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'scroll',
        other_id: 'i_n11403', dim_hash: '59791599'
    },
    sapwood_blade: {
        type: 'sword', tier: 4,
        wam: 'talisman', common_rare: 'chaos_s', common: 'red_satin',
        other_id: 'i_n11417', dim_hash: '313300315'
    },
    skyrider_sword: {
        type: 'sword', tier: 3,
        wam: 'aerosiderite', common_rare: 'bone_shard', common: 'f_insignia',
        other_id: 'i_n11306', dim_hash: '753806407'
    },
    skyward_blade: {
        type: 'sword', tier: 5,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'slime',
        other_id: 'i_n11502', dim_hash: '70572199'
    },
    summit_shaper: {
        type: 'sword', tier: 5,
        wam: 'guyun', common_rare: 'sacrificial_knife', common: 'mask',
        other_id: 'i_n11504', dim_hash: '225064260'
    },
    sword_of_descension: {
        type: 'sword', tier: 4,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'th_insignia',
        other_id: 'i_n11412', dim_hash: '1736208559'
    },
    the_alley_flash: {
        type: 'sword', tier: 4,
        wam: 'decarabian', common_rare: 'horn', common: 'scroll',
        other_id: 'i_n11410', dim_hash: '1895310359'
    },
    the_black_sword: {
        type: 'sword', tier: 4,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'slime',
        other_id: 'i_n11409', dim_hash: '1617057727'
    },
    the_flute: {
        type: 'sword', tier: 4,
        wam: 'boreal_wolf', common_rare: 'ley_line', common: 'slime',
        other_id: 'i_n11402', dim_hash: '1872079607'
    },
    travelers_handy_sword: {
        type: 'sword', tier: 3,
        wam: 'dandelion_gladiator', common_rare: 'chaos', common: 'scroll',
        other_id: 'i_n11303', dim_hash: '1498335679'
    },
};
