<template>
    <div class="flex items-center">
        <div class="relative">
            <input type="text" class="bg-gray-700 border-none rounded-md pr-8 w-full sm:w-64"
                :placeholder="$t('search')"
                :value="search"
                @input="$emit('update:search', $event.target.value)"
                @keydown.escape="$emit('update:search', '')"
            >
            <button class="flex items-center justify-center absolute right-0 inset-y-0 mr-1 opacity-50" v-if="search.length" @click="$emit('update:search', '')">
                <x-icon class="w-6 h-6"/>
            </button>
        </div>

        <div class="flex">
            <select
                :value="version"
                @change="$emit('update:version', $event.target.value)"
                class="bg-gray-700 ml-2 border-none !ring-0"
                :class="version !== 'all' ? 'rounded-l-md' : 'rounded-md'"
            >
                <option value="all">{{ $t('all_f') }}</option>
                <option
                    v-for="ver in versions"
                    :value="ver"
                    :key="`option-${ver}`"
                >
                    {{ ver }}
                </option>
            </select>
            <button class="flex items-center justify-center bg-gray-700 rounded-r-md px-2" v-if="version !== 'all'" @click="$emit('update:version', 'all')">
                <x-icon class="w-6 h-6 opacity-50"/>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        versions: Array,
        search: String,
        version: String
    }
}
</script>
