<template>
    <div class="h-full relative">
        <div class="flex flex-col w-24 sm:w-28 rounded-lg overflow-hidden shadow-md relative h-full">
            <div class="w-24 sm:w-28 h-24 sm:h-28 overflow-hidden relative bg-gradient-to-br flex-shrink-0"
                :class="darkTierClass[character.tier]"
            >
                <img :src="`${spaces}/characters/select/${character.traveler ? `traveler_${gender}` : selected}.png`" class="w-full h-full" v-if="!character.custom">
                <img :src="`${url}img/other/custom_${character.gender}.png`" class="w-full h-full" v-else>

                <img :src="`${url}img/elements/${character.element}.png`" class="absolute bottom-0 right-0 w-6 h-6" v-if="character.element">

                <div
                    class="absolute bottom-0 left-0 bg-gray-900/60 text-white text-xs rounded-full w-8 m-1 font-semibold tracking-widest"
                    v-if="characterCons"
                >
                    C{{ characterCons }}
                </div>
            </div>
            <div class="uppercase font-semibold text-xs px-1 py-1 tracking-tight leading-tight h-full flex items-center justify-center" :class="character.tier === 4 ? 'bg-epic-700' : 'bg-legendary-700'">
                {{ character.traveler ? `${character.name} (${$t(character.element)})` : character.name }}
            </div>
        </div>

        <div class="absolute top-0 right-0 -m-1 text-xs leading-none flex flex-col items-end">
            <template v-if="characterGoal">
                <div v-tippy.arrow.right.html="`${selected}-character-tippy`" class="bg-blue-500 rounded-full mb-px py-1 px-2" :class="{ 'opacity-25': characterGoal.current.text === characterGoal.goal.text }">
                    <level small :level="characterGoal.current"/>
                </div>

                <div :id="`${selected}-character-tippy`" class="flex items-center">
                    <level class="w-8" small :level="characterGoal.current"/>
                    <template v-if="characterGoal.current.text !== characterGoal.goal.text">
                        <chevron-double-right-icon class="w-4 mx-1"/>
                        <level class="w-8" small :level="characterGoal.goal"/>
                    </template>
                </div>
            </template>

            <template v-if="weaponGoal">
                <div v-tippy.arrow.right.html="`${selected}-weapon-tippy`" class="bg-pink-500 rounded-full mb-px py-1 px-2" :class="{ 'opacity-25': weaponGoal.current.text === weaponGoal.goal.text }">
                    <level small :level="weaponGoal.current"/>
                </div>

                 <div :id="`${selected}-weapon-tippy`" class="flex items-center">
                    <level class="w-8" small :level="weaponGoal.current"/>
                    <template v-if="weaponGoal.current.text !== weaponGoal.goal.text">
                        <chevron-double-right-icon class="w-4 mx-1"/>
                        <level class="w-8" small :level="weaponGoal.goal"/>
                    </template>
                </div>
            </template>
        </div>

        <div class="absolute top-0 left-0 -m-1 text-xs leading-none flex">
            <template v-if="talentGoals.length">
                <div v-tippy.arrow.right.html="`${selected}-talents-tippy`" class="font-semibold bg-purple-500 mr-px rounded-full flex items-center justify-center w-6 h-6" :class="{ 'opacity-25': !anyTalentGoal }">
                    <img :src="`${url}img/general/talent.png`" class="w-4"/>
                </div>

                <div :id="`${selected}-talents-tippy`" class="flex flex-col">
                    <div v-for="(t, i) in talentGoals" :key="`${selected}-talent-${i}`" class="flex items-center">
                        {{ t.normal.current }} / {{ t.skill.current }} / {{ t.burst.current }}
                        <template v-if="t.normal.current !== t.normal.goal || t.skill.current !== t.skill.goal || t.burst.current !== t.burst.goal">
                            <chevron-double-right-icon class="w-4 mx-1"/>
                            {{ t.normal.goal }} / {{ t.skill.goal }} / {{ t.burst.goal }}
                        </template>
                    </div>
                </div>
            </template>

            <template v-if="artifactGoal && (artifactGoal.sands || artifactGoal.goblet || artifactGoal.circlet || (artifactGoal.artifacts && artifactGoal.artifacts.length))">
                <div v-tippy.arrow.right.html="`${selected}-artifacts-tippy`" class="font-semibold bg-orange-500 rounded-full flex items-center justify-center w-6 h-6" :class="{ 'opacity-25': artifactGoalIsDone }">
                    <img :src="`${url}img/general/artifact.png`" class="h-5"/>
                </div>

                <div :id="`${selected}-artifacts-tippy`" class="flex flex-col -mb-2">
                    <div v-for="type in ['sands', 'goblet', 'circlet']" :key="type">
                        <div v-if="artifactGoal[type]" class="mb-2">
                            <div class="font-semibold text-sm">{{ $t(type) }}</div>
                            <div class="text-xs">{{ $t(artifactGoal[type]) }}</div>
                        </div>
                    </div>

                    <div v-if="artifactGoal.artifacts && artifactGoal.artifacts.length" class="mb-2">
                        <hr class="opacity-25 mb-1" v-if="artifactGoal.sands || artifactGoal.goblet || artifactGoal.circlet"/>
                        <div v-for="a in artifactGoal.artifacts" :key="a" class="text-sm font-semibold">{{ $t(a) }}</div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Level from './Level'

export default {
    components: { Level },

    props: {
        selected: String
    },

    computed: {
        character () {
            return this.customs[this.selected] || {
                name: this.$t(this.selected),
                ...this.characters[this.selected]
            }
        },

        characterGoal () {
            return this.goals.find(g => g.character === this.selected && g.type === 'character')
        },

        characterCons () {
            return this.characterGoal?.cons || 0
        },

        talentGoals () {
            return this.goals.filter(g => g.character === this.selected && g.type === 'talent') || []
        },

        anyTalentGoal () {
            return this.talentGoals.filter(tg => {
                return tg.normal.goal > tg.normal.current || tg.skill.goal > tg.skill.current || tg.burst.goal > tg.burst.current
            }).length
        },

        weaponGoal () {
            return this.goals.find(g => g.character === this.selected && g.type === 'weapon')
        },

        artifactGoal () {
            return this.goals.find(g => g.character === this.selected && g.type === 'artifact')
        },

        artifactGoalIsDone () {
            if (!this.artifactGoal) return true
            let goal = this.artifactGoal
            if ((goal.sands && !goal.done?.sands) || (goal.goblet && !goal.done?.goblet) || (goal.circlet && !goal.done?.circlet)) return false
            if (goal.artifacts && goal.artifacts.length && goal.artifacts.filter(a => !goal.done?.[a]).length) return false
            return true
        }
    }
}
</script>
