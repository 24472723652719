export default {
    obsidian_codex: { max_tier: 5, other_id: 'i_n400331', dim_hash: '3345941393', release: '2024-08-28' },
    scroll_of_the_hero: { max_tier: 5, other_id: 'i_n400321', dim_hash: '3615686241', release: '2024-08-28' },
    vourukashas_glow: { max_tier: 5, other_id: 'i_n400250', dim_hash: '235897163' },
    nymphs_dream: { max_tier: 5, other_id: 'i_n400240', dim_hash: '1925210475' },
    adventurer: { max_tier: 3, other_id: 'i_n400045', dim_hash: '674805713' },
    lucky_dog: { max_tier: 3, other_id: 'i_n400050', dim_hash: '409348857' },
    traveling_doctor: { max_tier: 3, other_id: 'i_n400060', dim_hash: '19715033' },
    instructor: { max_tier: 4, other_id: 'i_n400032', dim_hash: '1175029185' },
    berserker: { max_tier: 4, other_id: 'i_n400022', dim_hash: '694267465' },
    the_exile: { max_tier: 4, other_id: 'i_n400042', dim_hash: '634336161' },
    brave_heart: { max_tier: 4, other_id: 'i_n400007', dim_hash: '50514673' },
    resolution_of_sojourner: { max_tier: 4, other_id: 'i_n400002', dim_hash: '35587211' },
    martial_artist: { max_tier: 4, other_id: 'i_n400027', dim_hash: '194176337' },
    gambler: { max_tier: 4, other_id: 'i_n400037', dim_hash: '334829249' },
    defenders_will: { max_tier: 4, other_id: 'i_n400012', dim_hash: '2078239241' },
    scholar: { max_tier: 4, other_id: 'i_n400057', dim_hash: '674552305' },
    tiny_miracle: { max_tier: 4, other_id: 'i_n400017', dim_hash: '1811625' },
    gladiators_finale: { max_tier: 5, other_id: 'i_n400088', dim_hash: '163914217' },
    wanderers_troupe: { max_tier: 5, other_id: 'i_n400098', dim_hash: '147298547' },
    noblesse_oblige: { max_tier: 5, other_id: 'i_n400118', dim_hash: '913281913' },
    maiden_beloved: { max_tier: 5, other_id: 'i_n400083', dim_hash: '83115355' },
    retracing_bolide: { max_tier: 5, other_id: 'i_n400158', dim_hash: '809663817' },
    crimson_witch_of_flames: { max_tier: 5, other_id: 'i_n400113', dim_hash: '278145233' },
    lavawalker: { max_tier: 5, other_id: 'i_n400078', dim_hash: '817784209' },
    heart_of_depth: { max_tier: 5, other_id: 'i_n400163', dim_hash: '136617793' },
    thundering_fury: { max_tier: 5, other_id: 'i_n400108', dim_hash: '77282553' },
    thundersoother: { max_tier: 5, other_id: 'i_n400073', dim_hash: '809005113' },
    viridescent_venerer: { max_tier: 5, other_id: 'i_n400093', dim_hash: '466554623' },
    blizzard_strayer: { max_tier: 5, other_id: 'i_n400068', dim_hash: '325571548' },
    archaic_petra: { max_tier: 5, other_id: 'i_n400153', dim_hash: '1181556057' },
    bloodstained_chivalry: { max_tier: 5, other_id: 'i_n400123', dim_hash: '1010593545' },
    prayers_for_destiny: { max_tier: 4, other_id: 'i_n400132', dim_hash: '98606657' },
    prayers_for_illumination: { max_tier: 4, other_id: 'i_n400127', dim_hash: '2060049099' },
    prayers_for_wisdom: { max_tier: 4, other_id: 'i_n400137', dim_hash: '212557731' },
    prayers_to_springtime: { max_tier: 4, other_id: 'i_n400147', dim_hash: '262428003' },
    tenacity_of_the_millelith: { max_tier: 5, other_id: 'i_n400168', dim_hash: '277526977' },
    pale_flame: { max_tier: 5, other_id: 'i_n400173', dim_hash: '588361993' },
    reminiscence_of_shime: { max_tier: 5, other_id: 'i_n400178', dim_hash: '129430300' },
    seal_of_insulation: { max_tier: 5, other_id: 'i_n400183', dim_hash: '850845273' },
    husk_of_opulent_dreams: { max_tier: 5, other_id: 'i_n400188', dim_hash: '104325481' },
    "ocean-hued_clam": { max_tier: 5, other_id: 'i_n400193', dim_hash: '1353628719' },
    echoes_of_an_offering: { max_tier: 5, other_id: 'i_n400203', dim_hash: '1137032809' },
    vermillion_hereafter: { max_tier: 5, other_id: 'i_n400198', dim_hash: '1301749516' },
    deepwood_memories: { max_tier: 5, other_id: 'i_n400208', dim_hash: '1232816025' },
    gilded_dreams: { max_tier: 5, other_id: 'i_n400213', dim_hash: '55003377' },
    desert_pavilion_chronicle: { max_tier: 5, other_id: 'i_n400220', dim_hash: '1473084923' },
    flower_of_paradise_lost: { max_tier: 5, other_id: 'i_n400230', dim_hash: '440290627' },
    marechaussee_hunter: { max_tier: 5, other_id: 'i_n400261', dim_hash: '730759547' },
    golden_troupe: { max_tier: 5, other_id: 'i_n400271', dim_hash: '2210493321' },
    nighttime_whispers: { max_tier: 5, other_id: 'i_n400291', dim_hash: '279470883' },
    song_of_days_past: { max_tier: 5, other_id: 'i_n400281', dim_hash: '2803305851' },
    fragment_of_harmonic_whimsy: { max_tier: 5, other_id: 'i_n400301', dim_hash: '1492570003', release: '2024-04-24' },
    unfinished_reverie: { max_tier: 5, other_id: 'i_n400311', dim_hash: '352459163', release: '2024-04-24' },
};
