export default [
    [ '2024-08-25', 'pt', 'items', 'Adicionados novos [bold:Itens e Artefatos] da Versão 5.0' ],
    [ '2024-08-25', 'en', 'items', 'Added new Version 5.0 [bold:Items and Artifacts]' ],
    [ '2024-08-25', 'pt', 'weapons', 'Adicionadas novas [bold:Armas] da Versão 5.0' ],
    [ '2024-08-25', 'en', 'weapons', 'Added new Version 5.0 [bold:Weapons]' ],
    [ '2024-08-25', 'pt', 'characters', 'Adicionada a nova Personagem [bold:Mualani]' ],
    [ '2024-08-25', 'en', 'characters', 'Added the new Character [bold:Mualani]' ],
    [ '2024-08-25', 'pt', 'characters', 'Adicionada a nova Personagem [bold:Kachina]' ],
    [ '2024-08-25', 'en', 'characters', 'Added the new Character [bold:Kachina]' ],
    [ '2024-08-25', 'pt', 'events', 'Adicionados novos [bold:Eventos] da Versão 5.0' ],
    [ '2024-08-25', 'en', 'events', 'Added new Version 5.0 [bold:Events]' ],
    [ '2024-08-04', 'pt', 'tweaks', 'Seletor do Seelie pequeno agora navega para a mesma página do Seelie selecionado' ],
    [ '2024-08-04', 'en', 'tweaks', 'Small Seelie Selector now navigates to the same page of selected Seelie' ],
    [ '2024-08-04', 'pt', 'tweaks', 'Seletor do Seelie da página inicial agora mantém a ordem' ],
    [ '2024-08-04', 'en', 'tweaks', 'Main page Seelie Selector now keeps the same order' ],
    [ '2024-07-21', 'pt', 'features', 'SEELIE.me para [bold:Zenless Zone Zero] agora está disponível! Acesse <a class="text-purple-300" href="https://zzz.seelie.me">https://zzz.seelie.me</a>' ],
    [ '2024-07-21', 'en', 'features', 'SEELIE.me for [bold:Zenless Zone Zero] is now available! Visit <a class="text-purple-300" href="https://zzz.seelie.me">https://zzz.seelie.me</a>' ],
    [ '2024-07-21', 'pt', 'tweaks', 'Cada SEELIE.me agora possui cores dos ícones levemente diferentes, para melhor diferenciar os apps progressivos' ],
    [ '2024-07-21', 'en', 'tweaks', 'Each SEELIE.me now has slightly different icon colors to better differentiate progressive apps' ],
    [ '2024-07-16', 'pt', 'tweaks', 'Botões das tarefas foram ocultos, você pode editá-las clicando com o botão direito ou em "Mais"' ],
    [ '2024-07-16', 'en', 'tweaks', 'Task buttons have been hidden, you can edit them by right-clicking, or clicking "More"' ],
    [ '2024-07-16', 'pt', 'tweaks', 'Removidas as tarefas predefinidas' ],
    [ '2024-07-16', 'en', 'tweaks', 'Removed predefined tasks' ],
    [ '2024-07-16', 'pt', 'tweaks', 'Correções e melhorias internas' ],
    [ '2024-07-16', 'en', 'tweaks', 'Bug fixes and internal improvements' ],
    [ '2024-07-16', 'pt', 'events', 'Adicionados novos [bold:Eventos] da Versão 4.8' ],
    [ '2024-07-16', 'en', 'events', 'Added new Version 4.8 [bold:Events]' ],
    [ '2024-07-16', 'pt', 'bugs', 'Corrigido erro na contagem de itens forjáveis' ],
    [ '2024-07-16', 'en', 'bugs', 'Fixed bug in the count of craftable items' ],
    [ '2024-07-16', 'pt', 'features', 'Agora você pode reordenar as tarefas no Planejador' ],
    [ '2024-07-16', 'en', 'features', 'You can now reorder tasks in the Planner' ],
    [ '2024-06-13', 'pt', 'bugs', 'Corrigido bug dos interruptores (switches)' ],
    [ '2024-06-13', 'en', 'bugs', 'Fixed switches (toggle) bug' ],
    [ '2024-06-06', 'pt', 'tweaks', 'Corrigida a ordem dos itens do Inventário' ],
    [ '2024-06-06', 'en', 'tweaks', 'Fixed Inventory items order' ],
    [ '2024-06-05', 'pt', 'achievements', 'Adicionadas novas [bold:Conquistas] da Versão 4.7' ],
    [ '2024-06-05', 'en', 'achievements', 'Added new Version 4.7 [bold:Achievements]' ],
    [ '2024-06-05', 'pt', 'features', 'Agora você pode ocultar categorias concluídas das Conquistas' ],
    [ '2024-06-05', 'en', 'features', 'You can now hide the completed Achievements categories' ],
    [ '2024-06-05', 'pt', 'tweaks', 'Aumentado o limite de resinas para 200' ],
    [ '2024-06-05', 'en', 'tweaks', 'Increased resin cap to 200' ],
    [ '2024-06-05', 'pt', 'tweaks', 'Os filtros das Conquistas agora são persistentes' ],
    [ '2024-06-05', 'en', 'tweaks', 'Achievements filters are now persistent' ],
    [ '2024-06-05', 'pt', 'tweaks', 'O total de Conquistas é exibido dentro das categorias' ],
    [ '2024-06-05', 'en', 'tweaks', 'Total Achievements are displayed within the categories' ],
]
