export default {
	golden_house: ['tusk_of_monoceros_caeli', 'shard_of_a_foul_legacy', 'shadow_of_the_warrior'],
    tusk_of_monoceros_caeli: 'golden_house',
	shard_of_a_foul_legacy: 'golden_house',
	shadow_of_the_warrior: 'golden_house',
	stormterror: ['dvalins_claw', 'dvalins_plume', 'dvalins_sigh'],
    dvalins_claw: 'stormterror',
	dvalins_plume: 'stormterror',
	dvalins_sigh: 'stormterror',
	wolf_of_the_north: ['ring_of_boreas', 'spirit_locket_of_boreas', 'tail_of_boreas'],
    ring_of_boreas: 'wolf_of_the_north',
	spirit_locket_of_boreas: 'wolf_of_the_north',
	tail_of_boreas: 'wolf_of_the_north',
	azhdaha: ['dragon_lords_crown', 'bloodjade_branch', 'gilded_scale'],
    dragon_lords_crown: 'azhdaha',
	bloodjade_branch: 'azhdaha',
	gilded_scale: 'azhdaha',
	la_signora: ['signora_flower', 'signora_wings', 'signora_heart'],
    signora_flower: 'la_signora',
	signora_wings: 'la_signora',
	signora_heart: 'la_signora',
	narukami_boss: ['mudra_of_the_malefic_general', 'tears_of_the_calamitous_god', 'the_meaning_of_aeons'],
    mudra_of_the_malefic_general: 'narukami_boss',
	tears_of_the_calamitous_god: 'narukami_boss',
	the_meaning_of_aeons: 'narukami_boss',
	shouki_no_kami: ['puppet_strings', 'mirror_of_mushin', 'dakas_bell'],
    puppet_strings: 'shouki_no_kami',
	mirror_of_mushin: 'shouki_no_kami',
	dakas_bell: 'shouki_no_kami',
    guardian_of_apeps_oasis: ['worldspan_fern', 'primordial_greenbloom', 'everamber'],
    worldspan_fern: 'guardian_of_apeps_oasis',
    primordial_greenbloom: 'guardian_of_apeps_oasis',
    everamber: 'guardian_of_apeps_oasis',
    all_devouring_narwhal: ['lightless_silk_string', 'lightless_eye_of_the_maelstrom', 'lightless_mass'],
    lightless_silk_string: 'all_devouring_narwhal',
    lightless_eye_of_the_maelstrom: 'all_devouring_narwhal',
    lightless_mass: 'all_devouring_narwhal',
    the_knave: ['fading_candle', 'silken_feather', 'denial_and_judgment'],
    fading_candle: 'the_knave',
    silken_feather: 'the_knave',
    denial_and_judgment: 'the_knave',
}
