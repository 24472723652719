<template>
    <div class="">
        <button
            class="relative rounded-md overflow-hidden shadow bg-gray-700 border border-gray-600"
            :class="{ 'cursor-default': hideFuture }"
            v-tippy.dropdown="{ html: !hideFuture && uniqueId, onShow: opened, onHidden: hide, keepOpen }"
        >
            <div
                class="w-14 h-14 flex items-center justify-center bg-gradient-to-tl relative"
                :class="tierClass[item.type]?.[tier] || itemTierClass[item.item]"
                v-tippy.arrow="$t(((!isFuture(release?.show) && item.item) || `unknown_${item.type}`) + ((maxTier[item.type] > 0 && `.${tier}`) || ''))"
            >
                <img
                    :src="`${spaces}/items/${item.type}/${item.item}${(maxTier[item.type] > 0 ? `_${tier}` : '')}.png`"
                    class="max-h-[2.7rem] max-w-[2.7rem]"
                    v-if="!hideFuture"
                />
                <img
                    :src="`${url}img/other/question-mark.svg`"
                    class="max-h-[2.7rem] max-w-[2.7rem]"
                    v-else
                />

                <div class="absolute top-0 right-0 m-[0.1rem] bg-gray-900/50 rounded-lg font-mono px-1 py-px text-xs font-semibold" v-if="need">
                    {{ abbr(need, item.item !== 'mora') }}
                </div>
            </div>

            <div
                class="text-xs font-semibold py-1 flex items-center justify-center"
                :class="!missingTotal ? 'bg-green-700' : (haveTotal >= need ? 'bg-yellow-700' : 'bg-red-700')"
            >
                <component :is="missingTotal ? 'flag-icon' : 'check-circle-icon'" class="w-4 mr-1"/>
                {{ abbr(needTotal, item.item !== 'mora') }}
            </div>

            <!-- <div class="absolute bg-gray-700 inset-0 flex items-center justify-center opacity-75" v-if="!missing">
                <check-icon class="w-6 text-white"/>
            </div> -->
        </button>

        <div :id="uniqueId" class="tippy-seelie min-w-max flex flex-col items-center" v-if="!hideFuture">
            <div class="flex flex-col gap-y-1 mb-2 w-48 items-center">
                <div class="bg-gray-700/75 py-1 px-2 rounded-lg text-xs font-semibold">{{ abbr(need, item.item !== 'mora') }} {{ $t('needed_for_this_goal') }}</div>
                <div class="bg-gray-700/75 py-1 px-2 rounded-lg text-xs font-semibold">{{ abbr(needTotal, item.item !== 'mora') }} {{ $t('needed_for_all_goals') }}</div>

                <div class="bg-green-700/75 py-1 px-2 h-8 flex items-center text-center rounded-lg text-xs font-semibold leading-none" v-if="!missingTotal">{{ $t('have_amount_needed_all') }}</div>
                <div class="bg-yellow-700/75 py-1 px-2 h-8 flex items-center text-center rounded-lg text-xs font-semibold leading-none" v-else-if="haveTotal >= need">{{ $t('have_amount_needed_this') }}</div>
                <div class="bg-red-700/75 py-1 px-2 h-8 flex items-center text-center rounded-lg text-xs font-semibold leading-none" v-else>{{ $t('dont_have_enough') }}</div>
            </div>


            <div class="flex items-center justify-center" :class="mode === 'horizontal' ? 'flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-1' : 'space-x-1'">
                <component
                    :is="`inventory-item-${mode}`"
                    v-for="i in (maxTier[item.type] || 0) + 1"
                    :key="`${item.item}-${i}`"
                    :tier="-1 * i + (maxTier[item.type] || 0) + 1"
                    :type="item.type"
                    :item="item.item"
                    :size="item.item === 'mora' ? 'w-64' : 'sm:w-48'"
                    :is-opened="i == 1 ? isOpened : false"
                    :release="release?.release"
                    buttons
                    craft-button
                />
                <template v-if="item.type === 'boss'">
                    <inventory-item-vertical :tier="0" type="boss" :item="bosses[bosses[item.item]].filter(i => i != item.item)[0]" size="sm:w-48" buttons/>
                    <inventory-item-vertical :tier="0" type="boss" :item="bosses[bosses[item.item]].filter(i => i != item.item)[1]" size="sm:w-48" buttons/>
                    <inventory-item-vertical :tier="0" type="special" item="dream_solvent" size="sm:w-48" buttons/>
                </template>
                <template v-if="item.item === 'xp'">
                    <inventory-item-horizontal :tier="0" type="xp" item="xp_sub_1" size="w-full sm:w-28" hide-info/>
                    <inventory-item-horizontal :tier="0" type="xp" item="xp_sub_0" size="w-full sm:w-28" hide-info/>
                </template>
                <template v-if="item.item === 'wep_xp'">
                    <inventory-item-horizontal :tier="0" type="wep_xp" item="wep_xp_sub_1" size="w-full sm:w-28" hide-info/>
                    <inventory-item-horizontal :tier="0" type="wep_xp" item="wep_xp_sub_0" size="w-full sm:w-28" hide-info/>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import InventoryItemVertical from './InventoryItemVertical'
import InventoryItemHorizontal from './InventoryItemHorizontal'

export default {
    components: { InventoryItemVertical, InventoryItemHorizontal },

    name: 'item',

    props: {
        item: Object,
        release: Object,
        tier: Number
    },

    data () {
        return {
            uniqueId: Math.random().toString(36).substr(2, 9),
            isOpened: false
        }
    },

    computed: {
        keepOpen () {
            return this.settings.planner_keep_item_open
        },

        hideFuture () {
            return !!(this.isFuture(this.release?.show) || !this.item.item)
        },

        mode () {
            return ['mora', 'xp', 'wep_xp'].includes(this.item.item) ? 'horizontal' : 'vertical'
        },

        have () {
            return this.hideFuture ? 0 : this.getInventory(this.item.type, this.item.item, this.tier)
        },

        craft () {
            return this.hideFuture ? 0 : this.getCraft(this.item.type, this.item.item, this.tier)
        },

        haveTotal () {
            return this.have + this.craft
        },

        missingTotal () {
            return this.hideFuture ? this.need : this.getMissing(this.item.type, this.item.item, this.tier)
        },

        needTotal () {
            return this.hideFuture ? this.need : this.getNeed(this.item.type, this.item.item, this.tier)
        },

        need () {
            return this.maxTier[this.item.type] > 0 ? this.item.need[this.tier] :
                this.item.need / (this.item.item === 'xp' ? 20000 : (this.item.item === 'wep_xp' ? 10000 : 1))
        }
    },

    methods: {
        opened () {
            this.isOpened = true
        },

        hide () {
            this.isOpened = false
        }
    }
}
</script>
