<template>
    <div
        class="bg-gray-600 rounded overflow-hidden flex flex-col items-center justify-center w-16"
        :class="{ 'opacity-50': !need }"
        v-if="!options || options?.showAll || need"
    >
        <div class="relative w-full h-16 flex items-center justify-center bg-gradient-to-tl" :class="itemTierClass[item] || tierClass[type]?.[tier]">
            <img
                class="max-h-[3rem] max-w-[3rem]"
                v-tippy.arrow="maxTier[type] > 0 ? $t(`${item}.${tier}`) : $t(item)"
                :src="`${spaces}/items/${type}/${item}${(maxTier[type] > 0 ? `_${tier}` : '')}.png`"
            />
            <!-- unreleased -->
            <div
                v-if="isFuture(release)"
                class="absolute top-0 left-0 m-1 text-xs bg-red-500 rounded-full w-5 h-5 flex items-center justify-center"
                v-tippy.arrow="$t('unreleased_item')"
            >!</div>
            <!-- missing -->
            <div class="absolute top-0 right-0 m-1 font-semibold flex items-end cursor-default">
                <div v-if="missing && !options?.hideMissing" class="py-px px-1 rounded-full leading-none text-xs flex items-center bg-red-400" v-tippy.arrow="$t('missing')">
                    {{ abbr(missing, item === 'xp' || item === 'wep_xp') }}
                </div>
                <!-- craft -->
                <div v-if="craft && !options?.hideCraft" class="py-px px-1 ml-px rounded-full leading-none text-xs flex items-center bg-yellow-600" v-tippy.arrow="$t('craft')">
                    {{ craft }}
                </div>
                <!-- bossExtra -->
                <div v-if="bossExtra" class="py-px px-px ml-px rounded-full leading-none text-xs flex items-center bg-blue-600" v-tippy.arrow="$t('craft')">
                    <refresh-icon class="w-3 h-3"/>
                </div>
            </div>
        </div>

        <div class="w-full text-xs font-semibold">
            <template v-if="!options?.hideNeed">
                <div v-if="need > total" class="py-1 leading-none w-full flex items-center justify-center font-semibold bg-red-400">
                    <flag-icon class="w-4 h-4"/> <span class="mx-px">{{ abbr(need, item === 'xp' || item === 'wep_xp') }}</span>
                </div>
                <div v-else class="py-1 leading-none w-full flex items-center justify-center" :class="!need ? 'opacity-25 bg-gray-500' : 'font-semibold bg-green-500'">
                    <check-circle-icon class="w-4 h-4"/> <span class="mx-px">{{ abbr(need, item === 'xp' || item === 'wep_xp') }}</span>
                </div>
            </template>
        </div>

        <div class="relative flex items-center">
            <input
                type="number" min="0"
                class="text-sm text-center w-full"
                :class="isFuture(release) ? 'bg-red-800/40 border-red-800/50' : 'bg-gray-500'"
                :disabled="isFuture(release)"
                :value="have"
                ref="invInput"
                @input="(v) => setInventory(type, item, tier, v.target.value)"
                @keydown="e => keyPressed(e, true)"
                :tabindex="invTabIndex"
            >
                <button
                    class="absolute right-0 mr-px p-1 rounded-full leading-none text-xs flex items-center bg-yellow-600"
                    v-tippy="`<div class=\'text-center text-xs\'>${$t('crafted_explanation')}</div>`"
                    @click="crafted"
                    v-if="craftButton && craft"
                    tabindex="-1"
                >
                    <beaker-icon class="w-3"/>
                </button>
        </div>
        <div class="flex items-center justify-center font-bold w-full" v-if="!isFuture(release) && buttons == true">
            <button class="bg-gray-600 w-full py-1 font-bold rounded-bl" tabindex="-1" @click="setInventory(type, item, tier, have-1)">-</button>
            <button class="bg-gray-600 w-full py-1 font-bold rounded-br" tabindex="-1" @click="setInventory(type, item, tier, have+1)">+</button>
        </div>

        <div v-if="showAddInput">
            <input
                type="number"
                class="text-sm text-center w-full"
                :class="isFuture(release) ? 'bg-red-800/40 border-red-800/50' : 'bg-gray-500'"
                @keydown="keyPressed"
                ref="addInput"
                @blur="blurAddInput"
                :tabindex="addTabIndex"
            >
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: String,
        item: String,
        tier: Number,
        options: Object,
        release: String,
        isOpened: Boolean,
        size: { type: String, default: 'sm:w-48' },
        hideInfo: { type: Boolean, default: false },
        buttons: { type: [Boolean, String], default: false },
        craftButton: { type: [Boolean, String], default: false },
        addInput: { type: [Boolean, String], default: false },
        index: { type: Number, default: 1 },
        length: { type: Number, default: 1 },
    },

    watch: {
        isOpened (value) {
            if (value) {
                if (this.tabOrder == 'sequential_add' && this.$refs.addInput) return this.$refs.addInput.focus()

                return this.$refs.invInput.focus()
            }
        }
    },

    computed: {
        have () {
            return this.getInventory(this.type, this.item, this.tier)
        },

        need () {
            return this.getNeed(this.type, this.item, this.tier)
        },

        craft () {
            return this.getCraft(this.type, this.item, this.tier)
        },

        bossExtra () {
            return (this.type == 'boss' && this.getBossExtra(this.item)) || 0
        },

        total () {
            return this.getTotal(this.type, this.item, this.tier)
        },

        missing () {
            return this.getMissing(this.type, this.item, this.tier)
        },

        showAddInput () {
            return this.addInput && this.settings.planner_show_add_input
        },

        tabOrder () {
            return this.settings.planner_tab_order
        },

        invTabIndex () {
            if (!this.showAddInput) return this.index

            if (this.length == 1 && this.tabOrder == 'sequential_add') return 2
            if (this.length == 1 && this.tabOrder != 'sequential_add') return 1

            if (this.tabOrder == 'alternating') return this.index * 2
            if (this.tabOrder == 'sequential_inv') return this.index
            if (this.tabOrder == 'sequential_add') return this.index + this.length

            return 0
        },

        addTabIndex () {
            if (this.length == 1 && this.tabOrder == 'sequential_add') return 1
            if (this.length == 1 && this.tabOrder != 'sequential_add') return 2

            if (this.tabOrder == 'alternating') return this.index * 2 + 1
            if (this.tabOrder == 'sequential_inv') return this.index + this.length
            if (this.tabOrder == 'sequential_add') return this.index

            return 0
        }
    },

    methods: {
        crafted () {
            let craft = this.craft
            this.setInventory(this.type, this.item, this.tier, this.have + craft)
            this.adjust(this.tier - 1, craft * 3)
        },

        adjust (tier, quantity) {
            let have = this.getInventory(this.type, this.item, tier)
            let need = this.getNeed(this.type, this.item, tier)

            // have enough leftovers to use in crafting
            if (have - need - quantity >= 0) {
                return this.setInventory(this.type, this.item, tier, have - quantity)
            }

            // have leftovers to use in crafting, but not enough
            if (have - need > 0) {
                this.setInventory(this.type, this.item, tier, need)
                return this.adjust(tier - 1, (quantity - (have - need)) * 3)
            }

            // have no leftovers of this tier, use previous
            return this.adjust(tier - 1, quantity * 3)
        },

        blurAddInput (event) {
            if (Number(event.target.value)) {
                this.setInventory(this.type, this.item, this.tier, this.have + Number(event.target.value))
            }
            event.target.value = ''
        },

        nextInput (current) {
            return current.parentElement.querySelector(`input[tabindex="${document.activeElement.tabIndex+1}"]`)
                || current.nextElementSibling?.querySelector('input')
        },

        previousInput (current) {
            return current.parentElement.querySelector(`input[tabindex="${document.activeElement.tabIndex-1}"]`)
                || current.previousElementSibling?.querySelector('input')
        },

        keyPressed (event, set) {
            if (event.altKey || event.ctrlKey || event.shiftKey) return

            if (['w', 'W', 'a', 'A', 's', 'S', 'd', 'D'].includes(event.key)) event.preventDefault()

            if (['ArrowRight', 'd', 'D'].includes(event.key)) {
                let next = this.nextInput(this.$el)
                if (!next) return event.target.blur()

                next.focus()
                next.select()
            }
            else if (['ArrowLeft', 'a', 'A'].includes(event.key)) {
                let previous = this.previousInput(this.$el)
                if (!previous) return event.target.blur()

                previous.focus()
                previous.select()
            }
            else if (['w', 'W'].includes(event.key)) {
                if (set) this.setInventory(this.type, this.item, this.tier, this.have+1)
                else event.target.value = Number(event.target.value) + 1
            }
            else if (['s', 'S'].includes(event.key)) {
                if (set) this.setInventory(this.type, this.item, this.tier, this.have-1)
                else event.target.value = Number(event.target.value) - 1
            }
        }
    }
}
</script>
