<template>
    <div class="bg-gray-700/70 shadow rounded flex flex-col items-center justify-between relative">
        <div class="w-full">
            <div class="px-3 mt-3 text-lg font-semibold leading-tight text-center truncate w-full" v-tippy="$t(location)">
                {{ $t(location) }}
            </div>

            <div v-if="!runs && unobtainable"
                class="bg-red-500 mt-3 w-full text-sm font-semibold py-2 text-center" v-tippy="$t('unobtainable_desc')"
            >
                {{ $t('unobtainable') }}
            </div>

            <div v-else-if="Number(cost) && !artifact"
                class="bg-blue-600/70 mt-3 w-full text-sm font-semibold py-2 flex items-center justify-center"
                v-tippy="$t('estimated_desc')"
            >
                <template v-if="runs">
                    <div class="border-r pr-3 border-blue-500/80">x{{ runs }}</div>
                    <div class="flex items-center mx-3">
                        <template v-if="cost != 60">{{ runs * cost }}</template>
                        <template v-else>{{ runs * 30 }}/{{ runs * 60 }}</template>
                        <img :src="`${spaces}/items/special/resin2.png`" class="w-4 ml-1"/>
                        <div v-if="cost == 20" class="flex items-center justify-center ml-1">
                            <span class="mr-1 text-white/50 text-xs">•</span> {{ Math.ceil(runs*cost/40) }} <img :src="`${spaces}/items/special/condensed_resin.png`" class="w-5 ml-1"/>
                        </div>
                    </div>
                    <div class="border-l pl-3 border-blue-500/80 lowercase">
                        <template v-if="cost != 60">{{ Math.ceil(runs * cost / 180) }} {{ $tc('day', Math.ceil(runs * cost / 180)) }}</template>
                        <template v-else>{{ runs }} {{ $tc('week', runs) }}</template>
                    </div>

                    <div v-if="unobtainable" class="ml-3 pl-3 border-l border-blue-500/80" v-tippy="$t('unobtainable_desc')">
                        <div class="bg-red-500 p-1 rounded-full flex items-center justify-center w-4 h-4">!</div>
                    </div>
                </template>
                <template v-else>
                    {{ $t('done') }}
                </template>
            </div>
        </div>

        <div v-if="artifact" class="bg-blue-600 mt-3 pl-2 pr-3 rounded-full font-semibold py-1 flex items-center justify-center">
            <img :src="`${spaces}/items/special/resin2.png`" class="w-5 mr-1"/> {{ cost || '40-60' }}
        </div>

        <div class="mr-1 ml-2 mt-3 flex flex-wrap items-center justify-center">
            <template v-for="(item, i) in activityItems" :key="`item-${i}`">
                <template v-for="i in (maxTier[item.type] || 0) + 1" :key="`${item.item}-${i}`">
                    <div class="mr-1 mb-1" v-if="item.artifact" v-tippy.arrow="$t(item.item)">
                        <div class="w-14 h-14 rounded-xl overflow-hidden relative bg-gradient-to-tl flex items-center justify-center"
                            :class="darkTierClass[artifacts[item.item].max_tier]"
                        >
                            <img :src="`${spaces}/artifacts/${item.item}.png`" class="max-h-[3rem] max-w-[3rem]">
                        </div>
                    </div>

                    <item :item="item" class="mr-1 mb-1"
                        :tier="-1 * i + (maxTier[item.type] || 0) + 1"
                        v-else-if="typeof item.missing === 'number' ? item.missing : item.missing[-1 * i + (maxTier[item.type] || 0) + 1]"
                        :add-input="!!cost"
                        total
                    />
                </template>
            </template>
        </div>

        <div class="mr-1 ml-2 mb-1 mt-3 flex flex-wrap leading-none items-center justify-center">
            <div v-for="character in forCharacters" :key="character" class="mb-1 mr-1">
                <button
                    class="bg-gray-500 leading-none rounded-lg overflow-hidden w-8 h-8"
                    @click="characterModal = character" @contextmenu.prevent="setInactive(character, true)"
                >
                    <img :src="`${spaces}/characters/select/${characters[character].traveler ? `traveler_${gender}` : character}.png`" class="w-full h-full" v-if="characters[character]"/>
                    <img :src="`${url}img/other/custom_${customs[character].gender}.png`" class="w-full h-full" v-else/>
                </button>
            </div>
            <div v-for="weapon in forWeapons" :key="`${weapon.key}-${weapon.id}`" class="mb-1 mr-1">
                <button
                    class="bg-gray-500 leading-none rounded-lg overflow-hidden w-8 h-8 flex items-center justify-center"
                    @click="weaponModal = weapon.id" @contextmenu.prevent="setInactive(weapon.id, true)"
                >
                    <img :src="`${spaces}/weapons/${weapon.key}.png`" class="h-full object-contain" v-if="weapons[weapon.key]"/>
                    <img :src="`${url}img/weapons/__${customs[weapon.key]?.type}-billet.png`" class="max-w-[1.7rem] max-h-[1.7rem]" v-else-if="customs[weapon.key]?.type"/>
                    <img :src="`${url}img/other/question-mark.svg`" class="w-6" v-else/>
                </button>
            </div>
            <div v-if="forCustom" class="mb-1 mr-1">
                <div class="bg-gray-500 leading-none rounded-lg overflow-hidden w-8 h-8 p-1">
                    <img :src="`${url}img/general/currency.png`" class="w-full h-full"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Item from './Item'
// import OverlayPanel from 'primevue/overlaypanel'
// import DomainInfo from './DomainInfo'

export default {
    components: {
        Item,
        // OverlayPanel,
        // DomainInfo
    },

    props: {
        cost: [Number, String],
        location: String,
        activityItems: [Array , Object],
        artifact: { type: Boolean, default: false }
    },

    data () {
        return {
            domains: [
                'midsummer_courtyard',
                'valley_of_remembrance',
                'domain_of_guyun',
                'hidden_palace_of_zhou_formula',
                'clear_pool_and_mountain_cavern',
                'peak_of_vindagnyr',
                'cecilia_garden',
                'hidden_palace_of_lianshan_formula',
                'forsaken_rift',
                'taishan_mansion',
                'ridge_watch',
            ]
        }
    },

    computed: {
        estimated () {
            if (this.artifact || !this.cost) return null
            return Object.values(this.activityItems).map(item => this.getEstimatedRuns(item))
        },

        runs () {
            if (!this.estimated) return null
            // console.log(this.estimated)
            // console.log(this.activityItems)
            if (this.cost === 20 || this.cost === 60) return this.estimated.reduce((a, b) => a + b.runs, 0)
            return Math.max(...this.estimated.flatMap(e => e?.runs)) || 0
        },

        unobtainable () {
            return this.estimated?.flatMap(e => e?.unobtainable).includes(true)
        },

        forCharacters () {
            return Object.values(this.activityItems).flatMap(i => i.characters)
                .filter((v, i, c) => v && c.indexOf(v) === i)
        },

        forWeapons () {
            return Object.values(this.activityItems).flatMap(i => i.weapons).map(w => ({
                ...w,
                goal: this.goals.find(g => g.id === w.id)
            })).filter(w => w.goal)
        },

        forCustom () {
            return Object.values(this.activityItems).flatMap(i => i.custom).filter(c => c).length
        }
    },
}
</script>
