<template>
    <div class="text-white font-semibold uppercase tracking-wide text-lg opacity-75 mt-4" v-if="isLoading">
        {{ $t('loading') }} {{ $t('achievements') }}...
    </div>

    <div v-else-if="!category && !areaId" class="text-white text-3xl opacity-60">
        Category or area not found
    </div>

    <div class="text-white w-full" v-else>
        <div class="sticky top-0 z-20 bg-gray-900 pt-[6.25rem] -mt-[6.5rem] lg:pt-14 lg:-mt-14 pb-2">
            <div class="flex mt-3">
                <router-link to="/achievements" class="bg-gray-700 px-2 rounded-md flex items-center justify-center mr-2">
                    <chevron-left-icon class="w-8 h-8 text-gray-400"/>
                </router-link>
                <div class="relative overflow-hidden w-full flex items-center justify-between bg-gray-700 py-2 px-2 rounded-md bg-gradient-to-br from-gray-700 to-gray-800">
                    <div class="absolute left-0 bg-gradient-to-br from-purple-600/60 to-purple-800/60 h-full transition-all" :style="`width:${percent}%`"></div>

                    <div class="flex">
                        <img :src="`${url}img/achievements/${categoryId}s.png`" class="h-10 w-8 object-contain flex-shrink-0 z-10" v-if="categoryId"/>
                        <img :src="`${url}img/areas/${areaId}.png`" class="h-10 w-8 object-contain flex-shrink-0 z-10" v-if="areaId"/>

                        <div class="ml-2 z-10 flex justify-center flex-col">
                            <p class="leading-none tracking-tight font-semibold text-lg">{{ category.n || $t(areaId) }}</p>
                            <p class="text-sm font-semibold opacity-75">{{ percent || 0 }}% ({{ done }}/{{ total }})</p>
                        </div>
                    </div>

                    <div class="font-semibold tracking-wide z-10">
                        {{ totalDone }} <span class="hidden sm:inline">/ {{ totalTotal }} <span class="text-gray-400">• {{ totalPercent }}%</span></span>
                    </div>
                </div>
                <button
                    class="ml-2 p-2 rounded-md flex w-14 items-center justify-center transition-all disabled:opacity-60 disabled:pointer-events-none"
                    @click="toggleShowDone"
                    :class="showDone || search.length || version !== 'all' || done == total ? 'bg-purple-600' : 'bg-gray-700'"
                    :disabled="search.length || version !== 'all' || done == total"
                >
                    <eye-icon class="w-6 h-6 opacity-75"/>
                </button>
            </div>
        </div>

        <ad class="mt-2"/>

        <div class="grid gap-2 mt-4">
            <!-- <transition-group name="list"> -->
                <achievement-container
                    v-for="achievement in filteredAchievements"
                    :key="`achievement-container-${achievement?.id || achievement[0]?.id}`"
                    :achievement="achievement"
                    @edit="v => editing = v"
                />
            <!-- </transition-group> -->
        </div>

        <ad class="mt-5" bottom/>

        <Dialog :draggable="false"
            :show-header="false"
            position="top"
            v-model:visible="editingModalVisible"
            modal
            style="width: 600px;"
            dismissable-mask
        >
            <div class="flex items-start justify-between mt-6">
                <div class="font-semibold leading-tight">
                    {{ editing.n }}
                </div>
                <button class="ml-3" @click="editingModalVisible = false">
                    <x-icon class="w-6 h-6"/>
                </button>
            </div>

            <div class="opacity-75 text-sm mt-1">
                {{ editing.d }}
            </div>

            <div class="relative mt-4">
                <Textarea
                    class="w-full !text-sm"
                    :placeholder="$t('notes')"
                    :model-value="achievements[editing.id]?.notes"
                    @input="e => updateNotes(editing.id, e.target.value)"
                    auto-resize
                    rows="2"
                    autofocus
                />
                <div class="absolute top-0 right-0 m-1"><question-mark-circle-icon class="w-5 ml-2" v-tippy="`<div class=\'text-xs\'>${$t('notes_format')}</div>`"/></div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import Ad from '@/components/Ad.vue'
import Dialog from 'primevue/dialog'
import Textarea from 'primevue/textarea'
import AchievementContainer from '@/components/AchievementContainer.vue'

export default {
    components: { Ad, Dialog, AchievementContainer, Textarea },

    props: {
        search: String,
        version: String,
    },

    data () {
        return {
            isLoading: true,
            category: {},
            achievementsList: [],
            showDone: false,
            editing: {},
            notesTimeout: null,
            filteredAchievements: [],
            debounce: null,
            flatTotal: []
        }
    },

    computed: {
        editingModalVisible: {
            get () { return !!this.editing?.id },
            set () { this.editing = {} }
        },

        categoryId () {
            return this.$route.params.id
        },

        areaId () {
            return this.$route.params.area
        },

        flatAchievements () {
            return this.achievementsList.flatMap(a => a)
        },

        total () {
            return this.flatAchievements.length
        },

        done () {
            return this.flatAchievements.filter(a => this.achievements[a.id]?.done).length
        },

        percent () {
            return Math.round(this.done * 100 / this.total)
        },

        // flatTotal () {
        //     return Object.values(this.categories).flatMap(c => c[1].a).flatMap(a => a)
        // },

        totalTotal () {
            return this.flatTotal.length
        },

        totalDone () {
            return this.flatTotal.filter(a => this.achievements[a.id]?.done).length
        },

        totalPercent () {
            return Math.round(this.totalDone * 100 / this.totalTotal)
        },

        orderedAchievements () {
            if (this.done == this.total) return this.filteredAchievements

            let achievements = this.filteredAchievements.slice()

            return achievements.sort((a, b) => {
                return this.isDone((Array.isArray(a) ? a[a.length -1 ] : a).id)
                    === this.isDone((Array.isArray(b) ? b[b.length -1 ] : b).id)
                    ? 0 : this.isDone((Array.isArray(a) ? a[a.length -1 ] : a).id) ? 1 : -1
            })
        }
    },

    async mounted () {
        this.loadAchievements()
    },

    watch: {
        '$i18n.locale' () {
            this.loadAchievements()
        },
        'categoryId' () {
            this.filterAchievements()
        },
        'version' () {
            this.filterAchievements()
        },
        'search' () {
            this.debounceSearch()
        },
    },

    methods: {
        filterAchievements () {
            this.scrollTop()

            let filter = a =>
                (
                    !this.search ||
                    a.s.indexOf(this.search) > -1 ||
                    a.c?.toLowerCase().indexOf(this.search) > -1 ||
                    a.q?.map(q => q.join(' ')).join(' ').toLowerCase().indexOf(this.search) > -1 ||
                    this.achievements[a.id]?.notes?.toLowerCase().indexOf(this.search) > -1
                ) &&
                (this.version == 'all' || a.v == this.version)

            this.filteredAchievements = this.achievementsList
                .filter(a => {
                    // done
                    if (this.done != this.total && !this.search.length && this.version == 'all' && !this.showDone && this.achievements[(Array.isArray(a) ? a[a.length-1] : a).id]?.done)
                        return false

                    if (!this.search.length && this.version == 'all') return true

                    if (Array.isArray(a)) {
                        return !!a.filter(filter).length
                    } else {
                        return filter(a)
                    }
                })
                .sort((a, b) => {
                    return this.isDone((Array.isArray(a) ? a[a.length -1 ] : a).id)
                        === this.isDone((Array.isArray(b) ? b[b.length -1 ] : b).id)
                        ? 0 : this.isDone((Array.isArray(a) ? a[a.length -1 ] : a).id) ? 1 : -1
                })
        },

        async loadAchievements () {
            const expM = /\{M#(.+?)?\}\{F#(?:.+?)?\}|\{F#(?:.+?)?\}\{M#(.+?)?\}/g
            const expF = /\{M#(?:.+?)?\}\{F#(.+?)?\}|\{F#(.+?)?\}\{M#(?:.+?)?\}/g

            const replaceAchievement = a => {
                let name = a.n.replace(this.gender == 'male' ? expM : expF, (_, b, c) => b || c || '').replace('#','')
                let desc = a.d.replace(this.gender == 'male' ? expM : expF, (_, b, c) => b || c || '').replace('#','')
                let altName = a.n.replace(this.gender == 'male' ? expF : expM, (_, b, c) => b || c || '').replace('#','')
                let altDesc = a.d.replace(this.gender == 'male' ? expF : expM, (_, b, c) => b || c || '').replace('#','')

                return {
                    ...a,
                    n: name,
                    d: desc,
                    s: `${name} ${altName} ${desc} ${altDesc}`.toLowerCase()
                }
            }

            this.isLoading = true
            const data = await import(`../locales/achievements/${this.$i18n.locale}.json`)

            // this.categories = Object.entries(data.default)
            // this.flatTotal = Object.values(this.categories).flatMap(c => c[1].a).flatMap(a => a)
            this.flatTotal = Object.values(Object.entries(data.default)).flatMap(c => c[1].a).flatMap(a => a)

            if (this.categoryId) {
                this.category = data.default[this.categoryId]
                this.achievementsList = (data.default[this.categoryId]?.a || []).map(a => {
                    if (Array.isArray(a)) return a.map(replaceAchievement)
                    return replaceAchievement(a)
                })
            }

            if (this.areaId) {
                this.achievementsList = Object.values(data.default).flatMap(c => c.a).map(a => {
                    if (Array.isArray(a)) return a.map(replaceAchievement)
                    return replaceAchievement(a)
                }).filter(a => a.c == this.areaId)
            }

            this.filterAchievements()
            this.isLoading = false
        },

        debounceSearch (clear) {
            clearTimeout(this.debounce)

            if (clear === true || !this.search.length) {
                this.debounce = setTimeout(() => {
                    this.filterAchievements()
                }, 200)
            }

            else {
                this.debounce = setTimeout(() => {
                    this.filterAchievements()
                }, 800)
            }
        },

        toggleShowDone () {
            this.showDone = !this.showDone
            this.filterAchievements()
        },

        scrollTop () {
            document.getElementById('main').scrollTo(0,0)
        },

        isDone (id) {
            return !!this.achievements[id]?.done
        },

        updateNotes (id, value) {
            clearTimeout(this.notesTimeout)

            this.notesTimeout = setTimeout(() => {
                let achievements = this.achievements

                this.achievements = {
                    ...achievements,
                    [id]: { ...(achievements[id] || {}), notes: value }
                }
            }, 500)
        }
    }
}
</script>

<style>
    .list-enter-active,
    .list-leave-active {
        transition: all .2s ease;
        position: absolute;
    }
    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: translateY(15px);
    }
    .list-move {
       transition: transform .2s ease;
    }
</style>
