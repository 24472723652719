<template>
    <div class="flex flex-wrap">
        <div class="w-full sm:w-6/12 p-2">
            <label for="name" class="block px-1 mb-1 text-xs font-semibold">{{ $t('name') }}</label>
            <input
                type="text"
                id="name"
                class="w-full bg-gray-700 rounded-md p-2 border-none"
                :class="errorName && 'ring ring-red-500'"
                v-model="custom.name"
                @input="errorName = !custom.name"
                maxlength="30"
            >
        </div>
        <div class="w-full sm:w-6/12 p-2">
            <div class="block px-1 mb-1 text-xs font-semibold">{{ $t('type') }}</div>
            <div class="flex items-center justify-center rounded-md overflow-hidden text-sm font-semibold h-10">
                <button
                    class="h-full w-full flex items-center justify-center overflow-hidden"
                    :class="!custom.type ? 'bg-teal-600/40' : 'bg-gray-700'"
                    @click="custom.type = null"
                >
                    <img :src="`${url}img/other/question-mark.svg`" class="h-6">
                </button>
                <button
                    v-for="weaponType in weaponTypes"
                    :key="`custom-type-${weaponType}`"
                    class="h-full w-full flex items-center justify-center overflow-hidden"
                    v-tippy="$t(weaponType)"
                    :class="custom.type == weaponType ? 'bg-teal-600/40' : 'bg-gray-700'"
                    @click="custom.type = weaponType"
                >
                    <img :src="`${url}img/weapons/__${weaponType}-billet.png`" class="h-8">
                </button>
            </div>
        </div>
        <div class="w-full p-2">
            <div class="block px-1 mb-1 text-xs font-semibold">{{ $t('rarity') }}</div>
            <div class="flex items-center justify-center rounded-md overflow-hidden text-sm font-semibold h-10">
               <button
                    v-for="i in 3"
                    :key="`custom-tier-${i+2}`"
                    class="flex items-center justify-center overflow-hidden w-1/2 h-full"
                    :class="custom.tier == i+2 ? 'bg-teal-600/40' : 'bg-gray-700'"
                    @click="custom.tier = i+2"
                >
                    <star-icon class="w-5 -mr-1" :class="['text-rare-550', 'text-epic-550', 'text-legendary-550'][i-1]" v-for="s in i+2" :key="`star-${i}-${s}`"/>
                </button>
            </div>
        </div>

        <div class="flex justify-between w-full mt-3">
            <div class="p-1 flex flex-col items-center justify-center w-full">
                <div class="font-semibold text-xs uppercase mb-1 text-center">{{ $t('wam') }}</div>
                <button class="bg-gray-700 rounded-full p-2 flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 md:h-14 md:w-14" v-tippy.dropdown="'wam'">
                    <img :src="`${spaces}/items/wam/${custom.wam}_0.png`" class="max-h-[1.5rem] max-w-[1.5rem] sm:max-h-[2rem] sm:max-w-[2rem]" v-if="custom.wam"/>
                    <img :src="`${url}img/other/question-mark.svg`" v-else>
                </button>
                <custom-goal-items type="wam" v-model:selected="custom.wam"/>
            </div>

            <div class="p-1 flex flex-col items-center justify-center w-full">
                <div class="font-semibold text-xs uppercase mb-1 text-center">{{ $t('common_rare') }}</div>
                <button class="bg-gray-700 rounded-full p-2 flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 md:h-14 md:w-14" v-tippy.dropdown="'common_rare'">
                    <img :src="`${spaces}/items/common_rare/${custom.common_rare}_0.png`" class="max-h-[1.5rem] max-w-[1.5rem] sm:max-h-[2rem] sm:max-w-[2rem]" v-if="custom.common_rare"/>
                    <img :src="`${url}img/other/question-mark.svg`" v-else>
                </button>
                <custom-goal-items type="common_rare" v-model:selected="custom.common_rare"/>
            </div>

            <div class="p-1 flex flex-col items-center justify-center w-full">
                <div class="font-semibold text-xs uppercase mb-1 text-center">{{ $t('common') }}</div>
                <button class="bg-gray-700 rounded-full p-2 flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 md:h-14 md:w-14" v-tippy.dropdown="'common'">
                    <img :src="`${spaces}/items/common/${custom.common}_0.png`" class="max-h-[1.5rem] max-w-[1.5rem] sm:max-h-[2rem] sm:max-w-[2rem]" v-if="custom.common"/>
                    <img :src="`${url}img/other/question-mark.svg`" v-else>
                </button>
                <custom-goal-items type="common" v-model:selected="custom.common"/>
            </div>
        </div>

        <div class="flex items-center justify-end w-full mt-6">
            <button class="bg-red-800/70 shadow rounded-md ml-1 px-4 py-2 font-semibold text-sm" @click="cancel" v-if="!editingGoal">{{ $t('cancel') }}</button>
            <button class="bg-red-800/70 shadow rounded-md ml-1 px-4 py-2 font-semibold text-sm" @click="remove" v-else>{{ $t('delete') }}</button>

            <button
                class="bg-yellow-600/70 shadow rounded-md ml-1 px-4 py-2 font-semibold text-sm disabled:pointer-events-none disabled:opacity-50"
                @click="$emit('update:replacing', selected)"
                v-if="editingGoal"
                :disabled="!goal"
            >{{ $t('replace') }}</button>

            <button class="bg-green-700/80 shadow rounded-md ml-1 px-4 py-2 font-semibold text-sm" @click="save">{{ $t('save') }}</button>
        </div>

        <div class="bg-gray-600 opacity-25 w-full h-1 my-4 mx-1 rounded-full" v-if="editingGoal"/>
    </div>
</template>

<script>
import CustomGoalItems from './CustomGoalItems.vue'

export default {
    emits: ['update:selected', 'update:editing', 'update:replacing', 'update:editing-goal'],

    components: { CustomGoalItems },

    props: {
        selected: String,
        editing: Boolean,
        editingGoal: Object,
    },

    data () {
        return {
            errorName: false,
            custom: {
                tier: 4,
                name: null,
                type: null,
                wam: null,
                common_rare: null,
                common: null,
            }
        }
    },

    computed: {
        customAdded () {
            return this.customs[this.editingGoal?.weapon]
        },

        goal () {
            return this.goals.find(g => g.weapon === this.selected)
        },
    },

    mounted () {
        if (this.customAdded) {
            this.custom = {
                tier: this.customAdded.tier,
                name: this.customAdded.name,
                type: this.customAdded.type,
                wam: this.customAdded.wam,
                common_rare: this.customAdded.common_rare,
                common: this.customAdded.common,
            }
        }
    },

    methods: {
        cancel () {
            this.$emit('update:selected', null)
        },

        remove () {
            if (!this.editingGoal || (this.selected == 'custom')) return
            this.removeGoal(this.goal)
            this.setCustom(this.selected, null)
            this.$emit('update:selected', null)
            this.$emit('update:editing', false)
            this.$emit('update:editing-goal', null)
        },

        save () {
            if (!this.custom.name || !this.custom.name.length) {
                this.errorName = true
                return
            }

            let id = (this.editingGoal && this.selected) || `custom-${Math.random().toString(36).substr(2, 6)}`

            if (!this.editingGoal) {
                let newGoal = this.addGoal({
                    type: 'weapon',
                    id: null,
                    character: null,
                    weapon: id,
                    current: { level: 1, asc: 0, text: '1' },
                    goal: { level: 1, asc: 0, text: '1' }
                })
                this.$emit('update:editing-goal', newGoal)
            }

            this.setCustom(id, {
                custom: 'weapon',
                tier: this.custom.tier,
                wam: this.custom.wam,
                common_rare: this.custom.common_rare,
                common: this.custom.common,
                type: this.custom.type,
                name: this.custom.name,
            })
            this.$emit('update:selected', id)
            this.$emit('update:editing', false)
        }
    }
}
</script>
