import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/HomeView.vue'
import Settings from '../views/SettingsView.vue'
import PrivacyPolicy from '../views/PrivacyPolicyView.vue'

import Characters from '../views/CharactersView.vue'
import Weapons from '../views/WeaponsView.vue'
import Planner from '../views/PlannerView.vue'
import Inventory from '../views/InventoryView.vue'
import Categories from '../views/CategoriesView.vue'
import Achievements from '../views/AchievementsView.vue'

const routes = [
    { path: '/', component: Home },
    { path: '/characters', component: Characters },
    { path: '/weapons', component: Weapons },
    { path: '/planner', component: Planner },
    { path: '/inventory', component: Inventory },
    { path: '/achievements', component: Categories, children: [
        { path: 'category/:id', component: Achievements },
        { path: 'commissions/:area', component: Achievements }
    ] },

    { path: '/settings', component: Settings },
    { path: '/policy', component: PrivacyPolicy },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior () {
        document.getElementById('main')?.scroll({ top: 0 })
    },
})

router.afterEach(() => {
    if (typeof window.reloadAdSlots == 'function') {
        window.reloadAdSlots()
        console.log('reloading ads')
    }
})

export default router
