<template>
    <div class="bg-gray-600 h-10 sm:h-14 shadow rounded overflow-hidden flex items-center" :class="size" v-if="!options || options?.showAll || need">
        <div class="relative w-10 sm:w-14 h-full flex items-center justify-center bg-gradient-to-tl" :class="itemTierClass[item] || tierClass[type]?.[tier]">
            <img
                class="max-h-[2rem] max-w-[2rem] sm:max-h-[2.5rem] sm:max-w-[2.5rem]"
                v-tippy.arrow="name"
                :src="`${spaces}/items/${type}/${item}${(maxTier[type] > 0 ? `_${tier}` : '')}.png`"
            />
            <!-- unreleased -->
            <div
                v-if="isFuture(release)"
                class="absolute top-0 left-0 m-1 text-xs bg-red-500 rounded-full w-5 h-5 flex items-center justify-center"
                v-tippy.arrow="$t('unreleased_item')"
            >!</div>
        </div>

        <div class="text-xs flex flex-wrap flex-shrink-0 px-2 -mr-1" v-if="!hideInfo">
            <!-- need -->
            <template v-if="!options?.hideNeed">
                <div v-if="need > total" class="rounded-lg py-px sm:py-1 pl-px pr-1 sm:px-px leading-none text-white flex flex-row sm:flex-col items-center justify-center mr-1 font-semibold bg-red-400">
                    <flag-icon class="w-4"/> <span class="mx-px">{{ abbr(need, item === 'xp' || item === 'wep_xp') }}</span>
                </div>
                <div v-else class="rounded-lg py-px sm:py-1 pl-px pr-1 sm:px-px leading-none text-white flex flex-row sm:flex-col items-center justify-center mr-1" :class="!need ? 'opacity-25 bg-gray-500' : 'font-semibold bg-green-500'">
                    <exclamation-circle-icon class="w-4"/> <span class="mx-px">{{ abbr(need, item === 'xp' || item === 'wep_xp') }}</span>
                </div>
            </template>
            <!-- missing -->
            <div v-if="missing && !options?.hideMissing" class="rounded-lg py-px sm:py-1 pl-px pr-1 sm:px-px leading-none text-white flex flex-row sm:flex-col items-center justify-center mr-1 font-semibold bg-red-400">
                <exclamation-circle-icon class="w-4"/> <span class="mx-px">{{ abbr(missing, item === 'xp' || item === 'wep_xp') }}</span>
            </div>
            <!-- craft -->
            <div v-if="craft && !options?.hideCraft" class="rounded-lg py-px sm:py-1 pl-px pr-1 sm:px-px leading-none text-white flex flex-row sm:flex-col items-center justify-center font-semibold bg-yellow-600">
                <beaker-icon class="w-4"/> <span class="mx-px">{{ craft }}</span>
            </div>
        </div>

        <input
            v-if="item === 'mora'"
            type="text"
            ref="input"
            class="ml-auto bg-gray-500 text-sm text-center h-full p-0 rounded w-24"
            v-model="moraValue"
            tabindex="1"
        >

        <input
            v-else
            ref="input"
            type="number" min="0"
            class="ml-auto text-sm text-center h-full p-0 rounded w-16"
            :class="[{ 'sm:rounded-l-none': hideInfo }, isFuture(release) ? 'bg-red-800/40 border-red-800/50' : 'bg-gray-500']"
            :disabled="isFuture(release)"
            :value="have"
            @input="(v) => setInventory(type, item, tier, v.target.value)"
            tabindex="1"
        >
    </div>
</template>

<script>
export default {
    props: {
        type: String,
        item: String,
        tier: Number,
        options: Object,
        release: String,
        isOpened: Boolean,
        size: {
            type: String,
            default: 'w-full sm:w-48'
        },
        hideInfo: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        name () {
            if (this.item.includes('xp_sub')) return null

            return this.maxTier[this.type] > 0 ? this.$t(`${this.item}.${this.tier}`) : this.$t(this.item)
        },

        index () {
            return this.totalCosts.findIndex(tc => tc.item === this.item)
        },

        have () {
            return this.getInventory(this.type, this.item, this.tier)
        },

        need () {
            return this.getNeed(this.type, this.item, this.tier)
        },

        craft () {
            return this.getCraft(this.type, this.item, this.tier)
        },

        total () {
            return this.getTotal(this.type, this.item, this.tier)
        },

        missing () {
            return this.getMissing(this.type, this.item, this.tier)
        },

        moraValue: {
            get () {
                return Number(this.have).toLocaleString()
                // return new Intl.NumberFormat().format(this.have)
            },
            set (v) {
                this.setInventory(this.type, this.item, this.tier, v.replace(/\D/g, ''))
            }
        }
    },

    watch: {
        isOpened (value) {
            if (value) this.$refs?.input?.focus()
        }
    },
}
</script>
