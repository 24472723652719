<template>
    <button
        @click="isVisible = true"
        class="bg-red-600 rounded py-2 px-3 shadow hover:shadow-lg transition-shadow font-semibold"
    >{{ $t('clean') }}</button>

    <Dialog
        v-model:visible="isVisible"
        dismissable-mask
        modal
        style="width: 420px;"
        :show-header="false"
    >
        <div class="mt-6">
            <p>{{ $t('clean_confirmation') }}</p>

            <div class="flex items-center justify-between mt-4">
                <button
                    class="bg-red-600 shadow rounded-md py-2 px-3 flex items-center font-semibold"
                    @click="clean"
                >
                    {{ $t('clean') }}
                </button>

                <button class="transition-all duration-100 font-semibold px-3 py-2 rounded hover:bg-gray-700/60" @click="isVisible = false">
                    {{ $t('cancel') }}
                </button>
            </div>
        </div>

    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'

export default {
    components: { Dialog },

    data () {
        return {
            isVisible: false
        }
    },

    methods: {
        clean () {
            this.inventory = []
            this.isVisible = false
        }
    }
}
</script>
