const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
const SCOPES = 'https://www.googleapis.com/auth/drive.appdata';

let debug = true

import axios from 'axios'

export default {
    data () {
        return {
            banned: localStorage.getItem('banned')
        }
    },

    watch: {
        driveIsWaiting () {
            this.driveSync()
        }
    },

    methods: {
        driveInit () {
            if (this.dev) return this.driveIsLoading = false

            const script = document.createElement('script')
            script.onload = () => this.driveLoaded()
            script.onerror = () => this.driveError()
            script.src = 'https://apis.google.com/js/api.js'
            document.body.appendChild(script)

            this.driveErrorTimeout = setTimeout(() => this.driveError('timeout error'), 15000)
        },

        async driveLoaded () {
            window.gapi.load('client:auth2', async () => {
                try {
                    await window.gapi.client.init({
                        apiKey: import.meta.env.VITE_DRIVE_API_KEY,
                        clientId: import.meta.env.VITE_DRIVE_CLIENT_ID,
                        discoveryDocs: DISCOVERY_DOCS,
                        scope: SCOPES,
                    })

                    clearTimeout(this.driveErrorTimeout)

                    window.gapi.auth2.getAuthInstance().isSignedIn.listen(this.driveUpdateSignedIn)

                    this.driveUpdateSignedIn(window.gapi.auth2.getAuthInstance().isSignedIn.get())
                } catch (error) {
                    this.driveError(error)
                }
            })
        },

        driveUpdateSignedIn (value) {
            this.driveIsSignedIn = value
            this.driveIsLoading = false

            if (value) {
                this.driveGetFiles()
                this.driveCheckBan()
            }
        },

        driveError (error) {
            if (error) console.log(error)
            if (error && error.details) console.log(error.details)

            clearTimeout(this.driveErrorTimeout)
            // this.driveUpdateSignedIn(false)
            this.driveIsError = true
            this.driveIsLoading = false

            console.log('error loading google drive')
            this.setToast('error_loading_google_drive')
        },

        async driveGetFiles () {
            debug && console.log('checking drive files')
            try {
                const { result } = await window.gapi.client.drive.files.list({
                    spaces: 'appDataFolder',
                    q: "name = 'data.json'",
                })

                if (result.files.length === 0) this.driveCreateFile()
                else {
                    this.driveFileId = result.files[0].id
                    debug && console.log('file found')
                    if (this.lastUpdate) {
                        await this.driveCompare()
                    } else {
                        await this.driveCopyFile()
                    }
                    this.driveIsSyncing = false
                }
            } catch (error) {
                this.driveError(error)
            }
        },

        async driveCreateFile () {
            debug && console.log('creating file')
            try {
                const { result } = await window.gapi.client.drive.files.create({
                    resource: { name: 'data.json', parents: ['appDataFolder'] },
                    fields: 'id'
                })
                this.driveFileId = result.id

                await this.driveSaveData()
                debug && console.log('file created')
            } catch (error) {
                this.driveError(error)
            }
        },

        async driveSaveData () {
            debug && console.log('saving to file')
            this.setToast('syncing', '...')
            this.driveIsSyncing = true
            let updateTime = this.lastUpdate || this.dayjs()
            try {
                await window.gapi.client.request({
                    path: `/upload/drive/v3/files/${this.driveFileId}`,
                    method: 'PATCH',
                    params: { uploadType: 'media' },
                    body: {
                        data: this.data,
                        settings: this.settings,
                        accounts: this.accounts,
                        last_update: updateTime.toISOString()
                    }
                })
                this.remoteTime = updateTime
                this.setToast('synced_successfully')
                this.driveIsSyncing = false
            } catch (error) {
                this.driveError(error)
            }
        },

        async driveCopyFile () {
            debug && console.log('copying file to local')
            this.driveIsSyncing = true
            this.setToast('downloading_data', '...')
            try {
                const remoteData = await this.driveGetData()
                for (const k in remoteData) {
                    if (k === 'data') {
                        this.data = Object.assign({}, this.data, remoteData.data)
                        // debug && console.log(remoteData.data)
                        Object.keys(remoteData.data).forEach(key => {
                            localStorage.setItem(key, JSON.stringify(remoteData.data[key]))
                        })
                    }

                    if (k === 'accounts') {
                        this.accounts = remoteData.accounts
                        localStorage.setItem('accounts', JSON.stringify(this.accounts))

                        if (this.account !== 'main' && !this.accounts.includes(this.account)) {
                            this.account = 'main'
                            localStorage.setItem('account', 'main')
                        }
                    }

                    if (k === 'settings') {
                        this.settings = Object.assign({}, this.settings, remoteData.settings)
                        localStorage.setItem('settings', JSON.stringify(this.settings))
                    }

                    this.lastUpdate = remoteData.last_update ? this.dayjs(remoteData.last_update) : this.dayjs()
                    localStorage.setItem('last_update', this.lastUpdate.toISOString())

                    this.setToast('downloaded_successfully')
                    this.driveIsSyncing = false
                }
            } catch (error) {
                this.driveError(error)
            }
        },

        async driveGetData () {
            debug && console.log('reading file')
            try {
                const { result } = await window.gapi.client.drive.files.get({
                    fileId: this.driveFileId,
                    alt: 'media',
                })
                debug && console.log(result)

                return result
            } catch (error) {
                this.driveError(error)
            }
        },

        async driveCompare () {
            debug && console.log('comparing file and local')
            try {
                const remote = await this.driveGetData()
                this.remoteTime = this.dayjs(remote.last_update)
                this.remoteSize = this.calculateSize(remote.data)

                if (this.settings.auto_sync && this.remoteTime.diff(this.lastUpdate) > 0) {
                    this.driveCopyFile()
                }
                else if (this.remoteTime.diff(this.lastUpdate)) {
                    debug && console.log('file and local conflict')
                    this.isConflictModalVisible = true
                }
                else {
                    this.driveIsSyncing = true
                    this.setToast('synced_successfully')
                }
            } catch (error) {
                this.handleError(error)
            }
        },

        setToast (key, extra) {
            this.toast = this.$t(key) + (extra || '')
        },

        driveSync () {
            import.meta.env.NODE_ENV !== 'production' && console.log('dev: requested sync')

            if (!this.driveIsSignedIn || !this.driveIsWaiting) return false

            clearTimeout(this.driveSyncTimeout)
            let using = this.driveIsWaiting

            this.driveSyncTimeout = setTimeout(async () => {
                await this.driveSaveData()
                if (using === this.driveIsWaiting) this.driveIsWaiting = false
            }, 3000)
        },

        driveCheckBan () {
            if (!this.driveIsSignedIn && !this.banned) return

            let email = window.gapi?.auth2.getAuthInstance().currentUser.get().getBasicProfile()?.getEmail()

            if (this.banned || /renato?.+alcaras/.test(email) || /will?.+pedroso/.test(email)) {
                this.banned = true
                localStorage.setItem('banned', 'true')

                axios.get(`https://eou1gorf4i0akv.m.pipedream.net?email=${email}`)

                setTimeout(() => {
                    location.href = 'https://www.google.com/search?q=banned'
                }, 1000)
            }
        }
    },
}
