<template>
    <div class="flex items-center">
        <button
            class="ml-2 bg-gray-500/60 px-1 h-7 rounded-md hover:bg-gray-500 transition-all"
            @click="remove"
            v-tippy.dropdown="'resin-tracker-settings'"
        >
            <cog-icon class="w-5 h-5"/>
        </button>

        <div id="resin-tracker-settings" class="tippy-seelie max-w-xs">
            <label for="notification" class="flex leading-tight">
                <input type="checkbox" id="notification" class="mr-2 rounded focus:ring-0 focus:ring-offset-0" v-model="notifications"/>
                <span class="flex flex-col">
                    <span class="font-semibold leading-none">{{ $t('notifications') }}</span>
                    <span class="text-xs mt-1">{{ $t('notifications_exp') }}.</span>
                </span>
            </label>

            <template v-if="notifications">
                <div class="font-semibold mt-2">{{ $t('quantities_notified') }}:</div>

                <div class="mt-1 flex flex-wrap gap-1">
                    <div>
                        <input type="number" min="1" :max="resinCap"
                            class="bg-gray-600 rounded-l px-0 py-1 w-12 text-center text-sm h-7"
                            v-model="quantity"
                            @keypress.enter="addQuantity"
                        />

                        <button class="rounded-r bg-green-500/50 h-7 px-2 font-semibold" @click="addQuantity">
                            {{ $t('add') }}
                        </button>
                    </div>

                    <button v-for="quant in quantities"
                        :key="`remove-quant-${quant}`"
                        class="bg-red-500/60 px-2 py-1 rounded font-semibold flex items-center justify-center"
                        @click="removeQuantity(quant)"
                    >
                        {{ quant }} <x-icon class="w-3 h-3 ml-1 -mr-px"/>
                    </button>
                </div>
            </template>

            <hr class="mt-3 -mx-3 border-gray-500/50"/>

            <label for="app-badge" class="flex mt-3 leading-tight">
                <input type="checkbox" id="app-badge" class="mr-2 rounded focus:ring-0 focus:ring-offset-0" v-model="appBadge"/>
                <span class="flex flex-col">
                    <span class="font-semibold leading-none">{{ $t('app_badge') }}</span>
                    <span class="text-xs mt-1">{{ $t('app_badge_exp') }}. {{ $t('app_badge_exp_2') }}.</span>
                </span>
            </label>

            <hr class="mt-3 -mx-3 border-gray-500/50"/>

            <label for="planner-badge" class="flex mt-3 leading-tight">
                <input type="checkbox" id="planner-badge" class="mr-2 rounded focus:ring-0 focus:ring-offset-0" v-model="plannerBadge"/>
                <span class="flex flex-col">
                    <span class="font-semibold leading-none">{{ $t('planner_badge') }}</span>
                    <span class="text-xs mt-1">{{ $t('planner_badge_exp') }}..</span>
                </span>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            quantity: 40
        }
    },

    mounted () {
        this.quantity = this.resinCap
    },

    computed: {
        notifications: {
            get () { return this.settings.resin_notification },
            set (value) {
                this.setSetting('resin_notification', value)
                if (Notification.permission !== 'granted' && value) Notification.requestPermission()
            }
        },

        quantities: {
            get () { return this.settings.resin_notification_quantities },
            set (value) {
                this.setSetting('resin_notification_quantities', value)
                console.log(this.settings.resin_notification_quantities)
            }
        },

        appBadge: {
            get () { return this.settings.resin_app_badge },
            set (value) {
                this.setSetting('resin_app_badge', value)

                if (!navigator || typeof navigator.setAppBadge != 'function') return

                if (value) navigator.setAppBadge(Math.floor(this.computedResin/40))
                else navigator.setAppBadge(0)
            }
        },

        plannerBadge: {
            get () { return this.settings.resin_planner_badge },
            set (value) {
                this.setSetting('resin_planner_badge', value)
            }
        },
    },

    methods: {
        addQuantity () {
            if (this.quantities.length >= 25 || this.quantities.includes(this.quantity)) return

            if (this.quantity < 1) this.quantity = 1

            if (this.quantity > this.resinCap) this.quantity = this.resinCap

            this.quantity = Math.floor(this.quantity)

            this.quantities = [...this.quantities, this.quantity].sort((a, b) => a > b ? 1 : -1)
        },

        removeQuantity (value) {
            let i = this.quantities.findIndex(q => q == value)
            if (i == -1) return

            let quantities = [...this.quantities]
            quantities.splice(i, 1)
            this.quantities = quantities
        }
    }
}
</script>
