<template>
    <div
        class="rounded py-2 px-2 shadow bg-gradient-to-br focus:outline-none"
        :class="[isActive ? 'from-gray-500 to-gray-700' : 'from-gray-700 to-gray-800', { 'opacity-40': done == total }]"
        @click="navigate"
        v-if="(search.length || version !== 'all' || !hideCompleted || done !== total)"
    >
        <div class="flex items-center">
            <img :src="`${url}img/achievements/${categoryId}s.png`" class="flex-shrink-0 w-6 h-7 object-contain mb-[-0.3rem]" v-if="categoryId"/>
            <img :src="`${url}img/areas/${area}.png`" class="flex-shrink-0 w-6 h-6 object-contain" v-else/>
            <p class="leading-none font-semibold ml-2 text-sm">{{ categoryName || $t(area) }}</p>
        </div>

        <div class="flex flex-col items-end justify-center mt-3 w-full">
            <div class="relative w-full h-1 rounded-full bg-gray-500/50 overflow-hidden">
                <div class="absolute left-0 bg-purple-600/60 h-1 transition-all" :style="`width:${percent}%`"></div>
            </div>
            <div class="text-xs font-semibold mt-px opacity-80">
                {{ percent || 0 }}% ({{ done }}/{{ total }})
            </div>
        </div>
    </div>
</template>

<script>
const expM = /\{M#(.+?)?\}\{F#(?:.+?)?\}|\{F#(?:.+?)?\}\{M#(.+?)?\}/g
const expF = /\{M#(?:.+?)?\}\{F#(.+?)?\}|\{F#(.+?)?\}\{M#(?:.+?)?\}/g

export default {
    props: {
        isActive: Boolean,
        achievs: Array,
        categoryName: String,
        categoryId: String,
        area: String,
        hideCompleted: Boolean,
        search: { type: String, default: '' },
        version: { type: String, default: 'all' },
    },

    computed: {
        total () {
            return this.achievs.length
        },

        done () {
            return this.achievs.filter(a => this.achievements[a.id]?.done).length
        },

        percent () {
            return Math.round(this.done * 100 / this.total)
        },

        filteredAchievements () {
            return this.achievs.map(a => {
                let name = a.n.replace(this.gender == 'male' ? expM : expF, (_, b, c) => b || c || '').replace('#','')
                let desc = a.d.replace(this.gender == 'male' ? expM : expF, (_, b, c) => b || c || '').replace('#','')
                let altName = a.n.replace(this.gender == 'male' ? expF : expM, (_, b, c) => b || c || '').replace('#','')
                let altDesc = a.d.replace(this.gender == 'male' ? expF : expM, (_, b, c) => b || c || '').replace('#','')

                return {
                    ...a,
                    n: name,
                    d: desc,
                    s: `${name} ${altName} ${desc} ${altDesc}`.toLowerCase()
                }
            }).filter(a => {
                if (!this.search.length && this.version == 'all') return true

                return (
                    !this.search ||
                    a.s.indexOf(this.search) > -1 ||
                    a.c?.toLowerCase().indexOf(this.search) > -1 ||
                    a.q?.map(q => q.join(' ')).join(' ').toLowerCase().indexOf(this.search) > -1 ||
                    this.achievements[a.id]?.notes?.toLowerCase().indexOf(this.search) > -1
                ) &&
                (this.version == 'all' || a.v == this.version)
            })
        },
    }
}
</script>
