<template>
    <div class="mt-2 flex items-center justify-around flex-col sm:flex-row">
        <div v-for="talent in ['normal', 'skill', 'burst']" :key="`talent-${talent}`" class="flex flex-col items-center w-36 mb-3">
            <div class="uppercase font-semibold text-sm m-2 bg-purple-500 rounded px-1">{{ $t(talent) }}</div>
            <div class="flex items-center justify-center">
                <div class="flex sm:flex-col">
                    <div class="flex bg-gray-500 rounded-l sm:rounded-l-none sm:rounded-t overflow-hidden">
                        <input
                            class="bg-gray-500 h-12 p-0 sm:h-10 text-center rounded-l sm:rounded-l-none sm:rounded-t border-none"
                            :class="consImproved(talent) ? 'w-8' : 'w-12'"
                            @input="changeTalent()" v-model="talents[talent].current" type="number" :min="1" :max="10"
                        >
                        <div class="bg-teal-600 flex items-center justify-center font-semibold text-xs w-4" v-if="consImproved(talent)">
                            {{ Number(talents[talent].current) + 3 }}
                        </div>
                    </div>
                    <div class="flex items-center justify-center font-bold flex-col sm:flex-row-reverse sm:w-12">
                        <button tabindex="-1" class="bg-gray-600 h-6 leading-none sm:w-1/2 w-8 font-bold rounded-tr sm:rounded-tr-none sm:rounded-br" @click="changeTalent(talent, 'current', +1)">+</button>
                        <button tabindex="-1" class="bg-gray-600 h-6 leading-none sm:w-1/2 w-8 font-bold rounded-br sm:rounded-br-none sm:rounded-bl" @click="changeTalent(talent, 'current', -1)">-</button>
                    </div>
                </div>

                <template v-if="talents[talent].current < 10">
                    <chevron-double-right-icon class="w-5 mx-2"/>

                    <div class="flex sm:flex-col">
                        <div class="flex bg-gray-500 rounded-l sm:rounded-l-none sm:rounded-t overflow-hidden">
                            <input
                                class="h-12 p-0 sm:h-10 text-center rounded-l sm:rounded-l-none sm:rounded-t border-none"
                                :class="[consImproved(talent) ? 'w-8' : 'w-12', { 'bg-gray-500': !haveAsc[talent], 'bg-yellow-600/60': haveAsc[talent] == 1, 'bg-red-700/70': haveAsc[talent] == 2 }]"
                                @input="changeTalent()" v-model="talents[talent].goal" type="number" :min="talents[talent].current" :max="10"
                            >
                            <div class="bg-teal-600 flex items-center justify-center font-semibold text-xs w-4" v-if="consImproved(talent)">
                                {{ Number(talents[talent].goal) + 3 }}
                            </div>
                        </div>
                        <div class="flex items-center justify-center font-bold flex-col sm:flex-row-reverse sm:w-12">
                            <button tabindex="-1" class="bg-gray-600 h-6 leading-none sm:w-1/2 w-8 font-bold rounded-tr sm:rounded-tr-none sm:rounded-br" @click="changeTalent(talent, 'goal', +1)">+</button>
                            <button tabindex="-1" class="bg-gray-600 h-6 leading-none sm:w-1/2 w-8 font-bold rounded-br sm:rounded-br-none sm:rounded-bl" @click="changeTalent(talent, 'goal', -1)">-</button>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>

    <div class="bg-yellow-600/40 rounded py-2 px-2 mt-2 text-sm font-semibold text-center" v-if="Object.values(haveAsc).includes(1)">
        {{ $t('ascend_first') }}
    </div>

    <div class="bg-red-700/40 rounded py-2 px-2 mt-2 text-sm font-semibold text-center" v-if="Object.values(haveAsc).includes(2)">
        {{ $t('dont_have_needed_ascension') }}
    </div>

    <individual-costs
        :is-inactive="inactive[selected]"
        @done="done"
        :subject="selected"
        :costs="Object.values(talentCosts(this.talentGoal))"
        v-if="anyGoal"
    />
</template>

<script>
import IndividualCosts from './IndividualCosts'

export default {
    components: { IndividualCosts },

    props: {
        selected: String
    },

    data () {
        return {
            talents: {
                normal: { current: 1, goal: 1 },
                skill: { current: 1, goal: 1 },
                burst: { current: 1, goal: 1 },
            },
            requiredAsc: [0, 0, 2, 3, 3, 4, 4, 5, 5, 6, 6]
        }
    },

    computed: {
        character () {
            return this.characters[this.selected] || this.customs[this.selected]
        },

        characterGoal () {
            return this.goals.find(g => g.type === 'character' && g.character === this.selected)
        },

        characterCons () {
            return this.characterGoal?.cons || 0
        },

        currentAsc () {
            return (this.characterGoal && this.characterGoal.current.asc) || 0
        },

        goalAsc () {
            return (this.characterGoal && this.characterGoal.goal.asc) || 0
        },

        talentGoal () {
            return this.goals.find(g => g.character === this.selected && g.type === 'talent')
        },

        anyGoal () {
            return this.talentGoal && (
                this.talentGoal.normal.goal > this.talentGoal.normal.current ||
                this.talentGoal.skill.goal > this.talentGoal.skill.current ||
                this.talentGoal.burst.goal > this.talentGoal.burst.current
            )
        },

        normalGoal () {
            return (this.talentGoal && this.talentGoal.normal.goal) || 0
        },

        skillGoal () {
            return (this.talentGoal && this.talentGoal.skill.goal) || 0
        },

        burstGoal () {
            return (this.talentGoal && this.talentGoal.burst.goal) || 0
        },

        haveAsc () {
            return {
                normal: this.requiredAsc[this.normalGoal] <= this.currentAsc ? 0 : (this.requiredAsc[this.normalGoal] <= this.goalAsc ? 1 : 2),
                skill: this.requiredAsc[this.skillGoal] <= this.currentAsc ? 0 : (this.requiredAsc[this.skillGoal] <= this.goalAsc ? 1 : 2),
                burst: this.requiredAsc[this.burstGoal] <= this.currentAsc ? 0 : (this.requiredAsc[this.burstGoal] <= this.goalAsc ? 1 : 2),
            }
        }
    },

    mounted () {
        if (this.talentGoal) {
            this.talents.normal = { current: this.talentGoal.normal.current, goal: this.talentGoal.normal.goal }
            this.talents.skill = { current: this.talentGoal.skill.current, goal: this.talentGoal.skill.goal }
            this.talents.burst = { current: this.talentGoal.burst.current, goal: this.talentGoal.burst.goal }
        }
    },

    methods: {
        consImproved (type) {
            if (!this.character.cons) return false
            if (this.characterCons >= 5) return this.character.cons[5] == type || this.character.cons[3] == type
            if (this.characterCons >= 3) return this.character.cons[3] == type
            return false
        },

        changeTalent (talent, option, qtd) {
            if (talent && option && qtd) {
                this.talents[talent][option] = Number(this.talents[talent][option]) + qtd
            }

            let talents = ['normal', 'skill', 'burst']

            talents.forEach(talent => {
                if (this.talents[talent].current < 1) this.talents[talent].current = 1
                if (this.talents[talent].goal < 1) this.talents[talent].goal = 1
                if (this.talents[talent].current > 10) this.talents[talent].current = 10
                if (this.talents[talent].goal > 10) this.talents[talent].goal = 10
                if (this.talents[talent].current > this.talents[talent].goal) this.talents[talent].goal = this.talents[talent].current
            })

            this.addGoal({
                type: 'talent',
                character: this.selected,
                ...this.talents
            })
        },

        done () {
            this.talents.normal.current = this.talents.normal.goal
            this.talents.skill.current = this.talents.skill.goal
            this.talents.burst.current = this.talents.burst.goal

            this.addGoal({
                type: 'talent',
                character: this.selected,
                ...this.talents
            })
        }
    }
}
</script>
