<template>
    <div class="text-white mt-6">
        <p class="ml-1 uppercase font-semibold" v-once>{{ $t('upcoming_birthdays') }}</p>

        <div class="flex flex-wrap gap-2 mt-2 h-20 sm:h-24 overflow-hidden">
            <div
                v-for="character in birthdays"
                :key="`add-${character.key}`"
                class="relative"
            >
                <div class="w-20 sm:w-24 rounded-xl overflow-hidden relative bg-gradient-to-br"
                    :class="darkTierClass[character.tier]"
                >
                    <img :src="`${spaces}/characters/select/${character.key}.png`">
                    <div class="absolute bottom-0 flex justify-center w-full bg-gray-700/80 text-sm font-semibold">
                        {{ character.birthdayTxt[locale] }}
                    </div>
                </div>
                <div class="bg-orange-700 absolute-tag !text-sm" v-if="isFuture(character.release)">{{ $t('soon')}}</div>
                <div class="bg-green-700 absolute-tag !text-sm" v-if="isNew(character.release)">{{ $t('new')}}</div>
                <p class="text-xs text-center font-semibold tracking-tight">{{ character.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        locale () {
            return this.$i18n.locale == 'pt' ? 'pt' : 'en'
        }
    },

    mounted () {
        if (!this.birthdays.length) this.getBirthdays()
    },

    methods: {
        getBirthdays () {
            let today = this.time
            let year = today.year()

            this.birthdays = Object.keys(this.characters)
                .filter(c => this.characters[c].birthday && !this.isFuture(this.characters[c].show))
                .map(c => {
                    let birth = this.characters[c].birthday

                    birth = this.dayjs(`${year}-${birth[0]}-${birth[1]}`)

                    if (birth.diff(today, 'day') < 0) birth = birth.set('year', year+1)

                    return {
                        key: c,
                        ...this.characters[c],
                        birthday: birth.toISOString(),
                        birthdayTxt: {
                            en: birth.format('MMM D'),
                            pt: birth.format('DD/M'),
                        }
                    }
                })
                .sort((a, b) => {
                    return this.dayjs(a.birthday).diff(b.birthday, 'day') > 0 ? 1 : -1
                })
                .slice(0, 8)
        }
    }
}
</script>
