<template>
    <div
        class="flex items-center justify-center font-semibold rounded-full"
        :class="small ? '' : 'w-10 h-10 leading-none flex-col'"
    >
        <div :class="small ? 'mr-px' : ''">{{ level.level }}</div>
        <img :src="`${url}img/general/ascension.png`" :class="[small ? 'w-3 ml-px' : 'w-4', { 'opacity-25': ascended[level.level] !== level.asc }]"/>
    </div>
</template>

<script>
export default {
    props: {
        small: {
            type: Boolean,
            default: false
        },
        level: Object
    },

    data () {
        return {
            ascended: {
                20: 1,
                40: 2,
                50: 3,
                60: 4,
                70: 5,
                80: 6
            },
        }
    },
}
</script>
