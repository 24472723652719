<template>
    <div v-if="any">
        <div class="bg-gray-600 opacity-25 w-full h-1 mt-1 mx-1 mb-3 rounded-full"/>

        <div class="inline-flex font-semibold items-center bg-orange-500 text-white py-1 pl-2 pr-3 rounded-full">
            <img :src="`${url}img/general/artifact.png`" class="w-5 mr-1"/>
            {{ $t('artifacts') }}
        </div>

        <div class="mt-4 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-3">
            <template v-for="cost in Object.keys(artifactFarming)">
                <planner-activity
                    v-for="(items, location) in artifactFarming[cost]"
                    class="border-2 border-orange-500"
                    :key="`activity-${location}`"
                    :location="location"
                    :activity-items="items"
                    :cost="cost"
                    artifact
                />
            </template>
        </div>
    </div>
</template>

<script>
import PlannerActivity from './PlannerActivity'

export default {
    components: {
        PlannerActivity
    },

    computed: {
        any () {
            return Object.values(this.artifactFarming).flatMap(a => Object.keys(a))?.length
        },

        artifactFarming () {
            let artifacts = {}

            this.goals.filter(g => g.type === 'artifact' && g.artifacts && g.artifacts.length).forEach(goal => {
                if (this.inactive[goal.character]) return

                goal.artifacts.forEach(artifact => {
                    if (!this.farming[artifact] || goal.done[artifact] || this.isFuture(this.artifacts[artifact].release)) return

                    this.farming[artifact].where.forEach(loc => {
                        let locationType = this.farming[artifact].type
                        let cost = this.resin[locationType] || 0

                        if (!artifacts[cost]) artifacts[cost] = {}
                        if (!artifacts[cost][loc]) artifacts[cost][loc] = {}
                        if (!artifacts[cost][loc][artifact]) artifacts[cost][loc][artifact] = {
                            artifact: true,
                            item: artifact,
                            missing: 0,
                            characters: [],
                            weapons: []
                        }
                        artifacts[cost][loc][artifact].characters.push(goal.character)
                    })
                })
            })
            return artifacts
        }
    }
}
</script>
