<template>
    <div class="flex bg-gray-700/70 rounded-2xl relative h-20 shadow-lg" :class="event.type == 'wish' && 'h-auto && sm:h-20 sm:col-span-2 md:col-span-1'">
        <div class="font-semibold text-sm rounded-md px-2 py-[.1rem] absolute top-0 right-0 m-2 shadow" :class="diffClass">
            {{ diffText }}
        </div>

        <div class="w-20 h-full flex items-center justify-center flex-shrink-0">
            <img :src="event.img" v-if="event.img" class="pointer-events-none object-cover min-h-[5rem] w-full h-full rounded-2xl"/>
            <img :src="$url('general/wish.png')" v-else-if="event.type == 'wish'" class="opacity-100 w-full h-full p-4"/>
            <img :src="$public('general/wish.png')" v-else class="w-full h-full object-scale-down"/>
        </div>

        <div class="py-2 px-3 leading-tight flex flex-col items-start justify-center">
            <a class="font-bold text-sm md:text-base mr-16 tracking-tight"
                :href="event.hoyo && `https://www.hoyolab.com/article/${event.hoyo}`"
                :class="event.hoyo && 'text-purple-100 hover:underline'"
                target="_blank"
            >
                {{ event.type == 'wish' ? $t('event_wishes') : event[`name_${locale}`] }} <external-link-icon class="inline w-4 h-4" v-if="event.hoyo"/>
            </a>

            <div v-if="event.characters || event.weapons" class="flex items-start gap-1 mt-2 sm:my-1 flex-wrap">
                <template v-if="event.characters">
                    <template v-for="character in event.characters">
                        <div class="relative" v-if="characters[character] && isFuture(characters[character].show)" v-tippy="$t(character)" :key="character">
                            <div class="w-10 h-10 overflow-hidden bg-gradient-to-br rounded-full" :class="darkTierClass[characters[character].tier]">
                                <img :src="`${spaces}/characters/select/${character}.png`" class="w-full h-full">
                            </div>
                            <div class="bg-green-700 absolute-tag !left-auto !right-0 !-m-1 !text-xs">{{ $t('new')}}</div>
                        </div>

                        <characters-modal :editing="character" v-else-if="characters[character]" :key="`modal-${character}`">
                            <template v-slot:trigger>
                                <div class="relative" v-tippy="$t(character)">
                                    <div class="w-10 h-10 overflow-hidden bg-gradient-to-br rounded-full" :class="darkTierClass[characters[character].tier]">
                                        <img :src="`${spaces}/characters/select/${character}.png`" class="w-full h-full">
                                    </div>
                                    <div class="bg-green-700 absolute-tag !left-auto !right-0 !-m-1 !text-xs" v-if="isFutureOrNew(characters[character].release)">{{ $t('new')}}</div>
                                </div>
                            </template>
                        </characters-modal>
                    </template>
                </template>

                <template v-if="event.weapons">
                    <router-link to="/weapons" v-for="weapon in event.weapons" :key="`link-${weapon}`">
                        <div class="relative" v-if="weapons[weapon]" v-tippy="$t(weapon)">
                            <div class="w-10 h-10 overflow-hidden relative bg-gradient-to-br rounded-full" :class="darkTierClass[weapons[weapon].tier]" >
                                <img :src="`${spaces}/weapons/${weapon}.png`" class="w-full h-full object-contain">
                            </div>
                            <div class="bg-green-700 absolute-tag !left-auto !right-0 !-m-1 !text-xs" v-if="isFutureOrNew(weapons[weapon].release)">{{ $t('new')}}</div>
                        </div>
                    </router-link>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
import CharactersModal from './CharactersModal'

export default {
    components: { CharactersModal },

    props: {
        event: Object,
        started: Boolean
    },

    computed: {
        locale () {
            return this.$i18n.locale == 'pt' ? 'pt' : 'en'
        },

        eventTime () {
            if (this.started) return this.event.end

            return this.event.start
        },

        diff () {
            return this.eventTime && (this.started || this.event.local ? this.serverTime : this.time).diff(this.eventTime, 'hour') * -1
        },

        diffText () {
            if (!this.eventTime) return this.$t('soon')

            if (this.diff < 24) return `${this.diff}h`

            let days = Math.floor(this.diff / 24)
            let hours = this.diff % 24

            return `${days}d ${hours}h`
        },

        diffClass () {
            if (!this.eventTime) return 'bg-gray-600'

            if (!this.started) return 'bg-blue-800'

            if (this.diff > 24*3) return 'bg-green-600'

            if (this.diff > 24) return 'bg-yellow-600'

            return 'bg-red-600'
        }
    }
}
</script>
