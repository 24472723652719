<template>
    <div v-if="parsed.length" class="relative inline-block overflow-hidden transition-all" :class="{ 'h-[48px]': !expanded && height > maxHeight }">
        <div v-html="parsed" ref="content" class="break leading-tight" :class="{ 'pb-6': expanded }"/>
        <button
            v-if="height > maxHeight"
            class="w-full py-1 text-xs absolute bottom-0 inset-x-0 flex items-center justify-center font-semibold"
            :class="buttonClass"
            @click="expanded = !expanded"
        >
            <chevron-down-icon class="w-4 transition-all mr-1" :class="{ 'rotate-180': expanded }"/>
            <span>{{ expanded ? $t('less') : $t('more') }}</span>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        note: String,
        buttonClass: { type: String, default: 'bg-purple-900/90' }
    },

    data () {
        return {
            height: 0,
            maxHeight: 48,
            expanded: false
        }
    },

    computed: {
        parsed () {
            return this.note
                .trim()
                // sanitize
                .replace( /&/g, "&amp;" ).replace( /</g, "&lt;" ).replace( />/g, "&gt;" ).replace( /"/g, "&quot;" ).replace( /'/g, "&#39;" )
                // images
                .replaceAll(/^(https?:\/\/\S+(\.png|\.jpg|\.gif)\S*)/gm, '<a href="$1" target="_blank"><img src="$1" class="max-wa-[50%]"></a>')
                .replaceAll(/ (https?:\/\/\S+(\.png|\.jpg|\.gif)\S*)/gm, '<a href="$1" target="_blank"><img src="$1" class="max-wa-[50%]"></a>')
                // links
                .replace(/[\[]{1}([^\]]+)[\]]{1}[\(]{1}([^\)\"]+)(\"(.+)\")?[\)]{1}/g, '<a href="$2" target="_blank" class="underline">$1</a>')
                // headings
                .replace(/[\#]{6} (.+)/g, '<h6 class="font-semibold">$1</h6>')
                .replace(/[\#]{5} (.+)/g, '<h5 class="text-base font-semibold">$1</h5>')
                .replace(/[\#]{4} (.+)/g, '<h4 class="text-lg font-semibold">$1</h4>')
                .replace(/[\#]{3} (.+)/g, '<h3 class="text-xl font-semibold">$1</h3>')
                .replace(/[\#]{2} (.+)/g, '<h2 class="text-2xl font-semibold">$1</h2>')
                .replace(/[\#]{1} (.+)/g, '<h1 class="text-3xl font-semibold">$1</h1>')
                // bold
                .replace(/[\*]{2}([^\*]+)[\*]{2}/g, '<b>$1</b>')
                // italic
                .replace(/[\*]{1}([^\*]+)[\*]{1}/g, '<i>$1</i>')
                // striked through
                .replace(/[\~]{2}([^\~]+)[\~]{2}/g, '<del>$1</del>')
                // paragraphs
                .replace(/^\s*(\n)?(.+)/gm, m => /\<(\/)?(h\d|ul|ol|li|blockquote|pre|img)/.test(m) ? m : '<p>'+m+'</p>')
                // new line
                .replace(/\n$/gim, '<br>')
                .trim()
        }
    },

    watch: {
        note () {
            this.$nextTick(() => this.height = this.$refs?.content.clientHeight)
        },

        height () {
            // console.log(this.height)
        }
    },

    mounted () {
        this.$nextTick(() => this.height = this.$refs?.content.clientHeight)
    }
}
</script>
