<template>
    <div v-if="small">
        <button
            class="flex items-center justify-center bg-gray-900 hover:bg-gray-950 px-4 py-1 rounded-full text-sm relative"
            v-tippy="{ html: 'seelieSelector', interactive: true, placement: 'bottom', trigger: 'click' }"
        >
            <img :src="$url(`general/${list[active].icon}.png`)" class="h-4 mr-2"/>{{ list[active].name }}

            <div v-if="hasNew" class="bg-green-600 absolute top-0 right-0 -mr-1 -mt-2 font-semibold text-white rounded-full px-1 text-[0.6rem] leading-snug">
                {{ $t('new') }}
            </div>
        </button>

        <div id="seelieSelector" class="tippy-seelie">
            <div class="-mx-3 -my-2">
                <template v-for="(seelie, key) in other" :key="`seelie-${key}`">
                    <a class="flex items-center hover:bg-gray-800 py-2 px-4" :href="seelie.url + $route.path" v-if="seelie.released || dev">
                        <img :src="$url(`general/${seelie.icon}.png`)" class="h-4 mr-2"/>{{ seelie.name }}
                    </a>
                </template>
            </div>
        </div>
    </div>
    <div class="flex flex-col md:flex-row mt-4 gap-x-2 gap-y-1" v-else>
        <a
            class="flex items-center px-4 py-3 w-full rounded-lg shadow-lg transition-all relative"
            :class="[key == active ? 'bg-gray-700/70 cursor-default pointer-events-none' : 'bg-gray-800/70 hover:bg-gray-800', { 'cursor-not-allowed': !seelie.released }]"
            :href="seelie.released ? seelie.url : null"
            v-for="(seelie, key) in list"
            :key="`seelie-${key}`"
        >
            <location-marker-icon class="h-8 text-purple-400 absolute top-0 left-0 -m-2" v-if="key == active"/>

            <img :src="$url(`general/${seelie.image}.png`)" class="h-12 rounded-full shadow"/>

            <div class="ml-2 font-semibold">
                <p>{{ seelie.name }}</p>
                <p v-if="!seelie.released" class="text-sm text-red-500">{{ $t('soon') }}</p>
                <p v-if="seelie.new" class="text-sm text-green-500">{{ $t('new') }}</p>
            </div>
        </a>
    </div>
</template>

<script>
import { LocationMarkerIcon } from '@heroicons/vue/solid'

export default {
    props: {
        active: String,
        small: Boolean
    },

    components: { LocationMarkerIcon },

    data () {
        const dev = import.meta.env.DEV

        return {
            list: {
                'gi': {
                    name: 'Genshin Impact', icon: 'wish', image: 'gi-icon', released: true,
                    url: dev ? 'http://localhost:3000' : 'https://seelie.me',
                },
                'hsr': {
                    name: 'Honkai: Star Rail', icon: 'warp', image: 'hsr-icon', released: true,
                    url: dev ? 'http://localhost:3001' : 'https://hsr.seelie.me',
                },
                'zzz': {
                    name: 'Zenless Zone Zero', icon: 'search', image: 'zzz-icon', released: true, new: true,
                    url: dev ? 'http://localhost:3002' : 'https://zzz.seelie.me',
                },
            }
        }
    },

    computed: {
        other () {
            let list = {...this.list}
            delete list[this.active]
            return list
        },

        hasNew () {
            return !!Object.values(this.list).filter(l => l.new)
        }
    }
}
</script>
