<template>
    <div class="text-white">
        <h1 class="text-3xl font-semibold">Privacy Policy</h1>
        <h2 class="text-xl font-semibold mt-4">Collected Data</h2>
        <ul class="list-disc list-inside">
            <li>SEELIE.me collets only navigational data, like visited urls, browser, operating system, device, country and referer.</li>
            <li>No personal data is stored in your server or database. All personal data is stored in your personal computer or mobile device, and, when using Google Drive sync, in Google databases, and is not managed by us.</li>
        </ul>
        <h2 class="text-xl font-semibold mt-4">Advertisement</h2>
        <ul class="list-disc list-inside">
            <li>We use third party advertisements to support our site. Some of these advertisers may be served from our third party advertiser. You can view their <a href="https://www.network-n.com/network-privacy-policy/" target="_blank" class="underline">privacy policy</a> and <a href="https://www.network-n.com/network-cookie-policy/" target="_blank" class="underline">cookie policy</a> here. You can manage your cookies <a @click="showConsentModal" class="underline cursor-pointer">here</a>.</li>
        </ul>
    </div>
</template>

<script>
export default {
    methods: {
        showConsentModal () {
            if (typeof window.__cmp != 'function') {
                this.toast = 'Error loading consent modal. Make sure adblock is disabled.'
            } else {
                window.__cmp('showConsentModal')
            }
        }
    }
}
</script>
