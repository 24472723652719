<template>
    <div class="flex mt-4 -m-px flex-wrap">
        <div v-for="character in plannerCharacters" :key="`planner-${character}`">
            <characters-modal :editing="character">
                <template v-slot:trigger>
                    <div class="bg-gradient-to-br shadow w-10 h-10 rounded-full overflow-hidden m-px"
                        v-tippy.arrow="customs[character]?.name || (characters[character].traveler ? `${$t(character)} (${$t(characters[character].element)})` : $t(character))"
                        :class="[
                            {'opacity-50 filter-grayscale': inactive[character]},
                            darkTierClass[(characters[character] || customs[character]).tier]
                        ]"
                    >
                        <img :src="`${spaces}/characters/select/${characters[character].traveler ? `traveler_${gender}` : character}.png`" class="w-full h-full" v-if="characters[character]">
                        <img :src="`${url}img/other/custom_${customs[character].gender}.png`" class="w-full h-full" v-else>
                    </div>
                </template>
            </characters-modal>
        </div>

        <div v-for="weapon in plannerWeapons" :key="`planner-weapon-${weapon.goal.id}`">
            <weapons-modal :goal="weapon.goal">
                <template v-slot:trigger>
                    <div class="bg-gradient-to-br shadow w-10 h-10 rounded-full overflow-hidden mx-px flex items-center justify-center"
                        v-tippy.arrow="weapon.name"
                        :class="[
                            {'opacity-50 filter-grayscale': inactive[weapon.goal.id]},
                            darkTierClass[weapon.tier]
                        ]"
                    >
                        <img :src="`${spaces}/weapons/${weapon.key}.png`" class="h-full object-contain" v-if="weapons[weapon.key]">
                        <img :src="`${url}img/weapons/__${customs[weapon.key]?.type}-billet.png`" class="max-w-[1.6rem] max-h-[1.6rem]" v-else-if="weapon.type"/>
                        <img :src="`${url}img/other/question-mark.svg`" class="w-7" v-else/>
                    </div>
                </template>
            </weapons-modal>
        </div>
    </div>

    <div class="text-xs text-gray-400 opacity-75" v-if="amount">
        {{ $t('planner_subject_click') }}
    </div>
</template>

<script>
import CharactersModal from './CharactersModal'
import WeaponsModal from './WeaponsModal'

export default {
    components: {
        CharactersModal, WeaponsModal
    },

    computed: {
        plannerCharacters () {
            return Object.keys(this.characters).concat(Object.keys(this.customs))
                .filter(c => this.customs[c]?.custom == 'character' || this.goals.find(g => g.character === c))
                .sort((a, b) => (this.customs[a]?.name || this.$t(a)).localeCompare(this.customs[b]?.name || this.$t(b)))
        },

        plannerWeapons () {
            return this.goals.filter(g => g.type === 'weapon' && !g.character)
                .map(g => ({
                    goal: g,
                    key: g.weapon,
                    name: this.$t(g.weapon),
                    ...this.weapons[g.weapon],
                    ...this.customs[g.weapon]
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
        },

        amount () {
            return this.plannerCharacters.length + this.plannerWeapons.length
        }
    }
}
</script>
