import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://lfzrgjxgpaoqbxcckukd.supabase.co'
const supabaseKey = import.meta.env.VITE_SUPABASE_KEY
const supabase = createClient(supabaseUrl, supabaseKey)

export default {
    methods: {
        async get (table) {
            try {
                const { data, error } = await supabase.from(table).select('*')

                if (error) throw error

                return data
            } catch (error) {
                console.log(error)
            }
        }
    }
}
