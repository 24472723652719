<template>
    <div
        class="rounded-md overflow-hidden flex flex-col w-20 bg-gradient-to-tl"
        :class="tierClass[type]?.[tier] || itemTierClass[item]"
        v-tippy.arrow="maxTier[type] > 0 ? $t(`${item}.${tier}`) : $t(item)"
    >
        <div class="h-20 w-full flex items-center justify-center flex-shrink-0 relative">
            <img
                class="max-h-[3.8rem] max-w-[3.8rem]"
                :src="`${spaces}/items/${type}/${item}${(maxTier[type] > 0 ? `_${tier}` : '')}.png`"
            />

            <div v-if="value && total >= need" class="absolute inset-0 bg-gray-900/50 flex items-center justify-center">
                <check-icon class="w-10 text-white"/>
            </div>
        </div>

        <input
            v-if="item === 'mora'"
            type="text"
            class="bg-gray-500 text-sm text-center h-full p-2 rounded-b w-full"
            v-model="moraValue"
        >

        <input
            v-else
            type="number" min="0"
            class="text-sm text-center h-full p-2 rounded-b w-full bg-gray-500"
            :value="value"
            @input="(v) => setCustomItem(type, item, tier, v.target.value)"
        >
    </div>
</template>

<script>
export default {
    props: {
        item: String,
        type: String,
        tier: Number
    },

    computed: {
        need () {
            return this.getNeed(this.type, this.item, this.tier)
        },

        total () {
            return this.getTotal(this.type, this.item, this.tier)
        },

        value () {
            return this.getCustomItem(this.type, this.item, this.tier)
        },

        moraValue: {
            get () {
                return this.value ? Number(this.value).toLocaleString() : null
            },
            set (v) {
                this.setCustomItem(this.type, this.item, this.tier, v.replace(/\D/g, ''))
            }
        }
    }
}
</script>
