<template>
    <div>
        <div class="flex items-center justify-between">
            <div class="flex items-center">
                <button class="bg-teal-600/60 hover:bg-teal-600 transition-all hover:shadow-lg rounded-lg py-2 px-3 text-sm font-semibold shadow" @click="adding = true">
                    {{ $t('add_task') }}
                </button>
            </div>

            <button
                class="text-xs py-1 px-2 rounded shadow ml-2 flex items-center"
                :class="showAll ? 'bg-yellow-600 font-semibold' : 'bg-gray-600'"
                @click="showAll = !showAll"
                v-if="allTasks.length"
            >
                <template v-if="!showAll"><pencil-alt-icon class="w-4 mr-1"/>{{ $t('more') }}</template>
                <template v-else>{{ $t('less') }}</template>
            </button>
        </div>

        <div class="mt-2 -m-1" ref="tasks-container">
            <task
                v-for="task in allTasks"
                :key="`task-${task.id}`"
                @edit="edit"
                :task="task"
                :data-id="task.id"
                :show-all="showAll"
                v-show="showAll || !task.future"
                @contextmenu.prevent="edit(task.id)"
            />
        </div>

        <button v-if="future" class="ml-1 font-semibold text-sm" @click="showAll = true">
            +{{ future }} {{ $tc('future_task', future) }}
        </button>
    </div>

    <Dialog :draggable="false" :header="$t('add_task')" position="top" v-model:visible="adding" modal style="width: 400px;" @hide="hide">
        <form @submit.prevent="send">
            <input required autofocus :placeholder="$t('task_name')" v-model="taskName" class="bg-gray-700 rounded py-2 px-3 border border-gray-500 w-full mt-2">

            <div class="flex flex-wrap mt-3 -mx-1">
                <div class="w-full sm:w-1/2 px-1">
                    <div class="ml-1 font-semibold text-sm">{{ $t('recurrence_days') }}</div>
                    <select class="bg-gray-700 rounded w-full" v-model="recurring">
                        <option :value="i" v-for="i in 7" :key="`days-${i}`">{{ i }}</option>
                    </select>
                </div>
                <div class="w-full sm:w-1/2 px-1 mt-2 sm:mt-0">
                    <div class="ml-1 font-semibold text-sm">{{ $t('starting_in') }}</div>
                    <select class="bg-gray-700 rounded w-full" v-model="starting">
                        <option :value="0">{{ $t('today') }} ({{ $t(days[resetDay.day()]) }})</option>
                        <option :value="i" v-for="i in 6" :key="`starting-${i}`">{{ $t(days[resetDay.add(i, 'days').day()]) }}</option>
                    </select>
                </div>
            </div>

            <div class="mt-3 ml-1 font-semibold text-sm flex items-center justify-between mb-px">
                <span>{{ $t('notes_etc') }}</span>
                <span><question-mark-circle-icon class="w-5 ml-2" v-tippy="`<div class=\'text-xs\'>${$t('notes_format')}</div>`"/></span>
            </div>
            <!-- <input class="bg-gray-600 rounded w-full py-2 px-3" v-model="taskNotes"> -->
            <Textarea
                class="w-full !text-sm"
                :placeholder="$t('notes')"
                v-model="taskNotes"
                auto-resize
                rows="2"
            />

            <button class="bg-teal-500 rounded w-full mt-3 py-2 font-semibold">{{ $t(typeof editing == 'number' ? 'save' : 'add') }}</button>
        </form>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import Task from './Task'
import Textarea from 'primevue/textarea'
import Sortable from 'sortablejs'

export default {
    components: { Dialog, Task, Textarea },

    data () {
        return {
            days: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
            adding: false,
            editing: null,
            taskName: null,
            recurring: 1,
            starting: 0,
            taskNotes: null,
            showAll: false
        }
    },

    mounted () {
        const container = this.$refs['tasks-container']
        if (!container) return

        new Sortable(container, {
            animation: 200,
            chosenClass: 'bg-gray-500/100',
            handle: '.handle',
            store: {
                get: () => this.tasksOrder ? this.tasksOrder.split('|') : [],
                set: sortable => { this.tasksOrder = sortable.toArray().join('|') }
            }
        })
    },

    computed: {
        allTasks () {
            return this.tasks.map(task => ({...task, future: this.resetDay.diff(task.next, 'days') < 0}))
        },

        filteredTasks () {
            return this.allTasks.filter(task => this.showAll || !task.future)
        },

        tasksOrder: {
            get () { return this.settings.tasks_order[this.account] },
            set (value) {
                this.setSetting('tasks_order', { ...this.settings.tasks_order, [this.account]: value })
            }
        },

        future () {
            return this.tasks.length - this.filteredTasks.length
        }
    },

    watch: {
        'allTasks.length' () {
            if (!this.allTasks.length) this.showAll = false
        }
    },

    methods: {
        hide () {
            this.taskName = null
            this.recurring = 1
            this.starting = 0
            this.taskNotes = null
            this.editing = null
            this.adding = false
        },

        edit (id) {
            let task = this.tasks.find(task => task.id == id)
            if (!task) return

            this.editing = task.id
            this.taskName = task.task
            this.recurring = task.recurring
            this.taskNotes = task.notes

            let diff = this.dayjs(task.next).diff(this.resetDay, 'day')
            this.starting = diff <= 0 ? 0 : (diff)

            this.adding = true
        },

        send () {
            let tasks = this.tasks

            if (typeof this.editing == 'number') {
                let index = tasks.findIndex(task => task.id == this.editing)
                if (index < 0) return

                tasks[index] = {
                    id: this.editing,
                    task: this.taskName,
                    recurring: this.recurring,
                    notes: this.taskNotes,
                    next: this.resetDay.add(this.starting, 'days').format('YYYY-MM-DD')
                }
            } else {
                tasks.push({
                    id: (tasks.map(t => t.id).filter(id => typeof id == 'number').sort((a, b) => a < b ? 1 : -1)[0] || 0) + 1,
                    task: this.taskName,
                    recurring: this.recurring,
                    notes: this.taskNotes,
                    next: this.resetDay.add(this.starting, 'days').format('YYYY-MM-DD')
                })
            }

            this.tasks = tasks

            this.hide()
        },
    }
}
</script>
