<template>
    <div class="font-semibold text-lg mt-7">
        {{ $t('conflict_title') }}
    </div>

    <div class="bg-gray-700 rounded py-2 px-3 mt-3">
        <div class="flex items-center">
            <google-drive-icon class="w-8 mr-3"/>
            <div>
                <p class="font-semibold">
                    {{ $t('google_drive_data') }} -
                    <span v-if="driveOlder" class="text-red-400">{{ $t('older') }}</span>
                    <span v-else class="text-green-400">{{ $t('newer') }}</span>
                    - {{ remoteSize }} KB
                </p>
                <p>{{ $t('last_modified') }}: {{ remoteTime?.format('DD MMM YYYY, HH:mm:ss') }}</p>
            </div>
        </div>
        <button class="text-center w-full bg-gray-600 rounded-md p-2 mt-3 shadow font-semibold" @click="useRemote">
            {{ $t('use_google_drive_data') }}
            <span class="text-red-400" v-if="smallerDrive"><br>{{ $t('be_careful_smaller_file') }}</span>
        </button>
    </div>

    <div class="bg-gray-700 rounded py-2 px-3 mt-4">
        <div class="flex items-center">
            <desktop-computer-icon class="w-8 mr-3"/>
            <div>
                <p class="font-semibold">
                    {{ $t('local_browser_data') }} -
                    <span v-if="!driveOlder" class="text-red-400">{{ $t('older') }}</span>
                    <span v-else class="text-green-400">{{ $t('newer') }}</span>
                    - {{ localSize }} KB
                </p>
                <p>{{ $t('last_modified') }}: {{ lastUpdate.format('DD MMM YYYY, HH:mm:ss') }}</p>
            </div>
        </div>
        <button class="text-center w-full bg-gray-600 rounded-md p-2 mt-3 shadow font-semibold" @click="useLocal">
            {{ $t('use_local_browser_data') }}
            <span class="text-red-400" v-if="smallerBrowser"><br>{{ $t('be_careful_smaller_file') }}</span>
        </button>
    </div>
</template>

<script>
export default {
    emits: ['driveSave', 'driveCopy'],

    data () {
        return {
            localSize: null
        }
    },

    computed: {
        driveOlder () {
            let diff = this.remoteTime?.diff(this.lastUpdate)
            return !diff || this.remoteTime.diff(this.lastUpdate) < 0
        },

        smallerDrive () {
            return this.remoteSize / this.localSize < 0.9
        },

        smallerBrowser () {
            return this.localSize / this.remoteSize < 0.9
        }
    },

    mounted () {
        this.localSize = this.calculateSize({
            data: this.data,
            settings: this.settings,
            accounts: this.accounts,
            last_update: this.lastUpdate?.toISOString()
        })
    },

    methods: {
        useRemote () {
            this.$emit('driveCopy')
            this.isConflictModalVisible = false
        },

        useLocal () {
            this.$emit('driveSave')
            this.isConflictModalVisible = false
        }
    }
}
</script>
