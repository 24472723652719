<template>
    <div class="flex bg-gray-600 rounded-lg overflow-hidden">
        <div class="flex flex-col w-24 h-24 sm:w-12 sm:h-12 flex-shrink-0">
            <div class="w-full h-full overflow-hidden relative bg-gradient-to-tl"
                :class="darkTierClass[character.tier]"
            >
                <img :src="`${spaces}/characters/select/${character.traveler ? `traveler_${gender}` : selected}.png`" class="w-full h-full object-contain" v-if="!character.custom">
                <img :src="`${url}img/other/custom_${character.gender}.png`" class="w-full h-full" v-else>

                <img :src="`${url}img/elements/${character.element}.png`" class="absolute bottom-0 right-0 w-6 h-6" v-if="character.element">
            </div>
        </div>

        <div class="ml-2 w-full py-2 sm:py-0 leading-none flex items-center justify-around sm:justify-start flex-col sm:flex-row text-sm sm:text-base">
            <div class="sm:w-28 flex justify-center" v-if="characterGoal">
                <div class="flex items-center bg-blue-500 rounded-full py-1 px-3" :class="{ 'opacity-25': characterGoal.current.text === characterGoal.goal.text }">
                    <div class="flex items-center">
                        <level small :level="characterGoal.current"/>
                        <template v-if="characterGoal.current.text !== characterGoal.goal.text">
                            <chevron-double-right-icon class="w-4 mx-1"/>
                            <level small :level="characterGoal.goal"/>
                        </template>
                    </div>
                </div>
            </div>
            <div v-else class="hidden sm:block w-28"></div>

            <div class="sm:w-44 flex justify-center overflow-hidden lg:hidden" v-if="talentGoal">
                <div class="flex items-center bg-purple-500 rounded-full py-1 px-3 tracking-wide" :class="{ 'opacity-25': !anyTalentGoal }">
                    {{ talentGoal.normal.current }}/{{ talentGoal.skill.current }}/{{ talentGoal.burst.current }}
                    <template v-if="anyTalentGoal">
                        <chevron-double-right-icon class="w-4 mx-1"/>
                        <div class="font-semibold">{{ talentGoal.normal.goal }}/{{ talentGoal.skill.goal }}/{{ talentGoal.burst.goal }}</div>
                    </template>
                </div>
            </div>
            <div v-else class="hidden sm:block w-44 lg:hidden"></div>

            <div class="hidden lg:flex items-center justify-center">
                <div v-for="talent in ['normal', 'skill', 'burst']" :key="`talent-${talent}`" class="w-24 overflow-hidden flex justify-center">
                    <div class="flex items-center bg-purple-500 rounded-full py-1 pl-2 pr-3" :class="{ 'opacity-25': talentGoal[talent].current == talentGoal[talent].goal }" v-if="talentGoal">
                        <div class="flex items-center" v-if="true">
                            <div class="bg-purple-600 w-6 rounded-full font-semibold mr-1 text-xs uppercase tracking-tight">{{ $t(`${talent}_fl`) }}</div>

                            <div class="flex items-center">
                                <div class="font-semibold">{{ talentGoal[talent].current }}</div>
                                <template v-if="talentGoal[talent].current != talentGoal[talent].goal">
                                    <chevron-double-right-icon class="w-4 mx-1"/>
                                    <div class="font-semibold">{{ talentGoal[talent].goal }}</div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sm:w-28 flex justify-center" v-if="weaponGoal">
                <div class="flex items-center bg-pink-500 rounded-full py-1 px-3" :class="{ 'opacity-25': weaponGoal.current.text === weaponGoal.goal.text }">
                    <level class="w-8" small :level="weaponGoal.current"/>
                    <template v-if="weaponGoal.current.text !== weaponGoal.goal.text">
                        <chevron-double-right-icon class="w-4 mx-1"/>
                        <level class="w-8" small :level="weaponGoal.goal"/>
                    </template>
                </div>
            </div>
            <div v-else class="hidden sm:block w-28"></div>

            <div class="hidden sm:flex items-center ml-auto mr-1" v-if="artifactGoal">
                <div class="bg-orange-500 px-2 py-1 rounded-full font-semibold text-xs uppercase tracking-tight" :class="{ 'opacity-25': artifactGoalIsDone }">
                    <img :src="`${url}img/general/artifact.png`" class="h-6"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Level from './Level'

export default {
    components: { Level },

    props: {
        selected: String
    },

    computed: {
        character () {
            return this.customs[this.selected] || {
                name: this.$t(this.selected),
                ...this.characters[this.selected]
            }
        },

        characterGoal () {
            return this.goals.find(g => g.character === this.selected && g.type === 'character')
        },

        talentGoal () {
            return this.goals.find(g => g.character === this.selected && g.type === 'talent')
        },

        anyTalentGoal () {
            return this.talentGoal && (
                this.talentGoal.normal.goal > this.talentGoal.normal.current ||
                this.talentGoal.skill.goal > this.talentGoal.skill.current ||
                this.talentGoal.burst.goal > this.talentGoal.burst.current
            )
        },

        weaponGoal () {
            return this.goals.find(g => g.character === this.selected && g.type === 'weapon')
        },

        artifactGoal () {
            return this.goals.find(g => g.character === this.selected && g.type === 'artifact')
        },

        artifactGoalIsDone () {
            if (!this.artifactGoal) return true
            let goal = this.artifactGoal
            if ((goal.sands && !goal.done?.sands) || (goal.goblet && !goal.done?.goblet) || (goal.circlet && !goal.done?.circlet)) return false
            if (goal.artifacts && goal.artifacts.length && goal.artifacts.filter(a => !goal.done?.[a]).length) return false
            return true
        }
    }
}
</script>
