export default {
	// main
	xp: { type: 'leyline', when: ['any'], where: ['xp_leyline'] },
	mora: { type: 'leyline', when: ['any'], where: ['mora_leyline'] },
	wep_xp: { when: ['any'], where: ['blacksmith'] },

	// weekly boss
	tusk_of_monoceros_caeli: { type: 'weekly_boss', when: ['any'], where: ['golden_house'] },
	shard_of_a_foul_legacy: { type: 'weekly_boss', when: ['any'], where: ['golden_house'] },
	shadow_of_the_warrior: { type: 'weekly_boss', when: ['any'], where: ['golden_house'] },
	dvalins_claw: { type: 'weekly_boss', when: ['any'], where: ['stormterror'] },
	dvalins_plume: { type: 'weekly_boss', when: ['any'], where: ['stormterror'] },
	dvalins_sigh: { type: 'weekly_boss', when: ['any'], where: ['stormterror'] },
	ring_of_boreas: { type: 'weekly_boss', when: ['any'], where: ['wolf_of_the_north'] },
	spirit_locket_of_boreas: { type: 'weekly_boss', when: ['any'], where: ['wolf_of_the_north'] },
	tail_of_boreas: { type: 'weekly_boss', when: ['any'], where: ['wolf_of_the_north'] },
	dragon_lords_crown: { type: 'weekly_boss', when: ['any'], where: ['azhdaha'] },
	bloodjade_branch: { type: 'weekly_boss', when: ['any'], where: ['azhdaha'] },
	gilded_scale: { type: 'weekly_boss', when: ['any'], where: ['azhdaha'] },
	signora_flower: { type: 'weekly_boss', when: ['any'], where: ['la_signora'] },
	signora_wings: { type: 'weekly_boss', when: ['any'], where: ['la_signora'] },
	signora_heart: { type: 'weekly_boss', when: ['any'], where: ['la_signora'] },
	mudra_of_the_malefic_general: { type: 'weekly_boss', when: ['any'], where: ['narukami_boss'] },
	tears_of_the_calamitous_god: { type: 'weekly_boss', when: ['any'], where: ['narukami_boss'] },
	the_meaning_of_aeons: { type: 'weekly_boss', when: ['any'], where: ['narukami_boss'] },
	puppet_strings: { type: 'weekly_boss', when: ['any'], where: ['shouki_no_kami'] },
	mirror_of_mushin: { type: 'weekly_boss', when: ['any'], where: ['shouki_no_kami'] },
	dakas_bell: { type: 'weekly_boss', when: ['any'], where: ['shouki_no_kami'] },
	worldspan_fern: { type: 'weekly_boss', when: ['any'], where: ['guardian_of_apeps_oasis'] },
	primordial_greenbloom: { type: 'weekly_boss', when: ['any'], where: ['guardian_of_apeps_oasis'] },
	everamber: { type: 'weekly_boss', when: ['any'], where: ['guardian_of_apeps_oasis'] },
	lightless_silk_string: { type: 'weekly_boss', when: ['any'], where: ['all_devouring_narwhal'] },
	lightless_eye_of_the_maelstrom: { type: 'weekly_boss', when: ['any'], where: ['all_devouring_narwhal'] },
	lightless_mass: { type: 'weekly_boss', when: ['any'], where: ['all_devouring_narwhal'] },
	fading_candle: { type: 'weekly_boss', when: ['any'], where: ['the_knave'] },
	silken_feather: { type: 'weekly_boss', when: ['any'], where: ['the_knave'] },
	denial_and_judgment: { type: 'weekly_boss', when: ['any'], where: ['the_knave'] },

	// gems
	agnidus_agate: { when: ['any'], where: ['bosses_or_alchemy'] }, // wolf_of_the_north, pyro_regisvine, pyro_hypostasis
	brilliant_diamond: { when: ['any'], where: ['adventure_rank'] },
	prithiva_topaz: { when: ['any'], where: ['bosses_or_alchemy'] }, // wolf_of_the_north, geo_hypostasis
	shivada_jade: { when: ['any'], where: ['bosses_or_alchemy'] }, // wolf_of_the_north, golden_house, cryo_regisvine, cryo_hypostasis, maguu_kenki
	vajrada_amethyst: { when: ['any'], where: ['bosses_or_alchemy'] }, // golden_house, stormterror, electro_hypostasis
	varunada_lazurite: { when: ['any'], where: ['bosses_or_alchemy'] }, // golden_house, stormterror, oceanid
	vayuda_turqoise: { when: ['any'], where: ['bosses_or_alchemy'] }, // stormterror, anemo_hypostasis', maguu_kenki
	nagadus_emerald: { when: ['any'], where: ['bosses_or_alchemy'] },

	// boss items
	basalt_pillar: { type: 'boss', when: ['any'], where: ['geo_hypostasis'] },
	cleansing_heart: { type: 'boss', when: ['any'], where: ['oceanid'] },
	everflame_seed: { type: 'boss', when: ['any'], where: ['pyro_regisvine'] },
	hoarfrost_core: { type: 'boss', when: ['any'], where: ['cryo_regisvine'] },
	hurricane_seed: { type: 'boss', when: ['any'], where: ['anemo_hypostasis'] },
	lightning_prism: { type: 'boss', when: ['any'], where: ['electro_hypostasis'] },
	juvenile_jade: { type: 'boss', when: ['any'], where: ['primo_geovishap'] },
	crystalline_bloom: { type: 'boss', when: ['any'], where: ['cryo_hypostasis'] },
	maguu_kishin: { type: 'boss', when: ['any'], where: ['maguu_kenki'] },
	perpetual_heart: { type: 'boss', when: ['any'], where: ['perpetual_mechanical_array'] },
	smoldering_pearl: { type: 'boss', when: ['any'], where: ['pyro_hypostasis'] },
	dew_of_repudiation: { type: 'boss', when: ['any'], where: ['hydro_hypostasis'] },
	storm_beads: { type: 'boss', when: ['any'], where: ['electro_oceanid'] },
	riftborn_regalia: { type: 'boss', when: ['any'], where: ['golden_wolflord'] },
	dragonheirs_false_fin: { type: 'boss', when: ['any'], where: ['bathysmal_vishap'] },
	runic_fang: { type: 'boss', when: ['any'], where: ['ruin_serpent'] },
	majestic_hooked_beak: { type: 'boss', when: ['any'], where: ['jadeplume_terrorshroom'] },
	thunderclap_fruitcore: { type: 'boss', when: ['any'], where: ['electro_regisvine'] },
	light_guiding_tetrahedron: { type: 'boss', when: ['any'], where: ['semi_perpetual_control_matrix'] },
	perpetual_caliber: { type: 'boss', when: ['any'], where: ['aeonblight_drake'] },
	quelled_creeper: { type: 'boss', when: ['any'], where: ['dendro_hypostasis'] },
	pseudo_stamens: { type: 'boss', when: ['any'], where: ['setekh_wenut'] },
	evergloom_ring: { type: 'boss', when: ['any'], where: ['iniquitous_baptist'] },
	clockwork_component_coppelia: { type: 'boss', when: ['any'], where: ['dirge_of_coppelia'] },
	clockwork_component_coppelius: { type: 'boss', when: ['any'], where: ['nemesis_of_coppelius'] },
	emperors_resolution: { type: 'boss', when: ['any'], where: ['emperor_of_fire_and_iron'] },
	fontemer_unihorn: { type: 'boss', when: ['any'], where: ['millennial_pearl_seahorse'] },
	tourbillon_device: { type: 'boss', when: ['any'], where: ['experimental_field_generator'] },
	water_that_failed: { type: 'boss', when: ['any'], where: ['hydro_tulpa'] },
	cloudseam_scale: { type: 'boss', when: ['any'], where: ['solitary_suanni'] },
	golden_melody: { type: 'boss', when: ['any'], where: ['legatus_golem'] },
	binding_blessing: { type: 'boss', when: ['any'], where: ['qucusaur_tyrant'] },
	flamegranate: { type: 'boss', when: ['any'], where: ['yumkasaur'] },

	// local specialties
	calla_lily: { when: ['any'], where: ['mondstadt'] },
	cecilia: { when: ['any'], where: ['mondstadt'] },
	cor_lapis: { when: ['any'], where: ['liyue'] },
	dandelion_seed: { when: ['any'], where: ['mondstadt'] },
	glaze_lilly: { when: ['any'], where: ['liyue'] },
	jueyun_chili: { when: ['any'], where: ['liyue'] },
	noctilucous_jade: { when: ['any'], where: ['liyue'] },
	philanemo_mushroom: { when: ['any'], where: ['mondstadt'] },
	silk_flower: { when: ['any'], where: ['liyue'] },
	small_lamp_grass: { when: ['any'], where: ['mondstadt'] },
	starconch: { when: ['any'], where: ['liyue'] },
	valberry: { when: ['any'], where: ['mondstadt'] },
	violetgrass: { when: ['any'], where: ['liyue'] },
	windwheel_aster: { when: ['any'], where: ['mondstadt'] },
	wolfhook: { when: ['any'], where: ['mondstadt'] },
	qingxin: { when: ['any'], where: ['liyue'] },
	sea_ganoderma: { when: ['any'], where: ['inazuma'] },
	crystal_marrow: { when: ['any'], where: ['inazuma'] },
	sakura_bloom: { when: ['any'], where: ['inazuma'] },
	naku_weed: { when: ['any'], where: ['inazuma'] },
	dendrobium: { when: ['any'], where: ['inazuma'] },
	sango_pearl: { when: ['any'], where: ['watatsumi'] },
	tenkumo_fruit: { when: ['any'], where: ['seirai'] },
	onikabuto: { when: ['any'], where: ['inazuma'] },
	fluorescent_fungus: { when: ['any'], where: ['tsurumi'] },
	rukkhashava: { when: ['any'], where: ['sumeru'] },
	padisarah: { when: ['any'], where: ['sumeru'] },
	nilotpala: { when: ['any'], where: ['sumeru'] },
	kalpalata: { when: ['any'], where: ['sumeru'] },
	redcrest: { when: ['any'], where: ['sumeru'] },
	scarab: { when: ['any'], where: ['sumeru'] },
	sand_grease_pupa: { when: ['any'], where: ['sumeru'] },
	beryl_conch: { when: ['any'], where: ['fontaine'] },
	mourning_flower: { when: ['any'], where: ['sumeru'] },
	trishiraite: { when: ['any'], where: ['sumeru'] },
	romaritime_flower: { when: ['any'], where: ['fontaine'] },
	lumidouce_bell: { when: ['any'], where: ['fontaine'] },
	rainbow_rose: { when: ['any'], where: ['fontaine'] },
	lumitoile: { when: ['any'], where: ['fontaine'] },
	subdetection_unit: { when: ['any'], where: ['fontaine'] },
	lakelight_lily: { when: ['any'], where: ['fontaine'] },
	first_dewdrop: { when: ['any'], where: ['fontaine'] },
	clearwater_jade: { when: ['any'], where: ['chenyu_vale'] },
	sprayfeather: { when: ['any'], where: ['toyac_springs'] },
	chrysanthemum: { when: ['any'], where: ['huitztly_hill'] },
	quenepa: { when: ['any'], where: ['coatepec_mountain'] },
	saurian_succulent: { when: ['any'], where: ['sulfurous_veins'] },

	// common
	arrowhead: { when: ['any'], where: ['ranged_hilichurl'] },
	f_insignia: { when: ['any'], where: ['skirmisher'] },
	th_insignia: { when: ['any'], where: ['treasure_hoarder'] },
	mask: { when: ['any'], where: ['hilichurl'] },
	nectar: { when: ['any'], where: ['whopperflower'] },
	scroll: { when: ['any'], where: ['samachurl'] },
	slime: { when: ['any'], where: ['slimes'] },
	handguard: { when: ['any'], where: ['nobushi'] },
	spectral_husk: { when: ['any'], where: ['specters'] },
	spores: { when: ['any'], where: ['fungi'] },
	red_satin: { when: ['any'], where: ['eremites'] },
	transoceanic: { when: ['any'], where: ['fontemer_aberrants'] },
	gear: { when: ['any'], where: ['clockwork_meka'] },
	tainted_water: { when: ['any'], where: ['tainted_hydro_phantasms'] },
	core: { when: ['any'], where: ['breacher_primuses'] },
	n_fang: { when: ['any'], where: ['natlan_saurians'] },
	whistle: { when: ['any'], where: ['sauroform'] },
	will: { when: ['any'], where: ['wayob'] },
	ignited: { when: ['any'], where: ['avatars_of_lava'] },
	secret_source: { when: ['any'], where: ['hunter_seeker'] },

	// weapon common / common_rare / common rare
	bone_shard: { when: ['any'], where: ['geovishap_hatchling'] },
	chaos: { when: ['any'], where: ['ruin_guards'] },
	horn: { when: ['any'], where: ['mitachurl'] },
	ley_line: { when: ['any'], where: ['abyss_mage'] },
	mist: { when: ['any'], where: ['electro_cicin_mage'] },
	sacrificial_knife: { when: ['any'], where: ['agent'] },
	chaos_g: { when: ['any'], where: ['ruin_sentinel'] },
	prism: { when: ['any'], where: ['mirror_maiden'] },
	statuette: { when: ['any'], where: ['black_serpents'] },
	claw: { when: ['any'], where: ['rifthounds'] },
	fungal_nucleus: { when: ['any'], where: ['fungi'] },
	chaos_s: { when: ['any'], where: ['ruin_drake'] },
	d_prism: { when: ['any'], where: ['primal_construct'] },
	shell: { when: ['any'], where: ['blessbone'] },
    flower: { when: ['any'], where: ['hilichurl_rogue'] },
    pocket_watch: { when: ['any'], where: ['fatui_operative'] },
    fin: { when: ['any'], where: ['xuanwen_beast'] },
    hilt: { when: ['any'], where: ['praetorian_golem'] },

	// weapon
	aerosiderite: { type: 'domain', when: ['wed', 'sat', 'sun'], where: ['hidden_palace_of_lianshan_formula'] },
	boreal_wolf: { type: 'domain', when: ['tue', 'fri', 'sun'], where: ['cecilia_garden'] },
	dandelion_gladiator: { type: 'domain', when: ['wed', 'sat', 'sun'], where: ['cecilia_garden'] },
	decarabian: { type: 'domain', when: ['mon', 'thu', 'sun'], where: ['cecilia_garden'] },
	guyun: { type: 'domain', when: ['mon', 'thu', 'sun'], where: ['hidden_palace_of_lianshan_formula'] },
	mist_veiled_elixer: { type: 'domain', when: ['tue', 'fri', 'sun'], where: ['hidden_palace_of_lianshan_formula'] },
	sea_branch: { type: 'domain', when: ['mon', 'thu', 'sun'], where: ['court_of_flowing_sand'] },
	narukami: { type: 'domain', when: ['tue', 'fri', 'sun'], where: ['court_of_flowing_sand'] },
	mask_w: { type: 'domain', when: ['wed', 'sat', 'sun'], where: ['court_of_flowing_sand'] },
    talisman: { type: 'domain', when: ['mon', 'thu', 'sun'], where: ['tower_of_abject_pride'] },
	oasis_gardens: { type: 'domain', when: ['tue', 'fri', 'sun'], where: ['tower_of_abject_pride'] },
	scorching_might: { type: 'domain', when: ['wed', 'sat', 'sun'], where: ['tower_of_abject_pride'] },
    ancient_chord: { type: 'domain', when: ['mon', 'thu', 'sun'], where: ['echoes_of_the_deep_tides'] },
	dewdrop: { type: 'domain', when: ['tue', 'fri', 'sun'], where: ['echoes_of_the_deep_tides'] },
	pristine_sea: { type: 'domain', when: ['wed', 'sat', 'sun'], where: ['echoes_of_the_deep_tides'] },
    bs_heart: { type: 'domain', when: ['mon', 'thu', 'sun'], where: ['ancient_watchtower'] },
	delirious: { type: 'domain', when: ['tue', 'fri', 'sun'], where: ['ancient_watchtower'] },
	night_wind: { type: 'domain', when: ['wed', 'sat', 'sun'], where: ['ancient_watchtower'] },

	// talents
	crown: { when: ['any'], where: ['event'] },
	ballad: { type: 'domain', when: ['wed', 'sat', 'sun'], where: ['forsaken_rift'] },
	diligence: { type: 'domain', when: ['tue', 'fri', 'sun'], where: ['taishan_mansion'] },
	freedom: { type: 'domain', when: ['mon', 'thu', 'sun'], where: ['forsaken_rift'] },
	gold: { type: 'domain', when: ['wed', 'sat', 'sun'], where: ['taishan_mansion'] },
	prosperity: { type: 'domain', when: ['mon', 'thu', 'sun'], where: ['taishan_mansion'] },
	resistance: { type: 'domain', when: ['tue', 'fri', 'sun'], where: ['forsaken_rift'] },
	transience: { type: 'domain', when: ['mon', 'thu', 'sun'], where: ['violet_court'] },
	elegance: { type: 'domain', when: ['tue', 'fri', 'sun'], where: ['violet_court'] },
	light: { type: 'domain', when: ['wed', 'sat', 'sun'], where: ['violet_court'] },
    admonition: { type: 'domain', when: ['mon', 'thu', 'sun'], where: ['steeple_of_ignorance'] },
	ingenuity: { type: 'domain', when: ['tue', 'fri', 'sun'], where: ['steeple_of_ignorance'] },
	praxis: { type: 'domain', when: ['wed', 'sat', 'sun'], where: ['steeple_of_ignorance'] },
    fairness: { type: 'domain', when: ['mon', 'thu', 'sun'], where: ['pale_forgotten_glory'] },
	justice: { type: 'domain', when: ['tue', 'fri', 'sun'], where: ['pale_forgotten_glory'] },
	order: { type: 'domain', when: ['wed', 'sat', 'sun'], where: ['pale_forgotten_glory'] },
	contention: { type: 'domain', when: ['mon', 'thu', 'sun'], where: ['blazing_ruins'] },
	kindling: { type: 'domain', when: ['tue', 'fri', 'sun'], where: ['blazing_ruins'] },
	conflict: { type: 'domain', when: ['wed', 'sat', 'sun'], where: ['blazing_ruins'] },

	// artifacts
	adventurer: { type: 'domain', when: ['any'], where: ['midsummer_courtyard'] },
	archaic_petra: { type: 'domain', when: ['any'], where: ['domain_of_guyun'] },
	berserker: { type: 'bosses', when: ['any'], where: ['bosses'] },
	bloodstained_chivalry: { type: 'domain', when: ['any'], where: ['clear_pool_and_mountain_cavern'] },
	brave_heart: { type: 'domain', when: ['any'], where: ['domain_of_guyun'] },
	crimson_witch_of_flames: { type: 'domain', when: ['any'], where: ['hidden_palace_of_zhou_formula'] },
	defenders_will: { type: 'domain', when: ['any'], where: ['hidden_palace_of_zhou_formula'] },
	gambler: { type: 'domain', when: ['any'], where: ['clear_pool_and_mountain_cavern'] },
	gladiators_finale: { type: 'bosses', when: ['any'], where: ['bosses'] },
	instructor: { type: 'bosses', when: ['any'], where: ['bosses'] },
	lavawalker: { type: 'domain', when: ['any'], where: ['hidden_palace_of_zhou_formula'] },
	lucky_dog: { type: 'domain', when: ['any'], where: ['domain_of_guyun'] },
	maiden_beloved: { type: 'domain', when: ['any'], where: ['valley_of_remembrance'] },
	martial_artist: { type: 'domain', when: ['any'], where: ['hidden_palace_of_zhou_formula'] },
	noblesse_oblige: { type: 'domain', when: ['any'], where: ['clear_pool_and_mountain_cavern'] },
	prayers_for_destiny: { type: 'boss', when: ['any'], where: ['oceanid'] },
	prayers_for_illumination: { type: 'boss', when: ['any'], where: ['pyro_regisvine', 'pyro_hypostasis'] },
	prayers_for_wisdom: { type: 'boss', when: ['any'], where: ['electro_hypostasis'] },
	prayers_to_springtime: { type: 'boss', when: ['any'], where: ['cryo_regisvine', 'cryo_hypostasis', 'perpetual_mechanical_array'] },
	resolution_of_sojourner: { type: 'domain', when: ['any'], where: ['midsummer_courtyard'] },
	retracing_bolide: { type: 'domain', when: ['any'], where: ['domain_of_guyun'] },
	scholar: { type: 'domain', when: ['any'], where: ['clear_pool_and_mountain_cavern'] },
	the_exile: { type: 'bosses', when: ['any'], where: ['bosses'] },
	thundering_fury: { type: 'domain', when: ['any'], where: ['midsummer_courtyard'] },
	thundersoother: { type: 'domain', when: ['any'], where: ['midsummer_courtyard'] },
	tiny_miracle: { type: 'domain', when: ['any'], where: ['valley_of_remembrance'] },
	traveling_doctor: { type: 'domain', when: ['any'], where: ['valley_of_remembrance'] },
	viridescent_venerer: { type: 'domain', when: ['any'], where: ['valley_of_remembrance'] },
	wanderers_troupe: { type: 'bosses', when: ['any'], where: ['bosses'] },
	blizzard_strayer: { type: 'domain', when: ['any'], where: ['peak_of_vindagnyr'] },
	heart_of_depth: { type: 'domain', when: ['any'], where: ['peak_of_vindagnyr'] },
	tenacity_of_the_millelith: { type: 'domain', when: ['any'], where: ['ridge_watch'] },
	pale_flame: { type: 'domain', when: ['any'], where: ['ridge_watch'] },
	reminiscence_of_shime: { type: 'domain', when: ['any'], where: ['momiji_dyed_court'] },
	seal_of_insulation: { type: 'domain', when: ['any'], where: ['momiji_dyed_court'] },
	husk_of_opulent_dreams: { type: 'domain', when: ['any'], where: ['slumbering_court'] },
	'ocean-hued_clam': { type: 'domain', when: ['any'], where: ['slumbering_court'] },
	echoes_of_an_offering: { type: 'domain', when: ['any'], where: ['the_lost_valley'] },
	vermillion_hereafter: { type: 'domain', when: ['any'], where: ['the_lost_valley'] },
	deepwood_memories: { type: 'domain', when: ['any'], where: ['spire_of_solitary_enlightenment'] },
	gilded_dreams: { type: 'domain', when: ['any'], where: ['spire_of_solitary_enlightenment'] },
	desert_pavilion_chronicle: { type: 'domain', when: ['any'], where: ['city_of_gold'] },
	flower_of_paradise_lost: { type: 'domain', when: ['any'], where: ['city_of_gold'] },
	vourukashas_glow: { type: 'domain', when: ['any'], where: ['molten_iron_fortress'] },
	nymphs_dream: { type: 'domain', when: ['any'], where: ['molten_iron_fortress'] },
	marechaussee_hunter: { type: 'domain', when: ['any'], where: ['denouement_of_sin'] },
	golden_troupe: { type: 'domain', when: ['any'], where: ['denouement_of_sin'] },
	nighttime_whispers: { type: 'domain', when: ['any'], where: ['waterfall_wen'] },
	song_of_days_past: { type: 'domain', when: ['any'], where: ['waterfall_wen'] },
	fragment_of_harmonic_whimsy: { type: 'domain', when: ['any'], where: ['faded_theater'] },
	unfinished_reverie: { type: 'domain', when: ['any'], where: ['faded_theater'] },
	obsidian_codex: { type: 'domain', when: ['any'], where: ['sanctum_of_rainbow_spirits'] },
	scroll_of_the_hero: { type: 'domain', when: ['any'], where: ['sanctum_of_rainbow_spirits'] },
}
