<template>
    <div class="h-full relative">
        <div class="flex w-24 sm:w-28 flex-col rounded-lg relative shadow-md overflow-hidden h-full">
            <div class="w-24 sm:w-28 h-24 sm:h-28 overflow-hidden relative bg-gradient-to-br flex-shrink-0 flex items-center justify-center"
                :class="darkTierClass[weapon.tier]"
            >
                <img :src="`${spaces}/weapons/${selected}.png`" class="w-full h-full object-contain" v-if="!weapon.custom">
                <img :src="`${url}img/weapons/__${weapon.type}-billet.png`" class="w-16 sm:w-20" v-else-if="weapon.type">
                <img :src="`${url}img/other/question-mark.svg`" class="w-16 sm:w-20" v-else>

                <div
                    class="m-1 absolute bottom-0 right-0 w-8 h-8 rounded-full overflow-hidden"
                    :class="character.tier === 5 ? 'bg-legendary-550' : 'bg-epic-550'"
                    v-if="character"
                    v-tippy.arrow="character.name || (character.traveler ? `${$t(goal.character)} (${$t(character.element)})` : $t(goal.character))"
                >
                    <img :src="`${spaces}/characters/select/${character.traveler ? `traveler_${gender}` : goal.character}.png`" class="w-full h-full" v-if="!character.custom"/>
                    <img :src="`${url}img/other/custom_${character.gender}.png`" class="w-full h-full" v-else/>
                </div>
            </div>
            <div class="uppercase font-semibold text-xs px-1 py-1 tracking-tight leading-tight h-full flex items-center justify-center" :class="weapon.tier === 4 ? 'bg-epic-700' : (weapon.tier === 5 ? 'bg-legendary-700' : 'bg-rare-700')">
                {{ weapon.name }}
            </div>
        </div>

        <div class="absolute top-0 right-0 -m-1 text-xs leading-none flex flex-col items-end" v-if="goal">
            <div v-tippy.arrow.right.html="`${goal.id}-weapon-tippy`" class="bg-pink-500 rounded-full mb-px py-1 px-2" :class="{ 'opacity-25': goal.current.text === goal.goal.text }">
                <level small :level="goal.current"/>
            </div>

            <div :id="`${goal.id}-weapon-tippy`" class="flex items-center">
                <level class="w-8" small :level="goal.current"/>
                <template v-if="goal.current.text !== goal.goal.text">
                    <chevron-double-right-icon class="w-4 mx-1"/>
                    <level class="w-8" small :level="goal.goal"/>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Level from './Level'

export default {
    components: { Level },

    props: {
        selected: String,
        weapon: Object
    },

    computed: {
        character () {
            let key = this.goal?.character
            return key && this.characters[key] || this.customs[key]
        },

        goal () {
            return this.weapon.goal
        }
    }
}
</script>
