<template>
    <div
        class="rounded py-4 px-2 shadow bg-gradient-to-br from-gray-700 to-gray-800 focus:outline-none flex flex-col items-center"
        :class="{ 'opacity-50': done == total }"
        @click="navigate"
        v-if="filteredAchievements.length && (search.length || version !== 'all' || !hideCompleted || done !== total)"
    >
        <img :src="`${url}img/achievements/${id}.png`" class="h-24"/>
        <p class="text-center leading-tight font-semibold mt-2 mb-3" :class="textSizeClass">{{ category.n }}</p>

        <div class="flex flex-col items-center justify-center mt-auto w-full">
            <div class="relative w-full h-2 rounded-full bg-gray-600/80 overflow-hidden">
                <div class="absolute left-0 bg-purple-500 h-2" :style="`width:${percent}%`"></div>
            </div>
            <div class="text-sm font-semibold mt-px opacity-80">
                {{ percent }}% ({{ done }}/{{ total }})
            </div>
        </div>
    </div>
</template>

<script>
const expM = /\{M#(.+?)?\}\{F#(?:.+?)?\}|\{F#(?:.+?)?\}\{M#(.+?)?\}/g
const expF = /\{M#(?:.+?)?\}\{F#(.+?)?\}|\{F#(.+?)?\}\{M#(?:.+?)?\}/g

export default {
    props: {
        id: Number,
        category: Object,
        hideCompleted: Boolean,
        search: String,
        version: String,
    },

    computed: {
        textSizeClass () {
            let len = this.category.n.length

            if (len <= 26) return 'text-xl'
            if (len <= 32) return 'text-lg'
            if (len <= 40) return 'text-base'
            return 'text-sm'
        },

        categoryAchievements () {
            return this.category.a.flatMap(a => a)
        },

        filteredAchievements () {
            return this.categoryAchievements.map(a => {
                let name = a.n.replace(this.gender == 'male' ? expM : expF, (_, b, c) => b || c || '').replace('#','')
                let desc = a.d.replace(this.gender == 'male' ? expM : expF, (_, b, c) => b || c || '').replace('#','')
                let altName = a.n.replace(this.gender == 'male' ? expF : expM, (_, b, c) => b || c || '').replace('#','')
                let altDesc = a.d.replace(this.gender == 'male' ? expF : expM, (_, b, c) => b || c || '').replace('#','')

                return {
                    ...a,
                    n: name,
                    d: desc,
                    s: `${name} ${altName} ${desc} ${altDesc}`.toLowerCase()
                }
            }).filter(a => {
                if (!this.search.length && this.version == 'all') return true

                return (
                    !this.search ||
                    a.s.indexOf(this.search) > -1 ||
                    a.c?.toLowerCase().indexOf(this.search) > -1 ||
                    a.q?.map(q => q.join(' ')).join(' ').toLowerCase().indexOf(this.search) > -1 ||
                    this.achievements[a.id]?.notes?.toLowerCase().indexOf(this.search) > -1
                ) &&
                (this.version == 'all' || a.v == this.version)
            })
        },

        total () {
            return this.categoryAchievements.length
        },

        done () {
            return this.categoryAchievements.filter(a => this.achievements[a.id]?.done).length
        },

        percent () {
            return Math.round(this.done * 100 / this.total)
        }
    }
}
</script>
