<template>
    <div class="mt-2 flex items-center justify-center leading-none">
        <button @click="e => $refs.characterCurrent.toggle(e)" class="bg-gray-500 rounded py-3 px-2 sm:w-32 flex items-center justify-center" :class="{ 'font-semibold': current }">
            <template v-if="current">
                {{ current.level }}
                <img :src="`${url}img/general/ascension.png`" class="w-4 ml-2" :class="{ 'opacity-25': ascended[current.level] !== current.asc }"/>
            </template>
            <template v-else>{{ $t('current_level') }}</template>
        </button>
        <template v-if="!current || current.level < 90">
            <chevron-double-right-icon class="w-5 mx-2"/>
            <button @click="e => $refs.characterGoal.toggle(e)" class="bg-gray-500 rounded py-3 px-2 sm:w-32 flex items-center justify-center" :class="{ 'font-semibold': goal }">
                <template v-if="goal">
                    {{ goal.level }}
                    <img :src="`${url}img/general/ascension.png`" class="w-4 ml-2" :class="{ 'opacity-25': ascended[goal.level] !== goal.asc }"/>
                </template>
                <template v-else>{{ $t('goal_level') }}</template>
            </button>
        </template>

        <OverlayPanel ref="characterCurrent" appendTo="body" style="width: 235px">
            <div class="-m-1 flex items-center justify-center flex-wrap">
                <button v-for="(option, key) in levelOptions" :key="`current-${key}`" @click="selectedCharacterCurrent(option)" class="m-1">
                    <level :level="option" :class="current && current.level === option.level && current.asc === option.asc ? 'bg-blue-500' : 'bg-gray-500'"/>
                </button>
            </div>
        </OverlayPanel>
        <OverlayPanel ref="characterGoal" appendTo="body" style="width: 235px">
            <div class="-m-1 flex items-center justify-center flex-wrap">
                <button v-for="(option, key) in levelOptions.filter(o => !current || o.level > current.level || o.asc > current.asc || (current.level === o.level && current.asc === o.asc))" :key="`current-${key}`"
                    class="m-1 rounded-full w-10 h-10 leading-none text-smm font-semibold flex items-center justify-center flex-col"
                    :class="goal && goal.level === option.level && goal.asc === option.asc ? 'bg-blue-500' : 'bg-gray-500'"
                    @click="selectedCharacterGoal(option)"
                >
                    {{ option.level }}
                    <img :src="`${url}img/general/ascension.png`" class="w-4" :class="{ 'opacity-25': ascended[option.level] !== option.asc }"/>
                </button>
            </div>
        </OverlayPanel>
    </div>

    <div class="flex items-center justify-center mt-2" v-if="fates">
        <img :src="`${spaces}/items/special/acquaint_fate.png`" class="w-10 p-px" v-for="i in fates" :key="`fate-${i}`"/>
    </div>

    <individual-costs
        :costs="Object.values(characterCosts(this.characterGoal))"
        :subject="selected"
        @done="done"
        v-if="characterGoal && JSON.stringify(current) !== JSON.stringify(goal)"
        :is-inactive="inactive[selected]"
    />
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel'
import IndividualCosts from './IndividualCosts'
import Level from './Level'

export default {
    components: { OverlayPanel, IndividualCosts, Level },

    props: {
        selected: String
    },

    data () {
        return {
            ascended: {
                20: 1,
                40: 2,
                50: 3,
                60: 4,
                70: 5,
                80: 6
            },
            levelOptions: [
                { level: 1, asc: 0, text: '1' },
                { level: 20, asc: 0, text: '20' }, { level: 20, asc: 1, text: '20 A' },
                { level: 40, asc: 1, text: '40' }, { level: 40, asc: 2, text: '40 A' },
                { level: 50, asc: 2, text: '50' }, { level: 50, asc: 3, text: '50 A' },
                { level: 60, asc: 3, text: '60' }, { level: 60, asc: 4, text: '60 A' },
                { level: 70, asc: 4, text: '70' }, { level: 70, asc: 5, text: '70 A' },
                { level: 80, asc: 5, text: '80' }, { level: 80, asc: 6, text: '80 A' },
                { level: 90, asc: 6, text: '90' },
            ],
            current: null,
            goal: null
        }
    },

    computed: {
        characterGoal () {
            return this.goals.find(g => g.character === this.selected && g.type === 'character')
        },

        fates () {
            if (!this.goal || !this.current || this.goal.asc < 1 || this.current.asc >= 5) return 0
            if (this.current.asc < 1) return this.goal.asc < 3 ? 1 : (this.goal.asc < 5 ? 2 : 3)
            if (this.current.asc < 3) return this.goal.asc < 3 ? 0 : (this.goal.asc < 5 ? 1 : 2)
            return this.goal.asc < 5 ? 0 : 1
        }
    },

    mounted () {
        if (this.characterGoal) {
            this.current = this.characterGoal.current
            this.goal = this.characterGoal.goal
        }
    },

    watch: {
        characterGoal: {
            handler () {
                if (this.characterGoal) {
                    this.current = this.characterGoal.current
                    this.goal = this.characterGoal.goal
                }
            }
        }
    },

    methods: {
        selectedCharacterCurrent (option) {
            this.$refs.characterCurrent.hide()
            this.current = option
            if (!this.goal) this.goal = option
            if (this.goal && (this.goal.level < option.level || this.goal.asc < option.asc)) this.goal = option
            this.addOrUpdateCharacter()
        },

        selectedCharacterGoal (option) {
            this.$refs.characterGoal.hide()
            this.goal = option
            if (!this.current) this.current = this.levelOptions[0]
            this.addOrUpdateCharacter()
        },

        addOrUpdateCharacter () {
            this.addGoal({
                type: 'character',
                character: this.selected,
                cons: this.characterGoal?.cons || 0,
                current: this.current,
                goal: this.goal
            })
        },

        done () {
            this.current = this.goal
            this.addOrUpdateCharacter()
        }
    }
}
</script>
