<template>
    <!-- <div class="bg-orange-700 rounded py-3 px-2 mt-4 leading-none tracking-tight font-semibold flex items-center" v-if="weapon && isFuture(weapon.release)">
        <exclamation-circle-icon class="w-6 mr-2 flex-shrink-0"/>
        {{ $t('unreleased_weapon_exp') }}
    </div> -->

    <div class="mt-2 flex flex-col items-center px-2" v-if="!selected || replacing">
        <div class="mt-2 font-semibold uppercase text-sm mb-1 text-center">{{ $t('filter') }}</div>

        <div class="flex flex-wrap mb-4 flex-col sm:flex-row">
            <div class="my-1 sm:my-0 sm:mx-1 flex items-center justify-center space-x-[0.2rem]" v-if="!this.replacing">
                <button @click="filter('rarity', i+2)" v-for="i in 3" :key="`filter-tier-${i+2}`" :class="rarityFilter === i+2 ? 'bg-yellow-500' : 'bg-gray-600'" class="flex w-8 sm:w-10 h-8 sm:h-10 rounded-xl items-center justify-center overflow-hidden">
                    <star-icon class="w-7" :class="{ 'text-rare-550': i === 1, 'text-epic-550': i === 2, 'text-legendary-550': i === 3 }"/>
                </button>
            </div>
            <div class="my-1 sm:my-0 sm:mx-1 flex items-center space-x-1" v-if="decoupled && (!this.replacing || !this.weapon?.type)">
                <button @click="filter('type', weaponType)" v-for="weaponType in weaponTypes" :key="`weapon-type-${weaponType}`" :class="typeFilter === weaponType ? 'bg-yellow-500' : 'bg-gray-600'" class="w-8 sm:w-10 h-8 sm:h-10 rounded-xl overflow-hidden">
                    <img :src="`${spaces}/weapons/_${weaponType}.png`">
                </button>
            </div>
        </div>

        <div class="flex flex-wrap items-start justify-center mt-2 -m-2 gap-x-2 gap-y-2">
            <div v-if="!filteredWeapons.length">
                {{ $t('no_weapons') }}
            </div>
            <template v-else>
                <button class="mx-1 mb-3 relative" v-if="decoupled && !replacing" @click="$emit('update:selected-decoupled', 'custom')">
                    <div class="w-16 sm:w-20 rounded-xl overflow-hidden relative bg-gradient-to-tl shadow p-4" :class="darkTierClass[0]">
                        <img :src="`${url}img/other/question-mark.svg`" class="">
                    </div>
                    <div class="flex items-center justify-center text-xs text-center font-semibold tracking-tighter sm:tracking-tight w-16 sm:w-20 leading-none sm:leading-tight mt-1">
                        {{ $t('custom_a') }}
                    </div>
                </button>
                <button
                    v-for="w in filteredWeapons"
                    :key="`add-${w.key}`"
                    class="relative"
                    @click="selected = w.key"
                >
                    <div class="w-16 sm:w-20 h-16 sm:h-20 rounded-xl overflow-hidden relative bg-gradient-to-tl shadow flex items-center justify-center"
                        :class="darkTierClass[w.tier]"
                    >
                        <img :src="`${spaces}/weapons/${w.key}.png`" class="h-full object-contain">
                    </div>
                    <div class="bg-orange-700 absolute-tag" v-if="isFuture(w.release)">{{ $t('soon')}}</div>
                    <div class="bg-green-700 absolute-tag" v-if="isNew(w.release)">{{ $t('new_a')}}</div>
                    <div class="flex items-center justify-center text-xs text-center font-semibold tracking-tighter sm:tracking-tight w-16 sm:w-20 leading-none sm:leading-tight mt-1">{{ w.name }}</div>
                </button>
            </template>
        </div>
    </div>

    <div class="mt-4" v-else>
        <div class="flex items-center justify-center" v-if="!decoupled">
            <div class="w-8 h-8 rounded-lg overflow-hidden relative bg-gradient-to-tl mr-2 flex-shrink-0 flex justify-center"
                :class="darkTierClass[weapon.tier]"
            >
                <img :src="`${spaces}/weapons/${selected}.png`" class="h-full object-contain">
            </div>
            <p class="uppercase sm:text-center text-xs sm:text-base font-semibold tracking-tight leading-none">{{ $t(selected) }}</p>
            <button class="ml-2 uppercase text-sm font-semibold bg-gray-700 rounded px-1 tracking-tight" @click="remove" v-if="!decoupled">{{ $t('remove') }}</button>
        </div>

        <div class="mt-3 flex items-center justify-center leading-none">
            <button @click="e => $refs.weaponCurrent.toggle(e)" class="bg-gray-500 rounded py-3 px-2 sm:w-32 flex items-center justify-center" :class="{ 'font-semibold': current }">
                <template v-if="current">
                    {{ current.level }}
                    <img :src="`${url}img/general/ascension.png`" class="w-4 ml-2" :class="{ 'opacity-25': ascended[current.level] !== current.asc }"/>
                </template>
                <template v-else>{{ $t('current_level') }}</template>
            </button>
            <template v-if="!current || current.level < 90">
                <chevron-double-right-icon class="w-5 mx-2"/>
                <button @click="e => $refs.weaponGoal.toggle(e)" class="bg-gray-500 rounded py-3 px-2 sm:w-32 flex items-center justify-center" :class="{ 'font-semibold': goal }">
                    <template v-if="goal">
                        {{ goal.level }}
                        <img :src="`${url}img/general/ascension.png`" class="w-4 ml-2" :class="{ 'opacity-25': ascended[goal.level] !== goal.asc }"/>
                    </template>
                    <template v-else>{{ $t('goal_level') }}</template>
                </button>
            </template>

            <OverlayPanel ref="weaponCurrent" appendTo="body" style="width: 235px">
                <div class="-m-1 flex items-center justify-center flex-wrap">
                    <button v-for="(option, key) in levelOptions" :key="`current-${key}`"
                        class="m-1 rounded-full w-10 h-10 leading-none text-smm font-semibold flex items-center justify-center flex-col"
                        :class="current && current.level === option.level && current.asc === option.asc ? 'bg-blue-500' : 'bg-gray-500'"
                        @click="selectedWeaponCurrent(option)"
                    >
                        {{ option.level }}
                        <img :src="`${url}img/general/ascension.png`" class="w-4" :class="{ 'opacity-25': ascended[option.level] !== option.asc }"/>
                    </button>
                </div>
            </OverlayPanel>
            <OverlayPanel ref="weaponGoal" appendTo="body" style="width: 235px">
                <div class="-m-1 flex items-center justify-center flex-wrap">
                    <button v-for="(option, key) in levelOptions.filter(o => !current || o.level > current.level || o.asc > current.asc || (current.level === o.level && current.asc === o.asc))" :key="`current-${key}`"
                        class="m-1 rounded-full w-10 h-10 leading-none text-smm font-semibold flex items-center justify-center flex-col"
                        :class="goal && goal.level === option.level && goal.asc === option.asc ? 'bg-blue-500' : 'bg-gray-500'"
                        @click="selectedWeaponGoal(option)"
                    >
                        {{ option.level }}
                        <img :src="`${url}img/general/ascension.png`" class="w-4" :class="{ 'opacity-25': ascended[option.level] !== option.asc }"/>
                    </button>
                </div>
            </OverlayPanel>
        </div>

        <individual-costs
            @done="done"
            :subject="weaponGoal?.id"
            :costs="Object.values(weaponCosts(this.weaponGoal))"
            :is-inactive="inactive[selectedCharacter || weaponGoal.id] || inactive[weaponGoal.character]"
            :is-weapon="!selectedCharacter"
            v-if="weaponGoal && JSON.stringify(current) !== JSON.stringify(goal)"
        />
    </div>
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel'
import IndividualCosts from './IndividualCosts'

export default {
    emits: ['update:selected-decoupled', 'update:editing-goal'],

    components: { OverlayPanel, IndividualCosts },

    props: {
        editingGoal: Object,
        selectedDecoupled: String,
        selectedCharacter: String,
        decoupled: { type: Boolean, default: false },
        replacing: Boolean
    },

    data () {
        return {
            ascended: { 20: 1, 40: 2, 50: 3, 60: 4, 70: 5, 80: 6 },
            levelOptions: [
                { level: 1, asc: 0, text: '1' },
                { level: 20, asc: 0, text: '20' }, { level: 20, asc: 1, text: '20 A' },
                { level: 40, asc: 1, text: '40' }, { level: 40, asc: 2, text: '40 A' },
                { level: 50, asc: 2, text: '50' }, { level: 50, asc: 3, text: '50 A' },
                { level: 60, asc: 3, text: '60' }, { level: 60, asc: 4, text: '60 A' },
                { level: 70, asc: 4, text: '70' }, { level: 70, asc: 5, text: '70 A' },
                { level: 80, asc: 5, text: '80' }, { level: 80, asc: 6, text: '80 A' },
                { level: 90, asc: 6, text: '90' },
            ],
            selected: this.editingGoal?.weapon || null,
            // addedGoal: null,
            typeFilter: null,
            rarityFilter: null
        }
    },

    computed: {
        current () {
            return this.weaponGoal?.current
        },

        goal () {
            return this.weaponGoal?.goal
        },

        character () {
            let character = this.selectedCharacter || this.weaponGoal?.character

            return this.characters[character] || this.customs[character]
        },

        weapon () {
            return this.weapons[this.selected] || this.customs[this.selected]
        },

        weaponGoal () {
            return this.editingGoal ||
                this.goals.find(g => this.selectedCharacter && g.character === this.selectedCharacter && g.type === 'weapon')
        },

        filteredWeapons () {
            return Object.keys(this.weapons)
                .filter(w => {
                    return (!this.rarityFilter || this.weapons[w].tier === this.rarityFilter) &&
                        (!this.typeFilter || this.weapons[w].type === this.typeFilter) &&
                        (!this.replacing || !this.weapon?.type || this.weapons[w].type == this.weapon?.type) &&
                        (!this.replacing || this.weapons[w].tier == this.weapon?.tier) &&
                        (!this.character?.weapon || this.weapons[w].type === this.character.weapon)
                        && !this.isFuture(this.weapons[w].show)
                })
                .map(w => ({ key: w, name: this.$t(w), ...this.weapons[w] }))
                .sort((a, b) => a.name.localeCompare(b.name))
        },
    },

    watch: {
        weaponGoal: {
            immediate: true,
            handler () {
                this.selected = this.weaponGoal?.weapon
            }
        },

        selected () {
            if (this.decoupled) {
                this.$emit('update:selected-decoupled', this.selected)
            }
        },
        selectedDecoupled () {
            this.selected = this.selectedDecoupled
        }
    },

    methods: {
        filter (type, value) {
            if (this[`${type}Filter`] === value) return this[`${type}Filter`] = null
            return this[`${type}Filter`] = value
        },

        selectedWeaponCurrent (option) {
            this.$refs.weaponCurrent.hide()

            let current = option
            let goal = this.goal

            if (!this.goal) goal = option
            if (this.goal && (this.goal.level < option.level || this.goal.asc < option.asc)) goal = option

            this.addOrUpdateWeapon(current, goal)
        },

        selectedWeaponGoal (option) {
            this.$refs.weaponGoal.hide()

            let goal = option
            let current = this.current

            if (!this.current) current = this.levelOptions[0]

            this.addOrUpdateWeapon(current, goal)
        },

        addOrUpdateWeapon (current, goal) {
            let newGoal = this.addGoal({
                type: 'weapon',
                id: this.weaponGoal?.id,
                character: this.selectedCharacter || this.weaponGoal?.character,
                weapon: this.selected,
                current, goal
            })
            this.$emit('update:editing-goal', newGoal)
        },

        remove () {
            if (this.weaponGoal) this.removeGoal(this.weaponGoal)
            this.selected = null
            // this.current = null
            // this.goal = null
        },

        done () {
            this.addOrUpdateWeapon(this.goal, this.goal)
        }
    }
}
</script>
