<template>
    <div class="bg-blue-800/75 py-3 pl-4 pr-3 rounded-md overflow-hidden">
        <div class="flex md:items-center md:flex-row" :class="resinTime && 'flex-col'">
            <div class="flex items-center">
                <img :src="`${spaces}/items/special/resin.png`" class="w-10"/>
                <div class="flex items-center h-8 ml-1" v-if="resinTime">
                    <input
                        v-if="editing"
                        class="bg-gray-600 text-lg rounded-md py-px px-0 w-9 text-center font-semibold focus:outline-none border-none"
                        autofocus type="number" ref="input" min="0"
                        v-model="roundedResin"
                        @focus="$event.target.select()"
                        @blur="editingBlur"
                    />
                    <span class="text-lg font-bold cursor-pointer inline-block text-center w-9" @click="editingFocus" v-else>{{ roundedResin }}</span>
                    <span class="text-sm ml-1 text-blue-200"><span class="text-xs">/</span> {{ resinCap }}</span>
                </div>
            </div>
            <div class="flex items-center md:ml-4 mt-2 md:mt-0" v-if="resinTime">
                <div class="flex overflow-hidden rounded-md border-white text-[0.84rem] leading-none">
                    <template v-for="value in [-20, -30, -40, -60, 60]" :key="`reduce-${value}`">
                        <button
                            :disabled="computedResin+1 < value*-1"
                            class="
                                font-semibold border-r border-white/10 px-[0.35rem] w-full h-7
                                last:border-none disabled:pointer-events-none
                                disabled:opacity-50 transition-all
                            "
                            :class="value > 0 ? 'bg-green-500/40 hover:bg-green-500/70' : 'bg-red-500/40 hover:bg-red-500/70'"
                            @click="addResin(value)"
                        >{{ (value > 0 && '+') || '' }}{{ value }}</button>
                    </template>
                </div>
                <resin-tracker-settings/>
                <button
                    class="ml-2 bg-red-500/60 px-1 h-7 rounded-md hover:bg-red-500 transition-all"
                    @click="remove"
                    v-tippy.arrow.down="$t('delete_resin_tracker')"
                    v-if="resinTime"
                >
                    <x-icon class="w-5 h-5"/>
                </button>
            </div>
            <button
                class="ml-2 bg-gray-500/60 px-3 h-8 rounded-md hover:bg-gray-600 transition-all text-sm font-semibold shadow"
                @click="setup"
                v-if="!resinTime"
            >
                {{ $t('start_resin_tracker') }}
            </button>
        </div>
        <div class="mt-2 text-[0.82rem] leading-snug text-blue-200" v-if="resinTime">
            <template v-if="computedResin < resinCap">
                <template v-for="value in Object.keys(times)" :key="`value-${value}`">
                    <div v-if="value > computedResin">
                        <strong>{{value}}</strong> {{ $t('in') }} {{ getIn(value) }} ({{ getTime(value) }})
                    </div>
                </template>
            </template>
            <div v-else>
                {{ $t('resins_full') }}
            </div>
        </div>
        <div class="text-base mt-2 flex items-center bg-blue-600/80 -mx-4 py-2 pr-4 pl-3 -mb-1">
            <flag-icon class="w-5 mr-1"/>
            <template v-if="estimation">
                <span class="font-bold mr-1">{{ estimation }}</span>
                <span class="lowercase text-sm">• {{ Math.ceil(estimation / 180) }} {{ $tc('day', Math.ceil(estimation / 180)) }}</span>
            </template>
            <div v-else class="text-sm font-semibold">
                {{ $t('no_resin_needed') }}
            </div>
            <question-mark-circle-icon class="w-4 ml-1" v-tippy.arrow="`<p class='text-center text-sm'>${$t('to_get_all_obtainable_items')}</p>`"/>
        </div>
    </div>
</template>

<script>
import ResinTrackerSettings from './ResinTrackerSettings.vue'

export default {
    components: { ResinTrackerSettings },

    props: ['estimation'],

    data () {
        return {
            editing: false,
            times: { 20:null,40:null,60:null,80:null,100:null,120:null,140:null,160:null,180:null,200:null }
        }
    },

    computed: {
        roundedResin: {
            get () {
                return Math.floor(this.computedResin)
            },
            set (v) {
                this.setResin(Number(v))
            }
        }
    },

    watch: {
        // resinTime: {
        //     immediate: true,
        //     handler (time) {
        //         // if (!time) return
        //         // Object.keys(this.times).forEach(value => {
        //         //     let diff = value - this.resinAmount
        //         //     let seconds = diff * this.resinInterval

        //         //     this.times[value] = dayjs(time).add(seconds, 'seconds')
        //         //         .format(this.settings.hour_format == 24 ? 'HH:mm' : 'hh:mm A')
        //         // })
        //     }
        // }
    },

    methods: {
        setResin (resin) {
            if (!resin || resin <= 0) resin = 0

            this.accountResin = {
                amount: Math.floor(resin*100)/100,
                time: this.dayjs().toString()
            }
        },

        addResin (value) {
            this.setResin(this.computedResin + value)
        },

        editingFocus () {
            // this.resin = this.computedResin
            this.editing = true
            this.$nextTick(() => this.$refs?.input?.focus())
        },

        editingBlur () {
            // this.setResin(this.resin)
            this.editing = false
        },

        getIn (value) {
            let seconds = Math.floor((value - this.computedResin) * this.resinInterval)

            let minutes = seconds / 60
            let minutesRound = Math.floor(minutes)
            // let leftoverSeconds = Math.round((minutes - minutesRound) * 60)

            let hours = minutesRound / 60
            let hoursRound = Math.floor(hours)
            let leftoverMinutes = Math.round((hours - hoursRound) * 60)

            return `${hoursRound}h${leftoverMinutes < 10 ? 0 : ''}${leftoverMinutes}`
        },

        getTime (value) {
            if (!this.resinTime) return

            let diff = value - this.resinAmount
            let seconds = diff * this.resinInterval

            return this.dayjs(this.resinTime).add(seconds, 'seconds')
                .format(this.settings.hour_format == 24 ? 'HH:mm' : 'hh:mm A')
        },

        updateTimer (time) {
            if (!time) return
            Object.keys(this.times).forEach(value => {
                let diff = value - this.resinAmount
                let seconds = diff * this.resinInterval

                this.times[value] = this.dayjs(time).add(seconds, 'seconds')
                    .format(this.settings.hour_format == 24 ? 'HH:mm' : 'hh:mm A')
            })
        },

        setup () {
            if (Notification.permission !== 'granted' && this.settings.power_notification) Notification.requestPermission()
            this.setResin(0)
            this.editing = true
            this.$nextTick(() => this.$refs?.input?.focus())
        },

        remove () {
            this.accountResin = {
                amount: 0,
                time: null
            }
        }
    }
}
</script>
