export default {
	parametric_transformer:	{ days: 7 },
	stormterror: 			{ days: 7, en: 'Mondstat - Stormterror\'s Lair', pt: 'Mondstat - Toca do Stormterror' },
	wolf_of_the_north: 		{ days: 7, en: 'Mondstat - Wolvendom', pt: 'Mondstat - Reino dos Lobos' },
	golden_house: 			{ days: 7, en: 'Liyue - Mt. Tianheng', pt: 'Liyue - Monte Tianheng' },
	azhdaha: 				{ days: 7, en: 'Liyue - Nantianmen', pt: 'Liyue - Nantianmen' },
	la_signora: 			{ days: 7, en: 'Inazuma', pt: 'Inazuma' },
	narukami_boss: 			{ days: 7, en: 'Inazuma', pt: 'Inazuma' },
	comissions: 			{ days: 1 },
	mondstat_general_goods: { days: 1, en: 'Mondstat', pt: 'Mondstat' },
	second_life: 			{ days: 1, en: 'Liyue', pt: 'Liyue' },
	good_hunter: 			{ days: 7, en: 'Mondstat (Restaurant)', pt: 'Mondstat (Restaurante)' },
	wanmin_restaurant: 		{ days: 1, en: 'Liyue (Restaurant)', pt: 'Liyue (Restaurante)' },
	floral_whisper: 		{ days: 3, en: 'Mondstat (Flora)', pt: 'Mondstat (Flora)' },
	chloris: 				{ days: 3, en: 'Mondstat - Windrise', pt: 'Mondstat - Origem do Vento' },
	ms_bai: 				{ days: 3, en: 'Liyue - Qingce Village', pt: 'Liyue - Vila Qingce' },
	verr_goldet: 			{ days: 2, en: 'Liyue - Wangshu Inn', pt: 'Liyue - Albergue Wangshu' },
	bubu_pharmacy: 			{ days: 3, en: 'Liyue', pt: 'Liyue' },
	jade_mystery: 			{ days: 3, en: 'Liyue', pt: 'Liyue' },
	draff: 					{ days: 1, en: 'Mondstat - Springvale', pt: 'Mondstat - Vale Termal' },
	sun_and_gao: 			{ days: 1, en: 'Liyue', pt: 'Liyue' },
	shiliu: 				{ days: 7, en: 'Mondstat', pt: 'Mondstat' },
	xigu_antiques: 			{ days: 7, en: 'Liyue (opens at 22:30)', pt: 'Liyue (abre às 22:30)' },
	zhang_shun: 			{ days: 7, en: 'Liyue', pt: 'Liyue' },
}
